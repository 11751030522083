import React from "react";
import Register from "./register.jsx";

function ExecutiveCommittee(){

    return (
      <Register
      />
    );
  }

export default ExecutiveCommittee;