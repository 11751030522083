import React, { Component } from "react";
import {Footer as FooterJsx } from "./footer.jsx";
import footerJson from "./footer.json";

export default class Footer extends Component {

    constructor(props) {

        super(props);

    };

    render() {

        let cardData = footerJson;
        return (
            <FooterJsx
                footerData={cardData}
            />

        );

    }



}