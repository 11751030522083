import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import DatePicker3 from "react-modern-calendar-datepicker";
import pdf from '../../assets/images/path.png';
import { Pagination } from '../admin/pagination';
import { baseURL, getProperDateMonth, uploadURL } from "../../constants/applicationConstants";

export const UploadPDFJsx = (props) => {
    const { data, onPaginationClick, getListData } = props;

    const [formData, setFormData] = useState({
        createdDate: null,
        name: '',
        localName: '',
        documnetType: '',
        isCurrent: true,
        fileName: ''
    });

    const [fileName, setFileName] = useState({
        fileName: ''
    })
    const [pdfData, setPdfData] = useState("")

    const [getPDFDataByRadioClick, setGetPDFDataByRadioClick] = useState(true);

    useEffect(() => {
        isFromSubmissionBlock()
    })

    const [formError, setFormError] = useState({})
    const [formSubmitButtonDisable, setFormSubmitButtonDisable] = useState(true)
    let [isProcessing, setProcessing] = useState(false);
    // let [loading, setLoading] = useState(false);
    const [editID, setEditID] = useState(null);

    const onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value) {
            if (!inputRegexValidation(name, value)) {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "regx" })
            } else {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "" })
            }
        } else {
            setFormData({ ...formData, [name]: '' })
            setFormError({})
        }
    };

    const inputRegexValidation = () => {
        return true;
    };

    const onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            setFormError({ ...formError, [name]: "req" })
        } else {
            if (!inputRegexValidation(name, value)) {
                setFormError({ ...formError, [name]: "regx" })
            }
        }
    }

    const isFromSubmissionBlock = () => {
        let { createdDate, name, localName, documnetType } = formData;
        if (name && createdDate?.day && localName && documnetType && fileName?.fileName) {
            if (
                name &&
                localName &&
                documnetType && fileName?.fileName && createdDate?.day
            ) {
                setFormSubmitButtonDisable(false)
            } else {
                setFormSubmitButtonDisable(true)
            }
        } else {
            setFormSubmitButtonDisable(true)
        }
    };

    const validFileName = (name) => name.includes('.pdf');

    const onFileChange = (e) => {
        const name = e.target.name;
        if (e.target.files[0]?.size) {
            if (e.target.files[0]?.size < 2097152) {
                if (e.target.files[0] && validFileName(e.target.files[0].name)) {
                    setFormData({ ...formData, [name]: e.target.files[0] })
                    setFileName({ ...fileName, [name]: e.target.files[0].name })
                }
                else {
                    toast.error('.PDF format required')
                }
            }
            else {
                toast.error('File should be less than 2mb')
            }
        }
    }

    const formatInputValue = (date) => {
        if (!date) return '';
        return `${date.day}/${date.month}/${date.year}`;
    };

    const onResetButtonClick = () => {
        setFormData({
            createdDate: null,
            name: '',
            localName: '',
            documnetType: '',
            isCurrent: true,
            fileName: ''
        })
        setFormError('');
        setFileName({
            fileName: ''
        })
        setEditID(null);
    }

    const formSubmitHandler = (e) => {
        e.preventDefault();
        // setLoading(true)
        setProcessing(true);

        if (editID) {
            let formData1 = new FormData();
            var theinput = document.getElementById('pdfMain')

            formData.fileName && formData1.append('document', theinput.files[0])
            formData1.append('name', formData.name)
            formData1.append('local_language_name', formData.localName)
            formData1.append('document_type', formData.documnetType)
            formData1.append('is_current', formData.isCurrent)
            formData1.append('uploaded_date', formData.createdDate && `${getProperDateMonth(formData.createdDate?.day)}/${getProperDateMonth(formData.createdDate?.month)}/${formData.createdDate.year}`)

            axios.put(`${uploadURL}pdfDocument/${editID}`, formData1, {
                headers: {
                    'access-token': JSON.parse(sessionStorage.getItem('authToken')),
                    'role': sessionStorage.getItem('role')
                }
            }).then(resp => {
                // setLoading(false);
                toast.success("PDF Updated Successfully!")
                setProcessing(false);
                onResetButtonClick();
                getListData();
            }).catch((err) => {
                toast.error(err?.response?.data.message)
                // setLoading(false);
                setProcessing(false);
            })
        } else {
            let formData1 = new FormData();
            var theinput = document.getElementById('pdfMain')

            formData1.append('document', theinput.files[0])
            formData1.append('name', formData.name)
            formData1.append('local_language_name', formData.localName)
            formData1.append('document_type', formData.documnetType)
            formData1.append('is_current', formData.isCurrent)
            formData1.append('uploaded_date', formData.createdDate && `${getProperDateMonth(formData.createdDate?.day)}/${getProperDateMonth(formData.createdDate?.month)}/${formData.createdDate.year}`)

            axios.post(`${uploadURL}pdfDocument`, formData1, {
                headers: {
                    'access-token': JSON.parse(sessionStorage.getItem('authToken')),
                    'role': sessionStorage.getItem('role')
                }
            }).then(resp => {
                // setLoading(false);
                if (resp.data.status === 'success') {
                    toast.success("PDF Added Successfully!")
                    setProcessing(false);
                    onResetButtonClick();
                    getListData();
                } else {
                    toast.error("Something wrong!")
                    setProcessing(false);
                }
            }).catch((err) => {
                toast.error(err?.response?.data.message)
                // setLoading(false);
                setProcessing(false);
            })
        }



    }


    function downloadPDF(binaryData) {
        // Convert the binary data to a Blob
        const blob = new Blob([binaryData], { type: 'application/pdf' });

        // Create a URL for the Blob
        const blobUrl = URL.createObjectURL(blob);

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'file.pdf'; // Set the desired file name

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
    }

    // download pdf binary form
    const getPdf = (data) => {
        axios.get(`${uploadURL}pdfDocument/${data.id}/file`, {
            responseType: 'arraybuffer',
            headers: {
                'access-token': JSON.parse(sessionStorage.getItem('authToken'))
            }
        }).then(res => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            window.open(URL.createObjectURL(blob));

        })
    };

    const onDeleteClick = (data) => {
        axios.delete(`${baseURL}pdfDocument/${data.id}`, {
            headers: {
                'access-token': JSON.parse(sessionStorage.getItem('authToken')),
                'role': sessionStorage.getItem('role')
            }
        }).then(res => {
            toast.success('Record Deleted Succesfully')
            getListData();
            onResetButtonClick();
        }).catch(() => {
            toast.error('Something went ')
        })
    }

    const onEditClick = (data) => {
        setEditID(data.id);
        const date = data?.uploaded_date.split('-');
        setFormData({
            createdDate: {
                day: parseInt(date[2]),
                month: parseInt(date[1]),
                year: parseInt(date[0])
            },
            name: data?.name,
            localName: data?.local_language_name,
            documnetType: data?.document_type,
            isCurrent: data?.is_current
        })
        setFileName({
            fileName: data?.file_path
        })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    const { t } = useTranslation();

    return (
        <div className="container officeStaffComponentOuter">
            <div className="bread_crums mt-3"><Link to="/">{t("FAQ.HOME")}</Link>/ <Link to="/uploadPDF">{t("UPLOADPDF.TITLE")}</Link></div><span className="print" onClick={() => window.print()}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="skyBlue" class="bi bi-printer-fill" viewBox="0 0 16 16">
                <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
            </svg> &nbsp; {t("PRINT.TITLE")}</span>
            <h2 className="loginHeading mt-2">
                {t("UPLOADPDF.TITLE")}
            </h2>
            <hr />
            <div className="input" >


                <InputGroup className="row" >
                    <InputGroup.Text id="basic-addon3" className="col-sm-3 mb-3">
                        {t("UPLOADPDF.CREATEDDATE")}<span className="star_highlight">*</span>
                    </InputGroup.Text>
                    <div className="col-sm-9" style={{ padding: '0px' }}>
                        <DatePicker3 className="form-control" name="createdDate"
                            value={(formData) ? formData.createdDate : null}
                            onChange={(date) => { setFormData({ ...formData, createdDate: date }) }}
                            inputPlaceholder="dd/mm/yyyy"
                            shouldHighlightWeekends
                            formatInputText={() => formatInputValue(formData && formData.createdDate)}
                        />
                    </div>
                </InputGroup>
                <InputGroup className="col-md-12"  >
                    <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                        {t("UPLOADPDF.NAME")}<span className="star_highlight">*</span>
                    </InputGroup.Text>
                    <FormControl id="basic-url" name='name' onBlur={onTouchHandler} aria-describedby="basic-addon3" value={formData.name} onChange={onInputChange} placeholder={t("UPLOADPDF.NAME_PLACEHOLDER")} />
                </InputGroup>
                <span className="text-danger">
                    {
                        formError['name'] &&
                        (formError['name'] === "req"
                            ? 'Name is required'
                            : 'Enter a valid Name')}
                </span>

                <InputGroup className="col-md-12"  >
                    <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                        {t("UPLOADPDF.LOCALNAME")}<span className="star_highlight">*</span>
                    </InputGroup.Text>
                    <FormControl id="basic-url" name='localName' onBlur={onTouchHandler} aria-describedby="basic-addon3" value={formData.localName} onChange={onInputChange} placeholder={t("UPLOADPDF.LOCALNAME_PLACEHOLDER")} />
                </InputGroup>
                <span className="text-danger">
                    {
                        formError['localName'] &&
                        (formError['localName'] === "req"
                            ? 'Name in Local Language is required'
                            : 'Enter a valid Name in Local Language')}
                </span>

                <InputGroup className="col-md-12" >
                    <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                        {t("UPLOADPDF.DOCUMENTTYPE")}<span className="star_highlight">*</span>
                    </InputGroup.Text>
                    <select name="documnetType"
                        // className={
                        //     formError['documnetType'] &&
                        //     (formError['documnetType'] === "req"
                        //         ? "form-control  is-invalid"
                        //         : "form-control")}

                        value={(formData) ? formData.documnetType : null}
                        onChange={(e) => { setFormData({ ...formData, documnetType: e.target.value }); onInputChange(e) }}
                        className="form-select customize_select" aria-label="Default select example">
                        <option value='' selected>{t("UPLOADPDF.SELECT")}</option>
                        <option value="GR">GR</option>
                        <option value="Laws And Acts">Laws And Acts</option>
                        <option value="Circular">Circular</option>
                        <option value="Notice">Notice</option>
                    </select>
                </InputGroup>

                <InputGroup className="col-md-12 upload-image" >
                    <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                        {t("UPLOADPDF.PATH")}<span className="star_highlight">*</span>
                    </InputGroup.Text>
                    <FormControl />
                    <Form.Control
                        name='fileName' onBlur={onTouchHandler} aria-describedby="basic-addon3" onChange={onFileChange}
                        className="select_image" id="pdfMain" type="file" style={{ color: 'transparent' }} />
                    <label className='selected-file-name' name={fileName.fileName} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileName.fileName}</label>
                </InputGroup>

                <div class="row">
                    <div class="col-sm-12 text-center">
                        <div class="form-check form-check-inline mb-3" id="basic-url">
                            <label class="form-check-label ml-3" for="inlineRadio2"> {t("UPLOADPDF.CURRENT")}</label>
                            <input name="isHavingProvisionalNumber"
                                onBlur={onTouchHandler}
                                checked={formData.isCurrent}
                                onChange={() => { setFormData({ ...formData, isCurrent: true }) }}
                                class="form-check-input ml-3"
                                type="radio" id="inlineRadio2" value={t("UPLOADPDF.CURRENT")} />
                        </div>
                        <div class="form-check form-check-inline mb-3" id="basic-url">
                            <label class="form-check-label ml-3" for="inlineRadio2"> {t("UPLOADPDF.ARCHIVE")}</label>
                            <input name="isHavingProvisionalNumber"
                                onBlur={onTouchHandler}
                                checked={!formData.isCurrent}
                                onChange={() => { setFormData({ ...formData, isCurrent: false }) }}
                                class="form-check-input ml-3"
                                type="radio" id="inlineRadio2" value={t("UPLOADPDF.ARCHIVE")} />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 text-center mb-5 mt-3">
                        <button id="btnSearch" class="btn center-block mr-4" onClick={() => onResetButtonClick()} >{t("REGISTER.RESET")}</button>
                        <button id="btnClear" disabled={formSubmitButtonDisable} onClick={formSubmitHandler} class="btn center-block">{!!isProcessing ? t("REGISTER.PROCESSING") : t("REGISTER.SUBMIT")}</button>
                    </div>
                </div>
            </div>

            <div className='input'>
                <InputGroup className="col-md-12"  >
                    <InputGroup.Text id="basic-addon3" className="col-md-12 mb-3">
                        {t("UPLOADPDF.GETDATA")}
                        <div style={{ marginTop: '15px' }}>
                            <div class="form-check form-check-inline mb-3" id="basic-url">
                                <label class="form-check-label ml-3" for="inlineRadio3"> {t("UPLOADPDF.CURRENT")}</label>
                                <input
                                    onBlur={onTouchHandler}
                                    checked={getPDFDataByRadioClick}
                                    onChange={() => setGetPDFDataByRadioClick(true)}
                                    class="form-check-input ml-3"
                                    type="radio" name="inlineRadioOptions2" id="inlineRadio3" value={t("UPLOADPDF.CURRENT")} />
                            </div>
                            <div class="form-check form-check-inline mb-3" id="basic-url">
                                <label class="form-check-label ml-3" for="inlineRadio4"> {t("UPLOADPDF.ARCHIVE")}</label>
                                <input
                                    onBlur={onTouchHandler}
                                    checked={!getPDFDataByRadioClick}
                                    onChange={() => setGetPDFDataByRadioClick(false)}
                                    class="form-check-input ml-3"
                                    type="radio" name="inlineRadioOptions2" id="inlineRadio4" value={t("UPLOADPDF.ARCHIVE")} />
                            </div>
                        </div>
                    </InputGroup.Text>
                </InputGroup>
            </div>

            <div className="scroll_table" style={{ marginBottom: '20px' }}>
                <table>
                    <thead style={{ background: 'white' }}>
                        <th>{t("UPLOADPDF.DOCUMENTNAME")}</th>
                        <th>{t("UPLOADPDF.DOCUMENTNAMELL")}</th>
                        <th> {t("UPLOADPDF.DATE")}</th>
                        <th> {t("UPLOADPDF.DOCUMENTPATH")}</th>
                        <th> {t("UPLOADPDF.DELETE")}</th>
                        <th> {t("UPLOADPDF.EDIT")}</th>
                    </thead>
                    <tbody>

                        {
                            data?.rows?.map(data => {
                                return (
                                    <tr>
                                        <td>{data.name}</td>
                                        <td>{data.local_language_name}</td>
                                        <td>{data.uploaded_date}</td>

                                        <td style={{ cursor: 'pointer', color: 'blue' }} onClick={() => getPdf(data)}><img src={pdf} height="25px" width="25px" alt="" /></td>
                                        {/* <td><a href={`${uploadURL}pdfDocuments/${data.file_path}`} target="_blank" rel="noreferrer"><img src={pdf} height="25px" width="25px"  alt=""/></a></td> */}
                                        <td style={{ cursor: 'pointer', color: 'blue' }} onClick={() => onDeleteClick(data)}>{t("UPLOADPDF.DELETE")}</td>
                                        <td style={{ cursor: 'pointer', color: 'blue' }} onClick={() => onEditClick(data)}>{t("UPLOADPDF.EDIT")}</td>
                                    </tr>

                                )
                            })
                        }
                        {
                            data?.rows?.length < 1 && <tr><td colSpan={7} style={{ textAlign: 'center', fontWeight: 600 }}>{t("ADMINDASHBOARD.NORECORD")}</td></tr>
                        }
                    </tbody>
                </table>

            </div>
            {
                <Pagination limit={10} totalCount={data?.count ?? 0} onPaginationButtonClick={(page) => onPaginationClick(page)} />
            }

            <ToastContainer />
        </div>
    )
}