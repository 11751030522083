import React from 'react';
import './termAndCondition.css';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function TermAndConditionJsx() {
    const { t } = useTranslation();
    return (
        <>
            <div className="container">
                <div className="bread_crums mt-3"><Link to="/">{t("HEADER.HOME")}</Link>/ <Link to="/termAndCondition">{t("Term And Condition.MAIN_HEAD")}</Link></div>

                <div className="heading">{t("Term And Condition.MAIN_HEAD")}</div>

                <div className="paragraph">{t("Term And Condition.P1")}</div>
            </div>
        </>
    )

}
export default TermAndConditionJsx;