import React, { useState, useEffect } from 'react'
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { baseURL, uploadURL, emailRegex, htmlRegex, nameRegex, phoneRegex, aadharRegex,encrypt } from "../../constants/applicationConstants";
import './grievance.css';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


export const GrievanceForm = () => {

    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        otp: '',
        systemOTP: '',
        andhar_number: '',
        complaint_file: '',
        andhar_photo: '',
        grievance: '',
    });

    const [fileName, setFileName] = useState({
        andhar_number: '',
        complaint_file: ''
    })

    const [formError, setFormError] = useState({})
    const [formSubmitButtonDisable, setFormSubmitButtonDisable] = useState(true)
    let [isProcessing, setProcessing] = useState(false);
    let [loading, setLoading] = useState(false);
    let [disableOTPButton, setOTPButton] = useState(true);
    const [submittingOTP, setSubmittingOTP] = useState(false);
    const [verifyingOTP, setVerifyingOTP] = useState(false);
    const [OTPStatus, setOTPStatus] = useState(null);
    const [emailVerificationStatus, setEmailVerificationStatus] = useState(null);

    useEffect(() => {
        isFromSubmissionBlock()
    })

    const onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value) {
            if (!inputRegexValidation(name, value)) {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "regx" })
            } else {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "" })
            }
        } else {
            setFormData({ ...formData, [name]: '' })
            setFormError({})
        }
    };

    const inputRegexValidation = (name, value) => {
        if (name === "name")
            return nameRegex.test(value)
        else if (name === 'grievance')
            return htmlRegex.test(value)
        else if (name === 'mobile')
            return phoneRegex.test(value);
        else if (name === 'email')
            return emailRegex.test(value);
        else if (name === 'andhar_number')
            return aadharRegex.test(value);
        else
            return true;
    };

    const isFromSubmissionBlock = () => {
        let { name, email, mobile, andhar_number, complaint_file, andhar_photo, grievance } = formData;
        if (name && email && mobile && andhar_number && complaint_file && andhar_photo && grievance && OTPStatus === 'success') {
            if (
                inputRegexValidation("name", name) &&
                inputRegexValidation("email", email) &&
                inputRegexValidation('mobile', mobile) &&
                inputRegexValidation('andhar_number', andhar_number) &&
                complaint_file && andhar_photo && grievance
            ) {
                setFormSubmitButtonDisable(false)
            } else {
                setFormSubmitButtonDisable(true)
            }
        } else {
            setFormSubmitButtonDisable(true)
        }
    };

    const onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            setFormError({ ...formError, [name]: "req" })
        } else {
            if (!inputRegexValidation(name, value)) {
                setFormError({ ...formError, [name]: "regx" })
            }
        }
    }

    const onResetButtonClick = () => {
        setFormData({
            name: '',
            mobile: '',
            email: '',
            otp: '',
            andhar_number: '',
            complaint_file: '',
            andhar_photo: '',
            grievance: ''
        })
        setFormError('');
        setOTPStatus(null);
        setEmailVerificationStatus(null);
        setFileName({
            andhar_number: '',
            complaint_file: '',
        })
    }

    const formSubmitHandler = (e) => {
        e.preventDefault();
        setLoading(true)
        setProcessing(true);
        const reqParams = {
            "name": formData.name,
            "mobile": formData.mobile,
            "email": formData.email,
            "aadhar_card_no": encrypt(formData.andhar_number),
            "complaint_file": formData.complaint_file,
            "aadhar_card_file": formData.andhar_photo,
            "grievance": formData.grievance
        }

        axios.post(`${baseURL}sendGrievance`, reqParams).then(resp => {
            setLoading(false);
            if (resp.data.status === 'success') {
                toast.success("Grievance added successfully!")
                setProcessing(false);
                onResetButtonClick();
            } else {
                toast.error("Something wrong!")
                setProcessing(false);
            }
        }).catch((err) => {
            toast.error(err?.response?.data.message)
            setLoading(false);
            setProcessing(false);
        })

    }

    const onSendOTPButtonClick = () => {
        const payload = {
            email: formData.email
        }
        setSubmittingOTP(true);
        axios.post(`${baseURL}createGrievanceOtp`, payload).then((res) => {
            if (res.data.status === 'success') {
                setOTPButton(false);
                toast.success(res?.data.message)
                setEmailVerificationStatus(res.data)
                setSubmittingOTP(false);
                setFormData({ ...formData, systemOTP: res.otp });
            }else{
                toast.error(res?.data.message)
            }

        }).catch((err) => {
            setSubmittingOTP(false);
            toast.error(err?.response?.data.message)
        })
    }

    const onVerifyOTPButtonClick = () => {
        const payload = {
            email: formData.email,
            otp: formData.otp
        }
        setVerifyingOTP(true);
        axios.post(`${baseURL}verifyGrievanceOtp`, payload).then((res) => {
            setOTPStatus(res.data.status)
            setVerifyingOTP(false);
        })
    }

    const validFileName = (name) => name.includes('.pdf') || name.includes('.jpeg') || name.includes('.jpg')

    const onFileChange = (e) => {
        const name = e.target.name;
        if (e.target.files[0]?.size) {
            if (e.target.files[0]?.size < 2097152) {
                if (e.target.files[0] && validFileName(e.target.files[0].name)) {
                    let formData1 = new FormData();
                    formData1.append('image', e.target.files[0])
                    axios.post(
                        `${uploadURL}uploadfile`,
                        formData1
                    )
                        .then(res => {
                            setFormData({ ...formData, [name]: res.data.profileImageName })
                            setFileName({ ...fileName, [name]: e.target.files[0].name })
                        })
                        .catch(error => {
                        })
                }
                else {
                    toast.error('.JPG/.JPEG/.PDF format required')
                }
            }
            else {
                toast.error('File should be less than 2mb')
            }
        }
    }

    const { t } = useTranslation();

    return (
        <>
            <div className="container officeStaffComponentOuter">
                <div className="bread_crums mt-3"><Link to="/">{t("GRIEVANCE.HOME")}</Link>/ <Link to="/grievance">{t("GRIEVANCE.GRIEVANCE")}</Link></div><span className="print" onClick={() => window.print()}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="skyBlue" class="bi bi-printer-fill" viewBox="0 0 16 16">
                    <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                    <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                </svg> &nbsp; {t("PRINT.TITLE")}</span>
                <h2 className="loginHeading mt-2">
                    {t("GRIEVANCE.GRIEVANCE")}
                </h2>
                <div className="input" >
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("GRIEVANCE.NAME")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl id="basic-url" name='name' onBlur={onTouchHandler} aria-describedby="basic-addon3" value={formData.name} onChange={onInputChange} placeholder={t("GRIEVANCE.NAME_PLACEHOLDER")} />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['name'] &&
                            (formError['name'] === "req"
                                ? 'Name is required'
                                : 'Enter a valid Name')}
                    </span>

                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3  mb-3">
                            {t("GRIEVANCE.MOBILE")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl name='mobile' onBlur={onTouchHandler} value={formData.mobile} onChange={onInputChange} id="basic-url" className="" aria-describedby="basic-addon2" placeholder={t("GRIEVANCE.MOBILE_PLACEHOLDER")} maxLength={10} />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['mobile'] &&
                            (formError['mobile'] === "req"
                                ? 'Mobile Id is required'
                                : 'Enter a valid Mobile No.')}
                    </span>
                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("GRIEVANCE.EMAIL")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl id="basic-url" disabled={OTPStatus === 'success'} name='email' onBlur={onTouchHandler} aria-describedby="basic-addon3" value={formData.email} onChange={onInputChange} placeholder={t("GRIEVANCE.EMAIL_PLACEHOLDER")} />
                        <button className="send_otp" disabled={OTPStatus === 'success'} onClick={() => onSendOTPButtonClick()}>{submittingOTP ? t("GRIEVANCE.SENDING") : t("GRIEVANCE.SENDOTP")}</button>
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['email'] &&
                            (formError['email'] === "req"
                                ? 'Email Id is required'
                                : 'Enter a valid Email Id')}
                    </span>
                    {emailVerificationStatus?.icon === 'success' && <span className='text-success'>{emailVerificationStatus.message}</span>}
                    {emailVerificationStatus?.icon === 'failure' && <span className='text-danger'>{emailVerificationStatus.message}</span>}
                    <InputGroup className="col-md-12">
                        <InputGroup.Text id="basic-addon3" className="col-md-3  mb-3">
                            {t("GRIEVANCE.ENTEROTP")}
                        </InputGroup.Text>
                        <FormControl id="basic-url" className="" aria-describedby="basic-addon1" onBlur={onTouchHandler} name="otp" value={formData.otp} onChange={onInputChange} style={{ marginLeft: '10px' }} disabled={disableOTPButton || OTPStatus === 'success'} placeholder={t("GRIEVANCE.OTP_PLACEHOLDER")} />
                        <button className="send_otp" disabled={OTPStatus === 'success'} onClick={() => onVerifyOTPButtonClick()}>{verifyingOTP ? t("GRIEVANCE.VERIFYING") : t("GRIEVANCE.VERIFYOTP")}</button>
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['otp'] &&
                            (formError['otp'] === "req"
                                ? 'OTP is required'
                                : 'Enter a valid OTP')}
                    </span>
                    {OTPStatus === 'success' && <span className='text-success'>{t("GRIEVANCE.SUCCESSOTP")}</span>}
                    {OTPStatus === 'failure' && <span className="text-danger">{t("GRIEVANCE.FAILEDOTP")}</span>}
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("GRIEVANCE.AADHAR")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl id="basic-url" type="password" autoComplete="off" name='andhar_number' onBlur={onTouchHandler} aria-describedby="basic-addon3" value={formData.andhar_number} onChange={onInputChange} placeholder={t("GRIEVANCE.AADHAR_PLACEHOLDER")} maxLength={12} />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['andhar_number'] &&
                            (formError['andhar_number'] === "req"
                                ? 'Aadhar is required'
                                : 'Enter a valid Aadhar')}
                    </span>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("GRIEVANCE.COMPLAINTFILE")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name='complaint_file' onBlur={onTouchHandler} aria-describedby="basic-addon3" onChange={onFileChange}
                            className="select_image" type="file" style={{ color: 'transparent' }} />
                        <label className='selected-file-name' name={fileName.complaint_file} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileName.complaint_file}</label>
                        <label className='file-name-warning' name={fileName.complaint_file} disabled={true} aria-describedby="basic-addon3">{t("GRIEVANCE.FILEWARNING")}</label>
                    </InputGroup>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("GRIEVANCE.AADHARCARD")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name='andhar_photo' onBlur={onTouchHandler} aria-describedby="basic-addon3" onChange={onFileChange}
                            className="select_image" type="file" style={{ color: 'transparent' }} />
                        <label className='selected-file-name' name={fileName.andhar_photo} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileName.andhar_photo}</label>
                        <label className='file-name-warning' name={fileName.complaint_file} disabled={true} aria-describedby="basic-addon3">{t("GRIEVANCE.FILEWARNING")}</label>
                    </InputGroup>
                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 feedback mb-3">
                            {t("GRIEVANCE.GRIEVANCE")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl className="feedback"  as="textarea" rows={3} id="basic-url" name='grievance' style={{ marginBottom: '20px', resize: 'none' }} onBlur={onTouchHandler} value={formData.grievance} onChange={onInputChange}
                         maxLength={500}  placeholder={t("GRIEVANCE.GRIEVANCE_PLACEHOLDER")} />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['grievance'] &&
                            (formError['grievance'] === "req"
                                ? 'grievance is required'
                                : 'Enter a valid grievance')}
                    </span>
                    <div class="row">
                        <div class="col-sm-12 text-center mb-5 mt-3">
                            <button id="btnSearch" class="btn center-block mr-4" onClick={onResetButtonClick} >{t("REGISTER.RESET")}</button>
                            <button disabled={formSubmitButtonDisable || !!isProcessing} onClick={formSubmitHandler} id="btnClear" class="btn center-block">{!!isProcessing ? t("REGISTER.PROCESSING") : t("REGISTER.SUBMIT")}</button>
                        </div>
                    </div>

                </div>
            </div>
            {(loading) &&
                <div className="loaderPosition">
                </div>
            }
            <ToastContainer />
        </>
    )
}