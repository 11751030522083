import React from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'; import "./bannerData.css";

// import bannerImg_1 from "../../assets/images/banner/bannerImg_1.png";
// import bannerImg_2 from '../../assets/images/banner/bannerImg_2.png';
// import bannerImg_4 from '../../assets/images/banner/bannerImg_4.png';
// import msvc_logo from '../../assets/images/msvc_logo.svg';
import main_banner from '../../assets/images/banner/msvc_banner.png';
import WebBanner1 from '../../assets/images/banner/WebBanner1.png';
import WebBanner2 from '../../assets/images/banner/WebBanner2.png';


const BannerDataJsx = () => {
  // const { t } = useTranslation();
  // const getImage = (imgurl) => {
  //   var img = t(`${imgurl}`)

  //   if (img === "BANNER_1") {
  //     return bannerImg_1;
  //   } else if (img === "BANNER_2") {
  //     return bannerImg_2;
  //   }
  //   else if (img === "BANNER_4") {
  //     return bannerImg_4;
  //   }
  //   else if (img === "MSVC_LOGO") {
  //     return msvc_logo;
  //   } else {
  //     return "";
  //   }
  // }
  return (
    <div className="bannerDataOuterDiv">
      <Carousel autoPlay infiniteLoop interval="5000" emulateTouch="true" swipeable>
        <div>
          <img src={main_banner}  alt=""/>
        </div>
        <div>
          <img src={WebBanner1}  alt=""/>
        </div>
        <div>
          <img src={WebBanner2}  alt=""/>
        </div>
      </Carousel>
    </div>
  );
};

export default BannerDataJsx;

