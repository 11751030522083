import React, { Component } from "react";
import { PhotoGallery as PhotoGalleryJsx } from "./photoGallery.jsx";
import photoGalleryJson from "./photoGallery.json";

class photoGallery extends Component {
    constructor(props) {
        super(props);
    };
    render() {
        let cardData = photoGalleryJson;
        return (
            <div className="gallaryConatiner">
                <PhotoGalleryJsx
                    title={cardData.title}
                    photoGalleryJson={cardData.images}
                />
            </div>

        );
    }
}

export default photoGallery;
