import { React, useEffect } from 'react';
import './systemEnvironment.css';
// import { useTranslation } from "react-i18next";
import JsonData from './data.json';
// import themeContext from '../../context/themeContext';
import greaterThanIcon from "../../assets/images/greaterThanIcon.svg";


function SystemEnvironmentJsx() {
    // const { mode, updateTheme } = useContext(themeContext)

    // const { t } = useTranslation();
    // const [data, setData] = useState([]);
    const getData = () => {
        fetch('data.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                // setData(myJson)
            });
    }
    useEffect(() => {
        getData()
    }, [])

    const DisplayData = JsonData.map(
        (item) => {

            return (
                <tr id="tablerow" >
                    <td>{item.Location}</td>
                    <td>{item.Materials}</td>
                </tr>
            )
        }
    )

    return (
        <>
            <div className="container">
                <div className="row mt-4">
                    <div className="col-sm-3">
                        <div className="DashboardTab">Dashboard</div>
                        <div className="faqComponent mt-4">
                            <div className="dashboardHeadDiv">
                                <h5 className="dashboardQuestion">1.0 Introduction</h5>
                                <button
                                    className="faqButton"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target={`#collapseExample`}
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                >
                                    <span className="show faqArrowIcon" id={`collapseExample`}>
                                        <img src={greaterThanIcon} className="faqIconRotate"  alt=""/>
                                    </span>
                                    <span className="collapse" id={`collapseExample`}>
                                        <img src={greaterThanIcon} className="faqIcon"  alt=""/>
                                    </span>
                                </button>
                            </div>
                            <div className="collapse dashboardAnswer" id={`collapseExample`}>
                                <div className="optionDiv">1.1. Purpose</div>
                                <div className="optionDiv">1.2. Scope of Project</div>
                                <div className="optionDiv">1.3. Glossary</div>
                                <div className="optionDiv">1.4. References</div>
                                <div className="optionDiv">1.5. Overview of Document</div>
                            </div>
                        </div>

                        <div className="faqComponent mt-4">
                            <div className="dashboardHeadDiv">
                                <h5 className="dashboardQuestion">2.0 Overall Description</h5>
                                <button
                                    className="faqButton"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target={`#collapseExample1`}
                                    aria-expanded="false"
                                    aria-controls="collapseExample1"
                                >
                                    <span className="show faqArrowIcon" id={`collapseExample1`}>
                                        <img src={greaterThanIcon} className="faqIconRotate" alt="" />
                                    </span>
                                    <span className="collapse" id={`collapseExample1`}>
                                        <img src={greaterThanIcon} className="faqIcon"  alt=""/>
                                    </span>
                                </button>
                            </div>
                            <div className="collapse dashboardAnswer" id={`collapseExample1`}>
                                <div className="optionDiv">2.1. System Environment </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-9">
                    <div class="p-2 border text-white" style={{ backgroundColor: "#F2C27A" }} >2.1 System Environment</div>
                <table className="table .table-borderless">

                    <tr className="tableHead">
                        <th scope="col">Location</th>
                        <th scope="col">Materials</th>
                    </tr>

                    <tbody>
                        {DisplayData}
                    </tbody>
                </table>
            </div>
            <div id="space-footer"></div>
                    </div>
                </div>
                {/*  */}
            

        </>
    )

}
export default SystemEnvironmentJsx;