import React from 'react';
import { Component } from 'react';
import {AdminDashboard} from './adminDashboard';
import './admin.css';
import {baseURL} from '../../constants/applicationConstants';
import axios from 'axios';

export default class Admin extends Component{

    constructor(props){
        super(props);
        this.state = {
            doctorDetail:null,
            dropDownValue:null,
            searchValue:null
        }
    }

    onDropDownValueChange = (value) => {
        this.setState({dropDownValue:value});
        this.getDoctor(1,value)
    }

    getDoctor = (page,type,value='') =>{
        axios.get(`${baseURL}getDoctors?page=${page}&limit=10&type=${type}&name=${value}`,{
            headers:{
                'access-token':JSON.parse(sessionStorage.getItem('authToken')),
                'role':sessionStorage.getItem('role')
            }
        }).then((res)=>{
            this.setState({doctorDetail:res.data.result});
        })
    }

    onPaginationButtonClick = (page) =>{
        this.getDoctor(page,this.state.dropDownValue);
    }

    onSearchFieldChange = (value) =>{
        this.setState({searchValue:value})
        this.getDoctor(1,this.state.dropDownValue,value);
    }

    render(){
        return(
           <>
            <div className='admin-dashboard'>
                <AdminDashboard 
                doctorDetail={this.state.doctorDetail}
                onDropDownValueChange = {this.onDropDownValueChange}
                onPaginationButtonClick = {this.onPaginationButtonClick}
                onSearchFieldChange={this.onSearchFieldChange}
                />
            </div>
        </>
           
        )
    }
}