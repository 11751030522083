import React, { Component } from "react";
import DuplicateCertificateJsx from './duplicateCertificate.jsx';
import axios from 'axios';
import {baseURL} from '../../constants/applicationConstants';

class DuplicateCertificate extends Component{

  constructor(props){
    super(props);
    this.state={
      details:null
    }
  }

  componentDidMount(){
      // const id= JSON.parse(sessionStorage.getItem('auth'))?.data.master_doctor_id;
      const id= sessionStorage.getItem('masterID')?.trim();
      axios.get(`${baseURL}doctor/${id}`,{
        headers:{
          'access-token':JSON.parse(sessionStorage.getItem('authToken')),
          'role':sessionStorage.getItem('role')
      }
      }).then(res=>{
        this.setState({details:res.data.result})
      })
  }

  render(){
    return (
      <DuplicateCertificateJsx details={this.state.details} />
    );
  }
}

export default DuplicateCertificate;