import React from 'react';
import './photoGallery.css';
import { useTranslation } from "react-i18next";
import g_1 from '../../assets/images/photoGallary/g_1.svg';
import g_2 from '../../assets/images/photoGallary/g_2.svg';
import g_3 from '../../assets/images/photoGallary/g_3.svg';
import g_4 from '../../assets/images/photoGallary/g_4.svg';
import g_5 from '../../assets/images/photoGallary/g_5.svg';
import g_6 from '../../assets/images/photoGallary/g_6.svg';
import g_7 from '../../assets/images/photoGallary/g_7.svg';
import g_8 from '../../assets/images/photoGallary/g_8.svg';
import g_9 from '../../assets/images/photoGallary/g_9.svg';
import g_10 from '../../assets/images/photoGallary/g_10.svg';
import g_11 from '../../assets/images/photoGallary/g_11.svg';
import g_12 from '../../assets/images/photoGallary/g_12.svg';
import { useHistory } from "react-router";


export const PhotoGallery = (props) => {
    const history = useHistory();

    const { t } = useTranslation();
    let { title, photoGalleryJson } = props;
    const imageA = "PHOTOGALLERY.PHOTO_IMAGENAME_1";
    const imageB = "PHOTOGALLERY.PHOTO_IMAGENAME_2";
    const imageC = "PHOTOGALLERY.PHOTO_IMAGENAME_3";
    const imageD = "PHOTOGALLERY.PHOTO_IMAGENAME_4";
    const imageE = "PHOTOGALLERY.PHOTO_IMAGENAME_5";
    const imageF = "PHOTOGALLERY.PHOTO_IMAGENAME_6";
    const imageG = "PHOTOGALLERY.PHOTO_IMAGENAME_7";
    const imageH = "PHOTOGALLERY.PHOTO_IMAGENAME_8";
    const imageI = "PHOTOGALLERY.PHOTO_IMAGENAME_9";
    const imageJ = "PHOTOGALLERY.PHOTO_IMAGENAME_10";
    const imageK = "PHOTOGALLERY.PHOTO_IMAGENAME_11";
    const imageL = "PHOTOGALLERY.PHOTO_IMAGENAME_12";

    const onViewDetailClick = () => {
        history.push("/viewAlbum")
    }

    const getImage = (imageName) => {

        if (imageName === imageA) {
            return g_4;
        } else if (imageName === imageB) {
            return g_2;
        }
        else if (imageName === imageC) {
            return g_3;
        }
        else if (imageName === imageD) {
            return g_1;
        }
        else if (imageName === imageE) {
            return g_5;
        }
        else if (imageName === imageF) {
            return g_6;
        }
        else if (imageName === imageG) {
            return g_7;
        }
        else if (imageName === imageH) {
            return g_8;
        }
        else if (imageName === imageI) {
            return g_9;
        }
        else if (imageName === imageJ) {
            return g_10;
        }
        else if (imageName === imageK) {
            return g_11;
        }
        else if (imageName === imageL) {
            return g_12;
        }
    }
    return (
        <div className="photoGallery"onClick={() => onViewDetailClick()}>
            <h3 className="photo_gallary">{t(`${title}`)}</h3>
            <div className="PhotoGalleryInnerDiv">
                <div className="row">
                    {
                        photoGalleryJson.map((item, index) => {
                            if (index < 5) {

                                return (
                                    <>
                                        <div className="col-sm-2">
                                            <img className="photoGalleryImages" key={index} src={getImage(item.imageName)} alt=""></img>
                                        </div>
                                    </>
                                )
                            } else {
                                return (
                                    <>
                                        <div className="col-sm-2">
                                            <img className="photoGalleryImages" key={index} src={getImage(item.imageName)} alt=""></img>
                                        </div>
                                    </>)
                            }
                        })}


                </div>
            </div>

        </div>

    );

};
