import React, { useState, useEffect, useRef } from 'react'
import "./adminLogin.css"
import login from "./adminLogin.json";
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { userNameRegex } from "../../constants/applicationConstants";

import { baseURL, encrypt, CAPTCHA_SECRET_KEY } from "../../constants/applicationConstants";

const tmpData = {
  email: '',
  userName: ''
}

export const AdminLoginDashboard = () => {
  const { t } = useTranslation();
  let [isProcessing, setProcessing] = useState(false);
  const history = useHistory();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    userName: '',
  });
  const [refresh, setRefresh] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const [formError, setFormError] = useState({})
  const [formSubmitButtonDisable, setFormSubmitButtonDisable] = useState(true)
  const [formSubmitButtonDisable1, setFormSubmitButtonDisable1] = useState(true)


  const [recapchaVerified, setRecaptchaVerified] = useState(false);

  const recaptchaRef = useRef();

  useEffect(() => {
    isFromSubmissionBlock()
    isFromSubmissionBlock1()
  })

  const onInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value) {
      if (!inputRegexValidation(name, value)) {
        setFormData({ ...formData, [name]: value })
        setFormError({ ...formError, [name]: "regx" })
      } else {
        setFormData({ ...formData, [name]: value })
        setFormError({ ...formError, [name]: "" })
      }
    } else {
      setFormData({ ...formData, [name]: '' })
      setFormError({})
    }
  };

  const inputRegexValidation = (name, value) => {
    if (name === "userName")
      return userNameRegex.test(value)
    else
    return true;
  };

  const isFromSubmissionBlock = () => {
    let { password } = formData;
    // if ( password && recapchaVerified) {

    if (password && recapchaVerified) {
      if (
        !inputRegexValidation("password", password)
      ) {
        setFormSubmitButtonDisable(true)
      } else {
        setFormSubmitButtonDisable(false)
      }
    } else {
      setFormSubmitButtonDisable(true)
    }
  };

  const isFromSubmissionBlock1 = () => {
    let { userName } = formData;
    if (userName) {
      if (
        !inputRegexValidation("userName", userName)
      ) {
        setFormSubmitButtonDisable1(true)
      } else {
        setFormSubmitButtonDisable1(false)
      }
    } else {
      setFormSubmitButtonDisable1(true)
    }
  };

  const onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      setFormError({ ...formError, [name]: "req" })
    } else {
      if (!inputRegexValidation(name, value)) {
        setFormError({ ...formError, [name]: "regx" })
      }
    }
  }

  const onSubmitButtonClick = () => {


    setProcessing(true);
    const data = {
      // username: encrypt(formData.email),
      id: sessionStorage.getItem("loginId"),
      token: JSON.parse(sessionStorage.getItem('authenticateToken')),
      password: encrypt(formData.password)
    }
    axios.post(`${baseURL}admin/authenticate`, data).then((res) => {

      if (res.data.status === "success") {
        toast.success(res.data.message)
        setProcessing(false);
        // cookies.set("auth", JSON.stringify(res.data.message));
        sessionStorage.setItem("authToken", JSON.stringify(res.data?.data?.access_token));
        sessionStorage.setItem("masterID", JSON.stringify(res.data?.data?.master_doctor_id));
        sessionStorage.setItem("role", JSON.stringify(res.data?.data?.role));
        setTimeout(() => {
          history.push("/adminDashboard");
          window.location.reload()
        }, 3000)


      } else {
        toast.error(res.data.message)
        setProcessing(false);
      }
    }).catch(res => {
      toast.error(res.response?.data.message)
      // recaptchaRef.current.reset();

      setProcessing(false);
    })
  }

  const authenticate = () => {
    setProcessing(true);
    const data = {
      // username: encrypt(formData.email),
      username: formData.userName,

      password: encrypt(formData.password)
    }
    axios.post(`${baseURL}admin/account`, data).then((res) => {
      if (res.data.status === "success") {
        toast.success(res.data.message)
        setProcessing(false);
        sessionStorage.setItem("authenticateToken", JSON.stringify(res.data?.data?.token));
        sessionStorage.setItem("loginId", JSON.stringify(res.data?.data?.admin_id));
        setShowPassword(true)


      } else {
        toast.error(res.data.message)
        setProcessing(false);
      }
    }).catch(res => {
      toast.error(res.response?.data.message)

      setProcessing(false);
    })
  }

  const onChange = (value) => {
    if (value) {
      setRecaptchaVerified(true);
    }
  }

  const onResetClick = () => {
    setFormData({
      userName: '',
      email: '',
      password: '',
    })
    setRefresh(!refresh);
    setRecaptchaVerified(false);
  }

  return (
    <>
      <div className="container officeStaffComponentOuter">
        <div className="login_label mt-3">
          {t(`${login.description}`)}</div>
        <span className="print" onClick={() => window.print()}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="skyBlue" viewBox="0 0 16 16">
          <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
          <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
        </svg> &nbsp; {t("PRINT.TITLE")}</span>
        <h2 className="loginHeading mt-2">
          {t(`${login.heading}`)}
        </h2>
        <div className="account_in">{t("ADMINLOGIN.TITLE")}</div>
        <InputGroup className="col-md-12 mt-4" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("ADMINLOGIN.USERNAME")}
          </InputGroup.Text>
          <FormControl
            name="userName"
            onBlur={onTouchHandler}
            value={formData.userName ?? null}
            onChange={onInputChange}
            autocomplete="off"

            id="basic-url"
            aria-describedby="basic-addon3"
            placeholder={t("ADMINLOGIN.USERNAME_PLACEHOLDER")} />
        </InputGroup>
        <span className="text-danger">
          {
            formError['userName'] &&
            (formError['userName'] === "req"
              ? 'User Name is required'
              : 'Enter a valid User Name')}
        </span>
        {
          showPassword &&
          <>
            <InputGroup className="col-md-12" >
              <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                {t("ADMINLOGIN.PASSWORD")}
              </InputGroup.Text>
              <FormControl
                id="basic-url"
                name="password"
                type={'password'}
                onBlur={onTouchHandler}
                // value={formData.password ?? null}
                autocomplete="off"
                onChange={onInputChange}
                aria-describedby="basic-addon3"
                placeholder={t("ADMINLOGIN.PASSWORD_PLACEHOLDER")} />
            </InputGroup>
            <Link to={"/forgotPass"}>Forgot Password?</Link>
          </>
        }


      </div>
      {showPassword &&

        <>
          <div className="recaptcha_css">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={CAPTCHA_SECRET_KEY}
              onChange={onChange}
            />
          </div>
        </>
      }

      <div class="row">
        {showPassword ?
          <div class="col-sm-12 text-center mb-5 mt-3">
            <button id="btnSearch" class="btn center-block mr-4" onClick={() => onResetClick()} > {t("REGISTER.RESET")}</button>
            <button disabled={formSubmitButtonDisable || !!isProcessing} id="btnClear" class="btn center-block" onClick={onSubmitButtonClick}>{!!isProcessing ? t("REGISTER.PROCESSING") : t("REGISTER.SUBMIT")}</button>
          </div>
          :
          <div class="col-sm-12 text-center mb-5 mt-3">
            <button id="btnSearch" class="btn center-block mr-4" onClick={() => onResetClick()} > {t("REGISTER.RESET")}</button>
            <button disabled={formSubmitButtonDisable1 || !!isProcessing} id="btnClear" class="btn center-block" onClick={authenticate}>{!!isProcessing ? t("REGISTER.PROCESSING") : "Authenticate"}</button>
          </div>
        }


      </div>
      <ToastContainer />

    </>

  );
};

