import React from 'react';
import FAQJsx from './faq.jsx'
const FAQ =()=> {
    return (
        <FAQJsx
        />
    )
}

export default FAQ
