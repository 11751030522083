import React, { Component } from 'react'
import {PaymentScreen} from './paymentScreen';

export default class Payment extends Component{
    render(){
        return(
            <div>
                <PaymentScreen />
            </div>
        )
    }
   
}