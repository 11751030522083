import { React, useContext } from 'react';
import "./listView.css";
import listView from "./listView.json";
import { useTranslation } from "react-i18next";
import newsIcon from "../../assets/images/news icon.svg";
import linkIcon from "../../assets/images/link_icon.svg";
import veterinaryIcon from "../../assets/images/veterinary_icon.svg";
import themeContext from '../../context/themeContext';
import { Pagination } from '../admin/pagination';
import { uploadURL } from '../../constants/applicationConstants';

const ListViewJsx = (props) => {
  const { pdfList, onPaginationClick,links } = props;

  const { mode } = useContext(themeContext)
  const { t } = useTranslation();
  const getImage = (img) => {
    if (img === "NEWS") {
      return newsIcon;
    } else if (img === "LINKS") {
      return linkIcon;

    } else if (img === "VETERINARY") {
      return veterinaryIcon;
    } else {
      return newsIcon;
    }
  }

  return (
    <div className="listViewComponentOuter">
      <br />
      {listView.map((item, index) => {
        return (
          <div key={index} className="listViewComponent">
            <div className="listViewHead">
              <img className="listViewImg" src={getImage(t(`${item.icon}`))}  alt=""/>
              <div className='card-title'>{t(`${item.title}`)}</div>
              {/* <button
                className="listViewButton"
                type="button"
                data-toggle="collapse"
                data-target={`#collapseExample${index}`}
                aria-expanded="true"
                aria-controls="collapseExample"
              >
                <span className="" id={`collapseExample${index}`}>
                  _
                </span>
                <p className="collapse" id={`collapseExample${index}`}>
                  +
                </p>
              </button> */}
            </div>
            <div  key={index} className={(mode === 'dark') ? 'listViewUlDiv listViewUlDiv-dark' : 'listViewUlDiv'} id={`collapseExample${index}`}>
              <ul className="listViewUl">
                {
                  item?.title === "LISTVIEW.LIST_2.TITLE" ?
                    <>
                      {pdfList?.rows?.map((list, listIndex) => {
                        return <li key={listIndex + "a"}>
                          <a href={`https://${list.url}`} target="_blank" rel="noreferrer"
                            className={(mode === 'dark') ? 'listViewListLink listViewListLink-dark' : 'listViewListLink'}>
                            {list?.title}
                          </a>
                        </li>
                      })}
                      {
                        <Pagination limit={10} totalCount={pdfList?.count ?? 0} onPaginationButtonClick={(page) => onPaginationClick(page)} />
                      }
                    </>
                    :
                    item?.listOfBullets?.map((list, listIndex) => (
                      <>
                      <li key={listIndex + "a"}><a href={t(`${list[1]}`)} target="_blank" rel="noreferrer"
                        className={(mode === 'dark') ? 'listViewListLink listViewListLink-dark' : 'listViewListLink'}>
                        {list[2] ? t(`${list[2]}`) : null}
                        {list[2] ? <br /> : null}
                        {t(`${list[0]}`)}
                      </a>
                      </li>
                      {
                        index === 2 && listIndex === 3 &&
                        <> 
                        <li><div onClick={()=> {window.open(`${uploadURL}${links.regular}`,'_blank')}} className={(mode === 'dark') ? 'listViewListLink listViewListLink-dark' : 'listViewListLink'}><span>{t("LISTVIEW.LIST_3.LIST_OF_BULLETS.BULLET_5.DESC")}</span></div></li>
                        <li><div onClick={()=> {window.open(`${uploadURL}${links.provisional}`,'_blank')}} className={(mode === 'dark') ? 'listViewListLink listViewListLink-dark' : 'listViewListLink'}><span>{t("LISTVIEW.LIST_3.LIST_OF_BULLETS.BULLET_6.DESC")}</span></div></li>
                        <li><div onClick={()=> {window.open(`${uploadURL}${links.pending}`,'_blank')}} className={(mode === 'dark') ? 'listViewListLink listViewListLink-dark' : 'listViewListLink'}><span>{t("LISTVIEW.LIST_3.LIST_OF_BULLETS.BULLET_7.DESC")}</span></div></li>
                        </>
                      }
                      </>
                    ))
                }
              </ul>
              {item.readMoreLink ? (
                <a href={item.readMoreLink} target="_blank" rel="noreferrer">
                  <button className="listViewReadButton">{t("HOME.READMORE")}</button>
                </a>
              ) : null}
            </div>
          </div>

        );
      })}
    </div>
  );
};

export default ListViewJsx;
