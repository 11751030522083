import { React, useState, useContext, useEffect } from 'react';
import maharastra from '../../assets/images/maharastra.jpg';
import rupees from '../../assets/images/rupees.jpg';

import abt_icon from '../../assets/images/abt_icon.svg';
import home_icon from '../../assets/images/home_icon.svg';
import contct_icon from '../../assets/images/contct_icon.svg';
import acts_icon from '../../assets/images/acts_icon.svg';

import './header.css';
import icons from "./icons.json";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import 'flag-icon-css/css/flag-icon.min.css';
import i18next from "i18next";
import themeContext from '../../context/themeContext';
import fontsizeChanges from '../../context/fontsizeChanges';
import { isEmpty } from "lodash"
import { useHistory } from "react-router";
import dropd from '../../assets/images/dropdown1.png';



function HeaderJsx() {

    const { mode, updateTheme } = useContext(themeContext)
    const { updateFontSize } = useContext(fontsizeChanges)
    const [languageValue, setLanguage] = useState('मराठी');
    const [stableFontsize, updateStableFontsize] = useState('');
    const HOME = "Home";
    const ABOUT_US = "About Us";
    const CONTACT_US = "Contact Us";
    const ACTS = "Acts & Rule";
    const { t } = useTranslation();
    const history = useHistory();


    // this function is used for chnage the website language

    useEffect(() => {
        updateStableFontsize(Number(window.getComputedStyle(document.body).fontSize.slice(0, -2))
        );
    }, [stableFontsize])
    useEffect(() => {
        heading()
    }, [])

    const getIcon = (iconInfo) => {
        if (iconInfo === HOME) {
            return home_icon;
        } else if (iconInfo === ABOUT_US) {
            return abt_icon;
        } else if (iconInfo === CONTACT_US) {
            return contct_icon;
        }
        else if (iconInfo === ACTS) {
            return acts_icon;
        }
        else {
            return "";
        }
    }

    const onChangeLanguage = () => {

        if (languageValue === 'English') {
            setLanguage('मराठी')
            i18next.changeLanguage('en')
        } else {
            setLanguage('English')
            i18next.changeLanguage('mr')
        }

    }

    const authToken = sessionStorage.getItem("authToken");
    const role = JSON.parse(sessionStorage.getItem('role'));


    const onBackgroundChange = (theme) => {

        if (theme === 'dark') {
            document.body.style.backgroundColor = '#000';
            document.body.style.color = '#fff';
        } else {
            document.body.style.backgroundColor = '#fff';
            document.body.style.color = '#212529';
        }
        updateTheme(theme)

    }


    const onFontSizedChange = (size) => {

        let currentFontsize = Number(window.getComputedStyle(document.body).fontSize.slice(0, -2))

        if (size === 'minus') {
            currentFontsize = currentFontsize - 1;
        } else if (size === 'plus') {
            currentFontsize = currentFontsize + 1;
        } else {
            currentFontsize = stableFontsize
        }

        document.body.style.fontSize = currentFontsize + 'px';
        updateFontSize(size)

    }

    const onLoginDropdownClick = (e) => {
        const value = e.target.value;
        value && history.push(`/${value}`);
        
    }

    const logOut = () => {
        sessionStorage.clear("authToken")
        sessionStorage.clear("masterID")
        sessionStorage.clear("authenticateToken")
        sessionStorage.clear("loginId")

        sessionStorage.clear("role")

        setTimeout(() => {
            history.push("/");
            window.location.reload()
        }, 3000)
    }

    const isAdmin = () => {
        return role === 'admin';
    }
    const heading = () => {
        if (role === "admin") {
            return (
                t("NavBar.ADMIN_DASHBOARD")
            )
        } else if (role === "user") {
            return (
                t("NavBar.DOCTOR_DASHBOARD")
            )
        } else {
            return (
                ""
            )
        }
    }

    return (
        <>
            <div className="topBar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-md-2 col-12">
                            {/* <ul className="social_icon mt-0">
                                <li><a href="https://twitter.com/i/flow/login"><img src={twitter} id="tw" /></a></li>
                                <li><a href="https://www.facebook.com/"><img src={facebook} id="fb" /></a></li>
                            </ul> */}
                        </div>
                        <div className="col-lg-auto col-md-auto col-12 ms-auto top_right">
                            <div className="d-flex justify-content-end">
                                <ul className="mt-0 topRight_menu">

                                    {/* <li>
                                        <Link to={"/SkiptoContent"}>
                                            {t("NavBar.SKIP_TO_CONTENT")}
                                        </Link>
                                    </li> */}
                                    <li>
                                        {
                                            isEmpty(authToken) &&
                                            <Link to={"/register"} className='register-link'>
                                                {t("NavBar.REGISTER")}
                                            </Link>
                                        }


                                    </li>
                                    {isEmpty(authToken) ? (
                                        <li>
                                            <select name="login" id="login" onClick={(e) => onLoginDropdownClick(e)} style={{ margin: '2px 10px' }}>
                                                <option value=''>{t("NavBar.LOGIN")}</option>
                                                <option value="adminLogin">{t("NavBar.ADMINLOGIN")}</option>
                                                <option value="login">{t("NavBar.DOCTORLOGIN")}</option>
                                            </select>
                                        </li>
                                    ) :
                                        <li className="logout"><a href="javascrip:void(0)"
                                            onClick={() => logOut()}>{t("NavBar.LOGOUT")}</a></li>}

                                    <li className="border-end-0">
                                        <ul className="list-group list-group-horizontal mt-0 zoomFeature">
                                            <li><a href="javascrip:void(0)"
                                                onClick={() => onFontSizedChange('minus')}>A-</a></li>
                                            <li><a href="javascrip:void(0)"
                                                onClick={() => onFontSizedChange('stable')}>A</a></li>
                                            <li><a href="javascrip:void(0)"
                                                onClick={() => onFontSizedChange('plus')}>A+</a></li>
                                        </ul>

                                    </li>
                                    <li>
                                        <ul className="list-group list-group-horizontal mt-0 colorMode">
                                            <li><a href="javascrip:void(0)"
                                                onClick={() => onBackgroundChange('light')}>A</a></li>
                                            <li><a href="javascrip:void(0)"
                                                onClick={() => onBackgroundChange('dark')}>A</a></li>
                                        </ul>

                                    </li>
                                    <li className="language"><a onClick={() => onChangeLanguage()}>{languageValue}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header>
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light row ">
                        <ul className="header_logo mt-0 col-lg-5 col-md-4 col-12">
                            <li><a href="/"><img className="maharas" src={maharastra} height="70"  alt=""/></a></li>
                            <li className="logoText" ><a className={(mode === 'dark') ? 'logoTxt logoTxt-dark' : 'logoTxt'} href="/">{t("NavBar.STATE_SPECIFIC")}<br /> {t("NavBar.VETERNIARY_COUNCIL")}</a></li>
                            <li><a href="/"><img className="rupees" src={rupees} height="70" id="image2"  alt=""/></a></li>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample02" aria-controls="navbarsExample02" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                        </ul>

                        <div className="collapse navbar-collapse col-lg-8 col-md-8 col-12 pe-0" id="navbarsExample02">
                            <ul className="navbar-nav ms-auto main_menu mt-0">
                                {
                                    icons.map((element) => {
                                        if (element.iconInfo === "About Us") {
                                            return < >
                                                <li className="nav-item dropdown">
                                                    <a className="nav-link dropdown-toggle" href="javascript.void(0)" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src={getIcon(element.iconInfo)} className="header-nav-icon" height="40" id="tw"  alt=""/>
                                                        <span className={(mode === 'dark') ? 'nav-link nav-link-dark' : 'nav-link'} aria-current="page" href="javascript.void(0)" id="icon-text"> {t(`${element.name}`)}</span>
                                                    </a>
                                                    <ul className={(mode === 'dark') ? 'dropdown-menu dropdown-menu-dark' : 'dropdown-menu'} aria-labelledby="navbarDropdown" >

                                                        <li>

                                                            <Link to={"/corporateProfile"} className="dropdown-item">
                                                                {t("NavBar.CORPORATE_PROFILE")}
                                                            </Link>
                                                        </li>
                                                        <hr />
                                                        <li>
                                                            <Link to={"/executiveCommittee"} className="dropdown-item">
                                                                {t("NavBar.EXECUTIVE_COMMITTEE")}
                                                            </Link>
                                                        </li>
                                                        <hr />
                                                        <li>
                                                            <Link to={"/disiplinaryCommittee"} className="dropdown-item">
                                                                {t("NavBar.DISCIPLINARY_COMMITTEE")}
                                                            </Link>
                                                        </li>
                                                        <hr />

                                                        <li>
                                                            <Link to={"/officeStaff"} className="dropdown-item">
                                                                {t("NavBar.OFFICE_STAFF")}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </>
                                        } else {
                                            return < >
                                                <li className="nav-item">
                                                    <Link className={(mode === 'dark') ? 'nav-link nav-link-dark' : 'nav-link'} to={element.path} id="navbarDropdown" role="button">
                                                        <img src={getIcon(element.iconInfo)} className="header-nav-icon" height="40" id="tw"  alt=""/>
                                                        <span className={(mode === 'dark') ? 'nav-link nav-link-dark' : 'nav-link'} aria-current="page" href="javascript.void(0)" id="icon-text"> {t(`${element.name}`)}</span>
                                                    </Link>
                                                </li>
                                            </>
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
            <div className="container"> <h4 className="mt-3">{heading()}</h4></div>
            <div className={(mode === 'dark') ? "secondary_menu_dark" : "secondary_menu"}>
                <div className="container">

                                {!isAdmin() && <>

                                    <div className="row">
                                        <div className="col-sm-2">
                                            <Link to={"/circular"} className={(mode === 'dark') ? "nav-link navLinkColor" : "nav-link"}>
                                                {t("NavBar.CIRCULARS")}
                                            </Link>
                                        </div>
                                        <div className="col-sm-2">
                                            <Link to={"/faq"} className={(mode === 'dark') ? "nav-link navLinkColor" : "nav-link"}>
                                                {t("NavBar.FAQ")}
                                            </Link>
                                        </div>
                                        <div className="col-sm-2">
                                            <Link to={"/feedback"} className={(mode === 'dark') ? "nav-link navLinkColor" : "nav-link"}>
                                                {t("NavBar.FEEDBACK")}
                                            </Link>
                                        </div>
                                        <div className="col-sm-2">
                                            <Link to={"/grievance"} className={(mode === 'dark') ? "nav-link navLinkColor" : "nav-link"}>
                                                {t("NavBar.GRIEVANCE")}
                                            </Link>
                                        </div>
                                    </div>
                                </>}
                                {isAdmin() &&
                                    <>
                                        <div className="row">
                                            <div className="col-sm-2">
                                                <div>
                                                    <Link to={"/adminDashboard"} className={(mode === 'dark') ? "nav-link navLinkColor" : "nav-link"}>
                                                        {t("NavBar.ADMIN")}
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-sm-1 mr-5">
                                                <div>
                                                    <Link to={"/masterDashboard"} className={(mode === 'dark') ? "nav-link navLinkColor" : "nav-link"}>
                                                        {t("NavBar.MASTER")}
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-sm-2 mr-1">
                                                <a className="nav-link dropdown-toggle" href="javascript.void(0)" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span className={(mode === 'dark') ? "nav-link navLinkColor" : "nav-link"}>
                                                        {t("NavBar.UPLOAD_ALBUM")} <img className="dropimg" src={dropd}  alt=""/>
                                                    </span>

                                                </a>
                                                <div id="album_drop" className={(mode === 'dark') ? 'dropdown-menu dropdown-menu-dark' : 'dropdown-menu'} aria-labelledby="navbarDropdown" >

                                                    <li>

                                                        <Link to={"/uploadAlbum"} className="dropdown-item album_color">
                                                            {t("NavBar.ALBUM_FOR_PHOTO")}
                                                        </Link>
                                                    </li>
                                                    <hr />
                                                    <li>
                                                        <Link to={"/uploadImage"} className="dropdown-item album_color">
                                                            {t("NavBar.UPLOAD_PHOTO")}
                                                        </Link>
                                                    </li>
                                                    <hr />
                                                    <li>
                                                        <Link to={"/uploadPressAlbum"} className="dropdown-item album_color">
                                                            {t("NavBar.PRESS_ALBUM")}
                                                        </Link>
                                                    </li>
                                                    <hr />

                                                    <li>
                                                        <Link to={"/uploadPressImage"} className="dropdown-item album_color">
                                                            {t("NavBar.PRESS_IMAGE")}
                                                        </Link>
                                                    </li>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div>
                                                    <Link to={"/uploadPDF"} className={(mode === 'dark') ? "nav-link navLinkColor" : "nav-link"}>
                                                        {t("NavBar.UPLOAD_PDF")}
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div>
                                                    <Link to={"/uploadNews"} className={(mode === 'dark') ? "nav-link navLinkColor" : "nav-link"}>
                                                        {t("NavBar.UPLOAD_NEWS")}
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div>
                                                    <Link to={"/loginLog"} className={(mode === 'dark') ? "nav-link navLinkColor" : "nav-link"}>
                                                        {t("NavBar.LIST_VIEW")}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                </div>
            </div>

        </>
    )



}
export default HeaderJsx;