import React from 'react';
import axios from 'axios';
const FileUpload = () => {

    let formData = new FormData();

    const onFileChange = (e) => {
        if (e.target.files[0]) {
            formData.append('image', e.target.files[0])
        }
    }

    const submitFile = () => {
        axios.post(
            'http://localhost:4001/uploadfile',
            formData
        )
            .then(res => {
            })
            .catch(error => {
            })
    }
    return (
        <div className="container">
            <div>
                <input type="file" name="file_upload" onChange={onFileChange} />
            </div>
            <div><button onClick={submitFile}>submit</button></div>
        </div>
    )
}

export default FileUpload;
