import React, { useEffect, useState } from 'react';
// import { useHistory } from "react-router";

const IdleLogout = () => {
  const [isActive, setIsActive] = useState(true);
  // const history = useHistory();

  let timeout; // To hold the timeout reference

  const logOut = () => {
    sessionStorage.clear("authToken")
    sessionStorage.clear("masterID")
    sessionStorage.clear("role")
    window.location.href = '/';   
}

  const logout = () => {
    // Implement logout logic here
    // alert("ddd")
    // console.log('Logging out...');
    logOut();
  };

  const resetTimeout = () => {
    clearTimeout(timeout); // Clear the previous timeout

    // Set a new timeout for 5 mnts
    timeout = setTimeout(logout, 5 * 60 * 1000);
  };

  const onActivity = () => {
    if (!isActive) {
      setIsActive(true); // Set back to active state
    }
    resetTimeout(); // Reset timeout on user activity
  };

  useEffect(() => {
    const events = ['mousemove', 'mousedown', 'keydown', 'scroll', 'touchstart']; // Events to track user activity

    const resetTimeoutOnEvents = () => {
      events.forEach((event) => {
        document.addEventListener(event, onActivity); // Attach event listeners
      });
      resetTimeout(); // Start the initial timeout
    };

    resetTimeoutOnEvents(); // Initialize event listeners and timeout

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, onActivity); // Remove event listeners on component unmount
      });
      clearTimeout(timeout); // Clear the timeout on component unmount
    };
  }, [timeout]);

  return (
    <div>
      
    </div>
  );
};

export default IdleLogout;