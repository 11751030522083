import React, { useState, useEffect } from "react";
import './provisionalRegistration.css';
import { useTranslation } from "react-i18next";
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';
import { baseURL, uploadURL, fieldRegex, postalCodeRegex, htmlRegex, adharRegex, nameRegex, phoneRegex, emailRegex, passwordRegex, getProperDateMonth, encrypt } from "../../constants/applicationConstants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router";
import Data from '../register/register.json';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker3 from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";


const tmpData = {
    fullName: "",
    fatherName: "",
    motherName: "",
    dateOfBirth: null,
    gender: "",
    category: "",
    professionalStatus: "",
    nationality: "IN",
    stateResidence: "MH",
    andhar: '',
    sector: "",
    password: "",
    confirmPassword: "",
    degree: '',
    graduationCollegeName: "",
    graduationUniversityName: "",
    graduationDate: null,
    postGraduation: "",
    postGraduationCollegeName: "",
    postGraduationUniversityName: "",
    postGraduationMonth: "",
    postGraduationYear: "",
    postGraduationSubjects: "",
    phd: "",
    phdUniversityName: "",
    phdMonth: "",
    phdYear: "",
    postDoc: "",
    postDocCollegeName: "",
    postDocUniversityName: "",
    postDocMonth: "",
    postDocYear: "",
    universityName: '',
    collegeName: '',
    mobileNumber: '',
    email: '',
    alternateMobileNumber: '',
    alternateEmailId: '',
    presentProfessionalAdderess: '',
    presentProfessionalDistrict: '',
    presentProfessionalVillage: '',
    presentProfessionalTaluka: '',
    presentProfessionalState: 'MH',
    presentProfessionalPincode: '',
    permanentAddress: '',
    state: 'MH',
    district: '',
    taluka: '',
    village: '',
    pinCode: '',
    workingAddress: '',
    tempState: 'MH',
    tempDistrict: '',
    tempTaluka: '',
    tempVillage: '',
    tempPincode: '',
    awards: "",
    seminars: "",
    CVE: "",
    isWorkingAddressSame: false,
    isPermanentAddressSame: false
}

export const ProvisionalRegistration = (props) => {
    const history = useHistory();
    const formatInputValue = (date) => {
        if (!date) return '';
        return `${date.day}/${date.month}/${date.year}`;
    };

    const [formData, setFormData] = useState({
        fullName: '',
        fatherName: '',
        motherName: '',
        dateOfBirth: null,
        gender: '',
        professionalStatus: '',
        category: '',
        nationality: 'IN',
        stateResidence: 'MH',
        andhar: '',
        sector: '',
        password: '',
        confirmPassword: '',
        degree: '',
        graduationCollegeName: "",
        graduationUniversityName: "",
        graduationDate: null,
        postGraduation: "",
        postGraduationCollegeName: "",
        postGraduationUniversityName: "",
        postGraduationMonth: "",
        postGraduationYear: "",
        postGraduationSubjects: "",
        phd: "",
        phdUniversityName: "",
        phdMonth: "",
        phdYear: "",
        postDoc: "",
        postDocCollegeName: "",
        postDocUniversityName: "",
        postDocMonth: "",
        postDocYear: "",
        universityName: '',
        collegeName: '',
        mobileNumber: '',
        alternateMobileNumber: '',
        alternateEmailId: '',
        presentProfessionalAdderess: '',
        presentProfessionalDistrict: '',
        presentProfessionalVillage: '',
        presentProfessionalTaluka: '',
        presentProfessionalState: 'MH',
        presentProfessionalPincode: '',
        permanentAddress: '',
        state: 'MH',
        district: '',
        taluka: '',
        village: '',
        pinCode: '',
        workingAddress: '',
        tempState: 'MH',
        tempDistrict: '',
        tempTaluka: '',
        tempVillage: '',
        tempPincode: '',
        awards: "",
        seminars: "",
        CVE: "",
        isWorkingAddressSame: false,
        isPermanentAddressSame: false
    })

    const tempFiles = {
        upload_photo: '',
        signature: '',
        degree_certificate: '',
        identity_proof: '',
        andhar_card: '',
        domicile_certificate: '',
        dob_proof: '',
        payment_proof: ''
    }

    const [fileData, setFileData] = useState({
        upload_photo: '',
        signature: '',
        degree_certificate: '',
        identity_proof: '',
        andhar_card: '',
        domicile_certificate: '',
        dob_proof: '',
        payment_proof: ''
    })

    const [fileDataName, setFileDataName] = useState({ ...tempFiles })

    const [formError, setFormError] = useState({})
    const [formSubmitButtonDisable, setFormSubmitButtonDisable] = useState(true)
    let [isProcessing, setProcessing] = useState(false);
    const [fromData, setFromData] = useState(tmpData)
    // const [showUpdate, setShowUpdate] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);


    useEffect(() => {
        isFromSubmissionBlock()
    }, [formData, fromData, fileData]);


    // const validFileType = (type) => type.includes("image/jpeg") || type.includes("image/png") || type.includes("image/gif") || type.includes("application/pdf") || type.includes("application/msword");

    // const validFileName = (name) => name.includes('.doc') || name.includes('.docx') || name.includes('.pdf') || name.includes('.jpg') || name.includes('.jpeg') || name.includes('.png')

    const validFileName = (name) => name.includes('.pdf') || name.includes('.jpeg') || name.includes('.jpg')

    const validPhotoFileName = (name) => name.includes('.jpeg') || name.includes('.jpg')

    const uploadFile = (file, name) => {
        let formData1 = new FormData();
        formData1.append('image', file)
        axios.post(
            `${uploadURL}uploadfile`,
            formData1
        ).then(res => {
            setFileData({ ...fileData, [name]: res.data.profileImageName });
            setFileDataName({ ...fileDataName, [name]: file.name })
        })
            .catch(error => {
            })
    }

    const onFileChange = (e) => {
        const name = e.target.name;
        if (e.target.files[0]?.size) {
            if (e.target.files[0]?.size < 2097152) {
                if (name === 'upload_photo') {
                    if (e.target.files[0] && validPhotoFileName(e.target.files[0].name)) {
                        uploadFile(e.target.files[0], name)
                    }
                    else {
                        toast.error('.JPG/.JPEG format required')
                    }
                }
                else {
                    if (e.target.files[0] && validFileName(e.target.files[0].name)) {
                        uploadFile(e.target.files[0], name)
                    }
                    else {
                        toast.error('.JPG/.JPEG/.PDF format required')
                    }
                }
            }
            else {
                toast.error('File should be less than 2mb')
            }
        }

    }

    const onInputChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value) {
            if (!inputRegexValidation(name, value)) {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "regx" })
            } else {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "" })
            }
        } else {
            setFormData({ ...formData, [name]: '' })
            setFormError({ ...formError, [name]: "" })
        }
    };

    const inputRegexValidation = (name, value) => {
        if (name === "fullName" || name === 'fatherName' || name === 'motherName')
            return nameRegex.test(value)
        else if (name === 'permanentAddress' || name === 'village' || name === 'taluka' || name === 'district' || name === "workingAddress")
            return htmlRegex.test(value)
        else if ((name === 'mobileNumber') || (name === 'alternateMobileNumber'))
            return phoneRegex.test(value);
        else if (name === 'alternateEmailId' || name === 'email')
            return emailRegex.test(value);
        else if (name === 'password' || name === 'confirmPassword')
            return passwordRegex.test(value);
        else if (name === 'andhar')
            return adharRegex.test(value);
        else if (name === 'pinCode' || name === 'tempPincode' || name === 'presentProfessionalPincode')
            return postalCodeRegex.test(value);
        else if (name === 'graduationUniversityName' || name === "postGraduationUniversityName" || name === 'phdUniversityName' || name === 'postDocUniversityName' || name === "graduationCollegeName" || name === 'postGraduationCollegeName' || name === 'postDocColleageName' || name === "district" || name === 'tempDistrict' || name === 'presentProfessionalDistrict' || name === 'taluka' || name === 'tempTaluka' || name === 'presentProfessionalTaluka' || name === 'village' || name === 'tempVillage' || name === 'presentProfessionalVillage')
            return fieldRegex.test(value)
        else if (name === 'graduationYear' || name === 'postGraduationYear' || name === 'phdYear' || name === 'postDocYear')
            return value < 9999
        else
            return true;
    };

    const isFromSubmissionBlock = () => {
        let { fullName, email, mobileNumber, permanentAddress, district, pinCode, state, taluka, village, degree, graduationCollegeName, graduationUniversityName, andhar, fatherName, motherName, alternateEmailId, alternateMobileNumber, tempDistrict, tempPincode, tempTaluka, tempVillage } = formData;
        const { graduationDate, dateOfBirth } = fromData;
        if (fullName && email && mobileNumber && permanentAddress && district && pinCode && state && taluka && village && degree && graduationCollegeName && graduationUniversityName && graduationDate?.day && dateOfBirth?.day
            && mandatoryFieldsValidation()
        ) {
            if (
                inputRegexValidation("fullName", fullName) &&
                inputRegexValidation("email", email) &&
                inputRegexValidation("mobileNumber", mobileNumber) &&
                inputRegexValidation("permanentAddress", permanentAddress) &&
                inputRegexValidation("district", district) &&
                inputRegexValidation("pinCode", pinCode) &&
                inputRegexValidation("state", state) &&
                inputRegexValidation("taluka", taluka) &&
                inputRegexValidation("village", village) &&
                inputRegexValidation("degree", degree) &&
                inputRegexValidation("graduationCollegeName", graduationCollegeName) &&
                inputRegexValidation("graduationUniversityName", graduationUniversityName) &&
                inputRegexValidation("graduationDate", graduationDate) &&
                inputRegexValidation("dateOfBirth", dateOfBirth) &&
                mandatoryFieldsValidation() &&

                // non required fields

                (andhar ? inputRegexValidation("andhar", andhar) : true) &&
                (fatherName ? inputRegexValidation("fatherName", fatherName) : true) &&
                (motherName ? inputRegexValidation("motherName", motherName) : true) &&
                (alternateEmailId ? inputRegexValidation("alternateEmailId", alternateEmailId) : true) &&
                (alternateMobileNumber ? inputRegexValidation("alternateMobileNumber", alternateMobileNumber) : true) &&
                (tempDistrict ? inputRegexValidation("tempDistrict", tempDistrict) : true) &&
                (tempVillage ? inputRegexValidation("tempVillage", tempVillage) : true) &&
                (tempTaluka ? inputRegexValidation("tempTaluka", tempTaluka) : true) &&
                (tempPincode ? inputRegexValidation("tempPincode", tempPincode) : true)
            ) {
                setFormSubmitButtonDisable(false)
            } else {
                setFormSubmitButtonDisable(true)
            }
        }
        else {
            setFormSubmitButtonDisable(true)
        }
    };

    const onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            setFormError({ ...formError, [name]: "req" })
        } else {
            if (!inputRegexValidation(name, value)) {
                setFormError({ ...formError, [name]: "regx" })
            }
        }
    }

    const formSubmitHandler = (e) => {
        e.preventDefault();

        let personalInformation = {
            name: fromData.fullName,
            father_name: fromData.fatherName,
            mother_name: fromData.motherName,
            dob: fromData.dateOfBirth && `${getProperDateMonth(fromData.dateOfBirth?.day)}/${getProperDateMonth(fromData.dateOfBirth?.month)}/${fromData.dateOfBirth.year}`,
            gender: fromData.gender,
            category: fromData.category,
            nationality: fromData.nationality,
            aadhar_card_no: fromData.andhar ? encrypt(fromData.andhar) : null,
            residence_state: fromData.stateResidence
        }
        let educationalInformation = {
            graduation: fromData.degree,
            graduation_college_name: fromData.graduationCollegeName,
            graduation_university_name: fromData.graduationUniversityName,
            graduation_completion_date: fromData.graduationDate && `${getProperDateMonth(fromData.graduationDate?.day)}/${getProperDateMonth(fromData.graduationDate?.month)}/${fromData.graduationDate.year}`,
        }

        let contactDetails = {
            mobile: fromData.mobileNumber,
            email: fromData.email,
            alt_mobile: fromData.alternateMobileNumber,
            alt_email: fromData.alternateEmailId,
            professional_address: fromData.workingAddress,
            district: fromData.tempDistrict,
            village: fromData.tempVillage,
            state: fromData.tempState,
            taluka: fromData.tempTaluka,
            pincode: fromData.tempPincode,
            permanent_address: fromData.permanentAddress,
            permanent_address_state: fromData.state,
            permanent_address_district: fromData.district,
            permanent_address_taluka: fromData.taluka,
            permanent_address_village: fromData.village,
            permanent_address_pincode: fromData.pinCode,
        }

        let fileDetails = Object.keys(fileData).map((file) => {
            return { document_name: file, document_path: fileData[file] }
        })

        const reqParams = {
            "personalInformation": personalInformation,
            "educationalInformation": educationalInformation,
            "communicationDetails": contactDetails,
            "documents": fileDetails
        }

        // setIsLoading(true);
        setProcessing(true);
        axios.post(`${baseURL}provisionalSignup`, reqParams).then(resp => {
            if (resp.data.status === "success") {
                toast.success(resp.data.message)
                // setShowUpdate(true);
                // setIsLoading(false)
                setProcessing(false);
                setTimeout(() => {
                    // setShowUpdate(false);
                    history.push("/");
                }, 3000)
            } else {
                toast.error(resp.data.message)
                // setIsLoading(false)
                setProcessing(false);
            }
        }).catch((error) => {
            toast.error(error?.response?.data.message)
            // setIsLoading(false)
            setProcessing(false);
        })

    }
    const handleChange = event => {
        if (event.target.checked) {
            setFromData({
                ...fromData,
                workingAddress: fromData.permanentAddress,
                tempState: fromData.state,
                tempDistrict: fromData.district,
                tempTaluka: fromData.taluka,
                tempVillage: fromData.village,
                tempPincode: fromData.pinCode,
            })
            setFormData({
                ...formData,
                workingAddress: fromData.permanentAddress,
                tempState: fromData.state,
                tempDistrict: fromData.district,
                tempTaluka: fromData.taluka,
                tempVillage: fromData.village,
                tempPincode: fromData.pinCode,
            })
            setFormError({
                ...formError,
                tempPincode: fromData.pinCode ? inputRegexValidation("tempPincode", fromData.pinCode) ? '' : 'regx' : '',
                tempVillage: fromData.village ? inputRegexValidation("tempVillage", fromData.village) ? '' : 'regx' : '',
                tempTaluka: fromData.taluka ? inputRegexValidation("tempTaluka", fromData.taluka) ? '' : 'regx' : '',
                tempDistrict: fromData.district ? inputRegexValidation("tempDistrict", fromData.district) ? '' : 'regx' : '',
                workingAddress: fromData.permanentAddress ? inputRegexValidation("workingAddress", fromData.permanentAddress) ? '' : 'regx' : ''
            })
        } else {
            console.log('⛔️ Checkbox is NOT checked');
        }
        setIsSubscribed(current => !current);
    };

    // const onTemporaryCheckboxClick = (value) => {
    //     setFromData({ ...fromData, isWorkingAddressSame: !value })
    //     if (value) {
    //         setFromData({
    //             ...fromData,
    //             workingAddress: '',
    //             tempState: '',
    //             tempDistrict: '',
    //             tempTaluka: '',
    //             tempVillage: '',
    //             tempPincode: ''
    //         })
    //     }
    //     else {
    //         setFromData({
    //             ...fromData,
    //             workingAddress: fromData.permanentAddress,
    //             tempState: fromData.state,
    //             tempDistrict: fromData.district,
    //             tempTaluka: fromData.taluka,
    //             tempVillage: fromData.village,
    //             tempPincode: fromData.pinCode,
    //         })
    //         setFormError({
    //             ...formError,
    //             tempPincode: fromData.pinCode ? inputRegexValidation("tempPincode", fromData.pinCode) ? '' : 'regx' : '',
    //             tempVillage: fromData.village ? inputRegexValidation("tempVillage", fromData.village) ? '' : 'regx' : '',
    //             tempTaluka: fromData.taluka ? inputRegexValidation("tempTaluka", fromData.taluka) ? '' : 'regx' : '',
    //             tempDistrict: fromData.district ? inputRegexValidation("tempDistrict", fromData.district) ? '' : 'regx' : '',
    //             workingAddress: fromData.permanentAddress ? inputRegexValidation("workingAddress", fromData.permanentAddress) ? '' : 'regx' : ''
    //         })
    //     }
    // }

    const onResetButtonClick = () => {
        // setFromData(tmpData);
        // setFormData(tmpData);
        // setFormError({});
        // setFileData(tempFiles);
        // setFileDataName(tempFiles);
        window.location.reload()
    }

    // const getCurrentYear = () => {
    //     const date = new Date();
    //     return date.getFullYear();
    // }

    const mandatoryFieldsValidation = () => {
        const { andhar_card, degree_certificate, dob_proof, domicile_certificate, signature, identity_proof, upload_photo, payment_proof } = fileData;
        return !!(andhar_card && degree_certificate && dob_proof && domicile_certificate && signature && identity_proof && upload_photo && payment_proof)
    }

    const { t } = useTranslation();
    return (
        <>

            <div className="exe-comm">
                <div className="container">
                    <span className="print" onClick={() => window.print()}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="skyBlue" class="bi bi-printer-fill" viewBox="0 0 16 16">
                        <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                        <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                    </svg> &nbsp; {t("PRINT.TITLE")}</span>
                    <div className="heading mb-3">{t("PROVISIONALREGISTER.MAIN_HEAD")}</div>
                    <div class="p-2 border text-white" style={{ backgroundColor: "#de8461", fontWeight: "bold" }}>{t("Register.D1")}
                    </div>
                    <div className="input" >
                        <InputGroup className="col-md-12"  >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.FULLNAME")}<span className="star_highlight">*</span>
                            </InputGroup.Text>
                            <FormControl
                                type="text"
                                // className="form-control is-invalid"
                                className={
                                    formError['fullName'] &&
                                    (formError['fullName'] === "req"
                                        ? "form-control  is-invalid"
                                        : "form-control")}
                                name="fullName"
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.fullName : null}
                                onChange={(e) => { setFromData({ ...fromData, fullName: e.target.value }); onInputChangeHandler(e) }}
                                id="basic-url"
                                aria-describedby="basic-addon3" placeholder={t("REGISTER.DOCTORNAME_PLACEHOLDER")}
                                maxLength={60} />

                        </InputGroup>
                        <span className="text-danger">
                            {
                                formError['fullName'] &&
                                (formError['fullName'] === "req"
                                    ? 'Full Name is required'
                                    : 'Enter a valid Full Name')}
                        </span>

                        <InputGroup className="col-md-12" >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.NATIONALITY")}
                            </InputGroup.Text>
                            <select name="category"
                                // className={
                                //     formError['nationality'] &&
                                //     (formError['nationality'] === "req"
                                //         ? "form-control  is-invalid"
                                //         : "form-control")}
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.nationality : null}
                                onChange={(e) => { setFromData({ ...fromData, nationality: e.target.value }); onInputChangeHandler(e) }} id="basic-url" aria-describedby="basic-addon3" placeholder="Enter Nationality"
                                className="form-select customize_select" aria-label="Default select example">
                                {
                                    Data.country.map((country) => {
                                        return (
                                            <option value={country.code} selected={country.code === fromData.nationality}>{country.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </InputGroup>

                        <InputGroup className="col-md-12"  >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.FATHERSNAME")}
                            </InputGroup.Text>
                            <FormControl
                                type="text"
                                className="form-control"
                                name="fatherName"
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.fatherName : ''}
                                onChange={(e) => { setFromData({ ...fromData, fatherName: e.target.value }); onInputChangeHandler(e) }}
                                id="basic-url"
                                aria-describedby="basic-addon3" placeholder={t("REGISTER.FATHERSNAME_PLACEHOLDER")}
                                maxLength={60} />

                        </InputGroup>
                        <span className="text-danger">
                            {
                                formError['fatherName'] &&
                                (formError['fatherName'] === "req"
                                    ? ''
                                    : 'Enter a valid Father\'s Name')}
                        </span>

                        <InputGroup className="col-md-12"  >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.MOTHERSNAME")}
                            </InputGroup.Text>
                            <FormControl
                                type="text"
                                className="form-control"
                                name="motherName"
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.motherName : ''}
                                onChange={(e) => { setFromData({ ...fromData, motherName: e.target.value }); onInputChangeHandler(e) }}
                                id="basic-url"
                                aria-describedby="basic-addon3" placeholder={t("REGISTER.MOTHERSNAME_PLACEHOLDER")}
                                maxLength={60} />

                        </InputGroup>
                        <span className="text-danger">
                            {
                                formError['motherName'] &&
                                (formError['motherName'] === "req"
                                    ? ''
                                    : 'Enter a valid Mother\'s Name')}
                        </span>

                        <InputGroup className="col-md-12" >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.DOB")} <span className="star_highlight">*</span>
                            </InputGroup.Text>
                            <div className="col-sm-8" style={{ padding: '0px' }}>
                                <DatePicker3 className="form-control" name="dateOfBirth"
                                    value={(fromData) ? fromData.dateOfBirth : null}
                                    onChange={(date) => { setFromData({ ...fromData, dateOfBirth: date }) }}
                                    inputPlaceholder="dd/mm/yyyy"
                                    shouldHighlightWeekends
                                    formatInputText={() => formatInputValue(fromData && fromData.dateOfBirth)}
                                />
                            </div>
                        </InputGroup>
                        <span className="text-danger">
                            {
                                formError['dateOfBirth'] &&
                                (formError['dateOfBirth'] === "req"
                                    ? ''
                                    : 'Enter a valid Date Of Birth')}
                        </span>
                        <InputGroup className="col-md-12" >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                <div className="label_word">  {t("REGISTER.AADHAR")}</div>
                            </InputGroup.Text>
                            <FormControl name="andhar"
                                className="form-control"
                                onBlur={onTouchHandler}
                                type="password"
                                autoComplete="off"
                                value={(fromData) ? fromData.andhar : null}
                                onChange={(e) => { setFromData({ ...fromData, andhar: e.target.value }); onInputChangeHandler(e) }}
                                id="basic-url" aria-describedby="basic-addon3"
                                placeholder={t("REGISTER.AADHAR_PLACEHOLDER")}
                                maxLength={12} />
                        </InputGroup>
                        <span className="text-danger">
                            {
                                formError['andhar'] &&
                                (formError['andhar'] === "req"
                                    ? ''
                                    : 'Enter a valid Aadhar')}
                        </span>
                        <InputGroup className="col-md-12" >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.GENDER")}
                            </InputGroup.Text>
                            <div class="form-check form-check-inline mb-3" id="basic-url">
                                <label class="form-check-label ml-3" for="inlineRadio1">  {t("REGISTER.MALE")}</label>
                                <input name="gender"
                                    onBlur={onTouchHandler}

                                    checked={fromData.gender === 'Male'}
                                    onChange={(e) => { setFromData({ ...fromData, gender: e.target.value }); onInputChangeHandler(e) }} class="form-check-input ml-3" type="radio" id="inlineRadio1" value="Male" />
                            </div>
                            <div class="form-check form-check-inline mb-3" id="basic-url">
                                <label class="form-check-label ml-3" for="inlineRadio2">  {t("REGISTER.FEMALE")}</label>
                                <input name="gender"
                                    onBlur={onTouchHandler}

                                    checked={fromData.gender === 'Female'}
                                    onChange={(e) => { setFromData({ ...fromData, gender: e.target.value }); onInputChangeHandler(e) }} class="form-check-input ml-3" type="radio" id="inlineRadio2" value="Female" />
                            </div>
                            <div class="form-check form-check-inline mb-3" id="basic-url">
                                <label class="form-check-label ml-3" for="inlineRadio2">  {t("REGISTER.OTHER")}</label>
                                <input name="gender"
                                    onBlur={onTouchHandler}

                                    checked={fromData.gender === 'Other'}
                                    onChange={(e) => { setFromData({ ...fromData, gender: e.target.value }); onInputChangeHandler(e) }} class="form-check-input ml-3" type="radio" id="inlineRadio3" value="Other" />
                            </div>
                        </InputGroup>

                        <InputGroup className="col-md-12" >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.CATEGORY")}
                            </InputGroup.Text>
                            <select name="category"
                                // className={
                                //     formError['category'] &&
                                //     (formError['category'] === "req"
                                //         ? "form-control  is-invalid"
                                //         : "form-control")}
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.category : null}
                                onChange={(e) => { setFromData({ ...fromData, category: e.target.value }); onInputChangeHandler(e) }}
                                className="form-select customize_select" aria-label="Default select example">
                                <option selected>{t("DROPDOWN.SELECT")}</option>
                                <option value="General">General</option>
                                <option value="OBC">OBC</option>
                                <option value="SC">SC</option>
                                <option value="ST">ST</option>
                                <option value="NT">NT</option>
                                <option value="Other">Other</option>
                            </select>
                        </InputGroup>

                        <InputGroup className="col-md-12" >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.STATE")}
                            </InputGroup.Text>
                            <select name="category"
                                // className={
                                //     formError['stateResidence'] &&
                                //     (formError['stateResidence'] === "req"
                                //         ? "form-control  is-invalid"
                                //         : "form-control")}
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.stateResidence : null}
                                onChange={(e) => { setFromData({ ...fromData, stateResidence: e.target.value }); onInputChangeHandler(e) }} id="basic-url" aria-describedby="basic-addon3" placeholder="Enter state of Residence"
                                className="form-select customize_select" aria-label="Default select example">
                                {
                                    Data.states.map((state) => {
                                        return (
                                            <option value={state.key} selected={state.key === formData.state}>{state.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </InputGroup>
                    </div>
                    <div class="p-2 border text-white mt-4" style={{ backgroundColor: "#de8461", fontWeight: "bold" }}>{t("Register.D3")}
                    </div>
                    <InputGroup className="col-md-12 mt-4" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.MOBILE")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="mobileNumber" aria-describedby="basic-addon3"
                            placeholder={t("REGISTER.MOBILE_PLACEHOLDER")}
                            className={
                                formError['mobileNumber'] &&
                                (formError['mobileNumber'] === "req"
                                    ? "form-control  is-invalid"
                                    : "form-control")}
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.mobileNumber : null}
                            onChange={(e) => { setFromData({ ...fromData, mobileNumber: e.target.value }); onInputChangeHandler(e) }}
                            maxLength={10}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['mobileNumber'] &&
                            (formError['mobileNumber'] === "req"
                                ? 'Mobile Number is required'
                                : 'Enter a valid Mobile Number')}
                    </span>
                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.ALTMOBILE")}
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="alternateMobileNumber" aria-describedby="basic-addon3"
                            placeholder={t("REGISTER.ALTMOBILE_PLACEHOLDER")}
                            className="form-control"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.alternateMobileNumber : null}
                            onChange={(e) => { setFromData({ ...fromData, alternateMobileNumber: e.target.value }); onInputChangeHandler(e) }}
                            maxLength={10}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['alternateMobileNumber'] &&
                            (formError['alternateMobileNumber'] === "req"
                                ? ''
                                : 'Enter a valid Alternative  Mobile Number')}
                    </span>
                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.EMAIL")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="email" aria-describedby="basic-addon3" placeholder={t("REGISTER.EMAIL_PLACEHOLDER")}
                            className={
                                formError['email'] &&
                                (formError['email'] === "req"
                                    ? "form-control  is-invalid"
                                    : "form-control")}
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.email : null}
                            onChange={(e) => { setFromData({ ...fromData, email: e.target.value }); onInputChangeHandler(e) }}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['email'] &&
                            (formError['email'] === "req"
                                ? 'Email Id is required'
                                : 'Enter a valid Email Id')}
                    </span>
                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.ALTEMAIL")}
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="alternateEmailId" aria-describedby="basic-addon3" placeholder={t("REGISTER.ALTEMAIL_PLACEHOLDER")}
                            className="form-control"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.alternateEmailId : null}
                            onChange={(e) => { setFromData({ ...fromData, alternateEmailId: e.target.value }); onInputChangeHandler(e) }} />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['alternateEmailId'] &&
                            (formError['alternateEmailId'] === "req"
                                ? ''
                                : 'Enter a valid Alternative Email Id')}
                    </span>

                    <InputGroup className="col-md-12 mt-4" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.PERMANANETADDRESS")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="permanentAddress" aria-describedby="basic-addon3" placeholder={t("REGISTER.PERMANANETADDRESS_PLACEHOLDER")}
                            className={
                                formError['permanentAddress'] &&
                                (formError['permanentAddress'] === "req"
                                    ? "form-control  is-invalid"
                                    : "form-control")}
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.permanentAddress : null}
                            onChange={(e) => { setFromData({ ...fromData, permanentAddress: e.target.value }); onInputChangeHandler(e) }}
                            maxLength={100}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['permanentAddress'] &&
                            (formError['permanentAddress'] === "req"
                                ? 'Permanent Address is required'
                                : 'Enter a valid Permanent Address')}
                    </span>
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.VILLAGE")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl
                            type="text"
                            className={
                                formError['village'] &&
                                (formError['village'] === "req"
                                    ? "form-control  is-invalid"
                                    : "form-control")}
                            name="village"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.village : null}
                            onChange={(e) => { setFromData({ ...fromData, village: e.target.value }); onInputChangeHandler(e) }}
                            id="basic-url"
                            aria-describedby="basic-addon3" placeholder={t("REGISTER.VILLAGE_PLACEHOLDER")}
                            maxLength={50} />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['village'] &&
                            (formError['village'] === "req"
                                ? 'Village is required'
                                : 'Enter a valid Village')}
                    </span>
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.TALUKA")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl
                            type="text"
                            className={
                                formError['taluka'] &&
                                (formError['taluka'] === "req"
                                    ? "form-control  is-invalid"
                                    : "form-control")}
                            name="taluka"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.taluka : null}
                            onChange={(e) => { setFromData({ ...fromData, taluka: e.target.value }); onInputChangeHandler(e) }}
                            id="basic-url"
                            aria-describedby="basic-addon3" placeholder={t("REGISTER.TALUKA_PLACEHOLDER")}
                            maxLength={50} />

                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['taluka'] &&
                            (formError['taluka'] === "req"
                                ? 'Taluka is required'
                                : 'Enter a valid Taluka')}
                    </span>
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.DISTRICT")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl
                            type="text"
                            className={
                                formError['district'] &&
                                (formError['district'] === "req"
                                    ? "form-control  is-invalid"
                                    : "form-control")}
                            name="district"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.district : null}
                            onChange={(e) => { setFromData({ ...fromData, district: e.target.value }); onInputChangeHandler(e) }}
                            id="basic-url"
                            aria-describedby="basic-addon3" placeholder={t("REGISTER.DISTRICT_PLACEHOLDER")}
                            maxLength={50} />

                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['district'] &&
                            (formError['district'] === "req"
                                ? 'District is required'
                                : 'Enter a valid District')}
                    </span>
                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.PINCODE")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="pinCode" aria-describedby="basic-addon3" placeholder={t("REGISTER.PINCODE_PLACEHOLDER")}
                            className={
                                formError['pinCode'] &&
                                (formError['pinCode'] === "req"
                                    ? "form-control  is-invalid"
                                    : "form-control")}
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.pinCode : null}
                            onChange={(e) => { setFromData({ ...fromData, pinCode: e.target.value }); onInputChangeHandler(e) }}
                            maxLength={6}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['pinCode'] &&
                            (formError['pinCode'] === "req"
                                ? 'Pin Code is required'
                                : 'Enter a valid Pin Code')}
                    </span>

                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.PERSTATE")} <span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <select aria-label="Default select example" name="state"
                            // className={
                            //     formError['state'] &&
                            //     (formError['state'] === "req"
                            //         ? "form-control  is-invalid"
                            //         : "form-control")}
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.state : null}
                            className="form-select customize_select"
                            onChange={(e) => { setFromData({ ...fromData, state: e.target.value }); onInputChangeHandler(e) }}
                        >
                            {
                                Data.states.map((state) => {
                                    return (
                                        <option value={state.key} selected={state.key === formData.state}>{state.name}</option>
                                    )
                                })
                            }
                        </select>
                    </InputGroup>




                    <div class="form-check text-center" >
                        <input class="form-check-input " type="checkbox"
                            value={isSubscribed}
                            onChange={handleChange}
                            id="flexCheckDefault"
                        // onClick={() => onTemporaryCheckboxClick(fromData.isWorkingAddressSame)}
                        />
                        <label class="form-check-label" for="flexCheckChecked" id="ticked">
                            {t("REGISTER.TEMPADDRESSSAME")}
                        </label>
                    </div>

                    {/* working address */}
                    <InputGroup className="col-md-12 mt-4" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.TEMPADDRESS")}
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="workingAddress" aria-describedby="basic-addon3" placeholder={t("REGISTER.WORK_ADD_PLACEHOLDER")}
                            className="form-control"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.workingAddress : null}
                            onChange={(e) => { setFromData({ ...fromData, workingAddress: e.target.value }); onInputChangeHandler(e) }}
                            maxLength={100}
                        />
                    </InputGroup>
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.VILLAGE")}
                        </InputGroup.Text>
                        <FormControl
                            type="text"
                            className="form-control"
                            name="tempVillage"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.tempVillage : null}
                            onChange={(e) => { setFromData({ ...fromData, tempVillage: e.target.value }); onInputChangeHandler(e) }}
                            id="basic-url"
                            aria-describedby="basic-addon3" placeholder={t("REGISTER.VILLAGE_PLACEHOLDER")}
                            maxLength={50} />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['tempVillage'] &&
                            (formError['tempVillage'] === "req"
                                ? ''
                                : 'Enter a valid Village')}
                    </span>
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.TALUKA")}
                        </InputGroup.Text>
                        <FormControl
                            type="text"
                            className="form-control"
                            name="tempTaluka"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.tempTaluka : null}
                            onChange={(e) => { setFromData({ ...fromData, tempTaluka: e.target.value }); onInputChangeHandler(e) }}
                            id="basic-url"
                            aria-describedby="basic-addon3" placeholder={t("REGISTER.TALUKA_PLACEHOLDER")}
                            maxLength={50} />

                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['tempTaluka'] &&
                            (formError['tempTaluka'] === "req"
                                ? ''
                                : 'Enter a valid Taluka')}
                    </span>
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.DISTRICT")}
                        </InputGroup.Text>
                        <FormControl
                            type="text"
                            className="form-control"
                            name="tempDistrict"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.tempDistrict : null}
                            onChange={(e) => { setFromData({ ...fromData, tempDistrict: e.target.value }); onInputChangeHandler(e) }}
                            id="basic-url"
                            aria-describedby="basic-addon3" placeholder={t("REGISTER.DISTRICT_PLACEHOLDER")}
                            maxLength={50} />

                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['tempDistrict'] &&
                            (formError['tempDistrict'] === "req"
                                ? ''
                                : 'Enter a valid District')}
                    </span>

                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.PINCODE")}
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="tempPincode" aria-describedby="basic-addon3" placeholder={t("REGISTER.PINCODE_PLACEHOLDER")}
                            className="form-control"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.tempPincode : null}
                            onChange={(e) => { setFromData({ ...fromData, tempPincode: e.target.value }); onInputChangeHandler(e) }}
                            maxLength={6}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['tempPincode'] &&
                            (formError['tempPincode'] === "req"
                                ? ''
                                : 'Enter a valid Pin Code')}
                    </span>

                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.PERSTATE")}
                        </InputGroup.Text>
                        <select name="tempState" aria-label="Default select example"
                            // className={
                            //     formError['tempState'] &&
                            //     (formError['tempState'] === "req"
                            //         ? "form-control  is-invalid"
                            //         : "form-control")}
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.tempState : null}
                            className="form-select customize_select"
                            onChange={(e) => { setFromData({ ...fromData, tempState: e.target.value }); onInputChangeHandler(e) }}
                        >
                            {
                                Data.states.map((state) => {
                                    return (
                                        <option value={state.key} selected={state.key === formData.state}>{state.name}</option>
                                    )
                                })
                            }
                        </select>
                    </InputGroup>



                    <div class="p-2 border text-white mt-4" style={{ backgroundColor: "#de8461", fontWeight: "bold" }}>{t("Register.D2")}
                    </div>
                    <div className="input" >
                        <InputGroup className="col-md-12"  >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.GRADUATION")}<span className="star_highlight">*</span>
                            </InputGroup.Text>
                            <select name="degree"
                                // className={
                                //     formError['degree'] &&
                                //     (formError['degree'] === "req"
                                //         ? "form-control  is-invalid"
                                //         : "form-control")}
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.degree : null}
                                onChange={(e) => { setFromData({ ...fromData, degree: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                <option value="" selected>{t("DROPDOWN.SELECTGRADUATION")}</option>
                                <option value="B.V.Sc.">B.V.Sc.</option>
                                <option value="B.V.Sc. & A.H.">B.V.Sc. & A.H.</option>
                                <option value="B.V.Sc. (Vet.)">B.V.Sc. (Vet.)</option>
                                <option value="B.V.A.Sc.">B.V.A.Sc.</option>
                                <option value="G.V.Sc.">G.V.Sc.</option>
                                <option value="G.V.Sc. & A.H.">G.V.Sc. & A.H.</option>
                                <option value="G.B.V.C.">G.B.V.C.</option>
                                <option value="L.V.P.">L.V.P.</option>
                                <option value="M.R.C.V.S.">M.R.C.V.S.</option>
                                <option value="B.Vet.Med.">B.Vet.Med.</option>
                                <option value="B.V.M.">B.V.M.</option>
                                <option value="OTHER">OTHER</option>
                            </select>
                        </InputGroup>
                        <InputGroup className="col-md-12" >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.COLLEGENAME")}<span className="star_highlight">*</span>
                            </InputGroup.Text>
                            <FormControl id="basic-url" name="graduationCollegeName" aria-describedby="basic-addon3" placeholder={t("REGISTER.COLLEGENAME_PLACEHOLDER")}
                                className={
                                    formError['graduationCollegeName'] &&
                                    (formError['graduationCollegeName'] === "req"
                                        ? "form-control  is-invalid"
                                        : "form-control")}
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.graduationCollegeName : null}
                                onChange={(e) => { setFromData({ ...fromData, graduationCollegeName: e.target.value }); onInputChangeHandler(e) }}
                                maxLength={100}
                            />
                        </InputGroup>
                        <span className="text-danger">
                            {
                                formError['graduationCollegeName'] &&
                                (formError['graduationCollegeName'] === "req"
                                    ? 'College Name is required'
                                    : 'Enter a valid College Name')}
                        </span>
                        <InputGroup className="col-md-12" >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.UNIVERSITYNAME")}<span className="star_highlight">*</span>
                            </InputGroup.Text>
                            <FormControl id="basic-url" name="graduationUniversityName" aria-describedby="basic-addon3" placeholder={t("REGISTER.UNIVERSITYNAME_PLACEHOLDER")}
                                className={
                                    formError['graduationUniversityName'] &&
                                    (formError['graduationUniversityName'] === "req"
                                        ? "form-control  is-invalid"
                                        : "form-control")}
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.graduationUniversityName : null}
                                onChange={(e) => { setFromData({ ...fromData, graduationUniversityName: e.target.value }); onInputChangeHandler(e) }}
                                maxLength={100}
                            />
                        </InputGroup>
                        <span className="text-danger">
                            {
                                formError['graduationUniversityName'] &&
                                (formError['graduationUniversityName'] === "req"
                                    ? 'University Name is required'
                                    : 'Enter a valid University Name')}
                        </span>
                        <InputGroup className="col-md-12" >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.COMPLETIONDATE")}<span className="star_highlight">*</span>
                            </InputGroup.Text>
                            <div className="col-sm-8" style={{ padding: '0px' }}>
                                <DatePicker3 className="form-control" name="graduationDate"
                                    value={(fromData) ? fromData.graduationDate : null}
                                    onChange={(date) => { setFromData({ ...fromData, graduationDate: date }) }}
                                    inputPlaceholder="dd/mm/yyyy"
                                    shouldHighlightWeekends
                                    formatInputText={() => formatInputValue(fromData && fromData.graduationDate)}
                                />
                            </div>
                        </InputGroup>
                        <span className="text-danger">
                            {
                                formError['graduationDate'] &&
                                (formError['graduationDate'] === "req"
                                    ? 'Course Completion Date is required'
                                    : 'Enter a valid Course Completion Date')}
                        </span>
                    </div>
                    <div class="p-2 border text-white mt-4" style={{ backgroundColor: "#de8461", fontWeight: "bold" }}>{t("Register.D4")}
                    </div>
                    <p className="mt-2 mandatory_section"> {t("REGISTER.FILEUPLOADINFO")}</p>

                    <InputGroup className="col-md-12 mt-4 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.PHOTO")} <span className="star_highlight">*</span>{t("REGISTER.PHOTO1")}
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name="upload_photo"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            className="select_image hidden" type="file"
                            style={{ color: 'transparent' }}
                        />
                        <label className='selected-file-name' name={fileDataName.upload_photo} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.upload_photo}</label>
                    </InputGroup>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.SIGNATURE")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name="signature"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            className="select_image" type="file"
                            style={{ color: 'transparent' }}
                        />
                        <label className='selected-file-name' name={fileDataName.signature} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.signature}</label>
                    </InputGroup>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.AADHARCARD")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name="andhar_card"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            className="select_image" type="file"
                            style={{ color: 'transparent' }}
                        />
                        <label className='selected-file-name' name={fileDataName.andhar_card} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.andhar_card}</label>
                    </InputGroup>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.COURSECOMPLETION")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name="degree_certificate"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            className="select_image" type="file"
                            style={{ color: 'transparent' }}
                        />
                        <label className='selected-file-name' name={fileDataName.degree_certificate} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.degree_certificate}</label>
                    </InputGroup>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.IDENTITYPROOF")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name="identity_proof"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            className="select_image" type="file"
                            style={{ color: 'transparent' }}
                        />
                        <label className='selected-file-name' name={fileDataName.identity_proof} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.identity_proof}</label>
                    </InputGroup>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.STATECERTIFICATE")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control className="select_image"
                            name="domicile_certificate"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            type="file"
                            style={{ color: 'transparent' }}
                        />
                        <label className='selected-file-name' name={fileDataName.domicile_certificate} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.domicile_certificate}</label>
                    </InputGroup>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.DOBPROOF")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name="dob_proof"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            className="select_image" type="file"
                            style={{ color: 'transparent' }}
                        />
                        <label className='selected-file-name' name={fileDataName.dob_proof} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.dob_proof}</label>
                    </InputGroup>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.PAYMENTPROOF")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name="payment_proof"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            className="select_image" type="file"
                            style={{ color: 'transparent' }}
                        />
                        <label className='selected-file-name' name={fileDataName.payment_proof} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.payment_proof}</label>
                    </InputGroup>
                    <div className="mandatory_section">{t("REGISTER.MANDATORYFILED")} (<span className="star_highlight">*</span>)</div>
                    <div class="row">
                        <div class="col-sm-12 text-center mb-5 mt-3">
                            <button id="btnSearch" class="btn center-block mr-4" onClick={() => onResetButtonClick()} >{t("REGISTER.RESET")}</button>
                            <button onClick={formSubmitHandler}
                                disabled={formSubmitButtonDisable}
                                // id={`${!formSubmitButtonDisable ? 'btnClear' : 'btnSearch'}`} 
                                id={'btnClear'}
                                class="btn center-block">{!!isProcessing ? t("REGISTER.PROCESSING") : t("REGISTER.SUBMIT")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />

        </>
    )

}
export default ProvisionalRegistration;