import React from 'react';
import './executiveCommittee.css';
import { useTranslation } from "react-i18next";
function ExecutiveCommitteeJsx() {
    const { t } = useTranslation();
    return (
        <>
            <div className="exe-comm">
                <div className="container">
                    <div className="executive_linking">{t("HEADER.HOME")} /{t("HEADER.ABOUTUS")}  / {t("ExecutiveCommittee.MAIN_HEAD")}</div>
                    <div className="heading">{t("ExecutiveCommittee.MAIN_HEAD")}</div>
                    <div id="title-space"></div>

                </div>
            </div>
        </>
    )

}
export default ExecutiveCommitteeJsx;