import React from 'react';
import ContactUsJsx from "./contactUs.jsx";
import contactUsJson from "./contactUs.json";

import "./contactUs.css";

export default function contactUS() {
  let contactUs= contactUsJson;
  return (
    <div>
      <ContactUsJsx
      contactUsdata = {contactUs} />
    </div>
  )
}
