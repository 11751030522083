import React, { Component } from "react";
import ActAndRulesJsx from "./actAndRules.jsx";
import axios from 'axios'
import { uploadURL } from "../../constants/applicationConstants";

class ActAndRules extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pdfList: null,
      page: 1,
      startDate:'',
      endDate:'',
      name:''
    }
  }

  componentDidMount() {
    this.getData();
  }

   getData = () =>{
    axios.get(`${uploadURL}pdfDocument/list?document_type=Laws And Acts&start_date=${this.state.startDate}&end_date=${this.state.endDate}&name=${this.state.name}`, {
      headers: {
        'access-token': JSON.parse(sessionStorage.getItem('authToken')),
        'role': sessionStorage.getItem('role')
      }
    }).then(res => {
      this.setState({ pdfList: res?.data.data })
    })
  }

  getProperDateFormat = (date) =>{
    if(date){
        const data = date.split('/');
        return `${data[1]}/${data[0]}/${data[2]}`
    }
        return ''
  }


  onPaginationClick = (page) => {
    this.setState({ page: page })
  }

  onFilterChange = (data) =>{
    const {startDate,endDate,searcValue} = data;
    this.setState({startDate:this.getProperDateFormat(startDate),endDate:this.getProperDateFormat(endDate),name:searcValue},()=>this.getData())
  }

  render() {
    return (
      <ActAndRulesJsx
        pdfList={this.state.pdfList}
        onPaginationClick={this.onPaginationClick}
        onFilterChange={this.onFilterChange}
      />
    );
  }
}

export default ActAndRules;