import React from "react";
import ExecutiveCommitteeJsx from "./executiveCommittee.jsx";

function ExecutiveCommittee(){

    return (
      <ExecutiveCommitteeJsx
      />
    );
  }

export default ExecutiveCommittee;