import UploadAlbumJsx from "./uploadAlbum.jsx";

function UploadImage(){

    return (
      <UploadAlbumJsx
      />
    );
  }

export default UploadImage;