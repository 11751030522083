import React from 'react';
import { Component } from 'react';
import {AdminLoginDashboard} from './adminLoginDasboard';

export default class AdminLogin extends Component{
    render(){
        return(
            <AdminLoginDashboard />
        )
    }
}