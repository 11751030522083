import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Pagination } from '../admin/pagination';
import { baseURL } from "../../constants/applicationConstants";


export const UploadNewsJSX = (props) => {
    const { data, onPaginationClick,getListData } = props;

    const [formData, setFormData] = useState({
        news: '',
        newsInMarathi: '',
        url: ''
    });

    useEffect(() => {
        isFromSubmissionBlock()
    })

    const [formError, setFormError] = useState({})
    const [formSubmitButtonDisable, setFormSubmitButtonDisable] = useState(true)
    let [isProcessing, setProcessing] = useState(false);
    // let [loading, setLoading] = useState(false);
    const [editID, setEditID] = useState(null);

    const onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value) {
            if (!inputRegexValidation(name, value)) {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "regx" })
            } else {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "" })
            }
        } else {
            setFormData({ ...formData, [name]: '' })
            setFormError({})
        }
    };

    const inputRegexValidation = (name, value) => {
        return true;
    };

    const onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            setFormError({ ...formError, [name]: "req" })
        } else {
            if (!inputRegexValidation(name, value)) {
                setFormError({ ...formError, [name]: "regx" })
            }
        }
    }

    const isFromSubmissionBlock = () => {
        let { news, newsInMarathi, url } = formData;
        if (news && newsInMarathi && url) {
            if (
                news && newsInMarathi && url
            ) {
                setFormSubmitButtonDisable(false)
            } else {
                setFormSubmitButtonDisable(true)
            }
        } else {
            setFormSubmitButtonDisable(true)
        }
    };

    const onResetButtonClick = () => {
        setFormData({
            news: '',
            newsInMarathi: '',
            url: ''
        })
    }

    const formSubmitHandler = (e) => {
        e.preventDefault();
        // setLoading(true)
        setProcessing(true);

        const payload = {
            title: formData.news,
            marathi_title: formData.newsInMarathi,
            url: formData.url
        }

        if (editID) {
            axios.put(`${baseURL}news/${editID}`, payload, {
                headers: {
                    'access-token': JSON.parse(sessionStorage.getItem('authToken')),
                    'role': sessionStorage.getItem('role')
                }
            }).then(resp => {
                // setLoading(false);
                    toast.success("News Updated Successfully!")
                    setProcessing(false);
                    onResetButtonClick();
                    getListData();
            }).catch((err) => {
                toast.error(err?.response?.data.message)
                // setLoading(false);
                setProcessing(false);
            })
        } else {
            axios.post(`${baseURL}news`, payload, {
                headers: {
                    'access-token': JSON.parse(sessionStorage.getItem('authToken')),
                    'role': sessionStorage.getItem('role')
                }
            }).then(resp => {
                // setLoading(false);
                if (resp.data.status === 'success') {
                    toast.success("News Added Successfully!")
                    setProcessing(false);
                    onResetButtonClick();
                    getListData();
                } else {
                    toast.error("Something wrong!")
                    setProcessing(false);
                }
            }).catch((err) => {
                toast.error(err?.response?.data.message)
                // setLoading(false);
                setProcessing(false);
            })
        }

    }

    const onDeleteClick = (data) => {
        axios.delete(`${baseURL}news/${data.id}`, {
            headers: {
                'access-token': JSON.parse(sessionStorage.getItem('authToken')),
                'role': sessionStorage.getItem('role')
            }
        }).then(res => {
            toast.success('Record Deleted Succesfully')
            getListData();
            onResetButtonClick();
        }).catch(() => {
            toast.error('Something went ')
        })
    }

    const onEditClick = (data) => {
        setEditID(data.id);
        setFormData({
            news: data?.title,
            newsInMarathi: data?.marathi_title,
            url: data?.url,
        })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    const { t } = useTranslation();

    const getURL = (url) =>{
        if(!url.includes('https://')){
            return `https://${url}`
        }
        else
            return url;
    }

    return (
        <div className="container officeStaffComponentOuter">
            <div className="bread_crums mt-3"><Link to="/">{t("FAQ.HOME")}</Link>/ <Link to="/uploadNews">{t("UPLOADNEWS.TITLE")}</Link></div><span className="print" onClick={() => window.print()}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="skyBlue" class="bi bi-printer-fill" viewBox="0 0 16 16">
                <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
            </svg> &nbsp; {t("PRINT.TITLE")}</span>
            <h2 className="loginHeading mt-2">
                {t("UPLOADNEWS.TITLE")}
            </h2>
            <hr />

            <div className="input" >
                <InputGroup className="col-md-12"  >
                    <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                        {t("UPLOADNEWS.NEWS")}<span className="star_highlight">*</span>
                    </InputGroup.Text>
                    <FormControl id="basic-url" name='news' onBlur={onTouchHandler} aria-describedby="basic-addon3" value={formData.news} onChange={onInputChange} placeholder={t("UPLOADNEWS.NEWS_PLACEHOLDER")} maxLength={500}/>
                </InputGroup>
                <span className="text-danger">
                    {
                        formError['news'] &&
                        (formError['news'] === "req"
                            ? 'News is required'
                            : 'Enter a valid News')}
                </span>

                <InputGroup className="col-md-12"  >
                    <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                        {t("UPLOADNEWS.NEWSMARATHI")}<span className="star_highlight">*</span>
                    </InputGroup.Text>
                    <FormControl id="basic-url" name='newsInMarathi' onBlur={onTouchHandler} aria-describedby="basic-addon3" value={formData.newsInMarathi} onChange={onInputChange} placeholder={t("UPLOADNEWS.NEWSMARATHI_PLACEHOLDER")} maxLength={500}/>
                </InputGroup>
                <span className="text-danger">
                    {
                        formError['newsInMarathi'] &&
                        (formError['newsInMarathi'] === "req"
                            ? 'News In Marathi is required'
                            : 'Enter a valid News In Marathi')}
                </span>

                <InputGroup className="col-md-12"  >
                    <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                        {t("UPLOADNEWS.URL")}<span className="star_highlight">*</span>
                    </InputGroup.Text>
                    <FormControl id="basic-url" name='url' onBlur={onTouchHandler} aria-describedby="basic-addon3" value={formData.url} onChange={onInputChange} placeholder={t("UPLOADNEWS.URL_PLACEHOLDER")} />
                </InputGroup>
                <span className="text-danger">
                    {
                        formError['url'] &&
                        (formError['url'] === "req"
                            ? 'URL is required'
                            : 'Enter a valid URL')}
                </span>


                <div class="row">
                    <div class="col-sm-12 text-center mb-5 mt-3">
                        <button id="btnSearch" class="btn center-block mr-4" onClick={() => onResetButtonClick()} >{t("REGISTER.RESET")}</button>
                        <button id="btnClear" disabled={formSubmitButtonDisable} onClick={formSubmitHandler} class="btn center-block">{!!isProcessing ? t("REGISTER.PROCESSING") : t("REGISTER.SUBMIT")}</button>
                    </div>
                </div>

            </div>

            <div className="scroll_table" style={{ marginBottom: '20px' }}>
                <table>
                    <thead style={{ background: 'white' }}>
                        <th>{t("UPLOADNEWS.SR")}</th>
                        <th>{t("UPLOADNEWS.NEWS")}</th>
                        <th> {t("UPLOADNEWS.NEWSMARATHI")}</th>
                        <th> {t("UPLOADNEWS.URL")}</th>
                        <th> {t("UPLOADNEWS.EDIT")}</th>
                        <th> {t("UPLOADNEWS.DELETE")}</th>
                    </thead>
                    <tbody>

                        {
                            data?.rows?.map((data, index) => {
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{data.title}</td>
                                        <td>{data.marathi_title}</td>
                                        <td><a href={getURL(data.url)} target="_blank" rel="noreferrer">{data.url}</a></td>
                                        <td style={{ cursor: 'pointer', color: 'blue' }} onClick={() => onEditClick(data)}>{t("UPLOADNEWS.EDIT")}</td>
                                        <td style={{ cursor: 'pointer', color: 'blue' }} onClick={() => onDeleteClick(data)}>{t("UPLOADNEWS.DELETE")}</td>
                                    </tr>

                                )
                            })
                        }
                        {
                            data?.length < 1 && <tr><td colSpan={6} style={{ textAlign: 'center',fontWeight:600  }}>{t("ADMINDASHBOARD.NORECORD")}</td></tr>
                        }
                    </tbody>
                </table>

            </div>
            {
                <Pagination limit={10} totalCount={data?.count ?? 0} onPaginationButtonClick={(page) => onPaginationClick(page)} />
            }
        <ToastContainer />
        </div>
    )
}