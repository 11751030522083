import { React, useEffect } from 'react';
import "./officeStaff.css";
import officeStaff from "./officeStaff.json";

import { useTranslation } from "react-i18next";
import axios from 'axios';
import { baseURL } from "../../constants/applicationConstants";
import img2 from "../../assets/images/Mrunali.png";
import img1 from "../../assets/images/Baldev.png";
import defaultImg from "../../assets/images/man.png";
import { Link } from "react-router-dom";


const OfficeStaffJsx = () => {
  const { t } = useTranslation();
  // const { mode, updateTheme } = useContext(themeContext)
  // const [doctorsData, setDoctorsData] = useState([]);


  useEffect(() => {
    staffLick()
  }, [])

  const getImage = (header) => {
    if (header === 'abc') {
      return img1;
    } else if (header === 'xyz') {
      return img2;
    } else {
      return defaultImg;
    }
  }

  const staffLick = () => {
    axios.get(`${baseURL}getDoctors?limit=20`).then(resp => {
      if (resp.status === 200) {
        // setDoctorsData(resp.data.rows)
      } else {

      }
    }).catch((err) => {

    })
  };

  return (
    <div className="container officeStaffComponentOuter">
      <div  className="bread_crums mt-3"><Link to="/">{t("UPLOAD_PRESS_MAIN_IMAGE.HOME")}</Link>/ <Link to="/officeStaff"> {t(`${officeStaff.heading}`)}</Link></div>
      <h2 className="officeStaffHeadline">
        {t(`${officeStaff.heading}`)}
      </h2>
      <div className="officeStaffmainFlexDiv row">
        {/* {officeStaff?.ARR?.map((item, index) => {
          return (
            <div className="col-sm-4 main_container_div">
            <div key={index} className="officeStaffComponent">
              <h5 className ={(mode==='dark')?'officeStaffName officeStaffName_dark':'officeStaffName'}>{t(`${item.name}`)}</h5>
              <div className="officeStaffHeadDiv">
                <img src={linkIcon} className="officeStaffLinkIcon" />
                <a href={t(`profileDetails/${item.id}`)} className="officeStaffLink">More {t(`${item.name}`)}</a>
              </div>
            </div>
            </div>
          )
        })} */}
        <div className='office-staff-details-container'>
          {
            officeStaff?.data?.map((data) => {
              return < div className='office-staff-details'>
                <div className='image-section'>
                <img className="card-image" src={getImage(t(`${data.image}`))} alt=""></img>
                </div>
                <div>
                  {
                    data.values.map((value) => {
                      return <div className='details-section'>
                        <div className='left-side'>{t(`${value.name}`)}</div>
                        <div className='middle'>{':-'}</div>
                        <div className='right-side'>{t(`${value.value}`)}</div>
                      </div>
                    })
                  }
                </div>
              </div>
            })
          }
        </div>
      </div>
    </div>

  );
};

export default OfficeStaffJsx;
