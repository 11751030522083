import React from 'react';
import { useTranslation } from "react-i18next";
import img_1 from "../../assets/images/siteMap-arrow.svg";
import "./siteMap.css"
import { Link } from "react-router-dom";

export default function SiteMapJsx(props) {
    const { t } = useTranslation();
    let { siteMapData } = props;
    const aarowImg = "SITEMAP.SITEMAP_ARROW_IMAGE";
    const getImage = (ARROW_IMAGE) => {
        if (ARROW_IMAGE === aarowImg) {
            return img_1;
        }
    }
    return (
        siteMapData.map((index) => (
            <>
               
                <div className="siteMap_container">
                    <div className="container">
                    <div className="bread_crums mt-3"><Link to="/">{t("HEADER.HOME")}</Link>/ <Link to="/siteMap">{t("SITEMAP.TITLE")}</Link></div>
                    <div className="siteMap_heading">{t("SITEMAP.TITLE")}</div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="sitemap_menu"><span className="arrow_img"><img className="arrow" src={getImage(index.ARROW_IMAGE)} alt=""></img></span><span className="sitemap_menu_data">{t(`${index.SITEMAP_MENU_1}`)}</span></div>
                            </div>
                            <div className="col-md-4">
                                <div className="sitemap_menu"><span className="arrow_img"><img className="arrow" src={getImage(index.ARROW_IMAGE)} alt=""></img></span><span className="sitemap_menu_data">{t(`${index.SITEMAP_MENU_2}`)}</span></div>
                            </div>
                            <div className="col-md-4">
                                <div className="sitemap_menu"><span className="arrow_img"><img className="arrow" src={getImage(index.ARROW_IMAGE)} alt=""></img></span><span className="sitemap_menu_data">{t(`${index.SITEMAP_MENU_3}`)}</span></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-1"></div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3">
                                <div className="sitemap_menu"><span className="arrow_img"><img className="arrow" src={getImage(index.ARROW_IMAGE)} alt=""></img></span><span className="sitemap_menu_subdata">{t(`${index.SITEMAP_MENU_4}`)}</span></div>
                                <div className="sitemap_menu"><span className="arrow_img"><img className="arrow" src={getImage(index.ARROW_IMAGE)} alt=""></img></span><span className="sitemap_menu_subdata">{t(`${index.SITEMAP_MENU_5}`)}</span></div>
                                <div className="sitemap_menu"><span className="arrow_img"><img className="arrow" src={getImage(index.ARROW_IMAGE)} alt=""></img></span><span className="sitemap_menu_subdata">{t(`${index.SITEMAP_MENU_6}`)}</span></div>
                                <div className="sitemap_menu"><span className="arrow_img"><img className="arrow" src={getImage(index.ARROW_IMAGE)} alt=""></img></span><span className="sitemap_menu_subdata">{t(`${index.SITEMAP_MENU_7}`)}</span></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="sitemap_menu"><span className="arrow_img"><img className="arrow" src={getImage(index.ARROW_IMAGE)} alt=""></img></span><span className="sitemap_menu_data">{t(`${index.SITEMAP_MENU_8}`)}</span></div>
                                <div className="sitemap_menu"><span className="arrow_img"><img className="arrow" src={getImage(index.ARROW_IMAGE)} alt=""></img></span><span className="sitemap_menu_data">{t(`${index.SITEMAP_MENU_9}`)}</span></div>
                                <div className="sitemap_menu"><span className="arrow_img"><img className="arrow" src={getImage(index.ARROW_IMAGE)} alt=""></img></span><span className="sitemap_menu_data">{t(`${index.SITEMAP_MENU_10}`)}</span></div>
                            </div>
                            <div className="col-md-4">
                                <div className="sitemap_menu"><span className="arrow_img"><img className="arrow" src={getImage(index.ARROW_IMAGE)} alt=""></img></span><span className="sitemap_menu_data">{t(`${index.SITEMAP_MENU_11}`)}</span></div>
                                <div className="sitemap_menu"><span className="arrow_img"><img className="arrow" src={getImage(index.ARROW_IMAGE)} alt=""></img></span><span className="sitemap_menu_data">{t(`${index.SITEMAP_MENU_12}`)}</span></div>
                                <div className="sitemap_menu"><span className="arrow_img"><img className="arrow" src={getImage(index.ARROW_IMAGE)} alt=""></img></span><span className="sitemap_menu_data">{t(`${index.SITEMAP_MENU_13}`)}</span></div>
                            </div>
                            <div className="col-md-4">
                                <div className="sitemap_menu"><span className="arrow_img"><img className="arrow" src={getImage(index.ARROW_IMAGE)} alt=""></img></span><span className="sitemap_menu_data">{t(`${index.SITEMAP_MENU_14}`)}</span></div>
                                <div className="sitemap_menu"><span className="arrow_img"><img className="arrow" src={getImage(index.ARROW_IMAGE)} alt=""></img></span><span className="sitemap_menu_data">{t(`${index.SITEMAP_MENU_15}`)}</span></div>
                                <div className="sitemap_menu"><span className="arrow_img"><img className="arrow" src={getImage(index.ARROW_IMAGE)} alt=""></img></span><span className="sitemap_menu_data">{t(`${index.SITEMAP_MENU_16}`)}</span></div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )

        )


    )
}
