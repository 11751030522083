import React from 'react';
import SiteMapJsx from "./siteMap.jsx";
import siteMapJson from "./siteMap.json";

export default function SiteMap() {
    let MapData = siteMapJson;
    return (
      
        <div>
            <SiteMapJsx 
            siteMapData = {MapData}
            />
        </div>
    )
}
