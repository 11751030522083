import React from "react";
import "./help.css";
import help from "./help.json";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const HelpJsx = () => {
  const { t } = useTranslation();

  return (
    <div className="helpComponentOuter">
       <div className="container bread_crums mt-3"><Link to="/">{t("HEADER.HOME")}</Link>/ <Link to="/help">{t(`${help.MAIN_HEAD}`)}</Link></div>
    <h2 className="helpHeadline">
        {t(`${help.MAIN_HEAD}`)}
      </h2>
      <div className="helpmainFlexDiv">
  {help.ARR.map((item, index) => {
    return (
<div className="helpInnerDiv" key={index}>
        <h4 className="helpSubHeadline">
        {t(`${item.SUB_HEAD}`)}
      </h4>
      <p>
      {t(`${item.SUB_PARA}`)}
      </p>
    {/* <a href={t(`${item.LINK}`)} target="_blank" className="officeStaffLink">
        {t(`${item.LINK_PARA}`)}
        </a> */}
</div>
) })}
    </div>
    </div>
  );
};

export default HelpJsx;
