import React from 'react';
import './actAndRules.css';
import { useTranslation } from "react-i18next";
import { useState, useEffect } from 'react';
import pdf from '../../assets/images/path.png';
import { Link } from "react-router-dom";
import { uploadURL } from '../../constants/applicationConstants';
import { Pagination } from '../admin/pagination';
import DatePicker3 from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

function ActAndRulesJsx(props) {
  const {pdfList,onPaginationClick,onFilterChange}= props;
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  const [searcValue, setSearchValue] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // const PDF = "pdf";

  // const getIcon = (iconInfo) => {
  //   if (iconInfo === PDF) {
  //     return pdf;
  //   } else {
  //     return "";
  //   }
  // }

  const getData = () => {
    fetch('data.json'
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson)
      });
  }
  useEffect(() => {
    getData()
  }, [])

  const formatInputValue = (date) => {
    if (!date) return '';
    return `${date.day}/${date.month}/${date.year}`;
  };

  const onSearchValueChange = (e) => {
    setSearchValue(e.target.value);
    onFilterChange({startDate:formatInputValue(startDate),endDate:formatInputValue(endDate),searcValue:e.target.value})
  }

  const onResetButtonClick = () =>{
    setStartDate(null);
    setEndDate(null)
    onFilterChange({startDate:'',endDate:'',searcValue})
  }

  const onSearchButtonClick = () =>{
    onFilterChange({startDate:formatInputValue(startDate),endDate:formatInputValue(endDate),searcValue})
  }

  const DisplayData = pdfList?.rows?.map(
    (item) => {

      return (
        <tr id="tablerow">
          <td>{item.name}</td>
          <td>{item.uploaded_date}</td>
          <td><a href={`${uploadURL}pdfDocuments/${item.file_path}`} target="_blank" rel="noreferrer"><img src={pdf} height="25px" width="25px" alt=""/></a></td>
          <td>{item.local_language_name}</td>
        </tr>
      )
    }
  )

  return (
    <>
      <div className="container">
        <div  className="bread_crums mt-3"><Link to="/">{t("UPLOAD_PRESS_MAIN_IMAGE.HOME")}</Link>/ <Link to="/actandrules">{t("ACTSANDRULES.MAIN_HEAD")}</Link></div><span className="print"  onClick={() => window.print()}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="skyBlue" class="bi bi-printer-fill" viewBox="0 0 16 16">
          <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
          <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
        </svg> &nbsp; {t("PRINT.TITLE")}</span>
        <div className="heading">{t("ACTSANDRULES.MAIN_HEAD")}</div>

        <div className='search-filters'>
          <div>
            <div className='filter-labels'>{t("ACTSANDRULES.START_DATE")}</div>
            <DatePicker3 className="form-control" name="startDate"
              value={startDate ?? null}
              onChange={(date) => { setStartDate(date) }}
              inputPlaceholder="dd/mm/yyyy"
              shouldHighlightWeekends
              formatInputText={() => formatInputValue(startDate)}
            />
          </div>
          <div className='end-date-filter'>
            <div className='filter-labels'>{t("ACTSANDRULES.END_DATE")}</div>
            <DatePicker3 className="form-control" name="endDate"
              value={endDate ?? null}
              onChange={(date) => { setEndDate(date) }}
              inputPlaceholder="dd/mm/yyyy"
              shouldHighlightWeekends
              formatInputText={() => formatInputValue(endDate)}
            />
          </div>

          <button 
            className='reset-button'
            onClick={onResetButtonClick}
          >
          {t("ACTSANDRULES.RESET")}
          </button>
          <button 
            className='search-button'
            onClick={onSearchButtonClick}
            disabled={!(startDate && endDate)}
          >
          {t("ACTSANDRULES.SEARCH")}
          </button>

          <div className='keyword-based-filter'>
            <div className='filter-labels'>{t("ACTSANDRULES.KEYWORD_BASED_SEARCH")}</div>
            <input className="form-control"
            type={'text'}
            value={searcValue ?? ''}
            onChange={(e) => onSearchValueChange(e)}
          />
          </div>

        </div>
       

        <table className="table .table-borderless">
          <tr className="tableHead">
            <th>{t("ACTSANDRULES.DOCUMENT_NAME")}</th>
            <th>{t("ACTSANDRULES.DATE")}</th>
            <th>{t("ACTSANDRULES.PATH")}</th>
            <th>{t("ACTSANDRULES.SIZE")}</th>
          </tr>
          <tbody>
            {DisplayData}
            {
                            pdfList?.rows?.length < 1 && <tr><td colSpan={7} style={{ textAlign: 'center',fontWeight:600  }}>{t("ADMINDASHBOARD.NORECORD")}</td></tr>
                        }
          </tbody>
        </table>
        <div id="circularspace-footer"></div>
        {
                <Pagination limit={10} totalCount={data?.count ?? 0} onPaginationButtonClick={(page) => onPaginationClick(page)} />
            }
      </div>
    </>
  )

}
export default ActAndRulesJsx;