import React from 'react';
import HelpJsx from './help.jsx'
const Help =()=> {
    return (
        <HelpJsx
        />
    )
}

export default Help
