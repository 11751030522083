import TermAndConditionJsx from "./termAndCondition.jsx";

function TermAndCondition(){

    return (
      <TermAndConditionJsx
      />
    );
  }

export default TermAndCondition;