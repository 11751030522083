import React from "react";
import ForgotPassJsx from "./forgotPass.jsx";

function ForgotPass(){

    return (
      <ForgotPassJsx
      />
    );
  }

export default ForgotPass;