import { React, useContext  } from 'react';
import './footer.css';
// import img2 from "../../assets/images/gigwLogo.svg";
// import img1 from "../../assets/images/logo.png";
import CompanyLogo from '../../assets/images/CompanyLogo.png'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import themeContext from '../../context/themeContext';


export const Footer = (props) => {
    const {mode} = useContext(themeContext)
    const { t } = useTranslation();
    let { footerData } = props;
    // const gigwlogoImg = "FOOTER.FOOTER_GIGELOGO_IMAGE";
    // const logoMOL = "FOOTER.FOOTER_LOGOMOL_IMAGE";
    // const getImage = (IMAGE) => {
    //     if (IMAGE === gigwlogoImg) {
    //         return img1;
    //     } else if (IMAGE === logoMOL) {
    //         return img2;
    //     }
    // }

    return (
        footerData.map((data) => (
            <footer className ={(mode==='dark')?'footer_whole footer_whole_dark':'footer_whole'}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3">
                            <ul className ={(mode==='dark')?'logoTxt logoTxt-dark':'logoTxt'}>
                                <li>
                                    <div className=" footer-link-second"><Link to={"/disclaimer"} className="footer-link" href="#">
                                        {t(`${data.FOOTER_MENU_1}`)}
                                    </Link></div>                                </li>

                                <li>
                                     <div className=" footer-link-second"><Link to={"/termAndCondition"} className="footer-link" href="#">
                                        {t(`${data.FOOTER_MENU_2}`)}
                                    </Link></div>
                                </li>

                                <li>
                                     <div className=" footer-link-second"><Link to={"/accessibilityStatement"} className="footer-link" href="#">
                                        {t(`${data.FOOTER_MENU_3}`)}
                                    </Link></div>
                                </li>
                                <li>
                                     <div className=" footer-link-second"><Link to={"/siteMap"} className="footer-link" href="#">
                                        {t(`${data.FOOTER_MENU_5}`)}
                                    </Link></div>
                                </li>
                                <li>
                                     <div className=" footer-link-second"><Link to={"/help"} className="footer-link" href="#">
                                        {t(`${data.FOOTER_MENU_4}`)}
                                    </Link></div>                                </li>
                            </ul>
                        </div>

                        <div class="col-sm-4">
                            {/* <ul >
                                <div className=" footer-link-second">{t(`${data.FOOTER_MENU_6}`)}</div>

                                <div className="footer-link-second">{t(`${data.FOOTER_MENU_7}`)} </div>

                                <div className="footer-link-second ">{t(`${data.FOOTER_MENU_8}`)}</div>

                            </ul> */}
                        </div>

                        <div class="col-sm-4">
                            <ul className ={(mode==='dark')?'social social_dark':'social'}>
                                <span >{t("FOOTER.MAINTAINED_BY")}</span>
                                {/* <div><img src={getImage(data.IMAGE_1)} alt="img1" className="logo"></img></div> */}

                                <div><img src={CompanyLogo} alt="img2" className="img-right"></img></div>
                                {/* <div style={{fontSize:'22px',color:'white'}}>{'ProSofos India Private Limited'}</div> */}

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="copyright">{t(`${data.FOOTER_MENU_9}`)}</div>

            </footer>
        )
        )

    );

};
