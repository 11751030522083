import ScreenReaderJsx from "./screenReader.jsx";

function ScreenReader(){

    return (
      <ScreenReaderJsx
      />
    );
  }

export default ScreenReader;