import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { baseURL, uploadURL } from "../../constants/applicationConstants";
import './viewImage.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { useTranslation } from "react-i18next";
// import { useHistory } from "react-router";

export const ViewImageJsx = () => {
    // const history = useHistory();

    // let [isProcessing, setProcessing] = useState(false);
    // let [loading, setLoading] = useState(false);

    const [photoListData, setPhotoData] = useState([]);

    // const { t } = useTranslation();

    useEffect(() => {
        getDoctorDetails()
    }, [])

    const getDoctorDetails = () => {
        const id = window.location.pathname.replace('/viewImage/', '');
        // const id= sessionStorage.getItem('masterID');
        axios.get(`${baseURL}albumPhotos/?album_id=${id}`).then((res) => {
            setPhotoData(res.data.result)

        }).catch((res) => {
            // setProcessing(false);
            toast.error(res.response?.data.message)
        })
    }


    return (
        <>
            <div className="container officeStaffComponentOuter">

                <h4>Album List</h4>
                {photoListData.length > 0 ? (
                    <div className="mb-5 mt-2">
                        {
                            photoListData?.map((data, index) => {
                                return (
                                    <>
                                        <img alt="album" src={`${uploadURL}albums/${data.file_path}`} className="preview_img ml-2"></img>

                                        <div>{data.title}</div>
                                    </>
                                )
                            })
                        }
                    </div>
                ) : <h6 className="text-center mb-4">No Albums Found.</h6>}

            </div>

            <ToastContainer />
        </>
    )
}
export default ViewImageJsx;