import React, { useState, useEffect } from 'react'
import "./doctorDetails.css"
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { baseURL, uploadURL, getProperDateMonth } from '../../constants/applicationConstants';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker3 from "react-modern-calendar-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DoctorDetailsJsx = (props) => {
  // const defaultValue = {
  //   year: 2019,
  //   month: 10,
  //   day: 5,
  // };

  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  // This arrangement can be altered based on how we want the date's format to appear.
  let currentDate = `${day}-${month}-${year}`;
  const newData = currentDate.split('-')
  console.log("iiiiiii",  parseInt(newData[2]) + 5)
  const defaultValue = {
    day: parseInt(newData[0]),
    month: parseInt(newData[1]),
    year: parseInt(newData[2])
  };
  const defaultValue1 = {
    day: parseInt(newData[0]),
    month: parseInt(newData[1]),
    year: parseInt(newData[2]) + 5
  };

  console.log(date);
  const history = useHistory();
  const { details, getDoctorDetails } = props;
  const [formData, setFormData] = useState({ radioValue: "1", startDate: null, endDate: null });
  const [selectedDay, setSelectedDay] = useState(defaultValue);
  const [selectedDay1, setSelectedDay1] = useState(defaultValue1);


  // const [formError, setFormError] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const [isCertificateSend, setIsCertificateSend] = useState(false);
  const [isIdentitySend, setIsIdentitySend] = useState(false);
  // const [imagePath, setImagePath] = useState("");
  const [comment, setComment] = useState("");


  useEffect(() => {
    const startData = details?.result?.registration_valid_from?.split('-');
    const endData = details?.result?.registration_valid_upto?.split('-');
    setFormData({
      ...details?.result,
      radioValue: details?.result?.account_status,
      startDate: details?.result?.registration_valid_from && {
        day: startData[2],
        month: startData[1],
        year: parseInt(startData[0])
      },
      endDate: details?.result?.registration_valid_upto && {
        day: endData[2],
        month: endData[1],
        year: parseInt(endData[0])
      }
    });
  }, [details]);

  const onRadioButtonClick = (e) => {
    let value = e.target.value;
    setFormData({ ...formData, radioValue: value })
  };

  const onUpdateButtonClick = () => {
    setIsLoading(true);
    const startDate1 = formData.startDate && `${formData.startDate?.day}/${formData.startDate?.month}/${formData.startDate.year}`
    const endDate1 = formData.endDate && `${formData.endDate?.day}/${formData.endDate?.month}/${formData.endDate.year}`
// 
const newStartDate = selectedDay && `${selectedDay?.day}/${selectedDay?.month}/${selectedDay.year}`
    const newEndDate = selectedDay1 && `${selectedDay1?.day}/${selectedDay1?.month}/${selectedDay1.year}`


const goingStartDate = newStartDate?.split('/')
const goingEndDate = newEndDate?.split('/')

    const startDate = startDate1?.split('/')
    const endDate = endDate1?.split('/');
    const payload = {
      account_status: parseInt(formData.radioValue),
      registration_valid_from:selectedDay ? `${getProperDateMonth(goingStartDate[0])}/${getProperDateMonth(goingStartDate[1])}/${goingStartDate[2]}` : startDate && `${getProperDateMonth(startDate[0])}/${getProperDateMonth(startDate[1])}/${startDate[2]}`,
      registration_valid_upto:selectedDay1 ? `${getProperDateMonth(goingEndDate[0])}/${getProperDateMonth(goingEndDate[1])}/${goingEndDate[2]}` : endDate && `${getProperDateMonth(endDate[0])}/${getProperDateMonth(endDate[1])}/${endDate[2]}`
    }

    console.log("nisha1111",payload)
    axios.put(`${baseURL}doctor/${formData.id}/status`, payload, {
      headers: {
        'access-token': JSON.parse(sessionStorage.getItem('authToken')),
        'role': sessionStorage.getItem('role')
      }
    }).then((res) => {
      setIsLoading(false)
      toast.success("Status Updated")
      getDoctorDetails();
    }).catch((res) => {
      setIsLoading(false)
      toast.error(res?.response.data.message)
    })
  }
  // const openModal = async (path) => {
  //   setImagePath(path)

  // }
  const onRejectButtonClick = () => {
    const payload = {
      comments: comment,
    }
    axios.put(`${baseURL}doctor/${formData.id}/reject`, payload, {
      headers: {
        'access-token': JSON.parse(sessionStorage.getItem('authToken')),
        'role': sessionStorage.getItem('role')
      }
    }).then((res) => {
      setIsLoading(false)
      toast.success("Successfully Rejected.")
      setTimeout(() => {
        history.push(`/adminDashboard`)
        window.location.reload()
      }, 3000)

    }).catch((res) => {
      setIsLoading(false)
      toast.error(res?.response.data.message)
    })
  }

  const onChangeHandler = (e) => {
    setComment(e.target.value)
  }
  // const onInputChangeHandler = (e) => {
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   if (value) {
  //     if (!inputRegexValidation(name, value)) {
  //       setFormData({ ...formData, [name]: value })
  //       setFormError({ ...formError, [name]: "regx" })
  //     } else {
  //       setFormData({ ...formData, [name]: value })
  //       setFormError({ ...formError, [name]: "" })
  //     }
  //   } else {
  //     setFormData({ ...formData, [name]: '' })
  //     setFormError({})
  //   }
  // };

  // const onTouchHandler = (e) => {
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   if (value === "") {
  //     setFormError({ ...formError, [name]: "req" })
  //   } else {
  //     if (!inputRegexValidation(name, value)) {
  //       setFormError({ ...formError, [name]: "regx" })
  //     }
  //   }
  // }

  // const inputRegexValidation = (name, value) => {
  //   if (name === 'startDate' || name === 'endDate')
  //     return value.split('-')[0] < 9999
  //   else
  //     return true;
  // };

  const onCertificateClick = () => {
    setIsCertificateSend(true);
    axios.post(`${baseURL}doctor/${formData.id}/registrationCertificate`, null, {
      headers: {
        'access-token': JSON.parse(sessionStorage.getItem('authToken')),
        'role': sessionStorage.getItem('role')
      }
    }).then((res) => {
      setIsCertificateSend(false)
      toast.success(res?.data.message)
    }).catch((res) => {
      setIsCertificateSend(false)
      toast.error(res?.response.data.message)
    })
  }

  const onProvisionalCertificateClick = () => {
    setIsCertificateSend(true);
    axios.post(`${baseURL}doctor/${formData.id}/provisionalCertificate`, null, {
      headers: {
        'access-token': JSON.parse(sessionStorage.getItem('authToken')),
        'role': sessionStorage.getItem('role')
      }
    }).then((res) => {
      setIsCertificateSend(false)
      toast.success(res?.data.message);
      getDoctorDetails();
    }).catch((res) => {
      setIsCertificateSend(false)
      toast.error(res?.response.data.message)
    })
  }

  const onIdentityClick = () => {
    setIsIdentitySend(true);
    axios.post(`${baseURL}doctor/${formData.id}/identityCard`, null, {
      headers: {
        'access-token': JSON.parse(sessionStorage.getItem('authToken')),
        'role': sessionStorage.getItem('role')
      }
    }).then((res) => {
      setIsIdentitySend(false)
      toast.success(res?.data.message)
    }).catch((res) => {
      setIsIdentitySend(false)
      toast.error(res?.response.data.message)
    })
  }

  const onViewCertificateClick = () => {
    window.open(`${uploadURL}${formData.registration_certificate_path}`, '_blank')
  }

  const onViewProvisionalCertificateClick = () => {
    window.open(`${uploadURL}${formData.provisional_certificate_path}`, '_blank')
  }

  const onViewIdentityClick = () => {
    window.open(`${uploadURL}${formData.identity_card_path}`, '_blank')
  }

  const getProperDateFormat = (data) => {
    const date = data?.split('-');
    return date ? `${date[2]}/${date[1]}/${date[0]}` : null;
  }
  const { t } = useTranslation();

  // const getDocumentName = {
  //   dob_proof: `${t('REGISTER.DOBPROOF')}`,
  //   birth_certificate: `${t('REGISTER.DOBPROOF')}`,
  //   domicile_certificate: `${t('REGISTER.STATECERTIFICATE')}`,
  //   andhar_card: `${t('REGISTER.AADHARCARD')}`,
  //   aadhar: `${t('REGISTER.AADHARCARD')}`,
  //   transcript: `${t('REGISTER.IDENTITYPROOF')}`,
  //   identity_proof: `${t('REGISTER.IDENTITYPROOF')}`,
  //   degree_certificate: `${t('REGISTER.COURSECOMPLETION')}`,
  //   graduation: `${t('REGISTER.COURSECOMPLETION')}`,
  //   signature: `${t('REGISTER.SIGNATURE')}`,
  //   upload_photo: `${t('REGISTER.PHOTO')}`,
  //   photo: `${t('REGISTER.PHOTO')}`,
  //   payment_proof: `${t('REGISTER.PAYMENTPROOF')}`,
  //   fir: `${t('REGISTER.FIR')}`,
  //   provisional_regidtration_certificate: `${t('REGISTER.PROVISIONALREGISTRATIONCERTIFICATE')}`,
  //   provisional_certificate: `${t('REGISTER.PROVISIONALREGISTRATIONCERTIFICATE')}`,
  //   affidavit_magistrate: `${t('REGISTER.AFFIDAVITMAGISTRATE')}`,
  //   affidavit: `${t('REGISTER.AFFIDAVITMAGISTRATE')}`
  // }

  const formatInputValue = (date) => {
    if (!date) return '';
    return `${date.day}/${date.month}/${date.year}`;
  };


  const isProvisionalUser = () => details?.result?.registration_type === 'provisional';

  const getFileName = (name) => {
    return formData?.document_doctors?.filter(data => data?.document_name === name)[0]?.document_path
  }

  return (
    <>

      <span data-bs-toggle="modal" data-bs-target="#cancelOrder1"></span>
      <div
        className="modal fade endSubscriptionMod"
        id="cancelOrder1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            {/* <div className="modal-body">
              <img className="previewImage" src={`${uploadURL}images/${imagePath}`} alt="" />
            </div> */}
            {/* <button type="button" class="btn btn-success mb-3">Change</button> */}
          </div>
        </div>
      </div>

      <div className="container officeStaffComponentOuter">
        <h3>
          {t("REGISTER.DOCTORINFO")}
        </h3>
        {/* <span>You are visiting abhinaba hazra's profile</span> */}
        {/* <div className="text-center">
          <img className="avatar" src="https://cdn.iconscout.com/icon/free/png-256/avatar-370-456322.png"></img>
        </div> */}
        <InputGroup className="col-md-12 mt-4" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.DOCTORNAME")}
          </InputGroup.Text>
          <FormControl name="name" id="basic-url" value={formData?.name} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.NATIONALITY")}
          </InputGroup.Text>
          <FormControl name="nationality" id="basic-url" value={formData?.nationality} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12 " >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.FATHERSNAME")}
          </InputGroup.Text>
          <FormControl name="fatherName" id="basic-url" value={formData?.father_name} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.MOTHERSNAME")}
          </InputGroup.Text>
          <FormControl name="motherName" id="basic-url" value={formData?.mother_name} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.DOB")}
          </InputGroup.Text>
          <FormControl name="dob" id="basic-url" value={getProperDateFormat(formData?.dob)} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.AADHAR")}
          </InputGroup.Text>
          <FormControl name="andhar" id="basic-url" value={formData?.aadhar_card_no?.replace(/.(?=.{4})/g, 'x')} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        {/* <div className="cardnumber"><div>{decrypt(cardDetails.card).replace(/[^a-z0-9]+/gi, '').replace(/(.{4})/g, '$1 ')}</div><div className="cardde"></div></div> */}

        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.GENDER")}
          </InputGroup.Text>
          <FormControl name="gender" id="basic-url" value={formData?.gender} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        {!isProvisionalUser() && <>
          <InputGroup className="col-md-12" >
            <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
              {t("REGISTER.PROFESSIONALSTATUS")}
            </InputGroup.Text>
            <FormControl name="professionalStatus" id="basic-url" value={formData?.professional_status} disabled={true} aria-describedby="basic-addon3" />
          </InputGroup>
          <InputGroup className="col-md-12" >
            <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
              {t("REGISTER.SECTOR")}
            </InputGroup.Text>
            <FormControl name="sector" id="basic-url" value={formData?.sector} disabled={true} aria-describedby="basic-addon3" />
          </InputGroup>
        </>}
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.CATEGORY")}
          </InputGroup.Text>
          <FormControl name="category" id="basic-url" value={formData?.category} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        {isProvisionalUser() && <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.STATE")}
          </InputGroup.Text>
          <FormControl name="residence_state" id="basic-url" value={formData?.residence_state} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>}


        <h3> {t("REGISTER.COMMUNICATIONDETAIL")}</h3>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.MOBILE")}
          </InputGroup.Text>
          <FormControl name="mobile" id="basic-url" value={formData?.contact_detail_doctor?.mobile} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.ALTMOBILE")}
          </InputGroup.Text>
          <FormControl name="alt_mobile" id="basic-url" value={formData?.contact_detail_doctor?.alt_mobile} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.EMAIL")}
          </InputGroup.Text>
          <FormControl name="email" id="basic-url" value={formData?.contact_detail_doctor?.email} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.ALTEMAIL")}
          </InputGroup.Text>
          <FormControl name="alt_email" id="basic-url" value={formData?.contact_detail_doctor?.alt_email} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>

        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.PERMANANETADDRESS")}
          </InputGroup.Text>
          <FormControl name="permanent_address" id="basic-url" value={formData?.contact_detail_doctor?.permanent_address} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.VILLAGE")}
          </InputGroup.Text>
          <FormControl name="village" id="basic-url" value={formData?.contact_detail_doctor?.permanent_address_village} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.TALUKA")}
          </InputGroup.Text>
          <FormControl name="taluka" id="basic-url" value={formData?.contact_detail_doctor?.permanent_address_taluka} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.DISTRICT")}
          </InputGroup.Text>
          <FormControl name="district" id="basic-url" value={formData?.contact_detail_doctor?.permanent_address_district} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>

        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.PINCODE")}
          </InputGroup.Text>
          <FormControl name="pincode" id="basic-url" value={formData?.contact_detail_doctor?.permanent_address_pincode} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.PERSTATE")}
          </InputGroup.Text>
          <FormControl name="state" id="basic-url" value={formData?.contact_detail_doctor?.permanent_address_state} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>



        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {isProvisionalUser() ? t("REGISTER.TEMPADDRESS") : t("REGISTER.PRESENTPROFESSIONLADDRESS")}
          </InputGroup.Text>
          <FormControl name="presentProfessionalAdderess" id="basic-url" value={formData?.contact_detail_doctor?.professional_address} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.VILLAGE")}
          </InputGroup.Text>
          <FormControl name="presentProfessionalVillage" id="basic-url" value={formData?.contact_detail_doctor?.village} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.TALUKA")}
          </InputGroup.Text>
          <FormControl name="presentProfessionalTaluka" id="basic-url" value={formData?.contact_detail_doctor?.taluka} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.DISTRICT")}
          </InputGroup.Text>
          <FormControl name="presentProfessionalDistrict" id="basic-url" value={formData?.contact_detail_doctor?.district} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.PINCODE")}
          </InputGroup.Text>
          <FormControl name="presentProfessionalPincode" id="basic-url" value={formData?.contact_detail_doctor?.pincode} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.PERSTATE")}
          </InputGroup.Text>
          <FormControl name="presentProfessionalState" id="basic-url" value={formData?.contact_detail_doctor?.state} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>



        {!isProvisionalUser() && <>
          <InputGroup className="col-md-12" >
            <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
              {t("REGISTER.WORKADDRESS")}
            </InputGroup.Text>
            <FormControl name="workingAddress" id="basic-url" value={formData?.working_address_doctor?.working_address} disabled={true} aria-describedby="basic-addon3" />
          </InputGroup>
          <InputGroup className="col-md-12" >
            <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
              {t("REGISTER.DISTRICT")}
            </InputGroup.Text>
            <FormControl name="tempDistrict" id="basic-url" value={formData?.working_address_doctor?.district} disabled={true} aria-describedby="basic-addon3" />
          </InputGroup>
          <InputGroup className="col-md-12" >
            <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
              {t("REGISTER.VILLAGE")}
            </InputGroup.Text>
            <FormControl name="tempVillage" id="basic-url" value={formData?.working_address_doctor?.village} disabled={true} aria-describedby="basic-addon3" />
          </InputGroup>
          <InputGroup className="col-md-12" >
            <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
              {t("REGISTER.STATE")}
            </InputGroup.Text>
            <FormControl name="tempState" id="basic-url" value={formData?.working_address_doctor?.state} disabled={true} aria-describedby="basic-addon3" />
          </InputGroup>
          <InputGroup className="col-md-12" >
            <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
              {t("REGISTER.TALUKA")}
            </InputGroup.Text>
            <FormControl name="tempTaluka" id="basic-url" value={formData?.working_address_doctor?.taluka} disabled={true} aria-describedby="basic-addon3" />
          </InputGroup>
          <InputGroup className="col-md-12" >
            <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
              {t("REGISTER.PINCODE")}
            </InputGroup.Text>
            <FormControl name="tempPincode" id="basic-url" value={formData?.working_address_doctor?.pincode} disabled={true} aria-describedby="basic-addon3" />
          </InputGroup>
        </>}
        <h3> {t("Register.D2")}</h3>

        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.GRADUATION")}
          </InputGroup.Text>
          <FormControl name="degree" id="basic-url" value={formData?.education_doctor?.graduation} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <div class="main-heading">
          <InputGroup className="col-md-12" >
            <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
              {t("REGISTER.COLLEGENAME")}
            </InputGroup.Text>
            <FormControl name="graduationCollegeName" id="basic-url" value={formData?.education_doctor?.graduation_college_name} disabled={true} aria-describedby="basic-addon3" />
          </InputGroup>
          <InputGroup className="col-md-12" >
            <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
              {t("REGISTER.UNIVERSITYNAME")}
            </InputGroup.Text>
            <FormControl name="graduationUniversityName" id="basic-url" value={formData?.education_doctor?.graduation_university_name} disabled={true} aria-describedby="basic-addon3" />
          </InputGroup>
          <InputGroup className="col-md-12" >
            <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
              {isProvisionalUser() ? t("REGISTER.COMPLETIONDATE") : t("REGISTER.COURSECOMPLETE")}
            </InputGroup.Text>
            {
              formData?.education_doctor?.graduation_completion_date ?
                <FormControl name="graduationMonth" id="basic-url" value={getProperDateFormat(formData?.education_doctor?.graduation_completion_date)} disabled={true} aria-describedby="basic-addon3" />
                :
                <>
                  <FormControl name="graduationMonth" id="basic-url" value={formData?.education_doctor?.graduation_completion_month} disabled={true} aria-describedby="basic-addon3" />
                  <FormControl name="graduationYear" id="basic-url" value={formData?.education_doctor?.graduation_completion_year} disabled={true} aria-describedby="basic-addon3" />
                </>
            }
          </InputGroup>
        </div>
        {!isProvisionalUser() && <>
          <InputGroup className="col-md-12" >
            <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
              {t("REGISTER.POSTGRADUATION")}
            </InputGroup.Text>
            <FormControl name="postGraduation" id="basic-url" value={formData?.education_doctor?.post_graduation} disabled={true} aria-describedby="basic-addon3" />
          </InputGroup>
          <div class="main-heading">
            <InputGroup className="col-md-12" >
              <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                {t("REGISTER.COLLEGENAME")}
              </InputGroup.Text>
              <FormControl name="postGraduationCollegeName" id="basic-url" value={formData?.education_doctor?.post_graduation_college_name} disabled={true} aria-describedby="basic-addon3" />
            </InputGroup>
            <InputGroup className="col-md-12" >
              <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                {t("REGISTER.UNIVERSITYNAME")}
              </InputGroup.Text>
              <FormControl name="postGraduationUniversityName" id="basic-url" value={formData?.education_doctor?.post_graduation_university_name} disabled={true} aria-describedby="basic-addon3" />
            </InputGroup>
            <InputGroup className="col-md-12" >
              <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                {t("REGISTER.COURSECOMPLETE")}
              </InputGroup.Text>
              <FormControl name="postGraduationMonth" id="basic-url" value={formData?.education_doctor?.post_graduation_completion_month} disabled={true} aria-describedby="basic-addon3" />
              <FormControl name="postGraduationYear" id="basic-url" value={formData?.education_doctor?.post_graduation_completion_year} disabled={true} aria-describedby="basic-addon3" />
            </InputGroup>
          </div>

          <InputGroup className="col-md-12" >
            <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
              {t("REGISTER.POSTGRADUATIONSUBJECTS")}
            </InputGroup.Text>
            <FormControl
              as="textarea"
              rows="4"
              name="postGraduationSubjects"
              style={{ marginBottom: '20px', resize: 'none' }}
              value={formData?.education_doctor?.post_graduation_subjects}
              aria-describedby="basic-addon3"
              disabled={true}
            />
          </InputGroup>

          <InputGroup className="col-md-12" >
            <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
              {t("REGISTER.PHD")}
            </InputGroup.Text>
            <FormControl name="phd" id="basic-url" value={formData?.education_doctor?.phd} disabled={true} aria-describedby="basic-addon3" />
          </InputGroup>
          <div class="main-heading">
            <InputGroup className="col-md-12" >
              <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                {t("REGISTER.UNIVERSITYNAME")}
              </InputGroup.Text>
              <FormControl name="phdUniversityName" id="basic-url" value={formData?.education_doctor?.phd_university_name} disabled={true} aria-describedby="basic-addon3" />
            </InputGroup>
            <InputGroup className="col-md-12" >
              <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                {t("REGISTER.COURSECOMPLETE")}
              </InputGroup.Text>
              <FormControl name="phdMonth" id="basic-url" value={formData?.education_doctor?.phd_completion_month} disabled={true} aria-describedby="basic-addon3" />
              <FormControl name="phdYear" id="basic-url" value={formData?.education_doctor?.phd_completion_year} disabled={true} aria-describedby="basic-addon3" />
            </InputGroup>
          </div>

          <InputGroup className="col-md-12" >
            <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
              {t("REGISTER.POSTDOC")}
            </InputGroup.Text>
            <FormControl name="postDoc" id="basic-url" value={formData?.education_doctor?.post_doc} disabled={true} aria-describedby="basic-addon3" />
          </InputGroup>
          <div class="main-heading">
            <InputGroup className="col-md-12" >
              <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                {t("REGISTER.COLLEGENAME")}
              </InputGroup.Text>
              <FormControl name="postDocCollegeName" id="basic-url" value={formData?.education_doctor?.post_doc_college_name} disabled={true} aria-describedby="basic-addon3" />
            </InputGroup>
            <InputGroup className="col-md-12" >
              <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                {t("REGISTER.UNIVERSITYNAME")}
              </InputGroup.Text>
              <FormControl name="postDocUniversityName" id="basic-url" value={formData?.education_doctor?.post_doc_university_name} disabled={true} aria-describedby="basic-addon3" />
            </InputGroup>
            <InputGroup className="col-md-12" >
              <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                {t("REGISTER.COURSECOMPLETE")}
              </InputGroup.Text>
              <FormControl name="postDocMonth" id="basic-url" value={formData?.education_doctor?.post_doc_completion_month} disabled={true} aria-describedby="basic-addon3" />
              <FormControl name="postDocYear" id="basic-url" value={formData?.education_doctor?.post_doc_completion_year} disabled={true} aria-describedby="basic-addon3" />
            </InputGroup>
          </div>
        </>}
        {!isProvisionalUser() && <h3>{t("Register.D5")}</h3>}

        {
          !isProvisionalUser() &&
          <>
            <InputGroup className="col-md-12" >
              <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                {t("REGISTER.AWARD")}
              </InputGroup.Text>
              <FormControl
                as="textarea"
                rows="4"
                name="postGraduationSubjects"
                style={{ marginBottom: '20px', resize: 'none' }}
                value={formData?.education_doctor?.awards}
                aria-describedby="basic-addon3"
                disabled={true}
              />
            </InputGroup>
            <InputGroup className="col-md-12" >
              <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                {t("REGISTER.SEMINAR")}
              </InputGroup.Text>
              <FormControl
                as="textarea"
                rows="4"
                name="postGraduationSubjects"
                style={{ marginBottom: '20px', resize: 'none' }}
                value={formData?.education_doctor?.seminars_and_conferences}
                aria-describedby="basic-addon3"
                disabled={true}
              />
            </InputGroup>
            <InputGroup className="col-md-12" >
              <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                {t("REGISTER.CVE")}
              </InputGroup.Text>
              <FormControl
                as="textarea"
                rows="4"
                name="postGraduationSubjects"
                style={{ marginBottom: '20px', resize: 'none' }}
                value={formData?.education_doctor?.cve_completed}
                aria-describedby="basic-addon3"
                disabled={true}
              />
            </InputGroup>
          </>
        }

        <h3> {t("Register.D4")}</h3>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.PHOTO")}
          </InputGroup.Text>
          {getFileName('upload_photo') && <button
            className="view_button"
            onClick={() => { window.open(`${uploadURL}images/${getFileName('upload_photo')}`, '_blank') }}
          >VIEW</button>}

          <FormControl id="basic-url" value={getFileName('upload_photo')} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>

        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.SIGNATURE")}
          </InputGroup.Text>
          {getFileName('signature') && <button
            className="view_button"
            onClick={() => { window.open(`${uploadURL}images/${getFileName('signature')}`, '_blank') }}
          >VIEW</button>}

          <FormControl id="basic-url" value={getFileName('signature')} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.AADHARCARD")}
          </InputGroup.Text>
          {(getFileName('andhar_card') || getFileName('aadhar')) && <button
            className="view_button"
            onClick={() => { window.open(`${uploadURL}images/${(getFileName('andhar_card') || getFileName('aadhar'))}`, '_blank') }}
          >VIEW</button>}

          <FormControl id="basic-url" value={(getFileName('andhar_card') || getFileName('aadhar'))} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.COURSECOMPLETION")}
          </InputGroup.Text>
          {(getFileName('degree_certificate') || getFileName('graduation')) && <button
            className="view_button"
            onClick={() => { window.open(`${uploadURL}images/${(getFileName('degree_certificate') || getFileName('graduation'))}`, '_blank') }}
          >VIEW</button>}

          <FormControl id="basic-url" value={(getFileName('degree_certificate') || getFileName('graduation'))} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.IDENTITYPROOF")}
          </InputGroup.Text>
          {getFileName('identity_proof') && <button
            className="view_button"
            onClick={() => { window.open(`${uploadURL}images/${getFileName('identity_proof')}`, '_blank') }}
          >VIEW</button>}

          <FormControl id="basic-url" value={getFileName('identity_proof')} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.STATECERTIFICATE")}
          </InputGroup.Text>
          {getFileName('domicile_certificate') && <button
            className="view_button"
            onClick={() => { window.open(`${uploadURL}images/${getFileName('domicile_certificate')}`, '_blank') }}
          >VIEW</button>}

          <FormControl id="basic-url" value={getFileName('domicile_certificate')} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.DOBPROOF")}
          </InputGroup.Text>
          {(getFileName('dob_proof') || getFileName('birth_certificate')) && <button
            className="view_button"
            onClick={() => { window.open(`${uploadURL}images/${(getFileName('dob_proof') || getFileName('birth_certificate'))}`, '_blank') }}
          >VIEW</button>}

          <FormControl id="basic-url" value={(getFileName('dob_proof') || getFileName('birth_certificate'))} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("REGISTER.PAYMENTPROOF")}
          </InputGroup.Text>
          {getFileName('payment_proof') && <button
            className="view_button"
            onClick={() => { window.open(`${uploadURL}images/${getFileName('payment_proof')}`, '_blank') }}
          >VIEW</button>}

          <FormControl id="basic-url" value={getFileName('payment_proof')} disabled={true} aria-describedby="basic-addon3" />
        </InputGroup>
        {
          !isProvisionalUser() &&
          <>
            <InputGroup className="col-md-12" >
              <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                {t("REGISTER.PROVISIONALREGISTRATIONCERTIFICATE")}
              </InputGroup.Text>
              {getFileName('provisional_regidtration_certificate') && <button
                className="view_button"
                onClick={() => { window.open(`${uploadURL}images/${getFileName('provisional_regidtration_certificate')}`, '_blank') }}
              >VIEW</button>}

              <FormControl id="basic-url" value={getFileName('provisional_regidtration_certificate')} disabled={true} aria-describedby="basic-addon3" />
            </InputGroup>
            <InputGroup className="col-md-12" >
              <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                {t("REGISTER.FIR")}
              </InputGroup.Text>
              {getFileName('fir') && <button
                className="view_button"
                onClick={() => { window.open(`${uploadURL}images/${getFileName('fir')}`, '_blank') }}
              >VIEW</button>}

              <FormControl id="basic-url" value={getFileName('fir')} disabled={true} aria-describedby="basic-addon3" />
            </InputGroup>
            <InputGroup className="col-md-12" >
              <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                {t("REGISTER.AFFIDAVIT")}
              </InputGroup.Text>
              {getFileName('affidavit_magistrate') && <button
                className="view_button"
                onClick={() => { window.open(`${uploadURL}images/${getFileName('affidavit_magistrate')}`, '_blank') }}
              >VIEW</button>}

              <FormControl id="basic-url" value={getFileName('affidavit_magistrate')} disabled={true} aria-describedby="basic-addon3" />
            </InputGroup>
          </>
        }

        {/* {
          (!!formData && formData.document_doctors || []).map((tracking, index) => {
            return (
              <>
                <InputGroup className="col-md-12" >
                  <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                    {getDocumentName[tracking.document_name]}

                  </InputGroup.Text>
                  {tracking.document_path && <button
                    className="view_button"
                    onClick={() => { window.open(`${uploadURL}images/${tracking.document_path}`, '_blank') }}
                  >VIEW</button>}

                  <FormControl name={tracking.document_name} id="basic-url" value={tracking.document_path} disabled={true} aria-describedby="basic-addon3" />
                </InputGroup>
              </>
            )
          }
          )
        } */}



        <div className="">
          <h3> {t("REGISTER.DOCTORLOGIN")}</h3>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="1" onChange={onRadioButtonClick} checked={formData?.radioValue == "1"} />
            <label className="form-check-label" htmlFor="flexRadioDefault1">
              {t("REGISTER.ENABLE")}
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="0" onChange={onRadioButtonClick} checked={formData?.radioValue == "0"} />
            <label className="form-check-label" htmlFor="flexRadioDefault2">
              {t("REGISTER.DISABLE")}
            </label>
          </div>

          <div style={{ marginTop: '10px' }}>
            <h3> {t("REGISTER.DOCUMENTVALIDITY")}</h3>
            <InputGroup className="col-md-12" >
              <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                {t("REGISTER.STARTDATE")}<span className="star_highlight">*</span>
              </InputGroup.Text>
              <div className="col-sm-8" style={{ padding: '0px' }}>
                <DatePicker3 className="form-control" name="startDate"
                  // value={(formData) ? formData.startDate : null}
                  value={selectedDay}
                  // onChange={(date) => { setSelectedDay({ ...formData, startDate: date }) }}
                  onChange={(date) => { setSelectedDay(date ) }}

                  inputPlaceholder="dd/mm/yyyy"
                  shouldHighlightWeekends
                  formatInputText={() => formatInputValue(formData && formData.startDate)}
                  inputClassName={details?.result?.registration_valid_from && "my-custom-input"}
                />
              </div>
            </InputGroup>

            <InputGroup className="col-md-12" >
              <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                {t("REGISTER.ENDDATE")}<span className="star_highlight">*</span>
              </InputGroup.Text>
              <div className="col-sm-8" style={{ padding: '0px' }}>
                <DatePicker3 className="form-control" name="endDate"
                  // value={(formData) ? formData.endDate : null}
                  value={selectedDay1}
                  // onChange={(date) => { setFormData({ ...formData, endDate: date }) }}
                  onChange={(date) => { setSelectedDay1(date ) }}

                  inputPlaceholder="dd/mm/yyyy"
                  shouldHighlightWeekends
                  formatInputText={() => formatInputValue(formData && formData.endDate)}
                  inputClassName={details?.result?.registration_valid_upto && "my-custom-input"}
                  minimumDate={(formData) ? formData.startDate : null}
                />
              </div>

            </InputGroup>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <div className="text-center">
                <button type="button" disabled={details?.result?.status === "rejected"} class="btn btn-danger btn-lg btn-outline-succe mt-4" data-bs-toggle="modal" data-bs-target="#cancelOrder" >{t("REGISTER.REJECT")}</button>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="text-center">
                <button type="button" disabled={!(selectedDay && selectedDay1)} class="btn btn-success btn-lg btn-outline-succe mt-4" onClick={() => onUpdateButtonClick()}>{isLoading ? t("REGISTER.UPDATING") : t("REGISTER.UPDATESTATUS")}</button>
              </div>
            </div>

          </div>


          <div
            className="modal fade endSubscriptionMod"
            id="cancelOrder"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"

          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <InputGroup className="col-md-12"  >
                    <InputGroup.Text id="basic-addon3" className="col-sm-12 mb-3">
                      {t("REGISTER.REJECTION")}
                    </InputGroup.Text>
                    <Form.Control
                      className="form-control"
                      as="textarea"
                      rows={4}
                      placeholder={'Enter Reasons of rejection'}
                      id="exampleFormControlTextarea1"
                      // rows="6"
                      name="comment"
                      onChange={onChangeHandler}
                      style={{ marginBottom: '20px', resize: 'none' }}

                    />
                  </InputGroup>
                  {/* {formData.id} */}
                  <div className="text-center">
                    <button type="button" class="btn btn-danger btn-lg btn-outline-succe mt-4" onClick={() => onRejectButtonClick()}>{t("REGISTER.REJECT_SUBMIT")}</button>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
        {
          formData.registration_type === "regular" ?
            <>
              <div className="row mt-4 mb-4">
                <div className="col-sm-6 mn_button">
                  <button type="button" disabled={!formData.registration_certificate_path} class="btn btn-success btn-lg btn-block" onClick={onViewCertificateClick}> {t("REGISTER.VIEWCERTIFICATE")}
                  </button>
                </div>
                <div className="col-sm-6 mn_button">
                  <button type="button" disabled={!formData.identity_card_path} class="btn btn-success btn-lg btn-block" onClick={onViewIdentityClick}> {t("REGISTER.VIEWIDCARD")}
                  </button>
                </div>
              </div>
              <div className="row mt-4 mb-4">
                <div className="col-sm-6 mn_button">
                  <button type="button" disabled={!formData.registration_certificate_path} class="btn btn-success btn-lg btn-block" onClick={onCertificateClick}>{isCertificateSend ? t("REGISTER.SENDING") : t("REGISTER.EMAILCERTIFICATE")}
                  </button>
                </div>
                <div className="col-sm-6 mn_button">
                  <button type="button" disabled={!formData.identity_card_path} class="btn btn-success btn-lg btn-block" onClick={onIdentityClick}>{isIdentitySend ? t("REGISTER.SENDING") : t("REGISTER.EMAILIDCARD")}
                  </button>
                </div>
              </div>
            </> :
            <>
              <div className="row mt-4 mb-4" style={{ justifyContent: 'center' }}>
                <div className="col-sm-6 mn_button">
                  <button type="button" disabled={!formData.provisional_certificate_path} class="btn btn-success btn-lg btn-block" onClick={onViewProvisionalCertificateClick}>{t("REGISTER.VIEWPROVISIONALCERTIFICATE")}
                  </button>
                </div>
              </div>
              <div className="row mb-4" style={{ justifyContent: 'center' }}>
                <div className="col-sm-6 mn_button">
                  <button type="button" disabled={!formData.provisional_certificate_path} class="btn btn-success btn-lg btn-block" onClick={onProvisionalCertificateClick}>{isCertificateSend ? t("REGISTER.SENDING") : t("REGISTER.EMAILPROVISIONALCERTIFICATE")}
                  </button>
                </div>
              </div>
            </>
        }
        <ToastContainer />
      </div>

    </>

  );
};

export default DoctorDetailsJsx;
