import React from 'react';
import './disiplinaryCommittee.css';
import { useTranslation } from "react-i18next";

function DisiplinaryCommitteeJsx() {
    const { t } = useTranslation();
    return (
        <>

            <div className="container">
            <div className="disiplinary_linking">{t("HEADER.HOME")}  /{t("HEADER.ABOUTUS")}  / {t("DisiplinaryCommittee.MAIN_HEAD")}</div>
           <div className="heading">{t("DisiplinaryCommittee.MAIN_HEAD")}</div>
           <div id="title-space"></div>
           </div>

        </>
    )

}
export default DisiplinaryCommitteeJsx;