import React, { useState, useEffect } from 'react'
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { baseURL, uploadURL, nameRegex } from "../../constants/applicationConstants";
import './uploadPressImage.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import deleteIcon from "../../assets/images/delete.png";

export const UploadPressImageJsx = () => {

    const [formData, setFormData] = useState({
        name: '',
        category: '',
        isAlbum: true,
        fileName: ''
    });


    const [fileName, setFileName] = useState({
        fileName: ''
    })
    const [albumListData, setAlbumData] = useState([]);
    const [albumPhoto, setAlbumPhoto] = useState([]);


    useEffect(() => {
        isFromSubmissionBlock()
    })

    const [formError, setFormError] = useState({})
    const [formSubmitButtonDisable, setFormSubmitButtonDisable] = useState(true)
    let [isProcessing, setProcessing] = useState(false);
    let [loading, setLoading] = useState(false);



    const onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value) {
            if (!inputRegexValidation(name, value)) {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "regx" })
            } else {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "" })
            }
        } else {
            setFormData({ ...formData, [name]: '' })
            setFormError({})
        }
    };

    const inputRegexValidation = (name, value) => {
        if (name === "name")
            return nameRegex.test(value)
        else
            return true;
    };

    const onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            setFormError({ ...formError, [name]: "req" })
        } else {
            if (!inputRegexValidation(name, value)) {
                setFormError({ ...formError, [name]: "regx" })
            }
        }
    }

    const isFromSubmissionBlock = () => {
        let { name, category, fileName } = formData;
        if (name && category) {
            if (
                inputRegexValidation("name", name) &&
                category && fileName
            ) {
                setFormSubmitButtonDisable(false)
            } else {
                setFormSubmitButtonDisable(true)
            }
        } else {
            setFormSubmitButtonDisable(true)
        }
    };

    const onResetButtonClick = () => {
        // setFormData({
        //     name: '',
        //     category: '',
        //     isAlbum: true,
        //     fileName: ''
        // })
        // setFormError('');
        // setFileName({
        //     fileName: ''
        // })
        window.location.reload()

    }

    const onDeleteClick = (data) => {

        if (window.confirm('Are you sure? you want to Delete this Album?')) {
            axios.delete(`${baseURL}albumPhoto/${data}`, {
                headers: {
                    'access-token': JSON.parse(sessionStorage.getItem('authToken')),
                    'role': sessionStorage.getItem('role')
                }
            }).then(res => {
                toast.success('Record Deleted Succesfully')
                getAlbumPhoto();
            }).catch(() => {
                toast.error('Something went ')
            })
        } else {
            // Do nothing!
        }
    }

    const formSubmitHandler = (e) => {
        e.preventDefault();
        setLoading(true)
        setProcessing(true);
        let formData1 = new FormData();
        var theinput = document.getElementById('imgMain')
        formData1.append('image', theinput.files[0])
        formData1.append('title', formData.name)
        formData1.append('album_id', formData.category)
        formData1.append('album_cover', formData.isAlbum)

        axios.post(`${uploadURL}albumPhoto`, formData1, {
            headers: {
                'access-token': JSON.parse(sessionStorage.getItem('authToken')),
                'role': sessionStorage.getItem('role')
            }
        }).then(resp => {
            setLoading(false);
            if (resp.data.status === 'success') {
                toast.success("Press Album photo uploaded!")
                setProcessing(false);
                getAlbumPhoto();
                onResetButtonClick();
            } else {
                toast.error("Something wrong!")
                setProcessing(false);
            }
        }).catch((err) => {
            toast.error(err?.response?.data.message)
            setLoading(false);
            setProcessing(false);
        })

    }





    const validFileName = (name) => name.includes('.jpeg') || name.includes('.jpg')

  
    const onFileChange = (e) => {
        const name = e.target.name;
        if (e.target.files[0]?.size) {
            if (e.target.files[0]?.size < 2097152) {
                if (e.target.files[0] && validFileName(e.target.files[0].name)) {
                    // let formData1 = new FormData();
                    // formData1.append('image', e.target.files[0])
                    setFormData({ ...formData, [name]: e.target.files[0] })
                    setFileName({ ...fileName, [name]: e.target.files[0].name })
                    // axios.post(
                    //     `${uploadURL}uploadfile`,
                    //     formData1
                    // )
                    //     .then(res => {
                    //         setFormData({ ...formData, [name]: res.data.profileImageName })
                    //         setFileName({ ...fileName, [name]: e.target.files[0].name })
                    //     })
                    //     .catch(error => {
                    //     })
                }
                else {
                    toast.error('.JPG/.JPEG format required')
                }
            }
            else {
                toast.error('File should be less than 2mb')
            }
        }
    }
    useEffect(() => {
        getAlbumList()
        getAlbumPhoto()
    }, [])
    const getAlbumList = () => {
        axios.get(`${baseURL}albums?isPressAlbum=true`, {
            headers: {
                'access-token': JSON.parse(sessionStorage.getItem('authToken')),
                'role': sessionStorage.getItem('role')
            }
        }).then((res) => {
            setAlbumData(res.data.result)

        }).catch((res) => {
            setProcessing(false);
            toast.error(res.response?.data.message)
        })
    }
    const getAlbumPhoto = () => {
        axios.get(`${baseURL}getAlbumsAndPhotos?isPressAlbum=true`, {
            headers: {
                'access-token': JSON.parse(sessionStorage.getItem('authToken')),
                'role': sessionStorage.getItem('role')
            }
        }).then((res) => {
            setAlbumPhoto(res.data.result)

        }).catch((res) => {
            setProcessing(false);
            toast.error(res.response?.data.message)
        })
    }

    const { t } = useTranslation();

    return (
        <>
            <div className="container officeStaffComponentOuter">
                <div className="bread_crums mt-3"><Link to="/">{t("UPLOAD_PRESS_MAIN_IMAGE.HOME")}</Link>/ <Link to="/uploadPressImage">{t("UPLOAD_PRESS_MAIN_IMAGE.UPLOAD_IMAGE")}</Link></div><span className="print" onClick={() => window.print()}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="skyBlue" class="bi bi-printer-fill" viewBox="0 0 16 16">
                    <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                    <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                </svg> &nbsp; {t("PRINT.TITLE")}</span>
                <h2 className="loginHeading mt-2">
                    {t("UPLOAD_PRESS_MAIN_IMAGE.UPLOAD_IMAGE")}
                </h2>
                <hr />
                <div className="input" >
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("UPLOAD_PRESS_MAIN_IMAGE.SELECT_PHOTO")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name='fileName' onBlur={onTouchHandler} aria-describedby="basic-addon3" onChange={onFileChange}
                            className="select_image" id="imgMain" type="file" style={{ color: 'transparent' }} />
                        <label className='selected-file-name' name={fileName.fileName} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileName.fileName}</label>
                    </InputGroup>

                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("UPLOAD_PRESS_MAIN_IMAGE.NAME")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl id="basic-url" name='name' onBlur={onTouchHandler} aria-describedby="basic-addon3" value={formData.name} onChange={onInputChange} placeholder={t("GRIEVANCE.NAME_PLACEHOLDER")} maxLength={100} />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['name'] &&
                            (formError['name'] === "req"
                                ? 'Name is required'
                                : 'Enter a valid Name')}
                    </span>
                    {/* <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("UPLOAD_PRESS_MAIN_IMAGE.CHECK")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <Form.Check className="upload_check" aria-label="option 1" />
                    </InputGroup> */}
                    <h6>{t("UPLOAD_PRESS_MAIN_IMAGE.CHECK")}</h6>
                    <div class="row">
                        <div class="col-sm-12 text-left">
                            <div class="form-check form-check-inline mb-3" id="basic-url">
                                <label class="form-check-label ml-3" for="inlineRadio2">  {t("UPLOAD_PRESS_MAIN_IMAGE.YES")}</label>
                                <input name="isHavingProvisionalNumber"
                                    onBlur={onTouchHandler}
                                    checked={formData.isAlbum}
                                    onChange={() => { setFormData({ ...formData, isAlbum: true }) }}
                                    class="form-check-input ml-3"
                                    type="radio"  id="inlineRadio2" value={t("UPLOADPDF.CURRENT")} />
                            </div>
                            <div class="form-check form-check-inline mb-3" id="basic-url">
                                <label class="form-check-label ml-3" for="inlineRadio2">  {t("UPLOAD_PRESS_MAIN_IMAGE.NO")}</label>
                                <input name="isHavingProvisionalNumber"
                                    onBlur={onTouchHandler}
                                    checked={!formData.isAlbum}
                                    onChange={() => { setFormData({ ...formData, isAlbum: false }) }}
                                    class="form-check-input ml-3"
                                    type="radio"  id="inlineRadio2" value={t("UPLOADPDF.ARCHIVE")} />
                            </div>
                        </div>
                    </div>

                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("UPLOAD_PRESS_MAIN_IMAGE.SELECT_ALBUM")}
                        </InputGroup.Text>
                        <select name="category"
                            // className={
                            //     formError['category'] &&
                            //     (formError['category'] === "req"
                            //         ? "form-control  is-invalid"
                            //         : "form-control")}
                            onBlur={onTouchHandler}

                            onChange={(e) => { setFormData({ ...formData, category: e.target.value }) }}
                            className="form-select customize_select" aria-label="Default select example">
                            <option value='' selected>{t("UPLOAD_PRESS_MAIN_IMAGE.SELECT")}</option>
                            {/* <option value="Msvc">Msvc</option>
                                <option value="Album 1">Album 1</option>
                                <option value="CSRF Magic">CSRF Magic</option> */}
                            {
                                albumListData.map((country) => {
                                    return (
                                        <option value={country.id}>{country.name}</option>
                                    )
                                })
                            }
                        </select>
                    </InputGroup>
                    <div class="row">
                        <div class="col-sm-12 text-center mb-5 mt-3">
                            <button id="btnSearch" class="btn center-block mr-4" onClick={onResetButtonClick} >{t("REGISTER.RESET")}</button>
                            <button disabled={formSubmitButtonDisable} onClick={formSubmitHandler} id="btnClear" class="btn center-block">{!!isProcessing ? t("REGISTER.PROCESSING") : t("REGISTER.SUBMIT")}</button>
                        </div>
                    </div>

                </div>
                <h4> {t("UPLOAD_PRESS_MAIN_IMAGE.IMAGELIST")}</h4>
                {albumPhoto.length > 0 ? (
                    <table className="mb-5">
                        <thead style={{ background: 'white' }}>
                            <th> {t("UPLOAD_PRESS_MAIN_IMAGE.IMAGETITLE")}</th>
                            <th> {t("UPLOAD_PRESS_MAIN_IMAGE.PREVIEW")}</th>

                        </thead>
                        <tbody>
                            {
                                albumPhoto?.map((data, index) => {
                                    return <tr>
                                        <td style={{ fontWeight: "bold" }}>{data.name}</td>
                                        <td>
                                            {
                                                data?.album_photos?.map((abhi, index) => {
                                                    return (
                                                        <>
                                                            <img src={`${uploadURL}albums/${abhi.file_path}`} className="preview_img ml-2" alt=""></img>
                                                            <img onClick={() => onDeleteClick(abhi.id)} src={deleteIcon} className="delete_img ml-2" alt=""></img>

                                                        </>
                                                    )
                                                })

                                            }
                                        </td>
                                    </tr>
                                })

                            }


                        </tbody>
                    </table>
                ) : <h6 className="text-center mb-4">No Albums Found.</h6>}
                {(loading) &&
                    <div className="loaderPosition">
                    </div>
                }
            </div>

            <ToastContainer />
        </>
    )
}
export default UploadPressImageJsx;