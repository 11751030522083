import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { baseURL } from "../../constants/applicationConstants";
import './viewAlbum.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

export const ViewAlbumJsx = () => {
    const history = useHistory();

    // let [isProcessing, setProcessing] = useState(false);
    // let [loading, setLoading] = useState(false);
    const [albumListData, setAlbumData] = useState([]);
    const [pressAlbumListData, setPressAlbumData] = useState([]);

    // const { t } = useTranslation();

    useEffect(() => {
        getAlbumList()
        getPressAlbumList()
    }, [])
    const getAlbumList = () => {
        axios.get(`${baseURL}albums`).then((res) => {
            setAlbumData(res.data.result)

        }).catch((res) => {
            // setProcessing(false);
            toast.error(res.response?.data.message)
        })
    }
    const getPressAlbumList = () => {
        axios.get(`${baseURL}albums?isPressAlbum=true`).then((res) => {
            setPressAlbumData(res.data.result)

        }).catch((res) => {
            // setProcessing(false);
            toast.error(res.response?.data.message)
        })
    }
    const onViewDetailClick = (id) => {
        history.push(`/viewImage/${id}`)
    }

    return (
        <>
            <div className="container officeStaffComponentOuter">
    
                <h4>Album List</h4>
                {albumListData.length > 0 ? (
                    <div className="mb-5 mt-2">
                        {
                            albumListData?.map((data, index) => {
                                return (
                                    <button onClick={() => onViewDetailClick(data.id)} type="button" class="btn btn-info ml-3 mt-3">{data.name}</button>

                                )
                            })
                        }
                    </div>
                ) : <h6 className="text-center mb-4">No Albums Found.</h6>}
                <hr/>
                <h4>Press Album List</h4>
                {pressAlbumListData.length > 0 ? (
                    <div className="mb-5 mt-2">
                        {
                            pressAlbumListData?.map((data, index) => {
                                return (
                                    <button onClick={() => onViewDetailClick(data.id)} type="button" class="btn btn-info ml-3 mt-3">{data.name}</button>

                                )
                            })
                        }
                    </div>
                ) : <h6 className="text-center mb-4">No Albums Found.</h6>}



            </div>

            <ToastContainer />
        </>
    )
}
export default ViewAlbumJsx;