import React, { Component } from "react";
import ProvisionalRegistration from "./provisionalRegistration.jsx";

function ProvisionalRegistrationScreen(){

    return (
      <ProvisionalRegistration
      />
    );
  }

export default ProvisionalRegistrationScreen;