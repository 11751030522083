import React,{useEffect,useState} from "react";
// import "./profileDetails";
import "./profileDetails.css"


import { useTranslation } from "react-i18next";
import doctor from "../../assets/images/doctor.jpg";
import axios from 'axios';
import {baseURL} from '../../constants/applicationConstants';

const ProfileDetailsJsx = () => {
  const { t } = useTranslation();

  const [userData,setUserData] = useState(null);


  useEffect(()=>{
    const id = window.location.pathname.replace('/profileDetails/','');
    axios.get(`${baseURL}getDoctor/${id}`).then((res)=>{
      setUserData(res.data);
    })  
  },[])

  return (
    <div className="container officeStaffComponentOuter">
      <div className="bread_crums mt-3">Home /About Us / Office Staff / Dr. {userData?.name}</div><span className="print"  onClick={() => window.print()}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="skyBlue" class="bi bi-printer-fill" viewBox="0 0 16 16">
        <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
        <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
      </svg> &nbsp; {t("PRINT.TITLE")}</span>
      <h2 className="profileHeading mt-3">
        {t(`Dr. ${userData?.name}`)}
      </h2>
      <div className="container row mb-5">
        <div className="col-sm-3 image_doctor">
          <img src={doctor} className="profileImage mt-2" alt="">
          </img>
        </div>
        <div className="col-sm-9">
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-3 col-form-label" id="left_side_label">Full Name:</label>
            <div class="col-sm-9">
              <input type="text" readonly class="form-control-plaintext" id="staticEmail" value={`Dr.  ${userData?.name}`} />
            </div>

            <label for="staticEmail" class="col-sm-3 col-form-label" id="left_side_label">Designation:</label>
            <div class="col-sm-9">
              <input type="text" readonly class="form-control-plaintext" id="staticEmail" value="" />
            </div>
            <label for="staticEmail" class="col-sm-3 col-form-label" id="left_side_label">Qualification::</label>
            <div class="col-sm-9">
              <input type="text" readonly class="form-control-plaintext" id="staticEmail" value={`${userData?.education_doctor?.degree}`} />
            </div>
            <label for="staticEmail" class="col-sm-3 col-form-label" id="left_side_label">Registration No.:</label>
            <div class="col-sm-9">
              <input type="text" readonly class="form-control-plaintext" id="staticEmail" value="" />
            </div>
            <label for="staticEmail" class="col-sm-3 col-form-label" id="left_side_label">specialization:</label>
            <div class="col-sm-9">
              <input type="text" readonly class="form-control-plaintext" id="staticEmail" value="" />
            </div>
            <label for="staticEmail" class="col-sm-3 col-form-label" id="left_side_label">Duties Assigned:</label>
            <div class="col-sm-9">
              <input type="text" readonly class="form-control-plaintext" id="staticEmail" value="" />
            </div>
            <label for="staticEmail" class="col-sm-3 col-form-label" id="left_side_label">Residential Address:</label>
            <div class="col-sm-9">
              <input type="text" readonly class="form-control-plaintext" id="staticEmail" value={`${userData?.contact_detail_doctor?.permanent_address}`} />
            </div>
            <label for="staticEmail" class="col-sm-3 col-form-label" id="left_side_label">Office Address::</label>
            <div class="col-sm-9">
              <input type="text" readonly class="form-control-plaintext" id="staticEmail" value={`${userData?.contact_detail_doctor?.temp_address}`} />
            </div>
            <label for="staticEmail" class="col-sm-3 col-form-label" id="left_side_label">Telephone No.:</label>
            <div class="col-sm-9">
              <input type="text" readonly class="form-control-plaintext" id="staticEmail" value="" />
            </div>
            <label for="staticEmail" class="col-sm-3 col-form-label" id="left_side_label">Mobile No.:</label>
            <div class="col-sm-9">
              <input type="text" readonly class="form-control-plaintext" id="staticEmail" value={`${userData?.contact_detail_doctor?.mobile}`} />
            </div>
            <label for="staticEmail" class="col-sm-3 col-form-label" id="left_side_label">Email Id:</label>
            <div class="col-sm-9">
              <input type="text" readonly class="form-control-plaintext" id="staticEmail" value={`${userData?.contact_detail_doctor?.email}`} />
            </div>
          </div>

        </div>

      </div>
    </div>

  );
};

export default ProfileDetailsJsx;
