import React, { useState } from 'react';

import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import {baseURL} from '../../constants/applicationConstants';
import { ToastContainer, toast } from 'react-toastify';


export const PaymentScreen = () => {
    const [number, setNumber] = useState('');
    let [isProcessing, setProcessing] = useState(false);

    const onNumberChange = (e) => {
        const value = e.target.value;
        setNumber(value);
    }

    const onResetButtonClick = () =>{
        setNumber('')
    }

    const formSubmitHandler = () =>{
        setProcessing(true);
        axios.post(`${baseURL}doctor/${number}/validity`).then((res) => {
            if (res.data.status === 'success') {
                toast.success(res?.data.message)
                setProcessing(false);
            }
        }).catch((err) => {
            toast.error(err?.response?.data.message)
            setProcessing(false);
        })
    }

    const { t } = useTranslation();

    return (
        <div className="container officeStaffComponentOuter">
            <h3 style={{margin:'20px 0px'}}>
            {t("VERIFYUSER.TITLE")}
        </h3>
            <InputGroup className="col-md-12" >
                <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                {t("VERIFYUSER.CERTIFICATE")}
                </InputGroup.Text>
                <FormControl
                    id="basic-url"
                    name="postDocCollegeName"
                    aria-describedby="basic-addon3"
                    placeholder=  {t("VERIFYUSER.CERTIFICATE_PLACEHOLDER")}
                    className="form-control"
                    value={number ?? null}
                    onChange={(e) => onNumberChange(e)}
                    maxLength={15}
                />
            </InputGroup>

            <div class="row">
                        <div class="col-sm-12 text-center mb-5 mt-3">
                            <button 
                            id="btnSearch" 
                            class="btn center-block mr-4" 
                            onClick={() => onResetButtonClick()} >{t("REGISTER.RESET")}</button>
                            <button
                            onClick={formSubmitHandler}
                            id={`${number ? 'btnClear' : 'btnSearch'}`}
                            disabled={!number}
                            class="btn center-block">{!!isProcessing ? t("REGISTER.PROCESSING") : t("REGISTER.SUBMIT")}</button>
                        </div>
                    </div>
            <ToastContainer />
        </div>
    )
}