import React from 'react';
import Login from './login.jsx'
const ProfileDetails =()=> {
    return (
        <Login
        />
    )
}

export default ProfileDetails;
