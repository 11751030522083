import React from 'react';
import BannerDataJsx from './bannerData.jsx'
const BannerData =()=> {
    return (
        <BannerDataJsx
        />
    )
}

export default BannerData
