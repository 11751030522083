import React from "react";
import ResetPassJsx from "./resetPass.jsx";

function ResetPass(){

    return (
      <ResetPassJsx
      />
    );
  }

export default ResetPass;