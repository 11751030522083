import React, { useState, useEffect, useRef } from 'react'
// import "./profileDetails";
import "./login.css"
import login from "./login.json";

import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import { useTranslation } from "react-i18next";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
// import cookies from "js-cookie"
import { useHistory } from "react-router";
import { baseURL, emailRegex, otpRegex, CAPTCHA_SECRET_KEY, encrypt } from "../../constants/applicationConstants";
import { Timer } from '../Timer/timer';
import ReCAPTCHA from "react-google-recaptcha";

const tmpData = {
  email: '',
  otp: ''
}

const LoginJsx = () => {
  const { t } = useTranslation();
  // let [loading, setLoading] = useState(false);
  let [isProcessing, setProcessing] = useState(false);
  const history = useHistory();

  const [formData, setFormData] = useState({
    email: '',
    otp: '',
  });

  const [formError, setFormError] = useState({})
  const [formSubmitButtonDisable, setFormSubmitButtonDisable] = useState(true)
  const [submittingOTP, setSubmittingOTP] = useState(false);
  const [OTPStatus, setOTPStatus] = useState(null);
  const [masterID, setMasterID] = useState(null);
  const [showTimer, setShowTimer] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [recapchaVerified, setRecaptchaVerified] = useState(false);
  const recaptchaRef = useRef();

  useEffect(() => {
    isFromSubmissionBlock()
  })

  const onInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value) {
      if (!inputRegexValidation(name, value)) {
        setFormData({ ...formData, [name]: value })
        setFormError({ ...formError, [name]: "regx" })
      } else {
        setFormData({ ...formData, [name]: value })
        setFormError({ ...formError, [name]: "" })
      }
    } else {
      setFormData({ ...formData, [name]: '' })
      setFormError({})
    }
  };

  const inputRegexValidation = (name, value) => {
    if (name === "email")
      return emailRegex.test(value)
    else if (name === 'otp')
      return otpRegex.test(value);
    else
      return true;
  };

  const isFromSubmissionBlock = () => {
    let { email, otp } = formData;
    if (email && otp) {
      if (
        !inputRegexValidation("email", email)
      ) {
        setFormSubmitButtonDisable(true)
      } else {
        setFormSubmitButtonDisable(false)
      }
    } else {
      setFormSubmitButtonDisable(true)
    }
  };

  const onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      setFormError({ ...formError, [name]: "req" })
    } else {
      if (!inputRegexValidation(name, value)) {
        setFormError({ ...formError, [name]: "regx" })
      }
    }
  }

  const onSubmitButtonClick = () => {
    // setLoading(true)
    setProcessing(true);
    const data = {
      otp: encrypt(formData.otp)
    }
    axios.post(`${baseURL}user/${masterID}/validateOTP`, data).then((res) => {
      recaptchaRef.current.reset();
      // setLoading(false)
      if (res.data.status === "success") {
        toast.success(res.data.message)
        setProcessing(false);
        // cookies.set("auth", JSON.stringify(res.data.message));
        sessionStorage.setItem("authToken", JSON.stringify(res.data?.data?.access_token));
        sessionStorage.setItem("masterID", JSON.stringify(parseInt(res.data?.data?.master_doctor_id)));
        sessionStorage.setItem("role", JSON.stringify(res.data?.data?.role));
        setTimeout(() => {
          history.push("/updateProfile/UpdateProfile");
          window.location.reload()
        }, 3000)

      } else {
        toast.error(res.data.message)
        setProcessing(false);
      }
    }).catch((res) => {
      setProcessing(false);
      toast.error(res.response?.data.message)
      recaptchaRef.current.reset();
    })
  }

  const onSendOTPButtonClick = () => {
    setSubmittingOTP(true);
    const payload = {
      "email": formData.email
    }
    axios.post(`${baseURL}login/sendOTP`, payload).then((res) => {
      if (res.data.status === "success") {
        setShowTimer(true);
        setSubmittingOTP(false);
        setMasterID(res.data.data.master_doctor_id);
        setOTPStatus(res.data.status)
        toast.success(res.data.message)
      }
      else {
        setSubmittingOTP(false);
        toast.error(res.data.message)
      }
    }).catch((res) => {
      setSubmittingOTP(false);
      toast.error(res.response?.data.message)
    })
  }

  const onTimerComplete = () => {
    setShowTimer(false);
    setShowResendButton(true);
  }

  const OnResendButtonClick = () => {
    setShowTimer(true);
    setShowResendButton(false);
    const payload = {
      "email": formData.email
    }
    axios.post(`${baseURL}login/sendOTP`, payload).then((res) => {
      setSubmittingOTP(false);
      setMasterID(res.data.data.master_doctor_id);
      setOTPStatus(res.data.status)
    }).catch(() => {
      setSubmittingOTP(false);
    })
    setRecaptchaVerified(false);
    recaptchaRef.current.reset();
  }

  const onResetButtonClick = () => {
    // setFormData({
    //   email: '',
    //   otp: '',
    // })
    // setFormError({})
    // setFormSubmitButtonDisable(true);
    // setShowTimer(false);
    window.location.reload();
  }

  const onChange = (value) => {
    if (value) {
        setRecaptchaVerified(true);
    }
}

  return (
    <>
      <div className="container officeStaffComponentOuter">
        <div className="login_label mt-3">{t("LOGIN.DESCRIPTION")}</div><span className="print" onClick={() => window.print()}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="skyBlue" class="bi bi-printer-fill" viewBox="0 0 16 16">
          <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
          <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
        </svg> &nbsp; {t("PRINT.TITLE")}</span>
        <h2 className="loginHeading mt-2">
          {t(`${login.heading}`)}
        </h2>
        <div className="account_in">{t("LOGIN.TITLE")}</div>
        <InputGroup className="col-md-12 mt-4" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("LOGIN.EMAIL")}
          </InputGroup.Text>
          <FormControl
            name="email"
            onBlur={onTouchHandler}
            value={formData.email}
            onChange={onInputChange}
            id="basic-url"
            aria-describedby="basic-addon3"
            placeholder={t("LOGIN.EMAIL_PLACEHOLDER")} />
          <button className="send_otp" disabled={OTPStatus === 'success' || !inputRegexValidation("email", formData.email)} onClick={() => onSendOTPButtonClick()}>{submittingOTP ? t("LOGIN.SENDING") : t("LOGIN.SENDOTP")}</button>
        </InputGroup>
        <span className="text-danger">
          {
            formError['email'] &&
            (formError['email'] === "req"
              ? 'Email Id is required'
              : 'Enter a valid Email Id')}
        </span>
        <InputGroup className="col-md-12" >
          <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
            {t("LOGIN.ENTEROTP")}
          </InputGroup.Text>
          <FormControl
            id="basic-url"
            name="otp"
            onBlur={onTouchHandler}
            value={formData.otp}
            onChange={onInputChange}
            aria-describedby="basic-addon3"
            placeholder={t("LOGIN.OTP_PLACEHOLDER")} />
        </InputGroup>
        <span className="text-danger">
          {
            formError['otp'] &&
            (formError['otp'] === "req"
              ? 'otp Id is required'
              : 'Enter a valid otp Id')}
        </span>
        {<InputGroup className="col-md-12" >
          <div id="basic-addon" className="col-md-3 mb-3">
            <Timer show={showTimer} onCompletion={() => onTimerComplete()} />

           
          </div>
          {
              showTimer === true ? t("LOGIN.SPAM_EMAIL_ALERT") : ""
            }
        </InputGroup>}
        {showResendButton && <InputGroup className="col-md-12" >
          <div id="basic-addon">
            <button id="btnResend" class="btn" onClick={OnResendButtonClick}>{t("LOGIN.RESENDOTP")}</button>
          </div>
        </InputGroup>}
      </div>
      <div className="recaptcha_css">
        <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={CAPTCHA_SECRET_KEY}
            onChange={onChange}
        />
      </div>

      <div class="row">
        <div class="col-sm-12 text-center mb-5 mt-3">
          <button id="btnSearch" class="btn center-block mr-4" onClick={() => onResetButtonClick()} >{t("REGISTER.RESET")}</button>
          <button disabled={formSubmitButtonDisable || !!isProcessing} id="btnClear" class="btn center-block" onClick={onSubmitButtonClick}>{!!isProcessing ? t("REGISTER.PROCESSING") : t("REGISTER.SUBMIT")}</button>
        </div>
      </div>
      <ToastContainer />

    </>

  );
};

export default LoginJsx;
