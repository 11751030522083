import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';
import { getMonthName, uploadURL, getProperDateMonth, baseURL, fieldRegex, postalCodeRegex, adharRegex, nameRegex, phoneRegex, emailRegex, passwordRegex, downloadPDF } from "../../constants/applicationConstants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router";
import Data from '../updateProfile/updateProfile.json';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker3 from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import './upgradeDegree.css';

const tmpData = {
    name: "",
    father_name: "",
    mother_name: "",
    dob: null,
    graduation_completion_date: null,
    gender: "",
    category: "",
    professional_status: "",
    nationality: "IN",
    residence_state: "MH",
    aadhar_card_no: "",
    sector: "",
    password: "",
    confirmPassword: "",
    graduation: '',
    graduation_college_name: "",
    graduation_university_name: "",
    graduation_completion_month: "",
    graduation_completion_year: "",
    post_graduation: "",
    // professional_status: "",
    post_graduation_college_name: "",
    post_graduation_university_name: "",
    post_graduation_completion_month: "",
    post_graduation_completion_year: "",
    post_graduation_subjects: "",
    phd: "",
    phd_university_name: "",
    phd_completion_month: "",
    phd_completion_year: "",
    post_doc: "",
    post_doc_college_name: "",
    post_doc_university_name: "",
    post_doc_completion_month: "",
    post_doc_completion_year: "",
    dateOfPassing: '',
    universityName: '',
    collegeName: '',
    mobile: '',
    alt_mobile: '',
    email: "",
    alt_email: '',
    professional_address: '',
    district: '',
    village: '',
    taluka: '',
    state: 'MH',
    pincode: '',
    permanent_address: '',
    permanent_address_state: 'MH',
    permanent_address_district: '',
    permanent_address_taluka: '',
    permanent_address_village: '',
    permanent_address_pincode: '',
    workingAddress: '',
    tempState: '',
    tempDistrict: '',
    tempTaluka: '',
    tempVillage: '',
    tempPincode: '',
    awards: "",
    seminars_and_conferences: "",
    cve_completed: "",
    achievements: "",
    innovations: "",
    clinicalPhotograph: "",
    clinicalVideos: "",
    extensionPhotograph: "",
    extensionVideos: "",
    isWorkingAddressSame: false,
    ispermanent_addressSame: false
}

export const UpgradeDegreeJsx = (props) => {
    const history = useHistory();
    const { details } = props;

    const [formData, setFormData] = useState({
        name: "",
        father_name: "",
        mother_name: "",
        dob: null,
        graduation_completion_date: null,
        gender: "",
        category: "",
        professional_status: "",
        nationality: "IN",
        residence_state: "MH",
        aadhar_card_no: "",
        sector: "",
        password: "",
        confirmPassword: "",
        graduation: '',
        // professional_status: '',
        graduation_college_name: "",
        graduation_university_name: "",
        graduation_completion_month: "",
        graduation_completion_year: "",
        post_graduation: "",
        post_graduation_college_name: "",
        post_graduation_university_name: "",
        post_graduation_completion_month: "",
        post_graduation_completion_year: "",
        post_graduation_subjects: "",
        phd: "",
        phd_university_name: "",
        phd_completion_month: "",
        phd_completion_year: "",
        post_doc: "",
        post_doc_college_name: "",
        post_doc_university_name: "",
        post_doc_completion_month: "",
        post_doc_completion_year: "",
        dateOfPassing: '',
        universityName: '',
        collegeName: '',
        mobile: '',
        alt_mobile: '',
        email: '',
        alt_email: '',
        professional_address: '',
        district: '',
        village: '',
        taluka: '',
        state: 'MH',
        pincode: '',
        permanent_address: '',
        permanent_address_state: 'MH',
        permanent_address_district: '',
        permanent_address_taluka: '',
        permanent_address_village: '',
        permanent_address_pincode: '',
        workingAddress: '',
        tempState: '',
        tempDistrict: '',
        tempTaluka: '',
        tempVillage: '',
        tempPincode: '',
        awards: "",
        seminars_and_conferences: "",
        cve_completed: "",
        achievements: "",
        innovations: "",
        clinicalPhotograph: "",
        clinicalVideos: "",
        extensionPhotograph: "",
        extensionVideos: "",
        isWorkingAddressSame: false,
        ispermanent_addressSame: false
    })

    const [fileData, setFileData] = useState({
        signature: '',
        degree_certificate: '',
        andhar_card: '',
        identity_proof: '',
        dob_proof: '',
        domicial_proof: '',
        affidavit_magistrate: '',
        fir: '',
    })

    const [formError, setFormError] = useState({})
    const [formSubmitButtonDisable, setFormSubmitButtonDisable] = useState(true)
    let [isProcessing, setProcessing] = useState(false);
    const [fromData, setFromData] = useState(tmpData)
    // const [showUpdate, setShowUpdate] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState('UpgradeDegree');
    const [dataFiles, setDataFiles] = useState([]);
    // const [imagePath, setImagePath] = useState("");

    useEffect(() => {
        const date = details?.dob?.split('-');
        const graduationDate = details?.education_doctor?.graduation_completion_date?.split('-');
        setFromData({
            ...details, ...details?.contact_detail_doctor, ...details?.education_doctor, ...details?.document_doctors,
            workingAddress: details?.working_address_doctor?.working_address,
            tempState: details?.working_address_doctor?.state,
            tempDistrict: details?.working_address_doctor?.district,
            tempTaluka: details?.working_address_doctor?.taluka,
            tempVillage: details?.working_address_doctor?.village,
            tempPincode: details?.working_address_doctor?.pincode,
            dob: details && date && {
                day: parseInt(date[2]),
                month: parseInt(date[1]),
                year: parseInt(date[0])
            },
            graduation_completion_date: details && graduationDate && {
                day: parseInt(graduationDate[2]),
                month: parseInt(graduationDate[1]),
                year: parseInt(graduationDate[0])
            },
            graduation_completion_month: details?.education_doctor?.graduation_completion_month ?? getMonthName[details?.education_doctor?.graduation_completion_date?.split('-')[2]],
            graduation_completion_year: details?.education_doctor?.graduation_completion_year ?? details?.education_doctor?.graduation_completion_date?.split('-')[0]
        });
        setFileData({ ...details?.document_doctors })
    }, [details]);

    useEffect(() => {
        setDataFiles(Object.values(fileData))
    }, [fileData])

    useEffect(() => {
        isFromSubmissionBlock()
    }, [formData, fromData, fileData])

    // const validFileName = (name) => name.includes('.doc') || name.includes('.docx') || name.includes('.pdf') || name.includes('.jpg') || name.includes('.jpeg') || name.includes('.png')

    const validFileName = (name) => name.includes('.pdf') || name.includes('.jpeg') || name.includes('.jpg')

    const validPhotoFileName = (name) => name.includes('.jpeg') || name.includes('.jpg')

    const uploadFile = (file, name) => {
        let formData1 = new FormData();
        formData1.append('image', file)
        axios.post(
            `${uploadURL}uploadfile`,
            formData1
        ).then(res => {
            if (name === 'clinicalPhotograph' || name === 'clinicalVideos' || name === 'extensionPhotograph' || name === 'extensionVideos') {
                setFromData({ ...fromData, [name]: res.data.profileImageName });
            }
            else {
                const index = dataFiles.findIndex(data => data?.document_name === name);
                if (index !== -1) {
                    let temp = [...dataFiles];
                    temp[index].document_path = res.data.profileImageName;
                    setDataFiles(temp)
                }
                else {
                    let temp = [...dataFiles];
                    temp.push({ document_name: name, document_path: res.data.profileImageName })
                    setDataFiles(temp)
                }

            }
        })
            .catch(error => {
            })

    }

    const onFileChange = (e) => {
        const name = e.target.name;
        if (e.target.files[0]?.size) {
            if (e.target.files[0].size < 2097152) {
                if (name === 'upload_photo') {
                    if (e.target.files[0] && validPhotoFileName(e.target.files[0].name)) {
                        uploadFile(e.target.files[0], name)
                    }
                    else {
                        toast.error('.JPG/.JPEG format required')
                    }
                }
                else {
                    if (e.target.files[0] && validFileName(e.target.files[0].name)) {
                        uploadFile(e.target.files[0], name)
                    }
                    else {
                        toast.error('.JPG/.JPEG/.PDF format required')
                    }
                }

            }
            else {
                toast.error('File should be less than 2mb')
            }
        }
    }

    const onInputChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value) {
            if (!inputRegexValidation(name, value)) {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "regx" })
            } else {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "" })
            }
        } else {
            setFormData({ ...formData, [name]: '' })
            setFormError({ ...formError, [name]: '' })
        }
    };

    const inputRegexValidation = (name, value) => {
        if (name === "name" || name === 'father_name' || name === 'mother_name')
            return nameRegex.test(value)
        else if ((name === 'mobile') || (name === 'alt_mobile'))
            return phoneRegex.test(value);
        else if (name === 'alt_email' || name === 'email')
            return emailRegex.test(value);
        else if (name === 'password' || name === 'confirmPassword')
            return passwordRegex.test(value);
        else if (name === 'aadhar_card_no')
            return adharRegex.test(value);
        else if (name === 'pincode' || name === 'tempPincode' || name === 'permanent_address_pincode')
            return postalCodeRegex.test(value);
        else if (name === 'post_graduation' || name === 'phd' || name === 'post_doc' || name === 'graduation_university_name' || name === "post_graduation_university_name" || name === 'phd_university_name' || name === 'post_doc_university_name' || name === "graduation_college_name" || name === 'post_graduation_college_name' || name === 'post_doc_college_name' || name === "district" || name === 'tempDistrict' || name === 'permanent_address_district' || name === 'taluka' || name === 'tempTaluka' || name === 'permanent_address_taluka' || name === 'village' || name === 'tempVillage' || name === 'permanent_address_village')
            return fieldRegex.test(value)
        else if (name === 'graduation_completion_year' || name === 'post_graduation_completion_year' || name === 'phd_completion_year' || name === 'post_doc_completion_year')
            return value < 9999
        else
            return true;
    };

    const fieldsRegexValidation = () => {
        let { name, email, mobile, permanent_address, permanent_address_district, permanent_address_pincode,
            permanent_address_state, permanent_address_taluka, permanent_address_village, graduation, graduation_college_name, 
            graduation_university_name, dob,
            aadhar_card_no,father_name,mother_name,alt_email,alt_mobile,district,village,taluka,pincode} = fromData;
        return inputRegexValidation("name", name) &&
            inputRegexValidation("email", email) &&
            inputRegexValidation("mobile", mobile) &&
            inputRegexValidation("permanent_address", permanent_address) &&
            inputRegexValidation("permanent_address_district", permanent_address_district) &&
            inputRegexValidation("permanent_address_pincode", permanent_address_pincode) &&
            inputRegexValidation("permanent_address_state", permanent_address_state) &&
            inputRegexValidation("permanent_address_taluka", permanent_address_taluka) &&
            inputRegexValidation("permanent_address_village", permanent_address_village) &&
            inputRegexValidation("graduation", graduation) &&
            inputRegexValidation("graduation_college_name", graduation_college_name) &&
            inputRegexValidation("graduation_university_name", graduation_university_name) &&
            inputRegexValidation("dob", dob) &&
            mandatoryFieldsValidation() &&

              // non required fields

              (aadhar_card_no ? inputRegexValidation("aadhar_card_no", aadhar_card_no) : true) &&
              (father_name ? inputRegexValidation("father_name", father_name) : true) &&
              (mother_name ? inputRegexValidation("mother_name", mother_name) : true) &&
              (alt_email ? inputRegexValidation("alt_email", alt_email) : true) &&
              (alt_mobile ? inputRegexValidation("alt_mobile", alt_mobile) : true) &&
              (district ? inputRegexValidation("district", district) : true) &&
              (village ? inputRegexValidation("village", village) : true) &&
              (taluka ? inputRegexValidation("taluka", taluka) : true) &&
              (pincode ? inputRegexValidation("pincode", pincode) : true)
    }

    const isFromSubmissionBlock = () => {
        let { name, email, mobile, permanent_address, permanent_address_district, permanent_address_pincode,
             permanent_address_state, permanent_address_taluka, permanent_address_village, graduation, graduation_college_name, 
             graduation_university_name, dob, graduation_completion_date, graduation_completion_month, graduation_completion_year,
             tempDistrict,tempPincode,tempVillage,tempTaluka,post_graduation_college_name,post_graduation_university_name,
             phd_university_name,post_doc_college_name,post_doc_university_name,phd,post_doc,post_graduation} = fromData;
        if (isProvisionalUser()) {
            if (name && email && mobile && permanent_address && permanent_address_district && permanent_address_pincode && permanent_address_state && permanent_address_taluka && permanent_address_village && graduation && graduation_college_name && graduation_completion_date?.day && dob?.day && graduation_university_name) {
                if (
                    fieldsRegexValidation() &&
                    inputRegexValidation("graduation_completion_date", graduation_completion_date)
                ) {
                    setFormSubmitButtonDisable(false)
                } else {
                    setFormSubmitButtonDisable(true)
                }
            }
            else {
                setFormSubmitButtonDisable(true)
            }
        }
        else {
            if (name && email && mobile && permanent_address && permanent_address_district && permanent_address_pincode && permanent_address_state && permanent_address_taluka && permanent_address_village && graduation && graduation_college_name && graduation_completion_month && graduation_completion_year && dob?.day && graduation_university_name) {
                if (
                    fieldsRegexValidation() &&
                    inputRegexValidation("graduation_completion_month", graduation_completion_month) &&
                    inputRegexValidation("graduation_completion_year", graduation_completion_year) &&

                     // non required fields

                  (tempDistrict ? inputRegexValidation("tempDistrict", tempDistrict) : true) &&
                  (tempVillage ? inputRegexValidation("tempVillage", tempVillage) : true) &&
                  (tempTaluka ? inputRegexValidation("tempTaluka", tempTaluka) : true) &&
                  (tempPincode ? inputRegexValidation("tempPincode", tempPincode) : true) &&
                  (post_graduation_college_name ? inputRegexValidation("post_graduation_college_name", post_graduation_college_name) : true) &&
                  (post_graduation_university_name ? inputRegexValidation("post_graduation_university_name", post_graduation_university_name) : true) &&
                  (phd_university_name ? inputRegexValidation("phd_university_name", phd_university_name) : true) &&
                  (post_doc_college_name ? inputRegexValidation("post_doc_college_name", post_doc_college_name) : true) &&
                  (post_doc_university_name ? inputRegexValidation("post_doc_university_name", post_doc_university_name) : true) &&
                  (phd ? inputRegexValidation("phd", phd) : true) && 
                  (post_doc ? inputRegexValidation("post_doc", post_doc) : true) && 
                  (post_graduation ? inputRegexValidation("post_graduation", post_graduation) : true)
                ) {
                    setFormSubmitButtonDisable(false)
                } else {
                    setFormSubmitButtonDisable(true)
                }
            }
            else {
                setFormSubmitButtonDisable(true)
            }
        }
    };


    const onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            setFormError({ ...formError, [name]: "req" })
        } else {
            if (!inputRegexValidation(name, value)) {
                setFormError({ ...formError, [name]: "regx" })
            }
        }
    }

    const formSubmitHandler = (e) => {
        e.preventDefault();
        let personalInformation = {
            name: fromData.name,
            father_name: fromData.father_name,
            mother_name: fromData.mother_name,
            dob: fromData.dob && `${getProperDateMonth(fromData.dob?.day)}/${getProperDateMonth(fromData.dob?.month)}/${fromData.dob.year}`,
            gender: fromData.gender,
            category: fromData.category,
            nationality: fromData.nationality,
            aadhar_card_no: fromData.aadhar_card_no
        }

        let educationalInformation = {
            graduation: fromData.graduation,
            graduation_college_name: fromData.graduation_college_name,
            graduation_university_name: fromData.graduation_university_name
        }

        if (isProvisionalUser()) {
            personalInformation = { ...personalInformation, residence_state: fromData.residence_state }

            educationalInformation = { ...educationalInformation, graduation_completion_date: fromData.graduation_completion_date && `${getProperDateMonth(fromData.graduation_completion_date?.day)}/${getProperDateMonth(fromData.graduation_completion_date?.month)}/${fromData.graduation_completion_date.year}` }
        }
        else {
            personalInformation = {
                ...personalInformation, professional_status: fromData.professional_status,
                sector: fromData.sector
            }

            educationalInformation = {
                ...educationalInformation,
                graduation_completion_month: fromData.graduation_completion_month,
                graduation_completion_year: fromData.graduation_completion_year,
                post_graduation: fromData.post_graduation,
                post_graduation_college_name: fromData.post_graduation_college_name,
                post_graduation_university_name: fromData.post_graduation_university_name,
                post_graduation_completion_month: fromData.post_graduation_completion_month,
                post_graduation_completion_year: fromData.post_graduation_completion_year,
                post_graduation_subjects: fromData.post_graduation_subjects,
                phd: fromData.phd,
                phd_university_name: fromData.phd_university_name,
                phd_completion_month: fromData.phd_completion_month,
                phd_completion_year: fromData.phd_completion_year,
                post_doc: fromData.post_doc,
                post_doc_college_name: fromData.post_doc_college_name,
                post_doc_university_name: fromData.post_doc_university_name,
                post_doc_completion_month: fromData.post_doc_completion_month,
                post_doc_completion_year: fromData.post_doc_completion_year,
                awards: fromData.awards,
                seminars_and_conferences: fromData.seminars_and_conferences,
                cve_completed: fromData.cve_completed,
                // achievements: fromData.achievements,
                // clinical_photograph: fromData.clinicalPhotograph,
                // clinical_video: fromData.clinicalVideos,
                // extension_photograph: fromData.extensionPhotograph,
                // extension_video: fromData.extensionVideos,
                // innovations: fromData.innovations 
            }
        }


        let contactDetails = {
            mobile: fromData.mobile,
            email: fromData.email,
            alt_mobile: fromData.alt_mobile,
            alt_email: fromData.alt_email,
            professional_address: fromData.professional_address,
            district: fromData.district,
            village: fromData.village,
            state: fromData.state,
            taluka: fromData.taluka,
            pincode: fromData.pincode,
            permanent_address: fromData.permanent_address,
            permanent_address_state: fromData.permanent_address_state,
            permanent_address_district: fromData.permanent_address_district,
            permanent_address_taluka: fromData.permanent_address_taluka,
            permanent_address_village: fromData.permanent_address_village,
            permanent_address_pincode: fromData.permanent_address_pincode
        }

        let workingContactDetails = {
            working_address: fromData.workingAddress,
            state: fromData.tempState,
            district: fromData.tempDistrict,
            taluka: fromData.tempTaluka,
            village: fromData.tempVillage,
            pincode: fromData.tempPincode,
        }

        let reqParams = {
            registration_type: fromData.registration_type ?? null,
            "personalInformation": personalInformation,
            "educationalInformation": educationalInformation,
            "communicationDetails": contactDetails,
            "documents": dataFiles
        }

        if (!isProvisionalUser()) {
            reqParams = { ...reqParams, "workingAddressDetails": workingContactDetails }
        }

        // setIsLoading(true);
        setProcessing(true);
        const id = sessionStorage.getItem('masterID').trim();
        axios.put(`${baseURL}doctor/${id}`, reqParams, {
            headers: {
                'access-token': JSON.parse(sessionStorage.getItem('authToken')),
                'role': sessionStorage.getItem('role')
            }
        }).then(resp => {
            if (resp.data.status === "success") {
                toast.success(resp.data.message)
                // setShowUpdate(true);
                // setIsLoading(false)
                setProcessing(false);
                setTimeout(() => {
                    // setShowUpdate(false);
                    history.push("/dashboard");
                }, 3000)
            } else {
                //alert("error")
                toast.success("Data Updated")
                // setIsLoading(false)
                setProcessing(false);
            }
        }).catch((error) => {
            toast.error(error?.response?.data.message)
            // setIsLoading(false)
            setProcessing(false);
        })

    }

    const onAddressCheckboxClick = (value) => {
        setFromData({ ...fromData, isWorkingAddressSame: !value })
        if (value) {
            setFromData({
                ...fromData,
                workingAddress: '',
                tempState: '',
                tempDistrict: '',
                tempTaluka: '',
                tempVillage: '',
                tempPincode: ''
            })
        }
        else {
            setFromData({
                ...fromData,
                workingAddress: fromData.professional_address,
                tempState: fromData.state,
                tempDistrict: fromData.district,
                tempTaluka: fromData.taluka,
                tempVillage: fromData.village,
                tempPincode: fromData.pincode,
            })
            setFormError({
                ...formError,
                tempPincode: fromData.pincode ? inputRegexValidation("tempPincode", fromData.pincode) ? '' : 'regx' : '',
                tempVillage: fromData.village ? inputRegexValidation("tempVillage", fromData.village) ? '' : 'regx' : '',
                tempTaluka: fromData.taluka ? inputRegexValidation("tempTaluka", fromData.taluka) ? '' : 'regx' : '',
                tempDistrict: fromData.district ? inputRegexValidation("tempDistrict", fromData.district) ? '' : 'regx' : '',
                workingAddress: fromData.professional_address ? inputRegexValidation("workingAddress", fromData.professional_address) ? '' : 'regx' : ''
            })
        }
    }


    const onProfessionalAddressCheckboxClick = (value) => {
        setFromData({ ...fromData, ispermanent_addressSame: !value })
        if (value) {
            setFromData({
                ...fromData,
                professional_address: '',
                state: '',
                district: '',
                taluka: '',
                village: '',
                pincode: ''
            })
        }
        else {
            setFromData({
                ...fromData,
                professional_address: fromData.permanent_address,
                state: fromData.permanent_address_state,
                district: fromData.permanent_address_district,
                taluka: fromData.permanent_address_taluka,
                village: fromData.permanent_address_village,
                pincode: fromData.permanent_address_pincode,
            })
            setFormError({
                ...formError,
                pincode: fromData.permanent_address_pincode ? inputRegexValidation("pincode", fromData.permanent_address_pincode) ? '' : 'regx' : '',
                village: fromData.permanent_address_village ? inputRegexValidation("village", fromData.permanent_address_village) ? '' : 'regx' : '',
                taluka: fromData.permanent_address_taluka ? inputRegexValidation("taluka", fromData.permanent_address_taluka) ? '' : 'regx' : '',
                district: fromData.permanent_address_district ? inputRegexValidation("district", fromData.permanent_address_district) ? '' : 'regx' : '',
                professional_address: fromData.permanent_address ? inputRegexValidation("professional_address", fromData.permanent_address) ? '' : 'regx' : ''
            })
        }
    }

    const onResetButtonClick = () => {
        // setFromData(tmpData);
        // setFormData(tmpData);
        // setFormError({});
        window.location.reload();
    }

    const getCurrentYear = () => {
        const date = new Date();
        return date.getFullYear();
    }

    const isProvisionalUser = () => fromData?.registration_type === 'provisional';

    const mandatoryFieldsValidation = () => {
        if (isProvisionalUser()) {
            // const notMandatoryFields = dataFiles?.filter(data => 
            // data?.document_name === 'degree_certificate' ||
            // data?.document_name === 'payment_proof' || data?.document_name === 'dob_proof' ||
            // data?.document_name === 'domicile_certificate' || data?.document_name === 'andhar_card' ||
            // data?.document_name === 'signature'
            // || data?.document_name === 'identity_proof' || data?.document_name === 'upload_photo')

            // return notMandatoryFields.length === 8
            return true;
        }

        else {
            // const notMandatoryFields = dataFiles.filter(data => 
            //     data?.document_name === 'degree_certificate' ||
            //     data?.document_name === 'payment_proof' || data?.document_name === 'dob_proof' ||
            //     data?.document_name === 'domicile_certificate' || data?.document_name === 'andhar_card' ||
            //     data?.document_name === 'signature' || data?.document_name === 'provisional_regidtration_certificate'
            //     || data?.document_name === 'identity_proof' || data?.document_name === 'upload_photo')

            // return notMandatoryFields.length === 9

            return true;
        }
    }


    const onTabClick = (value) => {
        setSelectedTab(value);
        history.push(`/updateProfile/${value}`);
    }

    const { t } = useTranslation();

    const getFileName = (name) => {
        return dataFiles.filter(data => data?.document_name === name)[0]?.document_path
    }

    const formatInputValue = (date) => {
        if (!date) return '';
        return `${date.day}/${date.month}/${date.year}`;
    };

    const onCertificateClick = () =>{
        if(details?.registration_certificate_path)
            downloadPDF(details?.registration_certificate_path,`${fromData.name}_Certificate`);
        else    
            toast.error("No certificate found")
    }

    const onIcardClick = () =>{
        if(details?.identity_card_path)
        downloadPDF(details?.identity_card_path,`${fromData.name}_ICard`);
    else    
        toast.error("No icard found")
    }

    return (
        <>
            <span data-bs-toggle="modal" data-bs-target="#cancelOrder"></span>
            <div
                className="modal fade endSubscriptionMod"
                id="cancelOrder"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        {/* <div className="modal-body">
                            <img className="previewImage" src={`${uploadURL}images/${imagePath}`} />
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="exe-comm">
                <div className="container">
                    {/* 3.0 Update Profile */}
                    <div class="p-2 border text-dark" style={{ backgroundColor: "#de8461",fontWeight:"bold" }}>3.0   {t("REGISTER.UPGRADEPROFILE")}
                    </div>
                    <div className='row'>
                        <div className="col-sm-3" >
                            <InputGroup className=" mt-4">
                                <InputGroup.Text id={selectedTab === 'UpdateProfile' ? 'basic-addon4' : 'basic-add'} className="col-md-20 mb-3" onClick={() => onTabClick('UpdateProfile')} style={{cursor:'pointer'}}>
                                    {t("REGISTER.UPDATEPROFILE")}
                                </InputGroup.Text>
                            </InputGroup>
                            <InputGroup className=" mt-1">
                                <InputGroup.Text id={selectedTab === 'UpgradeDegree' ? 'basic-addon4' : 'basic-add'} className="col-md-20 mb-3" onClick={() => onTabClick('UpgradeDegree')} style={{cursor:'pointer'}}>
                                    {t("REGISTER.UPGRADEPROFILE")}
                                </InputGroup.Text>
                            </InputGroup> <InputGroup className=" mt-1">
                                <InputGroup.Text id={selectedTab === 'DuplicateCertificate' ? 'basic-addon4' : 'basic-add'} className="col-md-20 mb-3" onClick={() => onTabClick('DuplicateCertificate')} style={{cursor:'pointer'}}>
                                    {t("REGISTER.DUPLICATECERTIFICATE")}
                                </InputGroup.Text>
                            </InputGroup> <InputGroup className=" mt-1">
                                <InputGroup.Text id={selectedTab === 'NOC' ? 'basic-addon4' : 'basic-add'} className="col-md-20 mb-3" onClick={() => onTabClick('NOC')} style={{cursor:'pointer'}}>
                                    {t("REGISTER.NOC")}
                                </InputGroup.Text>
                                </InputGroup> <InputGroup className=" mt-1">
                                <InputGroup.Text id={selectedTab === 'Certificate' ? 'basic-addon4' : 'basic-add'} className="col-md-20 mb-3" onClick={()=>{onCertificateClick()}} style={{cursor:'pointer'}}>
                                    {t("REGISTER.CERTIFICATE")}
                                </InputGroup.Text>
                            </InputGroup>
                            <InputGroup className=" mt-1">
                                <InputGroup.Text id={selectedTab === 'Icard' ? 'basic-addon4' : 'basic-add'} className="col-md-20 mb-3" onClick={() => {onIcardClick()}} style={{cursor:'pointer'}}>
                                    {t("REGISTER.ICARD")}
                                </InputGroup.Text>
                            </InputGroup>

                            
                        </div>
                        {
                            <div className="col-sm-9" >
                                <InputGroup className="col-md-12 mt-4" style={{pointerEvents:'none'}}  >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.DOCTORNAME")}<span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <FormControl
                                        type="text"
                                        className={
                                            formError['name'] &&
                                            (formError['name'] === "req"
                                                ? "form-control  is-invalid"
                                                : "form-control")}
                                        name="name"
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.name : null}
                                        onChange={(e) => { setFromData({ ...fromData, name: e.target.value }); onInputChangeHandler(e) }}
                                        id="basic-url"
                                        disabled={true}
                                        style={{color:'black'}}
                                        aria-describedby="basic-addon3" placeholder={t("REGISTER.DOCTORNAME_PLACEHOLDER")}
                                        maxLength={60} 
                                        />

                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['name'] &&
                                        (formError['name'] === "req"
                                            ? 'Full Name is required'
                                            : 'Enter a valid Full Name')}
                                </span>

                                <InputGroup className="col-md-12" style={{pointerEvents:'none'}}>
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.NATIONALITY")}
                                    </InputGroup.Text>
                                    <select name="category"
                                        // className={
                                        //     formError['nationality'] &&
                                        //     (formError['nationality'] === "req"
                                        //         ? "form-control  is-invalid"
                                        //         : "form-control")}
                                        onBlur={onTouchHandler}
                                        disabled={true}
                                        style={{color:'black'}}
                                        value={(fromData) ? fromData.nationality : null}
                                        onChange={(e) => { setFromData({ ...fromData, nationality: e.target.value }); onInputChangeHandler(e) }} id="basic-url" aria-describedby="basic-addon3" placeholder="Enter Nationality"
                                        className="form-select customize_select" aria-label="Default select example">
                                        {
                                            Data.country.map((country) => {
                                                return (
                                                    <option value={country.code} selected={country.code === fromData.nationality}>{country.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                   </InputGroup>

                                <InputGroup className="col-md-12" style={{pointerEvents:'none'}}  >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.FATHERSNAME")}
                                    </InputGroup.Text>
                                    <FormControl
                                        type="text"
                                        className="form-control"
                                        name="father_name"
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.father_name : null}
                                        onChange={(e) => { setFromData({ ...fromData, father_name: e.target.value }); onInputChangeHandler(e) }}
                                        id="basic-url"
                                        disabled={true}
                                        style={{color:'black'}}
                                        aria-describedby="basic-addon3" placeholder={t("REGISTER.FATHERSNAME_PLACEHOLDER")}
                                        maxLength={60} />

                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['father_name'] &&
                                        (formError['father_name'] === "req"
                                            ? ''
                                            : 'Enter a valid Father\'s Name')}
                                </span>

                                <InputGroup className="col-md-12" style={{pointerEvents:'none'}} >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.MOTHERSNAME")}
                                    </InputGroup.Text>
                                    <FormControl
                                        type="text"
                                        className="form-control"
                                        name="mother_name"
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.mother_name : null}
                                        onChange={(e) => { setFromData({ ...fromData, mother_name: e.target.value }); onInputChangeHandler(e) }}
                                        id="basic-url"
                                        disabled={true}
                                        style={{color:'black'}}
                                        aria-describedby="basic-addon3" placeholder={t("REGISTER.MOTHERSNAME_PLACEHOLDER")}
                                        maxLength={60} 
                                        />

                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['mother_name'] &&
                                        (formError['mother_name'] === "req"
                                            ? ''
                                            : 'Enter a valid Mother\'s Name')}
                                </span>

                                <InputGroup className="col-md-12 calender-disable" style={{pointerEvents:'none'}} >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.DOB")} <span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <div className="col-sm-8" style={{ padding: '0px' }}>
                                        <DatePicker3 className="form-control" name="dob"
                                            value={(fromData) ? fromData.dob : null}
                                            onChange={(date) => { setFromData({ ...fromData, dob: date }) }}
                                            inputPlaceholder="dd/mm/yyyy"
                                            shouldHighlightWeekends
                                            formatInputText={() => formatInputValue(fromData && fromData.dob)}
                                        />
                                    </div>
                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['dob'] &&
                                        (formError['dob'] === "req"
                                            ? ''
                                            : 'Enter a valid Date Of Birth')}
                                </span>
                                <InputGroup className="col-md-12" style={{pointerEvents:'none'}} >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        <div className="label_word"> {t("REGISTER.AADHAR")}</div>
                                    </InputGroup.Text>
                                    <FormControl name="aadhar_card_no"
                                        className="form-control"
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.aadhar_card_no : null}
                                        onChange={(e) => { setFromData({ ...fromData, aadhar_card_no: e.target.value }); onInputChangeHandler(e) }}
                                        id="basic-url" aria-describedby="basic-addon3"
                                        placeholder={t("REGISTER.AADHAR_PLACEHOLDER")}
                                        disabled={true}
                                        style={{color:'black'}}
                                        maxLength={12} 
                                    />
                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['aadhar_card_no'] &&
                                        (formError['aadhar_card_no'] === "req"
                                            ? ''
                                            : 'Enter a valid Aadhar')}
                                </span>
                                <InputGroup className="col-md-12" style={{pointerEvents:'none'}} >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.GENDER")}
                                    </InputGroup.Text>
                                    <div class="form-check form-check-inline mb-3" id="basic-url">
                                        <label class="form-check-label ml-3" for="inlineRadio1">{t("REGISTER.MALE")}</label>
                                        <input name="gender"
                                            onBlur={onTouchHandler}
                                            // value={(fromData) ? fromData.gender : null}
                                            checked={fromData.gender === 'Male'}
                                            disabled={true}
                                            style={{color:'black'}}
                                            onChange={(e) => { setFromData({ ...fromData, gender: e.target.value }); onInputChangeHandler(e) }} class="form-check-input ml-3" type="radio"  id="inlineRadio1" value="Male" />
                                    </div>
                                    <div class="form-check form-check-inline mb-3" id="basic-url">
                                        <label class="form-check-label ml-3" for="inlineRadio2">{t("REGISTER.FEMALE")}</label>
                                        <input name="gender"
                                            onBlur={onTouchHandler}
                                            checked={fromData.gender === 'Female'}
                                            // value={(fromData) ? fromData.gender : null}
                                            disabled={true}
                                            style={{color:'black'}}
                                            onChange={(e) => { setFromData({ ...fromData, gender: e.target.value }); onInputChangeHandler(e) }} class="form-check-input ml-3" type="radio"  id="inlineRadio2" value="Female" />
                                    </div>
                                    <div class="form-check form-check-inline mb-3" id="basic-url">
                                        <label class="form-check-label ml-3" for="inlineRadio2">{t("REGISTER.OTHER")}</label>
                                        <input name="gender"
                                            onBlur={onTouchHandler}
                                            checked={fromData.gender === 'Other'}
                                            // value={(fromData) ? fromData.gender : null}
                                            disabled={true}
                                            style={{color:'black'}}
                                            onChange={(e) => { setFromData({ ...fromData, gender: e.target.value }); onInputChangeHandler(e) }} class="form-check-input ml-3" type="radio"  id="inlineRadio2" value="Other" />
                                    </div>
                                </InputGroup>

                                {!isProvisionalUser() && <InputGroup className="col-md-12" style={{pointerEvents:'none'}} >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.PROFESSIONALSTATUS")}
                                    </InputGroup.Text>
                                    <select name="professional_status"
                                        // className={
                                        //     formError['professional_status'] &&
                                        //     (formError['professional_status'] === "req"
                                        //         ? "form-control  is-invalid"
                                        //         : "form-control")}
                                        onBlur={onTouchHandler}
                                        disabled={true}
                                        style={{color:'black'}}
                                        value={(fromData) ? fromData.professional_status : null}
                                        onChange={(e) => { setFromData({ ...fromData, professional_status: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                        <option selected>Select</option>
                                        <option value="Self-employed">Self-employed</option>
                                        <option value="Govt. sector">Govt. sector</option>
                                        <option value="Private sector">Private sector</option>
                                        <option value="Student">Student</option>
                                        <option value="Teaching">Teaching</option>
                                        <option value="Entrepreneur">Entrepreneur</option>
                                        <option value="Consultant">Consultant</option>
                                        <option value="Intern">Intern</option>
                                    </select>
                                </InputGroup>}

                                {!isProvisionalUser() && <InputGroup className="col-md-12" style={{pointerEvents:'none'}}>
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.SECTOR")}
                                    </InputGroup.Text>
                                    <select name="sector"
                                        // className={
                                        //     formError['sector'] &&
                                        //     (formError['sector'] === "req"
                                        //         ? "form-control  is-invalid"
                                        //         : "form-control")}
                                        onBlur={onTouchHandler}
                                        disabled={true}
                                        style={{color:'black'}}
                                        value={(fromData) ? fromData.sector : null}
                                        onChange={(e) => { setFromData({ ...fromData, sector: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                        <option selected>Select</option>
                                        <option value="Dairy">Dairy</option>
                                        <option value="Poultry">Poultry</option>
                                        <option value="Goat">Goat</option>
                                        <option value="Exotic pets">Exotic pets</option>
                                        <option value="Indigenous pets">Indigenous pets</option>
                                        <option value="Laboratory">Laboratory</option>
                                        <option value="Wildlife">Wildlife</option>
                                        <option value="Pharmacy">Pharmacy</option>
                                        <option value="Nutrition">Nutrition</option>
                                        <option value="Meat industry">Meat industry</option>
                                        <option value="Social sector">Social sector</option>
                                        <option value="NGO">NGO</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </InputGroup>}
                                <InputGroup className="col-md-12" style={{pointerEvents:'none'}}>
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.CATEGORY")}
                                    </InputGroup.Text>
                                    <select name="category"
                                        // className={
                                        //     formError['category'] &&
                                        //     (formError['category'] === "req"
                                        //         ? "form-control  is-invalid"
                                        //         : "form-control")}
                                        onBlur={onTouchHandler}
                                        disabled={true}
                                        style={{color:'black'}}
                                        value={(fromData) ? fromData.category : null}
                                        onChange={(e) => { setFromData({ ...fromData, category: e.target.value }); onInputChangeHandler(e) }}
                                        className="form-select customize_select" aria-label="Default select example">
                                        <option selected>{t("DROPDOWN.SELECT")}</option>
                                        <option value="General">General</option>
                                        <option value="OBC">OBC</option>
                                        <option value="SC">SC</option>
                                        <option value="ST">ST</option>
                                        <option value="NT">NT</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </InputGroup>

                                {isProvisionalUser() && <> <InputGroup className="col-md-12" style={{pointerEvents:'none'}}>
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.STATE")}
                                    </InputGroup.Text>
                                    <select name="category"
                                        // className={
                                        //     formError['residence_state'] &&
                                        //     (formError['residence_state'] === "req"
                                        //         ? "form-control  is-invalid"
                                        //         : "form-control")}
                                        onBlur={onTouchHandler}
                                        disabled={true}
                                        style={{color:'black'}}
                                        value={(fromData) ? fromData.residence_state : null}
                                        onChange={(e) => { setFromData({ ...fromData, residence_state: e.target.value }); onInputChangeHandler(e) }} id="basic-url" aria-describedby="basic-addon3" placeholder="Enter state of Residence"
                                        className="form-select customize_select" aria-label="Default select example">
                                        {
                                            Data.states.map((state) => {
                                                return (
                                                    <option value={state.key} selected={state.key === formData.state}>{state.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </InputGroup>
                                </>}
                                <div class="p-2 border text-white mt-4" style={{ backgroundColor: "#de8461",fontWeight:"bold" }}>{t("Register.D3")}
                                </div>

                                <InputGroup className="col-md-12 mt-4" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.MOBILE")}<span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <FormControl id="basic-url" name="mobile" aria-describedby="basic-addon3"
                                        placeholder={t("REGISTER.MOBILE_PLACEHOLDER")}
                                        className={
                                            formError['mobile'] &&
                                            (formError['mobile'] === "req"
                                                ? "form-control  is-invalid"
                                                : "form-control")}
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.mobile : null}
                                        onChange={(e) => { setFromData({ ...fromData, mobile: e.target.value }); onInputChangeHandler(e) }}
                                        maxLength={10}
                                    />
                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['mobile'] &&
                                        (formError['mobile'] === "req"
                                            ? 'Mobile Number is required'
                                            : 'Enter a valid Mobile Number')}
                                </span>
                                <InputGroup className="col-md-12" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.ALTMOBILE")}
                                    </InputGroup.Text>
                                    <FormControl id="basic-url" name="alt_mobile" aria-describedby="basic-addon3"
                                        placeholder={t("REGISTER.ALTMOBILE_PLACEHOLDER")}
                                        className="form-control"
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.alt_mobile : null}
                                        onChange={(e) => { setFromData({ ...fromData, alt_mobile: e.target.value }); onInputChangeHandler(e) }}
                                        maxLength={10}
                                    />
                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['alt_mobile'] &&
                                        (formError['alt_mobile'] === "req"
                                            ? ''
                                            : 'Enter a valid Alternative  Mobile Number')}
                                </span>
                                <InputGroup className="col-md-12" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.EMAIL")}<span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <FormControl id="basic-url" name="email" aria-describedby="basic-addon3" placeholder={t("REGISTER.EMAIL_PLACEHOLDER")}
                                        className={
                                            formError['email'] &&
                                            (formError['email'] === "req"
                                                ? "form-control  is-invalid"
                                                : "form-control")}
                                        onBlur={onTouchHandler}
                                        disabled={true}
                                        style={{color:'black'}}
                                        value={(fromData) ? fromData.email : null}
                                        onChange={(e) => { setFromData({ ...fromData, email: e.target.value }); onInputChangeHandler(e) }}
                                    />
                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['email'] &&
                                        (formError['email'] === "req"
                                            ? 'Email Id is required'
                                            : 'Enter a valid Email Id')}
                                </span>
                                <InputGroup className="col-md-12" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.ALTEMAIL")}
                                    </InputGroup.Text>
                                    <FormControl id="basic-url" name="alt_email" aria-describedby="basic-addon3" placeholder={t("REGISTER.ALTEMAIL_PLACEHOLDER")}
                                        className="form-control"
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.alt_email : null}
                                        onChange={(e) => { setFromData({ ...fromData, alt_email: e.target.value }); onInputChangeHandler(e) }} />
                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['alt_email'] &&
                                        (formError['alt_email'] === "req"
                                            ? ''
                                            : 'Enter a valid Alternative Email Id')}
                                </span>


                                <InputGroup className="col-md-12 mt-4" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.PERMANANETADDRESS")}<span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <FormControl id="basic-url" name="permanent_address" aria-describedby="basic-addon3" placeholder={t("REGISTER.PERMANANETADDRESS_PLACEHOLDER")}
                                        // className="form-control"
                                        className={
                                            formError['permanent_address'] &&
                                            (formError['permanent_address'] === "req"
                                                ? "form-control  is-invalid"
                                                : "form-control")}
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.permanent_address : null}
                                        onChange={(e) => { setFromData({ ...fromData, permanent_address: e.target.value }); onInputChangeHandler(e) }}
                                        maxLength={100}
                                    />
                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['permanent_address'] &&
                                        (formError['permanent_address'] === "req"
                                            ? 'Permanent Address is required'
                                            : 'Enter a valid Permanent Address')}
                                </span>
                                <InputGroup className="col-md-12"  >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.DISTRICT")}<span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <FormControl
                                        type="text"
                                        // className="form-control"
                                        className={
                                            formError['permanent_address_district'] &&
                                            (formError['permanent_address_district'] === "req"
                                                ? "form-control  is-invalid"
                                                : "form-control")}
                                        name="permanent_address_district"
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.permanent_address_district : null}
                                        onChange={(e) => { setFromData({ ...fromData, permanent_address_district: e.target.value }); onInputChangeHandler(e) }}
                                        id="basic-url"
                                        aria-describedby="basic-addon3" placeholder={t("REGISTER.DISTRICT_PLACEHOLDER")}
                                        maxLength={50} />

                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['permanent_address_district'] &&
                                        (formError['permanent_address_district'] === "req"
                                            ? 'District is required'
                                            : 'Enter a valid District')}
                                </span>
                                <InputGroup className="col-md-12"  >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.VILLAGE")}<span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <FormControl
                                        type="text"
                                        // className="form-control"
                                        className={
                                            formError['permanent_address_village'] &&
                                            (formError['permanent_address_village'] === "req"
                                                ? "form-control  is-invalid"
                                                : "form-control")}
                                        name="permanent_address_village"
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.permanent_address_village : null}
                                        onChange={(e) => { setFromData({ ...fromData, permanent_address_village: e.target.value }); onInputChangeHandler(e) }}
                                        id="basic-url"
                                        aria-describedby="basic-addon3" placeholder={t("REGISTER.VILLAGE_PLACEHOLDER")}
                                        maxLength={50} />
                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['permanent_address_village'] &&
                                        (formError['permanent_address_village'] === "req"
                                            ? 'Village is required'
                                            : 'Enter a valid Village')}
                                </span>
                                <InputGroup className="col-md-12"  >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.PERSTATE")}<span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <select aria-label="Default select example" name="permanent_address_state"
                                        // className={
                                        //     formError['permanent_address_state'] &&
                                        //     (formError['permanent_address_state'] === "req"
                                        //         ? "form-control  is-invalid"
                                        //         : "form-control")}
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.permanent_address_state : null}
                                        className="form-select customize_select"
                                        onChange={(e) => { setFromData({ ...fromData, permanent_address_state: e.target.value }); onInputChangeHandler(e) }}
                                    >
                                        {
                                            Data.states.map((state) => {
                                                return (
                                                    <option value={state.key} selected={state.key === formData.state}>{state.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </InputGroup>

                                <InputGroup className="col-md-12"  >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.TALUKA")}<span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <FormControl
                                        type="text"
                                        // className="form-control"
                                        className={
                                            formError['permanent_address_taluka'] &&
                                            (formError['permanent_address_taluka'] === "req"
                                                ? "form-control  is-invalid"
                                                : "form-control")}
                                        name="permanent_address_taluka"
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.permanent_address_taluka : null}
                                        onChange={(e) => { setFromData({ ...fromData, permanent_address_taluka: e.target.value }); onInputChangeHandler(e) }}
                                        id="basic-url"
                                        aria-describedby="basic-addon3" placeholder={t("REGISTER.TALUKA_PLACEHOLDER")}
                                        maxLength={50} />

                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['permanent_address_taluka'] &&
                                        (formError['permanent_address_taluka'] === "req"
                                            ? 'Taluka is required'
                                            : 'Enter a valid Taluka')}
                                </span>
                                <InputGroup className="col-md-12" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.PINCODE")}<span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <FormControl id="basic-url" name="permanent_address_pincode" aria-describedby="basic-addon3" placeholder={t("REGISTER.PINCODE_PLACEHOLDER")}
                                        // className="form-control"
                                        className={
                                            formError['permanent_address_pincode'] &&
                                            (formError['permanent_address_pincode'] === "req"
                                                ? "form-control  is-invalid"
                                                : "form-control")}
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.permanent_address_pincode : null}
                                        onChange={(e) => { setFromData({ ...fromData, permanent_address_pincode: e.target.value }); onInputChangeHandler(e) }}
                                        maxLength={6}
                                    />
                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['permanent_address_pincode'] &&
                                        (formError['permanent_address_pincode'] === "req"
                                            ? 'PinCode is required'
                                            : 'Enter a valid Pin Code')}
                                </span>

                                <div class="form-check text-center" >
                                    <input class="form-check-input " type="checkbox" value={(fromData) ? fromData.ispermanent_addressSame : null} id="flexCheckDefault"
                                        onClick={() => onProfessionalAddressCheckboxClick(fromData.ispermanent_addressSame)}
                                    />
                                    <label class="form-check-label" for="flexCheckChecked" id="ticked">
                                        {isProvisionalUser() ? t("REGISTER.TEMPADDRESSSAME") : t("REGISTER.SAMEPROFESSIONALADDRESS")}
                                    </label>
                                </div>

                                <InputGroup className="col-md-12 mt-4" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {isProvisionalUser() ? t("REGISTER.TEMPADDRESS") : t("REGISTER.PRESENTPROFESSIONLADDRESS")}
                                    </InputGroup.Text>
                                    <FormControl id="basic-url" name="professional_address" aria-describedby="basic-addon3" placeholder={t("REGISTER.WORK_ADD_PLACEHOLDER")}
                                        className="form-control"
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.professional_address : null}
                                        onChange={(e) => { setFromData({ ...fromData, professional_address: e.target.value }); onInputChangeHandler(e) }}
                                        maxLength={100}
                                    />
                                </InputGroup>
                                {!isProvisionalUser() && <div className={'address-note'}>{t("REGISTER.ADDRESSCHANGE")}</div>}
                                <span className="text-danger">
                                    {
                                        formError['professional_address'] &&
                                        (formError['professional_address'] === "req"
                                            ? ''
                                            : 'Enter a valid Present Professional Address')}
                                </span>
                                <InputGroup className="col-md-12"  >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.DISTRICT")}
                                    </InputGroup.Text>
                                    <FormControl
                                        type="text"
                                        className="form-control"
                                        name="district"
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.district : null}
                                        onChange={(e) => { setFromData({ ...fromData, district: e.target.value }); onInputChangeHandler(e) }}
                                        id="basic-url"
                                        aria-describedby="basic-addon3" placeholder={t("REGISTER.DISTRICT_PLACEHOLDER")}
                                        maxLength={50} />

                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['district'] &&
                                        (formError['district'] === "req"
                                            ? ''
                                            : 'Enter a valid District')}
                                </span>
                                <InputGroup className="col-md-12"  >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.VILLAGE")}
                                    </InputGroup.Text>
                                    <FormControl
                                        type="text"
                                        className="form-control"
                                        name="village"
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.village : null}
                                        onChange={(e) => { setFromData({ ...fromData, village: e.target.value }); onInputChangeHandler(e) }}
                                        id="basic-url"
                                        aria-describedby="basic-addon3" placeholder={t("REGISTER.VILLAGE_PLACEHOLDER")}
                                        maxLength={50} />
                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['village'] &&
                                        (formError['village'] === "req"
                                            ? ''
                                            : 'Enter a valid Village')}
                                </span>
                                <InputGroup className="col-md-12"  >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.PERSTATE")}
                                    </InputGroup.Text>
                                    <select aria-label="Default select example" name="state"
                                        // className={
                                        //     formError['state'] &&
                                        //     (formError['state'] === "req"
                                        //         ? "form-control  is-invalid"
                                        //         : "form-control")}
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.state : null}
                                        className="form-select customize_select"
                                        onChange={(e) => { setFromData({ ...fromData, state: e.target.value }); onInputChangeHandler(e) }}
                                    >
                                        {
                                            Data.states.map((state) => {
                                                return (
                                                    <option value={state.key} selected={state.key === formData.state}>{state.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </InputGroup>

                                <InputGroup className="col-md-12"  >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.TALUKA")}
                                    </InputGroup.Text>
                                    <FormControl
                                        type="text"
                                        className="form-control"
                                        name="taluka"
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.taluka : null}
                                        onChange={(e) => { setFromData({ ...fromData, taluka: e.target.value }); onInputChangeHandler(e) }}
                                        id="basic-url"
                                        aria-describedby="basic-addon3" placeholder={t("REGISTER.TALUKA_PLACEHOLDER")}
                                        maxLength={50} />

                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['taluka'] &&
                                        (formError['taluka'] === "req"
                                            ? ''
                                            : 'Enter a valid Taluka')}
                                </span>
                                <InputGroup className="col-md-12" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.PINCODE")}
                                    </InputGroup.Text>
                                    <FormControl id="basic-url" name="pincode" aria-describedby="basic-addon3" placeholder={t("REGISTER.PINCODE_PLACEHOLDER")}
                                        className="form-control"
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.pincode : null}
                                        onChange={(e) => { setFromData({ ...fromData, pincode: e.target.value }); onInputChangeHandler(e) }}
                                        maxLength={6}
                                    />
                                </InputGroup>
                                <span className="text-danger">
                                    {
                                        formError['pincode'] &&
                                        (formError['pincode'] === "req"
                                            ? ''
                                            : 'Enter a valid Pin Code')}
                                </span>
                                {!isProvisionalUser() && <>
                                    <div class="form-check text-center" >
                                        <input class="form-check-input " type="checkbox" value={(fromData) ? fromData.isWorkingAddressSame : null} id="flexCheckDefault"
                                            onClick={() => onAddressCheckboxClick(fromData.isWorkingAddressSame)}
                                        />
                                        <label class="form-check-label" for="flexCheckChecked" id="ticked">
                                            {t("REGISTER.SAMEPROFESSIONAL")}
                                        </label>
                                    </div>
                                    {/* working address */}
                                    <InputGroup className="col-md-12 mt-4" >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.WORKADDRESS")}
                                        </InputGroup.Text>
                                        <FormControl id="basic-url" name="workingAddress" aria-describedby="basic-addon3" placeholder="Enter Work address"
                                            className="form-control"
                                            onBlur={onTouchHandler}
                                            value={(fromData) ? fromData.workingAddress : null}
                                            onChange={(e) => { setFromData({ ...fromData, workingAddress: e.target.value }); onInputChangeHandler(e) }}
                                            maxLength={100}
                                        />
                                    </InputGroup>
                                    <InputGroup className="col-md-12"  >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.DISTRICT")}
                                        </InputGroup.Text>
                                        <FormControl
                                            type="text"
                                            className="form-control"
                                            name="tempDistrict"
                                            onBlur={onTouchHandler}
                                            value={(fromData) ? fromData.tempDistrict : null}
                                            onChange={(e) => { setFromData({ ...fromData, tempDistrict: e.target.value }); onInputChangeHandler(e) }}
                                            id="basic-url"
                                            aria-describedby="basic-addon3" placeholder="Enter District"
                                            maxLength={50} />

                                    </InputGroup>
                                    <span className="text-danger">
                                        {
                                            formError['tempDistrict'] &&
                                            (formError['tempDistrict'] === "req"
                                                ? ''
                                                : 'Enter a valid District')}
                                    </span>
                                    <InputGroup className="col-md-12"  >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.VILLAGE")}
                                        </InputGroup.Text>
                                        <FormControl
                                            type="text"
                                            className="form-control"
                                            name="tempVillage"
                                            onBlur={onTouchHandler}
                                            value={(fromData) ? fromData.tempVillage : null}
                                            onChange={(e) => { setFromData({ ...fromData, tempVillage: e.target.value }); onInputChangeHandler(e) }}
                                            id="basic-url"
                                            aria-describedby="basic-addon3" placeholder="Enter Village"
                                            maxLength={50} />
                                    </InputGroup>
                                    <span className="text-danger">
                                        {
                                            formError['tempVillage'] &&
                                            (formError['tempVillage'] === "req"
                                                ? ''
                                                : 'Enter a valid Village')}
                                    </span>
                                    <InputGroup className="col-md-12"  >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.PERSTATE")}
                                        </InputGroup.Text>
                                        <select name="tempState" aria-label="Default select example"
                                            // className={
                                            //     formError['tempState'] &&
                                            //     (formError['tempState'] === "req"
                                            //         ? "form-control  is-invalid"
                                            //         : "form-control")}
                                            onBlur={onTouchHandler}
                                            value={(fromData) ? fromData.tempState : null}
                                            className="form-select customize_select"
                                            onChange={(e) => { setFromData({ ...fromData, tempState: e.target.value }); onInputChangeHandler(e) }}
                                        >
                                            {
                                                Data.states.map((state) => {
                                                    return (
                                                        <option value={state.key} selected={state.key === formData.state}>{state.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </InputGroup>

                                    <InputGroup className="col-md-12"  >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.TALUKA")}
                                        </InputGroup.Text>
                                        <FormControl
                                            type="text"
                                            className="form-control"
                                            name="tempTaluka"
                                            onBlur={onTouchHandler}
                                            value={(fromData) ? fromData.tempTaluka : null}
                                            onChange={(e) => { setFromData({ ...fromData, tempTaluka: e.target.value }); onInputChangeHandler(e) }}
                                            id="basic-url"
                                            aria-describedby="basic-addon3" placeholder="Enter Taluka"
                                            maxLength={50} />

                                    </InputGroup>
                                    <span className="text-danger">
                                        {
                                            formError['tempTaluka'] &&
                                            (formError['tempTaluka'] === "req"
                                                ? ''
                                                : 'Enter a valid Taluka')}
                                    </span>

                                    <InputGroup className="col-md-12" >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.PINCODE")}
                                        </InputGroup.Text>
                                        <FormControl id="basic-url" name="tempPincode" aria-describedby="basic-addon3" placeholder="Enter Pin code"
                                            className="form-control"
                                            onBlur={onTouchHandler}
                                            value={(fromData) ? fromData.tempPincode : null}
                                            onChange={(e) => { setFromData({ ...fromData, tempPincode: e.target.value }); onInputChangeHandler(e) }}
                                            maxLength={6}
                                        />
                                    </InputGroup>
                                    <span className="text-danger">
                                        {
                                            formError['tempPincode'] &&
                                            (formError['tempPincode'] === "req"
                                                ? ''
                                                : 'Enter a valid Pin Code')}
                                    </span>
                                </>}

                                <div class="p-2 border text-white mt-4" style={{ backgroundColor: "#de8461",fontWeight:"bold" }}>{t("Register.D2")}
                                </div>

                                <InputGroup className="col-md-12"  >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        {t("REGISTER.GRADUATION")}<span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <select name="graduation"
                                        // className={
                                        //     formError['graduation'] &&
                                        //     (formError['graduation'] === "req"
                                        //         ? "form-control  is-invalid"
                                        //         : "form-control")}
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.graduation : null}
                                        onChange={(e) => { setFromData({ ...fromData, graduation: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                        <option value='' selected>{t("DROPDOWN.SELECTGRADUATION")}</option>
                                        <option value="B.V.Sc.">B.V.Sc.</option>
                                        <option value="B.V.Sc. & A.H.">B.V.Sc. & A.H.</option>
                                        <option value="B.V.Sc. (Vet.)">B.V.Sc. (Vet.)</option>
                                        <option value="B.V.A.Sc.">B.V.A.Sc.</option>
                                        <option value="G.V.Sc.">G.V.Sc.</option>
                                        <option value="G.V.Sc. & A.H.">G.V.Sc. & A.H.</option>
                                        <option value="G.B.V.C.">G.B.V.C.</option>
                                        <option value="L.V.P.">L.V.P.</option>
                                        <option value="M.R.C.V.S.">M.R.C.V.S.</option>
                                        <option value="B.Vet.Med.">B.Vet.Med.</option>
                                        <option value="B.V.M.">B.V.M.</option>
                                        <option value="OTHER">OTHER</option>
                                    </select>
                                </InputGroup>
                                <div class="main-heading">
                                    <InputGroup className="col-md-12" >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.COLLEGENAME")}<span className="star_highlight">*</span>
                                        </InputGroup.Text>
                                        <FormControl id="basic-url" name="graduation_college_name" aria-describedby="basic-addon3" placeholder={t("REGISTER.COLLEGENAME_PLACEHOLDER")}
                                            className={
                                                formError['graduation_college_name'] &&
                                                (formError['graduation_college_name'] === "req"
                                                    ? "form-control  is-invalid"
                                                    : "form-control")}
                                            onBlur={onTouchHandler}
                                            value={(fromData) ? fromData.graduation_college_name : null}
                                            onChange={(e) => { setFromData({ ...fromData, graduation_college_name: e.target.value }); onInputChangeHandler(e) }}
                                            maxLength={100}
                                        />
                                    </InputGroup>
                                    <span className="text-danger">
                                        {
                                            formError['graduation_college_name'] &&
                                            (formError['graduation_college_name'] === "req"
                                                ? 'College Name is required'
                                                : 'Enter a valid College Name')}
                                    </span>
                                    <InputGroup className="col-md-12" >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.UNIVERSITYNAME")}<span className="star_highlight">*</span>
                                        </InputGroup.Text>
                                        <FormControl id="basic-url" name="graduation_university_name" aria-describedby="basic-addon3" placeholder={t("REGISTER.UNIVERSITYNAME_PLACEHOLDER")}
                                            className={
                                                formError['graduation_university_name'] &&
                                                (formError['graduation_university_name'] === "req"
                                                    ? "form-control  is-invalid"
                                                    : "form-control")}
                                            onBlur={onTouchHandler}
                                            value={(fromData) ? fromData.graduation_university_name : null}
                                            onChange={(e) => { setFromData({ ...fromData, graduation_university_name: e.target.value }); onInputChangeHandler(e) }}
                                            maxLength={100}
                                        />
                                    </InputGroup>
                                    <span className="text-danger">
                                        {
                                            formError['graduation_university_name'] &&
                                            (formError['graduation_university_name'] === "req"
                                                ? 'University Name is required'
                                                : 'Enter a valid University Name')}
                                    </span>
                                    <InputGroup className="col-md-12" >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-4">
                                            {t("REGISTER.COMPLETIONDATE")}<span className="star_highlight">*</span>
                                        </InputGroup.Text>
                                        {
                                            isProvisionalUser() ?
                                                <div className="col-sm-8" style={{ padding: '0px' }}>
                                                    <DatePicker3 className="form-control" name="graduation_completion_date"
                                                        value={(fromData) ? fromData.graduation_completion_date : null}
                                                        onChange={(date) => { setFromData({ ...fromData, graduation_completion_date: date }) }}
                                                        inputPlaceholder="dd/mm/yyyy"
                                                        shouldHighlightWeekends
                                                        formatInputText={() => formatInputValue(fromData && fromData.graduation_completion_date)}
                                                    />
                                                </div>
                                                :
                                                <>
                                                    <select name="graduation_completion_month"
                                                        // className={
                                                        //     formError['graduation_completion_month'] &&
                                                        //     (formError['graduation_completion_month'] === "req"
                                                        //         ? "form-control  is-invalid"
                                                        //         : "form-control")}
                                                        onBlur={onTouchHandler}
                                                        value={(fromData) ? fromData.graduation_completion_month : null}
                                                        onChange={(e) => { setFromData({ ...fromData, graduation_completion_month: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                                        <option value='' selected>Month</option>
                                                        <option value="January">January</option>
                                                        <option value="February">February</option>
                                                        <option value="March">March</option>
                                                        <option value="April">April</option>
                                                        <option value="May">May</option>
                                                        <option value="June">June</option>
                                                        <option value="July">July</option>
                                                        <option value="August">August </option>
                                                        <option value="September">September</option>
                                                        <option value="October">October</option>
                                                        <option value="November">November</option>
                                                        <option value="December">December </option>
                                                    </select>
                                                    <select name="graduation_completion_year"
                                                        // className={
                                                        //     formError['graduation_completion_year'] &&
                                                        //     (formError['graduation_completion_year'] === "req"
                                                        //         ? "form-control  is-invalid"
                                                        //         : "form-control")}
                                                        onBlur={onTouchHandler}
                                                        value={(fromData) ? fromData.graduation_completion_year : null}
                                                        onChange={(e) => { setFromData({ ...fromData, graduation_completion_year: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                                        <option value='' selected>Year</option>
                                                        {
                                                            Data.Years.map((year) => {
                                                                return (
                                                                    <option value={year.value} disabled={year.value > getCurrentYear()}>{year.label}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </>
                                        }
                                    </InputGroup>
                                    {/* <span className="text-danger">
                                        {
                                            formError['graduation_completion_year'] &&
                                            (formError['graduation_completion_year'] === "req"
                                                ? 'Year is required'
                                                : 'Enter a valid Year')}
                                    </span> */}
                                </div>
                                {!isProvisionalUser() && <>
                                    <InputGroup className="col-md-12"  >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.POSTGRADUATION")}
                                        </InputGroup.Text>
                                        <FormControl id="basic-url" name="post_graduation" aria-describedby="basic-addon3" placeholder="Enter Post graduation"
                                            type='text'
                                            className="form-control"
                                            onBlur={onTouchHandler}
                                            value={(fromData) ? fromData.post_graduation : null}
                                            onChange={(e) => { setFromData({ ...fromData, post_graduation: e.target.value }); onInputChangeHandler(e) }}
                                            maxLength={60}
                                        />
                                    </InputGroup>
                                    <span className="text-danger">
                                        {
                                            formError['post_graduation'] &&
                                            (formError['post_graduation'] === "req"
                                                ? ''
                                                : 'Enter a valid Post Graduation')}
                                    </span>
                                    <div class="main-heading">
                                        <InputGroup className="col-md-12" >
                                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                                {t("REGISTER.COLLEGENAME")}
                                            </InputGroup.Text>
                                            <FormControl id="basic-url" name="post_graduation_college_name" aria-describedby="basic-addon3" placeholder="Enter College Name"
                                                className="form-control"
                                                onBlur={onTouchHandler}
                                                value={(fromData) ? fromData.post_graduation_college_name : null}
                                                onChange={(e) => { setFromData({ ...fromData, post_graduation_college_name: e.target.value }); onInputChangeHandler(e) }}
                                                maxLength={100}
                                            />
                                        </InputGroup>
                                        <span className="text-danger">
                                            {
                                                formError['post_graduation_college_name'] &&
                                                (formError['post_graduation_college_name'] === "req"
                                                    ? ''
                                                    : 'Enter a valid College Name')}
                                        </span>
                                        <InputGroup className="col-md-12" >
                                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                                {t("REGISTER.UNIVERSITYNAME")}
                                            </InputGroup.Text>
                                            <FormControl id="basic-url" name="post_graduation_university_name" aria-describedby="basic-addon3" placeholder="Enter University Name"
                                                className="form-control"
                                                onBlur={onTouchHandler}
                                                value={(fromData) ? fromData.post_graduation_university_name : null}
                                                onChange={(e) => { setFromData({ ...fromData, post_graduation_university_name: e.target.value }); onInputChangeHandler(e) }}
                                                maxLength={100}
                                            />
                                        </InputGroup>
                                        <span className="text-danger">
                                            {
                                                formError['post_graduation_university_name'] &&
                                                (formError['post_graduation_university_name'] === "req"
                                                    ? ''
                                                    : 'Enter a valid University Name')}
                                        </span>
                                        <InputGroup className="col-md-12" >
                                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                                {t("REGISTER.COURSECOMPLETE")}
                                            </InputGroup.Text>
                                            <select name="post_graduation_completion_month"
                                                // className="form-control"
                                                onBlur={onTouchHandler}
                                                value={(fromData) ? fromData.post_graduation_completion_month : null}
                                                onChange={(e) => { setFromData({ ...fromData, post_graduation_completion_month: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                                <option value="" selected>Month</option>
                                                <option value="January">January</option>
                                                <option value="February">February</option>
                                                <option value="March">March</option>
                                                <option value="April">April</option>
                                                <option value="May">May</option>
                                                <option value="June">June</option>
                                                <option value="July">July</option>
                                                <option value="August">August </option>
                                                <option value="September">September</option>
                                                <option value="October">October</option>
                                                <option value="November">November</option>
                                                <option value="December">December </option>
                                            </select>
                                            <select name="post_graduation_completion_year"
                                                // className="form-control"
                                                onBlur={onTouchHandler}
                                                value={(fromData) ? fromData.post_graduation_completion_year : null}
                                                onChange={(e) => { setFromData({ ...fromData, post_graduation_completion_year: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                                <option selected>Year</option>
                                                {
                                                    Data.Years.map((year) => {
                                                        return (
                                                            <option value={year.value} disabled={year.value > getCurrentYear()}>{year.label}</option>
                                                        )
                                                    })
                                                }
                                            </select>

                                        </InputGroup>
                                    </div>
                                    <InputGroup className="col-md-12"  >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.POSTGRADUATIONSUBJECTS")}
                                        </InputGroup.Text>
                                        <Form.Control
                                            className="form-control"
                                            as="textarea"
                                            rows={4}
                                            placeholder={'Enter Post Graduation Subjects '}
                                            id="exampleFormControlTextarea1"
                                            
                                            name="post_graduation_subjects"
                                            style={{ marginBottom: '20px', resize: 'none' }}
                                            value={(fromData) ? fromData.post_graduation_subjects : null}
                                            onChange={(e) => { setFromData({ ...fromData, post_graduation_subjects: e.target.value }); onInputChangeHandler(e) }}
                                        />
                                    </InputGroup>
                                    <InputGroup className="col-md-12"  >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.PHD")}
                                        </InputGroup.Text>
                                        <FormControl id="basic-url" name="phd" aria-describedby="basic-addon3" placeholder="Enter PhD."
                                            type='text'
                                            className="form-control"
                                            onBlur={onTouchHandler}
                                            value={(fromData) ? fromData.phd : null}
                                            onChange={(e) => { setFromData({ ...fromData, phd: e.target.value }); onInputChangeHandler(e) }}
                                            maxLength={60}
                                        />
                                    </InputGroup>
                                    <span className="text-danger">
                                        {
                                            formError['phd'] &&
                                            (formError['phd'] === "req"
                                                ? ''
                                                : 'Enter a valid PhD')}
                                    </span>
                                    <div class="main-heading">
                                        <InputGroup className="col-md-12" >
                                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                                {t("REGISTER.UNIVERSITYNAME")}
                                            </InputGroup.Text>
                                            <FormControl id="basic-url" name="phd_university_name" aria-describedby="basic-addon3" placeholder="Enter University Name"
                                                className="form-control"
                                                onBlur={onTouchHandler}
                                                value={(fromData) ? fromData.phd_university_name : null}
                                                onChange={(e) => { setFromData({ ...fromData, phd_university_name: e.target.value }); onInputChangeHandler(e) }}
                                                maxLength={100}
                                            />
                                        </InputGroup>
                                        <span className="text-danger">
                                            {
                                                formError['phd_university_name'] &&
                                                (formError['phd_university_name'] === "req"
                                                    ? ''
                                                    : 'Enter a valid University Name')}
                                        </span>
                                        <InputGroup className="col-md-12" >
                                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                                {t("REGISTER.COURSECOMPLETE")}
                                            </InputGroup.Text>
                                            <select name="phd_completion_month"
                                                // className="form-control"
                                                onBlur={onTouchHandler}
                                                value={(fromData) ? fromData.phd_completion_month : null}
                                                onChange={(e) => { setFromData({ ...fromData, phd_completion_month: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                                <option value="" selected>Month</option>
                                                <option value="January">January</option>
                                                <option value="February">February</option>
                                                <option value="March">March</option>
                                                <option value="April">April</option>
                                                <option value="May">May</option>
                                                <option value="June">June</option>
                                                <option value="July">July</option>
                                                <option value="August">August </option>
                                                <option value="September">September</option>
                                                <option value="October">October</option>
                                                <option value="November">November</option>
                                                <option value="December">December </option>
                                            </select>
                                            <select name="phd_completion_year"
                                                // className="form-control"
                                                onBlur={onTouchHandler}
                                                value={(fromData) ? fromData.phd_completion_year : null}
                                                onChange={(e) => { setFromData({ ...fromData, phd_completion_year: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                                <option selected>Year</option>
                                                {
                                                    Data.Years.map((year) => {
                                                        return (
                                                            <option value={year.value} disabled={year.value > getCurrentYear()}>{year.label}</option>
                                                        )
                                                    })
                                                }
                                            </select>

                                        </InputGroup>
                                    </div>
                                    <InputGroup className="col-md-12"  >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.POSTDOC")}
                                        </InputGroup.Text>
                                        <FormControl id="basic-url" name="post_doc" aria-describedby="basic-addon3" placeholder="Enter Post Doc (pdf)"
                                            type='text'
                                            className="form-control"
                                            onBlur={onTouchHandler}
                                            value={(fromData) ? fromData.post_doc : null}
                                            onChange={(e) => { setFromData({ ...fromData, post_doc: e.target.value }); onInputChangeHandler(e) }}
                                            maxLength={60}
                                        />
                                    </InputGroup>
                                    <span className="text-danger">
                                        {
                                            formError['post_doc'] &&
                                            (formError['post_doc'] === "req"
                                                ? ''
                                                : 'Enter a valid Post Doc (PDF)')}
                                    </span>
                                    <div class="main-heading">
                                        <InputGroup className="col-md-12" >
                                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                                {t("REGISTER.COLLEGENAME")}
                                            </InputGroup.Text>
                                            <FormControl id="basic-url" name="post_doc_college_name" aria-describedby="basic-addon3" placeholder="Enter College Name"
                                                className="form-control"
                                                onBlur={onTouchHandler}
                                                value={(fromData) ? fromData.post_doc_college_name : null}
                                                onChange={(e) => { setFromData({ ...fromData, post_doc_college_name: e.target.value }); onInputChangeHandler(e) }}
                                                maxLength={100}
                                            />
                                        </InputGroup>
                                        <span className="text-danger">
                                            {
                                                formError['post_doc_college_name'] &&
                                                (formError['post_doc_college_name'] === "req"
                                                    ? ''
                                                    : 'Enter a valid College Name')}
                                        </span>
                                        <InputGroup className="col-md-12" >
                                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                                {t("REGISTER.UNIVERSITYNAME")}
                                            </InputGroup.Text>
                                            <FormControl id="basic-url" name="post_doc_university_name" aria-describedby="basic-addon3" placeholder="Enter University Name"
                                                className="form-control"
                                                onBlur={onTouchHandler}
                                                value={(fromData) ? fromData.post_doc_university_name : null}
                                                onChange={(e) => { setFromData({ ...fromData, post_doc_university_name: e.target.value }); onInputChangeHandler(e) }}
                                                maxLength={100}
                                            />
                                        </InputGroup>
                                        <span className="text-danger">
                                            {
                                                formError['post_doc_university_name'] &&
                                                (formError['post_doc_university_name'] === "req"
                                                    ? ''
                                                    : 'Enter a valid University Name')}
                                        </span>
                                        <InputGroup className="col-md-12" >
                                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                                {t("REGISTER.COURSECOMPLETE")}
                                            </InputGroup.Text>
                                            <select name="post_doc_completion_month"
                                                // className="form-control"
                                                onBlur={onTouchHandler}
                                                value={(fromData) ? fromData.post_doc_completion_month : null}
                                                onChange={(e) => { setFromData({ ...fromData, post_doc_completion_month: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                                <option value="" selected>Month</option>
                                                <option value="January">January</option>
                                                <option value="February">February</option>
                                                <option value="March">March</option>
                                                <option value="April">April</option>
                                                <option value="May">May</option>
                                                <option value="June">June</option>
                                                <option value="July">July</option>
                                                <option value="August">August </option>
                                                <option value="September">September</option>
                                                <option value="October">October</option>
                                                <option value="November">November</option>
                                                <option value="December">December </option>
                                            </select>
                                            <select name="post_doc_completion_year"
                                                // className="form-control"
                                                onBlur={onTouchHandler}
                                                value={(fromData) ? fromData.post_doc_completion_year : null}
                                                onChange={(e) => { setFromData({ ...fromData, post_doc_completion_year: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                                <option selected>Year</option>
                                                {
                                                    Data.Years.map((year) => {
                                                        return (
                                                            <option value={year.value} disabled={year.value > getCurrentYear()}>{year.label}</option>
                                                        )
                                                    })
                                                }
                                            </select>

                                        </InputGroup>
                                    </div>

                                    <div class="p-2 border text-white mt-4" style={{ backgroundColor: "#de8461",fontWeight:"bold" }}>{t("Register.D5")}
                                    </div>
                                    <InputGroup className="col-md-12 mt-4" >
                                        <InputGroup.Text id="basic-addon3" className="col-md-6 mb-3">
                                            {t("REGISTER.AWARD")}
                                        </InputGroup.Text>
                                        <Form.Control
                                            className="form-control"
                                            as="textarea"
                                            name="awards"
                                            rows={4}
                                            placeholder={'Enter  Award (If any)  '}
                                            id="exampleFormControlTextarea1"
                                            
                                            style={{ marginBottom: '20px', resize: 'none' }}
                                            value={(fromData) ? fromData.awards : null}
                                            onChange={(e) => { setFromData({ ...fromData, awards: e.target.value }); onInputChangeHandler(e) }}
                                        />
                                    </InputGroup>
                                    <InputGroup className="col-md-12 mt-4" >
                                        <InputGroup.Text id="basic-addon3" className="col-md-6 mb-3">
                                            {t("REGISTER.SEMINAR")}
                                        </InputGroup.Text>
                                        <Form.Control
                                            className={
                                                formError['seminars_and_conferences'] &&
                                                (formError['seminars_and_conferences'] === "req"
                                                    ? "form-control  is-invalid"
                                                    : "form-control")}
                                            as="textarea"
                                            name="seminars_and_conferences"
                                            rows={4}
                                            placeholder={'Enter Organization of Seminars & Conferences'}
                                            id="exampleFormControlTextarea1"
                                            
                                            style={{ marginBottom: '20px', resize: 'none' }}
                                            value={(fromData) ? fromData.seminars_and_conferences : null}
                                            onChange={(e) => { setFromData({ ...fromData, seminars_and_conferences: e.target.value }); onInputChangeHandler(e) }}
                                        />
                                    </InputGroup>
                                    <InputGroup className="col-md-12 mt-4" >
                                        <InputGroup.Text id="basic-addon3" className="col-md-6 mb-3">
                                            {t("REGISTER.CVE")}
                                        </InputGroup.Text>
                                        <Form.Control
                                            className={
                                                formError['cve_completed'] &&
                                                (formError['cve_completed'] === "req"
                                                    ? "form-control  is-invalid"
                                                    : "form-control")}
                                            as="textarea"
                                            name="cve_completed"
                                            rows={4}
                                            placeholder={'Enter CVE completed  '}
                                            id="exampleFormControlTextarea1"
                                            
                                            style={{ marginBottom: '20px', resize: 'none' }}
                                            value={(fromData) ? fromData.cve_completed : null}
                                            onChange={(e) => { setFromData({ ...fromData, cve_completed: e.target.value }); onInputChangeHandler(e) }}
                                        />
                                    </InputGroup>


                                    {/* <div class="p-2 border text-white mt-4" style={{ backgroundColor: "#de8461",fontWeight:"bold" }}>{t("Register.D6")}
                                    </div>


                                    <InputGroup className="col-md-12 mt-4" >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.ACHIEVEMENT")}
                                        </InputGroup.Text>
                                        <Form.Control
                                            className={
                                                formError['achievements'] &&
                                                (formError['achievements'] === "req"
                                                    ? "form-control  is-invalid"
                                                    : "form-control")}
                                            as="textarea"
                                            name="achievements"
                                            rows={4}
                                            placeholder={'Enter Achievements'}
                                            id="exampleFormControlTextarea1"
                                            
                                            style={{ marginBottom: '20px',resize:'none' }}
                                            value={(fromData) ? fromData.achievements : null}
                                            onChange={(e) => { setFromData({ ...fromData, achievements: e.target.value }); onInputChangeHandler(e) }}
                                        />
                                    </InputGroup>
                                    <InputGroup className="col-md-12 mt-4" >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.CLINICALPHOTO")}
                                        </InputGroup.Text>
                                        <FormControl />
                                        <Form.Control
                                            name="clinicalPhotograph"
                                            onBlur={onTouchHandler}
                                            onChange={(e) => onFileChange(e)}
                                            className="select_image hidden" type="file"
                                            style={fromData.clinicalPhotograph ? { color: 'black' } : { color: 'transparent' }}
                                        />
                                    </InputGroup>

                                    <InputGroup className="col-md-12 mt-4" >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.CLINICALVIDEO")}
                                        </InputGroup.Text>
                                        <FormControl />
                                        <Form.Control
                                            name="clinicalVideos"
                                            onBlur={onTouchHandler}
                                            onChange={(e) => onFileChange(e)}
                                            className="select_image hidden" type="file"
                                            style={fromData.clinicalVideos ? { color: 'black' } : { color: 'transparent' }}
                                        />
                                    </InputGroup>

                                    <InputGroup className="col-md-12 mt-4" >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.EXTENSIONPHOTO")}
                                        </InputGroup.Text>
                                        <FormControl />
                                        <Form.Control
                                            name="extensionPhotograph"
                                            onBlur={onTouchHandler}
                                            onChange={(e) => onFileChange(e)}
                                            className="select_image hidden" type="file"
                                            style={fromData.extensionPhotograph ? { color: 'black' } : { color: 'transparent' }}
                                        />
                                    </InputGroup>

                                    <InputGroup className="col-md-12 mt-4" >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.EXTENSIONVIDEO")}
                                        </InputGroup.Text>
                                        <FormControl />
                                        <Form.Control
                                            name="extensionVideos"
                                            onBlur={onTouchHandler}
                                            onChange={(e) => onFileChange(e)}
                                            className="select_image hidden" type="file"
                                            style={fromData.extensionVideos ? { color: 'black' } : { color: 'transparent' }}
                                        />
                                    </InputGroup>


                                    <InputGroup className="col-md-12 mt-4" >
                                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                            {t("REGISTER.INNOVATION")}
                                        </InputGroup.Text>
                                        <Form.Control
                                            className={
                                                formError['innovations'] &&
                                                (formError['innovations'] === "req"
                                                    ? "form-control  is-invalid"
                                                    : "form-control")}
                                            as="textarea"
                                            name="innovations"
                                            rows={4}
                                            placeholder={'Enter Innovations (If any)'}
                                            id="exampleFormControlTextarea1"
                                            
                                            style={{ marginBottom: '20px',resize:'none' }}
                                            value={(fromData) ? fromData.innovations : null}
                                            onChange={(e) => { setFromData({ ...fromData, innovations: e.target.value }); onInputChangeHandler(e) }}
                                        />
                                    </InputGroup>
 */}

                                </>}

                                <div class="p-2 border text-white mt-4" style={{ backgroundColor: "#de8461",fontWeight:"bold" }}>{t("Register.D4")}
                                </div>

                                <InputGroup className="col-md-12 mt-4 upload-image" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-5 mb-3">
                                        {t("REGISTER.PHOTO")} <span className="star_highlight">*</span>{t("REGISTER.PHOTO1")}
                                    </InputGroup.Text>
                                    <FormControl />
                                    <Form.Control
                                        name="upload_photo"
                                        onBlur={onTouchHandler}
                                        onChange={(e) => onFileChange(e)}
                                        className="select_image hidden" type="file"
                                        style={{ color: 'transparent',display:'none' }}
                                    />
                                    {getFileName('upload_photo') && <><button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('upload_photo')}`, '_blank') }}
                                    >VIEW</button>
                                        <FormControl id="basic-url" value={getFileName('upload_photo')} aria-describedby="basic-addon3" />
                                    </>}
                                </InputGroup>
                                <InputGroup className="col-md-12 upload-image" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-5 mb-3">
                                        {t("REGISTER.SIGNATURE")}<span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <FormControl />
                                    <Form.Control
                                        name="signature"
                                        onBlur={onTouchHandler}
                                        onChange={(e) => onFileChange(e)}
                                        className="select_image" type="file"
                                        style={{ color: 'transparent',display:'none' }}
                                    />
                                    {getFileName('signature') && <><button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('signature')}`, '_blank') }}
                                    >VIEW</button>
                                        <FormControl id="basic-url" value={getFileName('signature')} aria-describedby="basic-addon3" />
                                    </>}
                                </InputGroup>
                                <InputGroup className="col-md-12 upload-image" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-5 mb-3">
                                        {t("REGISTER.AADHARCARD")}<span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <FormControl />
                                    <Form.Control
                                        name="andhar_card"
                                        onBlur={onTouchHandler}
                                        onChange={(e) => onFileChange(e)}
                                        className="select_image" type="file"
                                        style={{ color: 'transparent',display:'none' }}
                                    />
                                    {getFileName('andhar_card') && <><button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('andhar_card')}`, '_blank') }}
                                    >VIEW</button>
                                        <FormControl id="basic-url" value={getFileName('andhar_card')} aria-describedby="basic-addon3" />
                                    </>}
                                </InputGroup>
                                <InputGroup className="col-md-12 upload-image" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-5 mb-3">
                                        {t("REGISTER.COURSECOMPLETION")}<span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <FormControl />
                                    <Form.Control
                                        name="degree_certificate"
                                        onBlur={onTouchHandler}
                                        onChange={(e) => onFileChange(e)}
                                        className="select_image" type="file"
                                        style={{ color: 'transparent',display:'none' }}
                                    />
                                    {getFileName('degree_certificate') && <> <button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('degree_certificate')}`, '_blank') }}
                                    >VIEW</button>
                                        <FormControl id="basic-url" value={getFileName('degree_certificate')} aria-describedby="basic-addon3" />
                                    </>}
                                </InputGroup>
                                <InputGroup className="col-md-12 upload-image" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-5 mb-3">
                                        {t("REGISTER.IDENTITYPROOF")}<span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <FormControl />
                                    <Form.Control
                                        name="identity_proof"
                                        onBlur={onTouchHandler}
                                        onChange={(e) => onFileChange(e)}
                                        className="select_image" type="file"
                                        style={{ color: 'transparent',display:'none' }}
                                    />
                                    {getFileName('identity_proof') && <><button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('identity_proof')}`, '_blank') }}
                                    >VIEW</button>
                                        <FormControl id="basic-url" value={getFileName('identity_proof')} aria-describedby="basic-addon3" />
                                    </>}
                                </InputGroup>
                                <InputGroup className="col-md-12 upload-image" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-5 mb-3">
                                        {t("REGISTER.STATECERTIFICATE")}<span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <FormControl />
                                    <Form.Control className="select_image"
                                        name="domicile_certificate"
                                        onBlur={onTouchHandler}
                                        onChange={(e) => onFileChange(e)}
                                        type="file"
                                        style={{ color: 'transparent',display:'none' }}
                                    />
                                    {getFileName('domicile_certificate') && <><button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('domicile_certificate')}`, '_blank') }}
                                    >VIEW</button>
                                        <FormControl id="basic-url" value={getFileName('domicile_certificate')} aria-describedby="basic-addon3" />
                                    </>}
                                </InputGroup>
                                <InputGroup className="col-md-12 upload-image" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-5 mb-3">
                                        {t("REGISTER.DOBPROOF")}<span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <FormControl />
                                    <Form.Control
                                        name="dob_proof"
                                        onBlur={onTouchHandler}
                                        onChange={(e) => onFileChange(e)}
                                        className="select_image" type="file"
                                        style={{ color: 'transparent',display:'none' }}
                                    />
                                    {getFileName('dob_proof') && <><button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('dob_proof')}`, '_blank') }}
                                    >VIEW</button>
                                        <FormControl id="basic-url" value={getFileName('dob_proof')} aria-describedby="basic-addon3" />
                                    </>}
                                </InputGroup>

                                <InputGroup className="col-md-12 upload-image" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-5 mb-3">
                                        {t("REGISTER.PAYMENTPROOF")}<span className="star_highlight">*</span>
                                    </InputGroup.Text>
                                    <FormControl />
                                    <Form.Control
                                        name="payment_proof"
                                        onBlur={onTouchHandler}
                                        onChange={(e) => onFileChange(e)}
                                        className="select_image" type="file"
                                        style={{ color: 'transparent',display:'none' }}
                                    />
                                    {getFileName('payment_proof') && <><button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('payment_proof')}`, '_blank') }}
                                    >VIEW</button>
                                        <FormControl id="basic-url" value={getFileName('payment_proof')} aria-describedby="basic-addon3" />
                                    </>}
                                </InputGroup>
                                {!isProvisionalUser() && <>
                                    <InputGroup className="col-md-12 upload-image" >
                                        <InputGroup.Text id="basic-addon3" className="col-md-5 mb-3">
                                            {t("REGISTER.PROVISIONALREGISTRATIONCERTIFICATE")}<span className="star_highlight">*</span>
                                        </InputGroup.Text>
                                        <FormControl />
                                        <Form.Control
                                            name="provisional_regidtration_certificate"
                                            onBlur={onTouchHandler}
                                            onChange={(e) => onFileChange(e)}
                                            className="select_image" type="file"
                                            style={{ color: 'transparent',display:'none' }}
                                        />
                                        {getFileName('provisional_regidtration_certificate') && <><button
                                            className="show-image"
                                            onClick={() => { window.open(`${uploadURL}images/${getFileName('provisional_regidtration_certificate')}`, '_blank') }}
                                        >VIEW</button>
                                            <FormControl id="basic-url" value={getFileName('provisional_regidtration_certificate')} aria-describedby="basic-addon3" />
                                        </>}
                                    </InputGroup>
                                    <InputGroup className="col-md-12 upload-image" >
                                        <InputGroup.Text id="basic-addon3" className="col-md-5 mb-3">
                                            {t("REGISTER.FIR")}
                                        </InputGroup.Text>
                                        <FormControl />
                                        <Form.Control
                                            name="fir"
                                            onBlur={onTouchHandler}
                                            onChange={(e) => onFileChange(e)}
                                            className="select_image" type="file"
                                            style={{ color: 'transparent',display:'none' }}
                                        />
                                        {getFileName('fir') && <><button
                                            className="show-image"
                                            onClick={() => { window.open(`${uploadURL}images/${getFileName('fir')}`, '_blank') }}
                                        >VIEW</button>
                                            <FormControl id="basic-url" value={getFileName('fir')} aria-describedby="basic-addon3" />
                                        </>}
                                    </InputGroup>
                                    <InputGroup className="col-md-12 upload-image" >
                                        <InputGroup.Text id="basic-addon3" className="col-md-5 mb-3">
                                            {t("REGISTER.AFFIDAVIT")}
                                        </InputGroup.Text>
                                        <FormControl />
                                        <Form.Control
                                            name="affidavit_magistrate"
                                            onBlur={onTouchHandler}
                                            onChange={(e) => onFileChange(e)}
                                            className="select_image" type="file"
                                            style={{ color: 'transparent',display:'none' }}
                                        />
                                        {getFileName('affidavit_magistrate') && <> <button
                                            className="show-image"
                                            onClick={() => { window.open(`${uploadURL}images/${getFileName('affidavit_magistrate')}`, '_blank') }}
                                        >VIEW</button>
                                            <FormControl id="basic-url" value={getFileName('affidavit_magistrate')} aria-describedby="basic-addon3" />
                                        </>}
                                    </InputGroup>

                                </>}
                                <div className="mandatory_section">{t("REGISTER.MANDATORYFILED")}</div>



                            </div>
                        }
                        {/* <div class="form-check text-center" >
                                <label class="pb-4 form-check-label" for="flexCheckChecked" id="ticked">
                                    Document with Proof
                            </label> */}
                        {/* </div> */}
                        {
                            selectedTab === 'Upgrade Degree' &&
                            <div className="col-8">
                                <InputGroup className="col-md-12"  >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        Degree
                            </InputGroup.Text>
                                    <select name="degree"
                                        // className={
                                        //     formError['degree'] &&
                                        //     (formError['degree'] === "req"
                                        //         ? "form-control  is-invalid"
                                        //         : "form-control")}
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.degree : null}
                                        onChange={(e) => { setFromData({ ...fromData, degree: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                        <option selected>Select</option>
                                        <option value="BVSC">B.V.Sc</option>
                                        <option value="BVSCAH">B.V.Sc & A.H.</option>
                                        <option value="MVSC">M.V.Sc</option>
                                        <option value="MVSCAH">M.V.Sc & A.H.</option>
                                        <option value="PHD">Ph.D.</option>
                                        <option value="BVM">B.V.M.</option>
                                        <option value="MRCVS">M.R.C.V.S.</option>
                                    </select>
                                </InputGroup>
                                <InputGroup className="col-md-12" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        Date of Passing
                            </InputGroup.Text>
                                    <Form.Control name="course_completion_date"
                                        className={
                                            formError['course_completion_date'] &&
                                            (formError['course_completion_date'] === "req"
                                                ? "form-control  is-invalid"
                                                : "form-control")}
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.course_completion_date : null}
                                        onChange={(e) => { setFromData({ ...fromData, course_completion_date: e.target.value }); onInputChangeHandler(e) }} id="basic-url" type="date"  />
                                </InputGroup>
                                <InputGroup className="col-md-12" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        University Name
                            </InputGroup.Text>
                                    <FormControl id="basic-url" name="university_name" aria-describedby="basic-addon3" placeholder="Enter University Name"
                                        className={
                                            formError['university_name'] &&
                                            (formError['university_name'] === "req"
                                                ? "form-control  is-invalid"
                                                : "form-control")}
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.university_name : null}
                                        onChange={(e) => { setFromData({ ...fromData, university_name: e.target.value }); onInputChangeHandler(e) }}
                                    />
                                </InputGroup>
                                <InputGroup className="col-md-12" >
                                    <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                        College Name
                            </InputGroup.Text>
                                    <FormControl id="basic-url" name="college_name" aria-describedby="basic-addon3" placeholder="Enter College Name"
                                        className={
                                            formError['college_name'] &&
                                            (formError['college_name'] === "req"
                                                ? "form-control  is-invalid"
                                                : "form-control")}
                                        onBlur={onTouchHandler}
                                        value={(fromData) ? fromData.college_name : null}
                                        onChange={(e) => { setFromData({ ...fromData, college_name: e.target.value }); onInputChangeHandler(e) }}
                                    />
                                </InputGroup>
                            </div>
                        }
                    </div>
                    {/* </div> */}

                    <div class="row">
                        <div class="col-sm-12 text-center mb-5 mt-3">
                            <button id="btnSearch" class="btn center-block mr-4" onClick={() => onResetButtonClick()} >{t("REGISTER.CANCEL")}</button>
                            <button onClick={formSubmitHandler}
                                disabled={formSubmitButtonDisable}
                                id={`${!formSubmitButtonDisable ? 'btnClear' : 'btnSearch'}`} class="btn center-block">{!!isProcessing ? t("REGISTER.PROCESSING") : t("REGISTER.SUBMIT")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />

        </>
    )

}
export default UpgradeDegreeJsx;