import React, { Component, Suspense, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";
import OfficeStaff from './components/officeStaff/officeStaff.js';
import FAQ from './components/faq/faq.js';
import AccessibilityStatement from './components/accessibilityStatement/accessibilityStatement.js';
import Header from './components/header/header.js';
import Footer from "./components/footer/footer.js";
import contactUs from './components/contactUS/contactUs.js';
import Home from './components/home/home.js';
import ExecutiveCommittee from './components/executiveCommittee/executiveCommittee.js';
import ProvisionalRegistrationScreen from './components/provisionalRegistration/provisionalRegistration.js';
import CorporateProfile from './components/corporateProfile/corporateProfile.js';
import DisiplinaryCommittee from './components/disiplinaryCommittee/disiplinaryCommittee.js';
import TermAndCondition from './components/termAndCondition/termAndCondition.js';

import Disclaimer from './components/disclaimer/disclaimer.js';
import SiteMap from './components/siteMap/siteMap.js';
import Grievance from './components/grievance/grievance.js';
import Dashboard from './components/doctorDashboard/dashboard.js';

import FileUpload from './components/fileUpload.js';

import ScrollTop from "./components/scrollTop.js"
import themeContext from "./context/themeContext.js";
import fontsizeChanges from "./context/fontsizeChanges.js";


import ScreenReader from './components/screenReader/screenReader.js';
import VariousFiles from './components/variousFilesFormat/variousFilesFormat.js';
import Help from "./components/help/help.js";
import Circular from "./components/circular/circular.js";
import Register from "./components/register/register.js";
import Feedback from './components/feedback/feedback.js'
import Login from './components/login/login.js';
import AdminLogin from './components/adminLogin/adminLogin';
import Master from './components/master/master';
import DoctorDetails from './components/doctorDetails/doctorDetails.js'
import Payment from './components/payment/payment';




import ActAndRules from "./components/actAndRules/actAndRules.js";
import ProfileDetails from "./components/profileDetails/profileDetails.js";
import UpdateProfile from "./components/updateProfile/updateProfile.js";
import UpgradeDegree from "./components/upgradeDegree/upgradeDegree.js";
import DuplicateCertificate from "./components/duplicateCertificate/duplicateCertificate.js";
import NOC from "./components/noc/noc.js";
// import Certificate from "./components/certificate/certificate.js";
// import ICard from "./components/icard/icard.js";
import AdminUpdate from "./components/adminUpdate/adminUpdate.js";

// import Introduction from "./components/introduction/introduction.js";
import Admin from './components/admin/admin';
import SystemEnvironemnt from "./components/systemEnvironment/systemEnvironment.js";
import UploadImage from "./components/uploadImage/uploadImage.js";
import UploadPressImage from "./components/uploadPressImage/uploadPressImage.js";

import UploadAlbum from "./components/uploadAlbum/uploadAlbum.js";
import ViewAlbum from "./components/viewAlbum/viewAlbum.js";
import ViewImage from "./components/viewImage/viewImage.js";


import UploadPressAlbum from "./components/uploadPressAlbum/uploadPressAlbum.js";
import UploadPDF from "./components/uploadPDF/uploadPDF.js";
import UploadNews from "./components/uploadNews/uploadNews.js";
import ForgotPass from "./components/forgotPass/forgotPass.js";
import ResetPass from "./components/resetPassword/resetPass.js";
import  LoginLogDetails  from "./components/loginLog/master.js";

class LegacyComponent extends Component {
  render() {
    return (
      <Router>
        <div className="aligningTheSiteApp">
          <Header />
          <ScrollTop>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/officeStaff" component={OfficeStaff} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/adminLogin" component={AdminLogin} />
              <Route exact path="/profileDetails/:id" component={ProfileDetails} />
              <Route exact path="/faq" component={FAQ} />
              <Route exact path="/help" component={Help} />
              <Route exact path="/dashboard" component={Dashboard} />

              <Route exact path="/variousFiles" component={VariousFiles} />

              <Route exact path="/fileUpload" component={FileUpload} />
              <Route exact path="/accessibilityStatement" component={AccessibilityStatement} />
              <Route exact path="/executiveCommittee" component={ExecutiveCommittee} />
              <Route exact path="/disiplinaryCommittee" component={DisiplinaryCommittee} />
              <Route exact path="/corporateProfile" component={CorporateProfile} />
              <Route exact path="/termAndCondition" component={TermAndCondition} />
              <Route exact path="/uploadImage" component={UploadImage} />
              <Route exact path="/uploadPressImage" component={UploadPressImage} />

              <Route exact path="/uploadAlbum" component={UploadAlbum} />
              <Route exact path="/viewAlbum" component={ViewAlbum} />
              <Route exact path="/viewImage/:id" component={ViewImage} />


              <Route exact path="/uploadPressAlbum" component={UploadPressAlbum} />

              <Route exact path="/uploadPDF" component={UploadPDF} />
              <Route exact path="/uploadNews" component={UploadNews} />


            <Route exact path="/contactUs" component={contactUs} />
              <Route exact path="/updateProfile/UpdateProfile" component={UpdateProfile} />
              <Route exact path="/updateProfile/UpgradeDegree" component={UpgradeDegree} />
              <Route exact path="/updateProfile/DuplicateCertificate" component={DuplicateCertificate} />
              <Route exact path="/updateProfile/NOC" component={NOC} />
              {/* <Route exact path="/updateProfile/Certificate" component={Certificate} />
              <Route exact path="/updateProfile/Icard" component={ICard} /> */}
              <Route exact path="/adminUpdate/:id" component={AdminUpdate} />
              {/* <Route exact path="/introduction" component={Introduction} /> */}
              <Route exact path="/systemEnvironment" component={SystemEnvironemnt} />
              <Route exact path="/disclaimer" component={Disclaimer} />
              <Route exact path="/siteMap" component={SiteMap} />
              <Route exact path="/doctorDetails/:id" component={DoctorDetails} />

              <Route exact path="/grievance" component={Grievance} />
              <Route exact path="/screenReader" component={ScreenReader} />
              <Route exact path="/circular" component={Circular} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/provisionalRegister" component={ProvisionalRegistrationScreen} />
              <Route exact path='/feedback' component={Feedback} />
              {/* <Route path="/personal-info" component={Step1Reg} /> */}
              <Route exact path="/actAndRules" component={ActAndRules} /> 
              <Route exact path="/adminDashboard" component={Admin} />
              <Route exact path="/masterDashboard" component={Master} />
              <Route exact path="/loginLog" component={LoginLogDetails} />

              <Route exact path="/verifyUser" component={Payment} />
              <Route exact path="/forgotPass" component={ForgotPass} />
              <Route exact path="/:resetPassword" component={ResetPass} />

            </Switch>
          </ScrollTop>
        </div>
        <Footer />
      </Router>

    );
  }
}

const MyComponent = withTranslation()(LegacyComponent);

const loadingMarkup = <h2>Loading..</h2>;

export default function App() {
  
  const [mode, setMode] = useState('light');
  const [fontSize, setFontSize] = useState('stable');

  const updateTheme = (data) => {
    setMode(data)
  }

  const updateFontSize = (data) => {
    setFontSize(data)
  }

  return (
    <Suspense fallback={loadingMarkup}>
    <themeContext.Provider value = {{mode:mode,updateTheme:updateTheme}}>
        <fontsizeChanges.Provider value = {{
          updateFontSize:updateFontSize,fontSize:fontSize}}>
          <MyComponent />
        </fontsizeChanges.Provider>
    </themeContext.Provider>
    </Suspense>
  );
}
