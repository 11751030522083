import VariousFilesJsx from "./variousFilesFormat.jsx";

function VariousFiles(){

    return (
      <VariousFilesJsx
      />
    );
  }

export default VariousFiles;