import React from "react";
import CorporateProfileJsx from "./corporateProfile.jsx";

function CorporateProfile(){

    return (
      <CorporateProfileJsx
      />
    );
  }

export default CorporateProfile;