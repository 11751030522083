import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

export const Pagination = (props) =>{
    const {limit,totalCount,onPaginationButtonClick} = props;
    const [activePage,setActive] = useState(1);

    const onNextButtonClick = () =>{
        setActive(activePage + 1)
        onPaginationButtonClick(activePage+1)
    }

    const onPreviousButtonClick = () =>{
        setActive(activePage - 1)
        onPaginationButtonClick(activePage-1)
    }

    useEffect(()=>{
        setActive(1);
    },[totalCount])

    const { t } = useTranslation();

    return(
        <div className='pagination-section'>
            <label className='filter-labels'>{t("ADMINDASHBOARD.ACTIVEPAGE")}</label>
            <div style={{display:'flex'}}>
                <button disabled={activePage === 1} onClick={()=>onPreviousButtonClick()}>{'<'}</button>
                <div className='active-page'>{activePage}</div>
                <button disabled={activePage >= (parseInt(totalCount/limit) + 1)} onClick={()=>onNextButtonClick()}>{'>'}</button>
            </div>
        </div>
    )
}