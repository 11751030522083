import UploadImageJsx from "./uploadImage.jsx";

function UploadImage(){

    return (
      <UploadImageJsx
      />
    );
  }

export default UploadImage;