import React from "react";
import DisiplinaryCommitteeJsx from "./disiplinaryCommittee.jsx";

function DisiplinaryCommittee(){

    return (
      <DisiplinaryCommitteeJsx
      />
    );
  }

export default DisiplinaryCommittee;