import React from 'react';
import  DisclaimerJsx  from "./disclaimer.jsx";
import disclaimerJson from "./disclaimer.json";

export default function Disclaimer() {
    let cardData= disclaimerJson;
    return (
       
        <div>
             <DisclaimerJsx 
         disclaimerdata = {cardData}
          />
        </div>
    )
}
