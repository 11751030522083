import React, { useState, useEffect, useRef } from "react";
import './register.css';
import { useTranslation } from "react-i18next";
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';
import { baseURL, uploadURL,encrypt, getProperDateMonth, fieldRegex, CAPTCHA_SECRET_KEY, htmlRegex, postalCodeRegex, adharRegex, nameRegex, phoneRegex, emailRegex, passwordRegex, getMonthName } from "../../constants/applicationConstants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router";
import Data from './register.json';
import { Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker3 from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import ReCAPTCHA from "react-google-recaptcha";
import barcode from "../../assets/images/barcode.jpg"

const tmpData = {
    provisionalNumber: '',
    fullName: "",
    fatherName: "",
    motherName: "",
    dateOfBirth: null,
    gender: "",
    category: "",
    professionalStatus: "",
    nationality: "IN",
    stateResidence: "MH",
    andhar: '',
    sector: "",
    password: "",
    confirmPassword: "",
    degree: '',
    graduationCollegeName: "",
    graduationUniversityName: "",
    graduationMonth: "",
    graduationYear: '',
    postGraduation: "",
    postGraduationCollegeName: "",
    postGraduationUniversityName: "",
    postGraduationMonth: "",
    postGraduationYear: '',
    postGraduationSubjects: "",
    phd: "",
    phdUniversityName: "",
    phdMonth: "",
    phdYear: null,
    postDoc: "",
    postDocCollegeName: "",
    postDocUniversityName: "",
    postDocMonth: "",
    postDocYear: null,
    dateOfPassing: '',
    universityName: '',
    collegeName: '',
    email: '',
    mobileNumber: '',
    alternateMobileNumber: null,
    alternateEmailId: '',
    presentProfessionalAdderess: '',
    presentProfessionalDistrict: '',
    presentProfessionalVillage: '',
    presentProfessionalTaluka: '',
    presentProfessionalState: 'MH',
    presentProfessionalPincode: '',
    permanentAddress: '',
    state: 'MH',
    district: '',
    taluka: '',
    village: '',
    pinCode: '',
    workingAddress: '',
    tempState: 'MH',
    tempDistrict: '',
    tempTaluka: '',
    tempVillage: '',
    tempPincode: '',
    awards: "",
    seminars: "",
    CVE: "",
    isWorkingAddressSame: false,
    isPermanentAddressSame: false
}

export const Register = (props) => {
    const history = useHistory();
    const [formData, setFormData] = useState({
        provisionalNumber: '',
        fullName: '',
        fatherName: '',
        motherName: '',
        dateOfBirth: null,
        gender: '',
        professionalStatus: '',
        category: '',
        nationality: 'IN',
        stateResidence: 'MH',
        andhar: '',
        sector: '',
        password: '',
        confirmPassword: '',
        degree: '',
        graduationCollegeName: "",
        graduationUniversityName: "",
        graduationMonth: "",
        graduationYear: '',
        postGraduation: "",
        postGraduationCollegeName: "",
        postGraduationUniversityName: "",
        postGraduationMonth: "",
        postGraduationYear: '',
        postGraduationSubjects: "",
        phd: "",
        phdUniversityName: "",
        phdMonth: "",
        phdYear: null,
        postDoc: "",
        postDocCollegeName: "",
        postDocUniversityName: "",
        postDocMonth: "",
        postDocYear: null,
        dateOfPassing: '',
        universityName: '',
        collegeName: '',
        email: '',
        mobileNumber: '',
        alternateMobileNumber: '',
        alternateEmailId: '',
        presentProfessionalAdderess: '',
        presentProfessionalDistrict: '',
        presentProfessionalVillage: '',
        presentProfessionalTaluka: '',
        presentProfessionalState: 'MH',
        presentProfessionalPincode: null,
        permanentAddress: '',
        state: 'MH',
        district: '',
        taluka: '',
        village: '',
        pinCode: null,
        workingAddress: '',
        tempState: 'MH',
        tempDistrict: '',
        tempTaluka: '',
        tempVillage: '',
        tempPincode: null,
        awards: "",
        seminars: "",
        CVE: "",
        isWorkingAddressSame: false,
        isPermanentAddressSame: false
    })

    const tempFiles = {
        signature: '',
        degree_certificate: '',
        identity_proof: '',
        andhar_card: '',
        domicile_certificate: '',
        dob_proof: '',
        payment_proof: '',
        provisional_regidtration_certificate: '',
        affidavit_magistrate: null,
        fir: null,
    }

    const [fileData, setFileData] = useState({
        signature: '',
        degree_certificate: '',
        identity_proof: '',
        andhar_card: '',
        domicile_certificate: '',
        dob_proof: '',
        payment_proof: '',
        provisional_regidtration_certificate: '',
        affidavit_magistrate: null,
        fir: null,
    })

    const [fileDataName, setFileDataName] = useState({ ...tempFiles })

    const [havingProvisionalNumber, setHavingProvisonalNumber] = useState(true);
    const [hideModal, setHideModal] = useState(false);
    const [formError, setFormError] = useState({})
    const [formSubmitButtonDisable, setFormSubmitButtonDisable] = useState(true)
    let [isProcessing, setProcessing] = useState(false);
    const [fromData, setFromData] = useState(tmpData)
    // const [showUpdate, setShowUpdate] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    const [dataFiles, setDataFiles] = useState([]);
    const [ID, setID] = useState(null);

    const [recapchaVerified, setRecaptchaVerified] = useState(false);
    const recaptchaRef = useRef();

    useEffect(() => {
        isFromSubmissionBlock()
    }, [formData, fromData, fileData]);


    // const validFileType = (type) => type.includes("image/jpeg") || type.includes("image/png") || type.includes("image/gif") || type.includes("application/pdf") || type.includes("application/msword");

    // const validFileName = (name) => name.includes('.doc') || name.includes('.docx') || name.includes('.pdf') || name.includes('.jpg') || name.includes('.jpeg') || name.includes('.png')

    const validFileName = (name) => name.includes('.pdf') || name.includes('.jpeg') || name.includes('.jpg')

    const validPhotoFileName = (name) => name.includes('.jpeg') || name.includes('.jpg')

    const uploadPhoto = (file, name) => {
        let formData1 = new FormData();
        formData1.append('image', file)
        axios.post(
            `${uploadURL}uploadfile`,
            formData1
        ).then(res => {
            if (!hideModal) {
                setFileData({ ...fileData, [name]: res.data.profileImageName });
                setFileDataName({ ...fileDataName, [name]: file.name })
            }
            else {
                const index = dataFiles.findIndex(data => data?.document_name === name);
                if (index !== -1) {
                    let temp = [...dataFiles];
                    temp[index].document_path = res.data.profileImageName;
                    setDataFiles(temp)
                }
                else {
                    let temp = [...dataFiles];
                    temp.push({ document_name: name, document_path: res.data.profileImageName })
                    setDataFiles(temp)
                    setFileData(temp);
                }
            }
        })
            .catch(error => {
            })
    }

    const onFileChange = (e) => {
        const name = e.target?.name;
        if (e.target.files[0]?.size) {
            if (e.target.files[0]?.size < 2097152) {
                if (name === 'upload_photo') {
                    if (e.target.files[0] && validPhotoFileName(e.target.files[0].name)) {
                        uploadPhoto(e.target.files[0], name)
                    }
                    else {
                        toast.error('.JPG/.JPEG format required')
                    }
                }
                else {
                    if (e.target.files[0] && validFileName(e.target.files[0].name)) {
                        uploadPhoto(e.target.files[0], name)
                    }
                    else {
                        toast.error('.JPG/.JPEG/.PDF format required')
                    }
                }
            }
            else {
                toast.error('File should be less than 2mb')
            }
        }
    }

    const onInputChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value) {
            if (!inputRegexValidation(name, value)) {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "regx" })
            } else {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "" })
            }
        } else {
            setFormData({ ...formData, [name]: '' })
            setFormError({ ...formError, [name]: "" })
        }
    };

    const inputRegexValidation = (name, value) => {
        if (name === "fullName" || name === 'fatherName' || name === 'motherName')
            return nameRegex.test(value)
        else if (name === 'permanentAddress' || name === 'village' || name === 'taluka' || name === 'district' || name === 'workingAddress' || name === 'presentProfessionalAdderess')
            return htmlRegex.test(value)
        else if ((name === 'mobileNumber') || (name === 'alternateMobileNumber'))
            return phoneRegex.test(value);
        else if (name === 'alternateEmailId' || name === 'email')
            return emailRegex.test(value);
        else if (name === 'password' || name === 'confirmPassword')
            return passwordRegex.test(value);
        else if (name === 'andhar')
            return adharRegex.test(value);
        else if (name === 'pinCode' || name === 'tempPincode' || name === 'presentProfessionalPincode')
            return postalCodeRegex.test(value);
        else if (name === 'postGraduation' || name === 'postGraduationSubjects' || name === 'phd' || name === 'postDoc' || name === 'graduationUniversityName' || name === "postGraduationUniversityName" || name === 'phdUniversityName' || name === 'postDocUniversityName' || name === "graduationCollegeName" || name === 'postGraduationCollegeName' || name === 'postDocCollegeName' || name === "district" || name === 'tempDistrict' || name === 'presentProfessionalDistrict' || name === 'taluka' || name === 'tempTaluka' || name === 'presentProfessionalTaluka' || name === 'village' || name === 'tempVillage' || name === 'presentProfessionalVillage' || name === 'awards' || name === 'seminars' || name === 'CVE')
            return fieldRegex.test(value)
        else if (name === 'graduationYear' || name === 'postGraduationYear' || name === 'phdYear' || name === 'postDocYear')
            return value < 9999
        else
            return true;
    };

    const isFromSubmissionBlock = () => {
        let { fullName, email, mobileNumber, permanentAddress, district, pinCode, state, taluka, village,
            degree, graduationCollegeName, graduationMonth, graduationUniversityName, graduationYear,
            andhar, fatherName, motherName, alternateEmailId, alternateMobileNumber, tempDistrict, tempPincode, tempTaluka, tempVillage,
            presentProfessionalDistrict, presentProfessionalPincode, presentProfessionalTaluka, presentProfessionalVillage, workingAddress,
            postGraduationCollegeName, postGraduationSubjects, postGraduationUniversityName, phdUniversityName, postDocCollegeName, postDocUniversityName,
            postGraduation, phd, postDoc, awards, seminars, CVE } = formData;
        const { dateOfBirth } = fromData;
        if (fullName && email && mobileNumber && permanentAddress && district && pinCode && state && taluka && village && degree && graduationCollegeName && graduationMonth && graduationUniversityName && graduationYear && dateOfBirth?.day && mandatoryFieldsValidation() && recapchaVerified) {
            if (
                inputRegexValidation("fullName", fullName) &&
                inputRegexValidation("email", email) &&
                inputRegexValidation("mobileNumber", mobileNumber) &&
                inputRegexValidation("permanentAddress", permanentAddress) &&
                inputRegexValidation("district", district) &&
                inputRegexValidation("pinCode", pinCode) &&
                inputRegexValidation("state", state) &&
                inputRegexValidation("taluka", taluka) &&
                inputRegexValidation("village", village) &&
                inputRegexValidation("degree", degree) &&
                inputRegexValidation("graduationCollegeName", graduationCollegeName) &&
                inputRegexValidation("graduationUniversityName", graduationUniversityName) &&
                inputRegexValidation("graduationMonth", graduationMonth) &&
                inputRegexValidation("graduationYear", graduationYear) &&
                inputRegexValidation("dateOfBirth", dateOfBirth) &&
                mandatoryFieldsValidation() &&

                // non required fields

                (andhar ? inputRegexValidation("andhar", andhar) : true) &&
                (fatherName ? inputRegexValidation("fatherName", fatherName) : true) &&
                (motherName ? inputRegexValidation("motherName", motherName) : true) &&
                (alternateEmailId ? inputRegexValidation("alternateEmailId", alternateEmailId) : true) &&
                (alternateMobileNumber ? inputRegexValidation("alternateMobileNumber", alternateMobileNumber) : true) &&
                (tempDistrict ? inputRegexValidation("tempDistrict", tempDistrict) : true) &&
                (tempVillage ? inputRegexValidation("tempVillage", tempVillage) : true) &&
                (tempTaluka ? inputRegexValidation("tempTaluka", tempTaluka) : true) &&
                (tempPincode ? inputRegexValidation("tempPincode", tempPincode) : true) &&
                (presentProfessionalDistrict ? inputRegexValidation("presentProfessionalDistrict", presentProfessionalDistrict) : true) &&
                (presentProfessionalVillage ? inputRegexValidation("presentProfessionalVillage", presentProfessionalVillage) : true) &&
                (presentProfessionalTaluka ? inputRegexValidation("presentProfessionalTaluka", presentProfessionalTaluka) : true) &&
                (workingAddress ? inputRegexValidation("workingAddress", workingAddress) : true) &&
                (presentProfessionalPincode ? inputRegexValidation("presentProfessionalPincode", presentProfessionalPincode) : true) &&
                (postGraduationCollegeName ? inputRegexValidation("postGraduationCollegeName", postGraduationCollegeName) : true) &&
                (postGraduationSubjects ? inputRegexValidation("postGraduationSubjects", postGraduationSubjects) : true) &&
                (postGraduationUniversityName ? inputRegexValidation("postGraduationUniversityName", postGraduationUniversityName) : true) &&
                (phdUniversityName ? inputRegexValidation("phdUniversityName", phdUniversityName) : true) &&
                (postDocCollegeName ? inputRegexValidation("postDocCollegeName", postDocCollegeName) : true) &&
                (postDocUniversityName ? inputRegexValidation("postDocUniversityName", postDocUniversityName) : true) &&
                (phd ? inputRegexValidation("phd", phd) : true) &&
                (postDoc ? inputRegexValidation("postDoc", postDoc) : true) &&
                (postGraduation ? inputRegexValidation("postGraduation", postGraduation) : true)&&
                (awards ? inputRegexValidation("awards", awards) : true)&&
                (seminars ? inputRegexValidation("seminars", seminars) : true)&&
                (CVE ? inputRegexValidation("CVE", CVE) : true)

            ) {
                setFormSubmitButtonDisable(false)
            } else {
                setFormSubmitButtonDisable(true)
            }
        }
        else {
            setFormSubmitButtonDisable(true)
        }
    };

    const onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            setFormError({ ...formError, [name]: "req" })
        } else {
            if (!inputRegexValidation(name, value)) {
                setFormError({ ...formError, [name]: "regx" })
            }
        }
    }

    const formSubmitHandler = (e) => {
        e.preventDefault();
        let personalInformation = {
            name: fromData.fullName,
            father_name: fromData.fatherName,
            mother_name: fromData.motherName,
            dob: fromData.dateOfBirth && `${getProperDateMonth(fromData.dateOfBirth?.day)}/${getProperDateMonth(fromData.dateOfBirth?.month)}/${fromData.dateOfBirth.year}`,
            gender: fromData.gender,
            category: fromData.category,
            nationality: fromData.nationality,
            professional_status: fromData.professionalStatus,
            aadhar_card_no: fromData.andhar ? encrypt(fromData.andhar) : null,
            sector: fromData.sector,
        }
        let educationalInformation = {
            graduation: fromData.degree,
            graduation_college_name: fromData.graduationCollegeName,
            graduation_university_name: fromData.graduationUniversityName,
            graduation_completion_month: fromData.graduationMonth,
            graduation_completion_year: fromData.graduationYear ? fromData.graduationYear : null,
            post_graduation: fromData.postGraduation,
            post_graduation_college_name: fromData.postGraduationCollegeName,
            post_graduation_university_name: fromData.postGraduationUniversityName,
            post_graduation_completion_month: fromData.postGraduationMonth,
            post_graduation_completion_year: fromData.postGraduationYear ? fromData.postGraduationYear : null,
            post_graduation_subjects: fromData.postGraduationSubjects,
            phd: fromData.phd,
            phd_university_name: fromData.phdUniversityName,
            phd_completion_month: fromData.phdMonth,
            phd_completion_year: fromData.phdYear,
            post_doc: fromData.postDoc,
            post_doc_college_name: fromData.postDocCollegeName,
            post_doc_university_name: fromData.postDocUniversityName,
            post_doc_completion_month: fromData.postDocMonth,
            post_doc_completion_year: fromData.postDocYear,
            awards: fromData.awards,
            seminars_and_conferences: fromData.seminars,
            cve_completed: fromData.CVE,
        }

        let contactDetails = {
            mobile: fromData.mobileNumber,
            email: fromData.email,
            alt_mobile: fromData.alternateMobileNumber ? fromData.alternateMobileNumber : null,
            alt_email: fromData.alternateEmailId,
            professional_address: fromData.presentProfessionalAdderess,
            working_address: fromData.workingAddress,
            post_graduation_subjects: fromData.postGraduationSubjects,
            district: fromData.presentProfessionalDistrict,
            village: fromData.presentProfessionalVillage,
            state: fromData.presentProfessionalState,
            taluka: fromData.presentProfessionalTaluka,
            pincode: fromData.presentProfessionalPincode ? fromData.presentProfessionalPincode : null,
            permanent_address: fromData.permanentAddress,
            permanent_address_state: fromData.state,
            permanent_address_district: fromData.district,
            permanent_address_taluka: fromData.taluka,
            permanent_address_village: fromData.village,
            permanent_address_pincode: fromData.pinCode ? fromData.pinCode : null,
        }

        let workingContactDetails = {
            working_address: fromData.workingAddress ? fromData.workingAddress : "",
            state: fromData.tempState ? fromData.tempState : "",
            district: fromData.tempDistrict ? fromData.tempDistrict : "",
            taluka: fromData.tempTaluka ? fromData.tempTaluka : "",
            village: fromData.tempVillage ? fromData.tempVillage : "",
            pincode: fromData.tempPincode ? fromData.tempPincode : null,
        }

        let fileDetails = Object.keys(fileData).map((file) => {
            return { document_name: file, document_path: fileData[file] }
        })

        const reqParams = {
            "parent_master_id": hideModal ? ID : null,
            "personalInformation": personalInformation,
            "educationalInformation": educationalInformation,
            "communicationDetails": contactDetails,
            "workingAddressDetails": workingContactDetails,
            "documents": !hideModal ? fileDetails : dataFiles
        }

        // setIsLoading(true);
        setProcessing(true);
        axios.post(`${baseURL}createDoctor`, reqParams).then(resp => {
            recaptchaRef.current.reset();
            if (resp.data.status === "success") {
                toast.success(resp.data.message)
                // setShowUpdate(true);
                // setIsLoading(false)
                setProcessing(false);
                setTimeout(() => {
                    // setShowUpdate(false);
                    history.push("/");
                }, 3000)
            } else {
                toast.error(resp.data.message)
                // setIsLoading(false)
                setProcessing(false);
            }
        }).catch((error) => {
            toast.error(error?.response?.data.message)
            recaptchaRef.current.reset();
            // setIsLoading(false)
            setProcessing(false);
        })

    }

    const onProfessionalAddressCheckboxClick = (value) => {
        setFromData({ ...fromData, isPermanentAddressSame: !value })
        if (value) {
            setFromData({
                ...fromData,
                presentProfessionalAdderess: '',
                presentProfessionalState: '',
                presentProfessionalDistrict: '',
                presentProfessionalTaluka: '',
                presentProfessionalVillage: '',
                presentProfessionalPincode: ''
            })
        }
        else {
            setFromData({
                ...fromData,
                presentProfessionalAdderess: fromData.permanentAddress,
                presentProfessionalState: fromData.state,
                presentProfessionalDistrict: fromData.district,
                presentProfessionalTaluka: fromData.taluka,
                presentProfessionalVillage: fromData.village,
                presentProfessionalPincode: fromData.pinCode,
            })

            setFormData({
                ...formData,
                presentProfessionalAdderess: fromData.permanentAddress,
                presentProfessionalState: fromData.state,
                presentProfessionalDistrict: fromData.district,
                presentProfessionalTaluka: fromData.taluka,
                presentProfessionalVillage: fromData.village,
                presentProfessionalPincode: fromData.pinCode,
            })

            setFormError({
                ...formError,
                presentProfessionalPincode: fromData.pinCode ? inputRegexValidation("tempPincode", fromData.pinCode) ? '' : 'regx' : '',
                presentProfessionalVillage: fromData.village ? inputRegexValidation("tempVillage", fromData.village) ? '' : 'regx' : '',
                presentProfessionalTaluka: fromData.taluka ? inputRegexValidation("tempTaluka", fromData.taluka) ? '' : 'regx' : '',
                presentProfessionalDistrict: fromData.district ? inputRegexValidation("tempDistrict", fromData.district) ? '' : 'regx' : '',
                presentProfessionalAdderess: fromData.professional_address ? inputRegexValidation("workingAddress", fromData.permanentAddress) ? '' : 'regx' : ''
            })
        }
    }

    const onAddressCheckboxClick = (value) => {
        setFromData({ ...fromData, isWorkingAddressSame: !value })
        if (value) {
            setFromData({
                ...fromData,
                workingAddress: '',
                tempState: '',
                tempDistrict: '',
                tempTaluka: '',
                tempVillage: '',
                tempPincode: ''
            })
        }
        else {
            setFromData({
                ...fromData,
                workingAddress: fromData.presentProfessionalAdderess,
                tempState: fromData.presentProfessionalState,
                tempDistrict: fromData.presentProfessionalDistrict,
                tempTaluka: fromData.presentProfessionalTaluka,
                tempVillage: fromData.presentProfessionalVillage,
                tempPincode: fromData.presentProfessionalPincode,
            })

            setFormData({
                ...formData,
                workingAddress: fromData.presentProfessionalAdderess,
                tempState: fromData.presentProfessionalState,
                tempDistrict: fromData.presentProfessionalDistrict,
                tempTaluka: fromData.presentProfessionalTaluka,
                tempVillage: fromData.presentProfessionalVillage,
                tempPincode: fromData.presentProfessionalPincode,
            })

            setFormError({
                ...formError,
                tempPincode: fromData.presentProfessionalPincode ? inputRegexValidation("tempPincode", fromData.presentProfessionalPincode) ? '' : 'regx' : '',
                tempVillage: fromData.presentProfessionalVillage ? inputRegexValidation("tempVillage", fromData.presentProfessionalVillage) ? '' : 'regx' : '',
                tempTaluka: fromData.presentProfessionalTaluka ? inputRegexValidation("tempTaluka", fromData.presentProfessionalTaluka) ? '' : 'regx' : '',
                tempDistrict: fromData.presentProfessionalDistrict ? inputRegexValidation("tempDistrict", fromData.presentProfessionalDistrict) ? '' : 'regx' : '',
                workingAddress: fromData.presentProfessionalAdderess ? inputRegexValidation("workingAddress", fromData.presentProfessionalAdderess) ? '' : 'regx' : ''
            })
        }
    }

    const onResetButtonClick = () => {
        setFromData(tmpData);
        setFormData(tmpData);
        setFormError({});
        setFileData(tempFiles);
        setFileDataName(tempFiles);
        setRecaptchaVerified(false);
        recaptchaRef.current.reset();
        // window.location.reload();
    }

    const getCurrentYear = () => {
        const date = new Date();
        return date.getFullYear();
    }

    const mandatoryFieldsValidation = () => {
        if (!hideModal) {
            const { andhar_card, degree_certificate, dob_proof, payment_proof, domicile_certificate, provisional_regidtration_certificate, signature, identity_proof, upload_photo } = fileData;
            return !!(andhar_card && degree_certificate && dob_proof && payment_proof && domicile_certificate && provisional_regidtration_certificate && signature && identity_proof && upload_photo)
        }
        else {
            const notMandatoryFields = dataFiles.filter(data => data?.document_name === 'andhar_card' ||
                data?.document_name === 'degree_certificate' ||
                data?.document_name === 'payment_proof' || data?.document_name === 'dob_proof' ||
                data?.document_name === 'domicile_certificate' || data?.document_name === 'provisional_regidtration_certificate' ||
                data?.document_name === 'signature'
                || data?.document_name === 'identity_proof' || data?.document_name === 'upload_photo')

            return notMandatoryFields.length === 9
        }
    }

    const onOkayButtonClick = () => {
        setHavingProvisonalNumber(false);
        axios.get(`${baseURL}registationDetail/${formData.provisionalNumber}`).then((res) => {
            const { result } = res?.data;
            if (result) {
                setHavingProvisonalNumber(true)
                setHideModal(true);
                const date = result?.dob.split('-');
                setFromData({
                    fullName: result?.name,
                    provisionalNumber: formData.provisionalNumber,
                    fatherName: result?.father_name,
                    motherName: result?.mother_name,
                    dateOfBirth: {
                        day: parseInt(date[2]),
                        month: parseInt(date[1]),
                        year: parseInt(date[0])
                    },
                    andhar: result?.aadhar_card_no,
                    nationality: result?.nationality ?? 'IN',
                    gender: result?.gender,
                    professionalStatus: result?.professional_status,
                    sector: result?.sector,
                    category: result?.category,
                    mobileNumber: result?.contact_detail_doctor?.mobile,
                    alternateMobileNumber: result?.contact_detail_doctor?.alt_mobile,
                    email: result?.contact_detail_doctor?.email,
                    alternateEmailId: result?.contact_detail_doctor?.alt_email,
                    permanentAddress: result?.contact_detail_doctor?.permanent_address,
                    state: result?.contact_detail_doctor?.permanent_address_state,
                    district: result?.contact_detail_doctor?.permanent_address_district,
                    taluka: result?.contact_detail_doctor?.permanent_address_taluka,
                    village: result?.contact_detail_doctor?.permanent_address_village,
                    pinCode: result?.contact_detail_doctor?.permanent_address_pincode,
                    presentProfessionalAdderess: result?.contact_detail_doctor?.professional_address,
                    workingAddress: result?.contact_detail_doctor?.working_address,
                    postGraduationSubjects: result?.contact_detail_doctor?.post_graduation_subjects,
                    presentProfessionalState: result?.contact_detail_doctor?.state,
                    presentProfessionalDistrict: result?.contact_detail_doctor?.district,
                    presentProfessionalTaluka: result?.contact_detail_doctor?.taluka,
                    presentProfessionalVillage: result?.contact_detail_doctor?.village,
                    presentProfessionalPincode: result?.contact_detail_doctor?.pincode,
                    degree: result?.education_doctor?.graduation,
                    graduationCollegeName: result?.education_doctor?.graduation_college_name,
                    graduationUniversityName: result?.education_doctor?.graduation_university_name,
                    awards: result?.contact_detail_doctor?.awards,
                    seminars: result?.contact_detail_doctor?.seminars_and_conferences,
                    CVE: result?.contact_detail_doctor?.cve_completed,
                    graduationMonth: getMonthName[result?.education_doctor?.graduation_completion_date.split('-')[1]],
                    graduationYear: result?.education_doctor?.graduation_completion_date.split('-')[0]
                })
                setID(result?.id);
                setFormData({
                    fullName: result?.name,
                    fatherName: result?.father_name,
                    motherName: result?.mother_name,
                    dateOfBirth: {
                        day: parseInt(date[1]),
                        month: parseInt(date[2]),
                        year: parseInt(date[0])
                    },
                    andhar: result?.aadhar_card_no,
                    nationality: result?.nationality ?? 'IN',
                    gender: result?.gender,
                    professionalStatus: result?.professional_status,
                    sector: result?.sector,
                    category: result?.category,
                    mobileNumber: result?.contact_detail_doctor?.mobile,
                    alternateMobileNumber: result?.contact_detail_doctor?.alt_mobile,
                    email: result?.contact_detail_doctor?.email,
                    alternateEmailId: result?.contact_detail_doctor?.alt_email,
                    permanentAddress: result?.contact_detail_doctor?.permanent_address,
                    state: result?.contact_detail_doctor?.permanent_address_state,
                    district: result?.contact_detail_doctor?.permanent_address_district,
                    taluka: result?.contact_detail_doctor?.permanent_address_taluka,
                    village: result?.contact_detail_doctor?.permanent_address_village,
                    pinCode: result?.contact_detail_doctor?.permanent_address_pincode,
                    presentProfessionalAdderess: result?.contact_detail_doctor?.professional_address,
                    workingAddress: result?.contact_detail_doctor?.working_address,
                    postGraduationSubjects: result?.contact_detail_doctor?.post_graduation_subjects,
                    presentProfessionalState: result?.contact_detail_doctor?.state,
                    presentProfessionalDistrict: result?.contact_detail_doctor?.district,
                    presentProfessionalTaluka: result?.contact_detail_doctor?.taluka,
                    presentProfessionalVillage: result?.contact_detail_doctor?.village,
                    presentProfessionalPincode: result?.contact_detail_doctor?.pincode,
                    degree: result?.education_doctor?.graduation,
                    graduationCollegeName: result?.education_doctor?.graduation_college_name,
                    graduationUniversityName: result?.education_doctor?.graduation_university_name,
                    awards: result?.contact_detail_doctor?.awards,
                    seminars: result?.contact_detail_doctor?.seminars_and_conferences,
                    CVE: result?.contact_detail_doctor?.cve_completed,
                    graduationMonth: getMonthName[result?.education_doctor?.graduation_completion_date.split('-')[1]],
                    graduationYear: result?.education_doctor?.graduation_completion_date.split('-')[0]
                })
                setFileData({ ...result?.document_doctors })
            }
            else {
                toast.error('Enter a valid provisional number.')
            }
        })
    }

    useEffect(() => {
        setDataFiles(Object.values(fileData))
    }, [fileData])

    const onNoClick = () => {
        setHideModal(false);
        setHavingProvisonalNumber(false);
        onResetButtonClick();
    }

    const formatInputValue = (date) => {
        if (!date) return '';
        return `${date.day}/${date.month}/${date.year}`;
    };

    const getFileName = (name) => {
        return dataFiles.filter(data => data?.document_name === name)[0]?.document_path
    }

    const onChange = (value) => {
        if (value) {
            setRecaptchaVerified(true);
        }
    }

    const { t } = useTranslation();
    return (
        <>
            <div
                className="modal fade endSubscriptionMod"
                id="cancelOrder"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"

            >
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <img className="previewImage" src={barcode}  alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={(havingProvisionalNumber && !hideModal)} onHide={() => { setHavingProvisonalNumber(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("REGISTER.PROVISONALNUMBER")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {
                            <InputGroup className="col-md-12"  >
                                <InputGroup.Text id="basic-addon3" className="col-md-6 mb-3">
                                    {t("REGISTER.PROVISONALNUMBER")}
                                </InputGroup.Text>
                                <FormControl
                                    type="text"
                                    // className="form-control is-invalid"
                                    className={
                                        formError['provisionalNumber'] &&
                                        (formError['provisionalNumber'] === "req"
                                            ? "form-control  is-invalid"
                                            : "form-control")}
                                    name="provisionalNumber"
                                    onBlur={onTouchHandler}
                                    value={(fromData) ? fromData.provisionalNumber : null}
                                    onChange={(e) => { setFromData({ ...fromData, provisionalNumber: e.target.value }); onInputChangeHandler(e) }}
                                    id="basic-url"
                                    aria-describedby="basic-addon3" placeholder=""
                                    maxLength={10} />
                                <span className="text-danger">
                                    {t("REGISTER.PROPER_POFISIONAL_ALERT")}<br /> {t("REGISTER.PROPER_POFISIONAL_SAMPLE")}
                                </span>

                            </InputGroup>

                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={() => onOkayButtonClick()}
                        class="btn btn-success">{t("REGISTER.OK")}</button>
                </Modal.Footer>
            </Modal>
            <div className="exe-comm">
                <div className="container">
                    <span className="print" onClick={() => window.print()}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="skyBlue" class="bi bi-printer-fill" viewBox="0 0 16 16">
                        <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                        <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                    </svg> &nbsp; {t("PRINT.TITLE")}</span>
                    <div className="heading mb-3">{t("Register.MAIN_HEAD")}</div>
                    <InputGroup className="row">
                        <InputGroup.Text id="basic-addon3" className="col-12 mb-3">
                            {t("REGISTER.HAVEPROVISONALNUMBER")}
                            <div style={{ marginTop: '15px' }}>
                                <div className="row">
                                    <div className="col-4">
                                        <div class="form-check form-check-inline mb-3" id="basic-url">
                                            <label class="form-check-label ml-3" for="inlineRadio2"> {t("REGISTER.YES")}</label>
                                            <input name="isHavingProvisionalNumber"
                                                key={havingProvisionalNumber}
                                                onBlur={onTouchHandler}
                                                checked={havingProvisionalNumber}
                                                onChange={() => setHavingProvisonalNumber(true)}
                                                class="form-check-input ml-3"
                                                type="radio" id="inlineRadio2" value="Yes" />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div class="form-check form-check-inline mb-3 ml-2" id="basic-url">
                                            <label class="form-check-label ml-3" for="inlineRadio2"> {t("REGISTER.NO")}</label>
                                            <input name="isHavingProvisionalNumber"
                                                key={havingProvisionalNumber}
                                                onBlur={onTouchHandler}
                                                checked={!havingProvisionalNumber}
                                                onChange={() => onNoClick()}
                                                class="form-check-input ml-3"
                                                type="radio" id="inlineRadio2" value="No" />
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </InputGroup.Text>
                    </InputGroup>
                    <div class="p-2 border text-white" style={{ backgroundColor: "#de8461", fontWeight: "bold" }}>{t("Register.D1")}
                    </div>
                    <div className="input" >
                        <InputGroup className="row"  >
                            <InputGroup.Text id="basic-addon3" className="col-8 mb-3">
                                {t("REGISTER.FULLNAME")}<span className="star_highlight">*</span>
                            </InputGroup.Text>
                            <FormControl
                                type="text"
                                // className="form-control is-invalid"
                                className={
                                    formError['fullName'] &&
                                    (formError['fullName'] === "req"
                                        ? "form-control  is-invalid"
                                        : "form-control col-4")}
                                name="fullName"
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.fullName : null}
                                onChange={(e) => { setFromData({ ...fromData, fullName: e.target.value }); onInputChangeHandler(e) }}
                                id="basic-url"
                                aria-describedby="basic-addon3" placeholder={t("REGISTER.DOCTORNAME_PLACEHOLDER")}
                                maxLength={60} />

                        </InputGroup>
                        <span className="text-danger">
                            {
                                formError['fullName'] &&
                                (formError['fullName'] === "req"
                                    ? 'Full Name is required'
                                    : 'Enter a valid Full Name')}
                        </span>

                        <InputGroup className="col-md-12" >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.NATIONALITY")}
                            </InputGroup.Text>
                            <select name="category"
                                // className={
                                //     formError['nationality'] &&
                                //     (formError['nationality'] === "req"
                                //         ? "form-control  is-invalid"
                                //         : "form-control")}
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.nationality : null}
                                onChange={(e) => { setFromData({ ...fromData, nationality: e.target.value }); onInputChangeHandler(e) }} id="basic-url" aria-describedby="basic-addon3" placeholder="Enter Nationality"
                                className="form-select customize_select" aria-label="Default select example">
                                {
                                    Data.country.map((country) => {
                                        return (
                                            <option value={country.code} selected={country.code === fromData.nationality}>{country.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </InputGroup>

                        <InputGroup className="col-md-12"  >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.FATHERSNAME")}
                            </InputGroup.Text>
                            <FormControl
                                type="text"
                                className="form-control"
                                name="fatherName"
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.fatherName : null}
                                onChange={(e) => { setFromData({ ...fromData, fatherName: e.target.value }); onInputChangeHandler(e) }}
                                id="basic-url"
                                aria-describedby="basic-addon3" placeholder={t("REGISTER.FATHERSNAME_PLACEHOLDER")}
                                maxLength={60} />

                        </InputGroup>
                        <span className="text-danger">
                            {
                                formError['fatherName'] &&
                                (formError['fatherName'] === "req"
                                    ? ''
                                    : 'Enter a valid Father\'s Name')}
                        </span>

                        <InputGroup className="col-md-12"  >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.MOTHERSNAME")}
                            </InputGroup.Text>
                            <FormControl
                                type="text"
                                className="form-control"
                                name="motherName"
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.motherName : null}
                                onChange={(e) => { setFromData({ ...fromData, motherName: e.target.value }); onInputChangeHandler(e) }}
                                id="basic-url"
                                aria-describedby="basic-addon3" placeholder={t("REGISTER.MOTHERSNAME_PLACEHOLDER")}
                                maxLength={60} />

                        </InputGroup>
                        <span className="text-danger">
                            {
                                formError['motherName'] &&
                                (formError['motherName'] === "req"
                                    ? ''
                                    : 'Enter a valid Mother\'s Name')}
                        </span>

                        <InputGroup className="row" >
                            <InputGroup.Text id="basic-addon3" className="col-sm-4 mb-3">
                                {t("REGISTER.DOB")}<span className="star_highlight">*</span>
                            </InputGroup.Text>
                            <div className="col-sm-8" style={{ padding: '0px' }}>
                                <DatePicker3 className="form-control" name="dateOfBirth"
                                    value={(fromData) ? fromData.dateOfBirth : null}
                                    onChange={(date) => { setFromData({ ...fromData, dateOfBirth: date }) }}
                                    inputPlaceholder="dd/mm/yyyy"
                                    shouldHighlightWeekends
                                    formatInputText={() => formatInputValue(fromData && fromData.dateOfBirth)}

                                />
                            </div>

                        </InputGroup>
                        <span className="text-danger">
                            {
                                formError['dateOfBirth'] &&
                                (formError['dateOfBirth'] === "req"
                                    ? ''
                                    : 'Enter a valid Date Of Birth')}
                        </span>
                        <InputGroup className="col-md-12" >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                <div className="label_word">  {t("REGISTER.AADHAR")}</div>
                            </InputGroup.Text>
                            <FormControl name="andhar"
                                className="form-control"
                                onBlur={onTouchHandler}
                                type="password"
                                value={(fromData) ? fromData.andhar : null}
                                onChange={(e) => { setFromData({ ...fromData, andhar: e.target.value }); onInputChangeHandler(e) }}
                                id="basic-url" aria-describedby="basic-addon3"
                                placeholder={t("REGISTER.AADHAR_PLACEHOLDER")}
                                maxLength={12} />
                        </InputGroup>
                        <span className="text-danger">
                            {
                                formError['andhar'] &&
                                (formError['andhar'] === "req"
                                    ? ''
                                    : 'Enter a valid Aadhar')}
                        </span>
                        <InputGroup className="col-md-12" >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.GENDER")}
                            </InputGroup.Text>
                            <div className="row">
                                <div className="col-4">
                                    <div class="form-check form-check-inline mb-3" id="basic-url">
                                        <label class="form-check-label ml-3" for="inlineRadio1"> {t("REGISTER.MALE")}</label>
                                        <input name="gender"
                                            onBlur={onTouchHandler}
                                           
                                            checked={fromData.gender === 'Male'}
                                            onChange={(e) => { setFromData({ ...fromData, gender: e.target.value }); onInputChangeHandler(e) }} class="form-check-input ml-3" type="radio"  id="inlineRadio1" value="Male" />
                                    </div>
                                </div>
                                <div className="col-4">

                                    <div class="form-check form-check-inline mb-3" id="basic-url">
                                        <label class="form-check-label ml-3" for="inlineRadio2"> {t("REGISTER.FEMALE")}</label>
                                        <input name="gender"
                                            onBlur={onTouchHandler}
                                           
                                            checked={fromData.gender === 'Female'}
                                            onChange={(e) => { setFromData({ ...fromData, gender: e.target.value }); onInputChangeHandler(e) }} class="form-check-input ml-3" type="radio"  id="inlineRadio2" value="Female" />
                                    </div>
                                </div>
                                <div className="col-4">

                                    <div class="form-check form-check-inline mb-3" id="basic-url">
                                        <label class="form-check-label ml-3" for="inlineRadio3"> {t("REGISTER.OTHER")}</label>
                                        <input name="gender"
                                            onBlur={onTouchHandler}
                                           
                                            checked={fromData.gender === 'Other'}
                                            onChange={(e) => { setFromData({ ...fromData, gender: e.target.value }); onInputChangeHandler(e) }} class="form-check-input ml-3" type="radio"  id="inlineRadio3" value="Other" />
                                    </div>
                                </div>
                            </div>
                        </InputGroup>

                        <InputGroup className="col-md-12" >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.PROFESSIONALSTATUS")}
                            </InputGroup.Text>
                            <select name="professionalStatus"
                                // className={
                                //     formError['professionalStatus'] &&
                                //     (formError['professionalStatus'] === "req"
                                //         ? "form-control  is-invalid"
                                //         : "form-control")}
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.professionalStatus : null}
                                onChange={(e) => { setFromData({ ...fromData, professionalStatus: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                <option value="" selected>{t("DROPDOWN.SELECT")}</option>
                                <option value="Self-employed">Self-employed</option>
                                <option value="Govt. sector">Govt. sector</option>
                                <option value="Private sector">Private sector</option>
                                <option value="Student">Student</option>
                                <option value="Teaching">Teaching</option>
                                <option value="Entrepreneur">Entrepreneur</option>
                                <option value="Consultant">Consultant</option>
                                <option value="Intern">Intern</option>
                            </select>
                        </InputGroup>

                        <InputGroup className="col-md-12" >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.SECTOR")}
                            </InputGroup.Text>
                            <select name="sector"
                                // className={
                                //     formError['sector'] &&
                                //     (formError['sector'] === "req"
                                //         ? "form-control  is-invalid"
                                //         : "form-control")}
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.sector : null}
                                onChange={(e) => { setFromData({ ...fromData, sector: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                <option value="" selected>{t("DROPDOWN.SELECT")}</option>
                                <option value="Dairy">Dairy</option>
                                <option value="Poultry">Poultry</option>
                                <option value="Goat">Goat</option>
                                <option value="Exotic pets">Exotic pets</option>
                                <option value="Indigenous pets">Indigenous pets</option>
                                <option value="Laboratory">Laboratory</option>
                                <option value="Wildlife">Wildlife</option>
                                <option value="Pharmacy">Pharmacy</option>
                                <option value="Nutrition">Nutrition</option>
                                <option value="Meat industry">Meat industry</option>
                                <option value="Social sector">Social sector</option>
                                <option value="NGO">NGO</option>
                                <option value="Other">Other</option>
                            </select>
                        </InputGroup>
                        <InputGroup className="col-md-12" >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.CATEGORY")}
                            </InputGroup.Text>
                            <select name="category"
                                // className={
                                //     formError['category'] &&
                                //     (formError['category'] === "req"
                                //         ? "form-control  is-invalid"
                                //         : "form-control")}
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.category : null}
                                onChange={(e) => { setFromData({ ...fromData, category: e.target.value }); onInputChangeHandler(e) }}
                                className="form-select customize_select" aria-label="Default select example">
                                <option value="" selected>{t("DROPDOWN.SELECT")}</option>
                                <option value="General">General</option>
                                <option value="OBC">OBC</option>
                                <option value="SC">SC</option>
                                <option value="ST">ST</option>
                                <option value="NT">NT</option>
                                <option value="Other">Other</option>
                            </select>
                        </InputGroup>

                    </div>
                    <div class="p-2 border text-white mt-4" style={{ backgroundColor: "#de8461", fontWeight: "bold" }}>{t("Register.D3")}
                    </div>
                    <InputGroup className="col-md-12 mt-4" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.MOBILE")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="mobileNumber" aria-describedby="basic-addon3"
                            placeholder={t("REGISTER.MOBILE_PLACEHOLDER")}
                            className={
                                formError['mobileNumber'] &&
                                (formError['mobileNumber'] === "req"
                                    ? "form-control  is-invalid"
                                    : "form-control")}
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.mobileNumber : null}
                            onChange={(e) => { setFromData({ ...fromData, mobileNumber: e.target.value }); onInputChangeHandler(e) }}
                            maxLength={10}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['mobileNumber'] &&
                            (formError['mobileNumber'] === "req"
                                ? 'Mobile Number is required'
                                : 'Enter a valid Mobile Number')}
                    </span>
                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.ALTMOBILE")}
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="alternateMobileNumber" aria-describedby="basic-addon3"
                            placeholder={t("REGISTER.ALTMOBILE_PLACEHOLDER")}
                            className="form-control"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.alternateMobileNumber : null}
                            onChange={(e) => { setFromData({ ...fromData, alternateMobileNumber: e.target.value }); onInputChangeHandler(e) }}
                            maxLength={10}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['alternateMobileNumber'] &&
                            (formError['alternateMobileNumber'] === "req"
                                ? ''
                                : 'Enter a valid Alternative  Mobile Number')}
                    </span>
                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.EMAIL")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="email" aria-describedby="basic-addon3" placeholder={t("REGISTER.EMAIL_PLACEHOLDER")}
                            className={
                                formError['email'] &&
                                (formError['email'] === "req"
                                    ? "form-control  is-invalid"
                                    : "form-control")}
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.email : null}
                            onChange={(e) => { setFromData({ ...fromData, email: e.target.value }); onInputChangeHandler(e) }}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['email'] &&
                            (formError['email'] === "req"
                                ? 'Email Id is required'
                                : 'Enter a valid Email Id')}
                    </span>
                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.ALTEMAIL")}
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="alternateEmailId" aria-describedby="basic-addon3" placeholder={t("REGISTER.ALTEMAIL_PLACEHOLDER")}
                            className="form-control"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.alternateEmailId : null}
                            onChange={(e) => { setFromData({ ...fromData, alternateEmailId: e.target.value }); onInputChangeHandler(e) }} />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['alternateEmailId'] &&
                            (formError['alternateEmailId'] === "req"
                                ? ''
                                : 'Enter a valid Alternative Email Id')}
                    </span>

                    <InputGroup className="col-md-12 mt-4" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.PERMANANETADDRESS")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="permanentAddress" aria-describedby="basic-addon3" placeholder={t("REGISTER.PERMANANETADDRESS_PLACEHOLDER")}
                            className={
                                formError['permanentAddress'] &&
                                (formError['permanentAddress'] === "req"
                                    ? "form-control  is-invalid"
                                    : "form-control")}
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.permanentAddress : null}
                            onChange={(e) => { setFromData({ ...fromData, permanentAddress: e.target.value }); onInputChangeHandler(e) }}
                            maxLength={100}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['permanentAddress'] &&
                            (formError['permanentAddress'] === "req"
                                ? 'Permanent Address is required'
                                : 'Enter a valid Permanent Address')}
                    </span>
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.VILLAGE")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl
                            type="text"
                            className={
                                formError['village'] &&
                                (formError['village'] === "req"
                                    ? "form-control  is-invalid"
                                    : "form-control")}
                            name="village"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.village : null}
                            onChange={(e) => { setFromData({ ...fromData, village: e.target.value }); onInputChangeHandler(e) }}
                            id="basic-url"
                            aria-describedby="basic-addon3" placeholder={t("REGISTER.VILLAGE_PLACEHOLDER")}
                            maxLength={50} />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['village'] &&
                            (formError['village'] === "req"
                                ? 'Village is required'
                                : 'Enter a valid Village')}
                    </span>
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.TALUKA")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl
                            type="text"
                            className={
                                formError['taluka'] &&
                                (formError['taluka'] === "req"
                                    ? "form-control  is-invalid"
                                    : "form-control")}
                            name="taluka"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.taluka : null}
                            onChange={(e) => { setFromData({ ...fromData, taluka: e.target.value }); onInputChangeHandler(e) }}
                            id="basic-url"
                            aria-describedby="basic-addon3" placeholder={t("REGISTER.TALUKA_PLACEHOLDER")}
                            maxLength={50} />

                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['taluka'] &&
                            (formError['taluka'] === "req"
                                ? 'Taluka is required'
                                : 'Enter a valid Taluka')}
                    </span>
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.DISTRICT")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl
                            type="text"
                            className={
                                formError['district'] &&
                                (formError['district'] === "req"
                                    ? "form-control  is-invalid"
                                    : "form-control")}
                            name="district"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.district : null}
                            onChange={(e) => { setFromData({ ...fromData, district: e.target.value }); onInputChangeHandler(e) }}
                            id="basic-url"
                            aria-describedby="basic-addon3" placeholder={t("REGISTER.DISTRICT_PLACEHOLDER")}
                            maxLength={50} />

                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['district'] &&
                            (formError['district'] === "req"
                                ? 'District is required'
                                : 'Enter a valid District')}
                    </span>
                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.PINCODE")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="pinCode" aria-describedby="basic-addon3" placeholder={t("REGISTER.PINCODE_PLACEHOLDER")}
                            className={
                                formError['pinCode'] &&
                                (formError['pinCode'] === "req"
                                    ? "form-control  is-invalid"
                                    : "form-control")}
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.pinCode : null}
                            onChange={(e) => { setFromData({ ...fromData, pinCode: e.target.value }); onInputChangeHandler(e) }}
                            maxLength={6}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['pinCode'] &&
                            (formError['pinCode'] === "req"
                                ? 'Pin Code is required'
                                : 'Enter a valid Pin Code')}
                    </span>
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.PERSTATE")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <select aria-label="Default select example" name="state"
                            // className={
                            //     formError['state'] &&
                            //     (formError['state'] === "req"
                            //         ? "form-control  is-invalid"
                            //         : "form-control")}
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.state : null}
                            className="form-select customize_select"
                            onChange={(e) => { setFromData({ ...fromData, state: e.target.value }); onInputChangeHandler(e) }}
                        >
                            {
                                Data.states.map((state) => {
                                    return (
                                        <option value={state.key} selected={state.key === formData.state}>{state.name}</option>
                                    )
                                })
                            }
                        </select>
                    </InputGroup>




                    <div class="form-check text-center" >
                        <input class="form-check-input " type="checkbox" value={(fromData) ? fromData.isPermanentAddressSame : null} id="flexCheckDefault"
                            onClick={() => onProfessionalAddressCheckboxClick(fromData.isPermanentAddressSame)}
                        />
                        <label class="form-check-label" for="flexCheckChecked" id="ticked">
                            {t("REGISTER.SAMEPROFESSIONALADDRESS")}
                        </label>
                    </div>

                    <InputGroup className="col-md-12 mt-4" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.PRESENTPROFESSIONLADDRESS")}
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="presentProfessionalAdderess" aria-describedby="basic-addon3" placeholder={t("REGISTER.PERMANANETADDRESS_PLACEHOLDER")}
                            className="form-control"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.presentProfessionalAdderess : null}
                            onChange={(e) => { setFromData({ ...fromData, presentProfessionalAdderess: e.target.value }); onInputChangeHandler(e) }}
                            maxLength={100}
                        />
                    </InputGroup>
                    <div className={'address-note'}>  {t("REGISTER.ADDRESSCHANGE")}</div>
                    <span className="text-danger">
                        {
                            formError['presentProfessionalAdderess'] &&
                            (formError['presentProfessionalAdderess'] === "req"
                                ? ''
                                : 'Enter a valid Present Professional Address')}
                    </span>
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.VILLAGE")}
                        </InputGroup.Text>
                        <FormControl
                            type="text"
                            className="form-control"
                            name="presentProfessionalVillage"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.presentProfessionalVillage : null}
                            onChange={(e) => { setFromData({ ...fromData, presentProfessionalVillage: e.target.value }); onInputChangeHandler(e) }}
                            id="basic-url"
                            aria-describedby="basic-addon3" placeholder={t("REGISTER.VILLAGE_PLACEHOLDER")}
                            maxLength={50} />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['presentProfessionalVillage'] &&
                            (formError['presentProfessionalVillage'] === "req"
                                ? ''
                                : 'Enter a valid Village')}
                    </span>
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.TALUKA")}
                        </InputGroup.Text>
                        <FormControl
                            type="text"
                            className="form-control"
                            name="presentProfessionalTaluka"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.presentProfessionalTaluka : null}
                            onChange={(e) => { setFromData({ ...fromData, presentProfessionalTaluka: e.target.value }); onInputChangeHandler(e) }}
                            id="basic-url"
                            aria-describedby="basic-addon3" placeholder={t("REGISTER.TALUKA_PLACEHOLDER")}
                            maxLength={50} />

                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['presentProfessionalTaluka'] &&
                            (formError['presentProfessionalTaluka'] === "req"
                                ? ''
                                : 'Enter a valid Taluka')}
                    </span>
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.DISTRICT")}
                        </InputGroup.Text>
                        <FormControl
                            type="text"
                            className="form-control"
                            name="presentProfessionalDistrict"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.presentProfessionalDistrict : null}
                            onChange={(e) => { setFromData({ ...fromData, presentProfessionalDistrict: e.target.value }); onInputChangeHandler(e) }}
                            id="basic-url"
                            aria-describedby="basic-addon3" placeholder={t("REGISTER.DISTRICT_PLACEHOLDER")}
                            maxLength={50} />

                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['presentProfessionalDistrict'] &&
                            (formError['presentProfessionalDistrict'] === "req"
                                ? ''
                                : 'Enter a valid District')}
                    </span>
                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.PINCODE")}
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="presentProfessionalPincode" aria-describedby="basic-addon3" placeholder={t("REGISTER.PINCODE_PLACEHOLDER")}
                            className="form-control"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.presentProfessionalPincode : null}
                            onChange={(e) => { setFromData({ ...fromData, presentProfessionalPincode: e.target.value }); onInputChangeHandler(e) }}
                            maxLength={6}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['presentProfessionalPincode'] &&
                            (formError['presentProfessionalPincode'] === "req"
                                ? ''
                                : 'Enter a valid Pin Code')}
                    </span>

                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.PERSTATE")}
                        </InputGroup.Text>
                        <select aria-label="Default select example" name="presentProfessionalState"
                            // className="form-control"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.presentProfessionalState : null}
                            className="form-select customize_select"
                            onChange={(e) => { setFromData({ ...fromData, presentProfessionalState: e.target.value }); onInputChangeHandler(e) }}
                        >
                            {
                                Data.states.map((state) => {
                                    return (
                                        <option value={state.key} selected={state.key === formData.state}>{state.name}</option>
                                    )
                                })
                            }
                        </select>
                    </InputGroup>



                    <div class="form-check text-center" >
                        <input class="form-check-input " type="checkbox" value={(fromData) ? fromData.isWorkingAddressSame : null} id="flexCheckDefault"
                            onClick={() => onAddressCheckboxClick(fromData.isWorkingAddressSame)}
                        />
                        <label class="form-check-label" for="flexCheckChecked" id="ticked">
                            {t("REGISTER.SAMEPROFESSIONAL")}
                        </label>
                    </div>
                    {/* working address */}
                    <InputGroup className="col-md-12 mt-4" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.WORKADDRESS")}
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="workingAddress" aria-describedby="basic-addon3" placeholder={t("REGISTER.WORK_ADD_PLACEHOLDER")}
                            className="form-control"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.workingAddress : null}
                            onChange={(e) => { setFromData({ ...fromData, workingAddress: e.target.value }); onInputChangeHandler(e) }}
                            maxLength={100}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['workingAddress'] &&
                            (formError['workingAddress'] === "req"
                                ? ''
                                : 'Enter a valid Working Address')}
                    </span>
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.VILLAGE")}
                        </InputGroup.Text>
                        <FormControl
                            type="text"
                            className="form-control"
                            name="tempVillage"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.tempVillage : null}
                            onChange={(e) => { setFromData({ ...fromData, tempVillage: e.target.value }); onInputChangeHandler(e) }}
                            id="basic-url"
                            aria-describedby="basic-addon3" placeholder={t("REGISTER.VILLAGE_PLACEHOLDER")}
                            maxLength={50} />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['tempVillage'] &&
                            (formError['tempVillage'] === "req"
                                ? ''
                                : 'Enter a valid Village')}
                    </span>
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.TALUKA")}
                        </InputGroup.Text>
                        <FormControl
                            type="text"
                            className="form-control"
                            name="tempTaluka"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.tempTaluka : null}
                            onChange={(e) => { setFromData({ ...fromData, tempTaluka: e.target.value }); onInputChangeHandler(e) }}
                            id="basic-url"
                            aria-describedby="basic-addon3" placeholder={t("REGISTER.TALUKA_PLACEHOLDER")}
                            maxLength={50} />

                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['tempTaluka'] &&
                            (formError['tempTaluka'] === "req"
                                ? ''
                                : 'Enter a valid Taluka')}
                    </span>
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.DISTRICT")}
                        </InputGroup.Text>
                        <FormControl
                            type="text"
                            className="form-control"
                            name="tempDistrict"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.tempDistrict : null}
                            onChange={(e) => { setFromData({ ...fromData, tempDistrict: e.target.value }); onInputChangeHandler(e) }}
                            id="basic-url"
                            aria-describedby="basic-addon3" placeholder={t("REGISTER.DISTRICT_PLACEHOLDER")}
                            maxLength={50} />

                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['tempDistrict'] &&
                            (formError['tempDistrict'] === "req"
                                ? ''
                                : 'Enter a valid District')}
                    </span>
                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.PINCODE")}
                        </InputGroup.Text>
                        <FormControl id="basic-url" name="tempPincode" aria-describedby="basic-addon3" placeholder={t("REGISTER.PINCODE_PLACEHOLDER")}
                            className="form-control"
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.tempPincode : null}
                            onChange={(e) => { setFromData({ ...fromData, tempPincode: e.target.value }); onInputChangeHandler(e) }}
                            maxLength={6}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['tempPincode'] &&
                            (formError['tempPincode'] === "req"
                                ? ''
                                : 'Enter a valid Pin Code')}
                    </span>
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.PERSTATE")}
                        </InputGroup.Text>
                        <select name="tempState" aria-label="Default select example"
                            // className={
                            //     formError['tempState'] &&
                            //     (formError['tempState'] === "req"
                            //         ? "form-control  is-invalid"
                            //         : "form-control")}
                            onBlur={onTouchHandler}
                            value={(fromData) ? fromData.tempState : null}
                            className="form-select customize_select"
                            onChange={(e) => { setFromData({ ...fromData, tempState: e.target.value }); onInputChangeHandler(e) }}
                        >
                            {
                                Data.states.map((state) => {
                                    return (
                                        <option value={state.key} selected={state.key === formData.state}>{state.name}</option>
                                    )
                                })
                            }
                        </select>
                    </InputGroup>




                    <div class="p-2 border text-white mt-4" style={{ backgroundColor: "#de8461", fontWeight: "bold" }}>{t("Register.D2")}
                    </div>
                    <div className="input" >
                        <InputGroup className="col-md-12"  >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.GRADUATION")}<span className="star_highlight">*</span>
                            </InputGroup.Text>
                            <select name="degree"
                                // className={
                                //     formError['degree'] &&
                                //     (formError['degree'] === "req"
                                //         ? "form-control  is-invalid"
                                //         : "form-control")}
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.degree : null}
                                onChange={(e) => { setFromData({ ...fromData, degree: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                <option value='' selected>{t("DROPDOWN.SELECTGRADUATION")}</option>
                                <option value="B.V.Sc.">B.V.Sc.</option>
                                <option value="B.V.Sc. & A.H.">B.V.Sc. & A.H.</option>
                                <option value="B.V.Sc. (Vet.)">B.V.Sc. (Vet.)</option>
                                <option value="B.V.A.Sc.">B.V.A.Sc.</option>
                                <option value="G.V.Sc.">G.V.Sc.</option>
                                <option value="G.V.Sc. & A.H.">G.V.Sc. & A.H.</option>
                                <option value="G.B.V.C.">G.B.V.C.</option>
                                <option value="L.V.P.">L.V.P.</option>
                                <option value="M.R.C.V.S.">M.R.C.V.S.</option>
                                <option value="B.Vet.Med.">B.Vet.Med.</option>
                                <option value="B.V.M.">B.V.M.</option>
                                <option value="OTHER">OTHER</option>
                            </select>
                        </InputGroup>
                        <div class="main-heading">
                            <InputGroup className="col-md-12" >
                                <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                    {t("REGISTER.COLLEGENAME")}<span className="star_highlight">*</span>
                                </InputGroup.Text>
                                <FormControl id="basic-url" name="graduationCollegeName" aria-describedby="basic-addon3" placeholder={t("REGISTER.COLLEGENAME_PLACEHOLDER")}
                                    className={
                                        formError['graduationCollegeName'] &&
                                        (formError['graduationCollegeName'] === "req"
                                            ? "form-control  is-invalid"
                                            : "form-control")}
                                    onBlur={onTouchHandler}
                                    value={(fromData) ? fromData.graduationCollegeName : null}
                                    onChange={(e) => { setFromData({ ...fromData, graduationCollegeName: e.target.value }); onInputChangeHandler(e) }}
                                    maxLength={100}
                                />
                            </InputGroup>
                            <span className="text-danger">
                                {
                                    formError['graduationCollegeName'] &&
                                    (formError['graduationCollegeName'] === "req"
                                        ? 'College Name is required'
                                        : 'Enter a valid College Name')}
                            </span>
                            <InputGroup className="col-md-12" >
                                <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                    {t("REGISTER.UNIVERSITYNAME")}<span className="star_highlight">*</span>
                                </InputGroup.Text>
                                <FormControl id="basic-url" name="graduationUniversityName" aria-describedby="basic-addon3" placeholder={t("REGISTER.UNIVERSITYNAME_PLACEHOLDER")}
                                    className={
                                        formError['graduationUniversityName'] &&
                                        (formError['graduationUniversityName'] === "req"
                                            ? "form-control  is-invalid"
                                            : "form-control")}
                                    onBlur={onTouchHandler}
                                    value={(fromData) ? fromData.graduationUniversityName : null}
                                    onChange={(e) => { setFromData({ ...fromData, graduationUniversityName: e.target.value }); onInputChangeHandler(e) }}
                                    maxLength={100}
                                />
                            </InputGroup>
                            <span className="text-danger">
                                {
                                    formError['graduationUniversityName'] &&
                                    (formError['graduationUniversityName'] === "req"
                                        ? 'University Name is required'
                                        : 'Enter a valid University Name')}
                            </span>
                            <InputGroup className="col-md-12" >
                                <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                    {t("REGISTER.COURSECOMPLETE")}<span className="star_highlight">*</span>
                                </InputGroup.Text>
                                <select name="graduationMonth"
                                    // className={
                                    //     formError['graduationMonth'] &&
                                    //     (formError['graduationMonth'] === "req"
                                    //         ? "form-control  is-invalid"
                                    //         : "form-control")}
                                    onBlur={onTouchHandler}
                                    value={(fromData) ? fromData.graduationMonth : null}
                                    onChange={(e) => { setFromData({ ...fromData, graduationMonth: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                    <option value='' selected>{t("DROPDOWN.MONTH")}</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August </option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December </option>
                                </select>
                                <select name="graduationYear"
                                    // className={
                                    //     formError['graduationYear'] &&
                                    //     (formError['graduationYear'] === "req"
                                    //         ? "form-control  is-invalid"
                                    //         : "form-control")}
                                    onBlur={onTouchHandler}
                                    value={(fromData) ? fromData.graduationYear : null}
                                    onChange={(e) => { setFromData({ ...fromData, graduationYear: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                    <option value='' selected>{t("DROPDOWN.YEAR")}</option>
                                    {
                                        Data.Years.map((year) => {
                                            return (
                                                <option value={year.value} disabled={year.value > getCurrentYear()}>{year.label}</option>
                                            )
                                        })
                                    }
                                </select>
                            </InputGroup>
                            <span className="text-danger">
                                {
                                    formError['graduationYear'] &&
                                    (formError['graduationYear'] === "req"
                                        ? 'Year is required'
                                        : 'Enter a valid Year')}
                            </span>
                        </div>
                        <InputGroup className="col-md-12"  >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.POSTGRADUATION")}
                            </InputGroup.Text>
                            <FormControl id="basic-url" name="postGraduation" aria-describedby="basic-addon3" placeholder={t("REGISTER.POSTGRADUATION_PLACEHOLDER")}
                                type='text'
                                className="form-control"
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.postGraduation : null}
                                onChange={(e) => { setFromData({ ...fromData, postGraduation: e.target.value }); onInputChangeHandler(e) }}
                                maxLength={60}
                            />
                        </InputGroup>
                        <span className="text-danger">
                            {
                                formError['postGraduation'] &&
                                (formError['postGraduation'] === "req"
                                    ? ''
                                    : 'Enter a valid Post Graduation')}
                        </span>
                        <div class="main-heading">
                            <InputGroup className="col-md-12" >
                                <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                    {t("REGISTER.COLLEGENAME")}
                                </InputGroup.Text>
                                <FormControl id="basic-url" name="postGraduationCollegeName" aria-describedby="basic-addon3" placeholder={t("REGISTER.COLLEGENAME_PLACEHOLDER")}
                                    className="form-control"
                                    onBlur={onTouchHandler}
                                    value={(fromData) ? fromData.postGraduationCollegeName : null}
                                    onChange={(e) => { setFromData({ ...fromData, postGraduationCollegeName: e.target.value }); onInputChangeHandler(e) }}
                                    maxLength={100}
                                />
                            </InputGroup>
                            <span className="text-danger">
                                {
                                    formError['postGraduationCollegeName'] &&
                                    (formError['postGraduationCollegeName'] === "req"
                                        ? ''
                                        : 'Enter a valid College Name')}
                            </span>
                            <InputGroup className="col-md-12" >
                                <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                    {t("REGISTER.UNIVERSITYNAME")}
                                </InputGroup.Text>
                                <FormControl id="basic-url" name="postGraduationUniversityName" aria-describedby="basic-addon3" placeholder={t("REGISTER.UNIVERSITYNAME_PLACEHOLDER")}
                                    className="form-control"
                                    onBlur={onTouchHandler}
                                    value={(fromData) ? fromData.postGraduationUniversityName : null}
                                    onChange={(e) => { setFromData({ ...fromData, postGraduationUniversityName: e.target.value }); onInputChangeHandler(e) }}
                                    maxLength={100}
                                />
                            </InputGroup>
                            <span className="text-danger">
                                {
                                    formError['postGraduationUniversityName'] &&
                                    (formError['postGraduationUniversityName'] === "req"
                                        ? ''
                                        : 'Enter a valid University Name')}
                            </span>
                            <InputGroup className="col-md-12" >
                                <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                    {t("REGISTER.COURSECOMPLETE")}
                                </InputGroup.Text>
                                <select name="postGraduationMonth"
                                    // className="form-control"
                                    onBlur={onTouchHandler}
                                    value={(fromData) ? fromData.postGraduationMonth : null}
                                    onChange={(e) => { setFromData({ ...fromData, postGraduationMonth: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                    <option value="" selected>{t("DROPDOWN.MONTH")}</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August </option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December </option>
                                </select>
                                <select name="postGraduationYear"
                                    // className="form-control"
                                    onBlur={onTouchHandler}
                                    value={(fromData) ? fromData.postGraduationYear : null}
                                    onChange={(e) => { setFromData({ ...fromData, postGraduationYear: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                    <option value="" selected>{t("DROPDOWN.YEAR")}</option>
                                    {
                                        Data.Years.map((year) => {
                                            return (
                                                <option value={year.value} disabled={year.value > getCurrentYear()}>{year.label}</option>
                                            )
                                        })
                                    }
                                </select>

                            </InputGroup>
                        </div>
                        <InputGroup className="col-md-12"  >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.POSTGRADUATIONSUBJECTS")}
                            </InputGroup.Text>
                            <Form.Control
                                className="form-control"
                                as="textarea"
                                rows={4}
                                placeholder={t("REGISTER.POSTGRADUATIONSUBJECTS_PLACEHOLDER")}
                                id="exampleFormControlTextarea1"
                                
                                name="postGraduationSubjects"
                                style={{ marginBottom: '20px', resize: 'none' }}
                                value={(fromData) ? fromData.postGraduationSubjects : null}
                                onChange={(e) => { setFromData({ ...fromData, postGraduationSubjects: e.target.value }); onInputChangeHandler(e) }}
                                maxLength={500}
                            />
                        </InputGroup>
                        <span className="text-danger">
                                {
                                    formError['postGraduationSubjects'] &&
                                    (formError['postGraduationSubjects'] === "req"
                                        ? ''
                                        : 'Enter a valid Post Graduation Subjects Name')}
                        </span>
                        <InputGroup className="col-md-12"  >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.PHD")}
                            </InputGroup.Text>
                            <FormControl id="basic-url" name="phd" aria-describedby="basic-addon3" placeholder={t("REGISTER.PHD_PLACEHOLDER")}
                                type='text'
                                className="form-control"
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.phd : null}
                                onChange={(e) => { setFromData({ ...fromData, phd: e.target.value }); onInputChangeHandler(e) }}
                                maxLength={60}
                            />
                        </InputGroup>
                        <span className="text-danger">
                            {
                                formError['phd'] &&
                                (formError['phd'] === "req"
                                    ? ''
                                    : 'Enter a valid PhD')}
                        </span>
                        <div class="main-heading">
                            <InputGroup className="col-md-12" >
                                <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                    {t("REGISTER.UNIVERSITYNAME")}
                                </InputGroup.Text>
                                <FormControl id="basic-url" name="phdUniversityName" aria-describedby="basic-addon3" placeholder={t("REGISTER.UNIVERSITYNAME_PLACEHOLDER")}
                                    className="form-control"
                                    onBlur={onTouchHandler}
                                    value={(fromData) ? fromData.phdUniversityName : null}
                                    onChange={(e) => { setFromData({ ...fromData, phdUniversityName: e.target.value }); onInputChangeHandler(e) }}
                                    maxLength={100}
                                />
                            </InputGroup>
                            <span className="text-danger">
                                {
                                    formError['phdUniversityName'] &&
                                    (formError['phdUniversityName'] === "req"
                                        ? ''
                                        : 'Enter a valid University Name')}
                            </span>
                            <InputGroup className="col-md-12" >
                                <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                    {t("REGISTER.COURSECOMPLETE")}
                                </InputGroup.Text>
                                <select name="phdMonth"
                                    // className="form-control"
                                    onBlur={onTouchHandler}
                                    value={(fromData) ? fromData.phdMonth : null}
                                    onChange={(e) => { setFromData({ ...fromData, phdMonth: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                    <option value="" selected>{t("DROPDOWN.MONTH")}</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August ">August </option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December </option>
                                </select>
                                <select name="phdYear"
                                    // className="form-control"
                                    onBlur={onTouchHandler}
                                    value={(fromData) ? fromData.phdYear : null}
                                    onChange={(e) => { setFromData({ ...fromData, phdYear: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                    <option value="" selected>{t("DROPDOWN.YEAR")}</option>
                                    {
                                        Data.Years.map((year) => {
                                            return (
                                                <option value={year.value} disabled={year.value > getCurrentYear()}>{year.label}</option>
                                            )
                                        })
                                    }
                                </select>

                            </InputGroup>
                        </div>
                        <InputGroup className="col-md-12"  >
                            <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                {t("REGISTER.POSTDOC")}
                            </InputGroup.Text>
                            <FormControl id="basic-url" name="postDoc" aria-describedby="basic-addon3" placeholder={t("REGISTER.POSTDOC_PLACEHOLDER")}
                                type='text'
                                className="form-control"
                                onBlur={onTouchHandler}
                                value={(fromData) ? fromData.postDoc : null}
                                onChange={(e) => { setFromData({ ...fromData, postDoc: e.target.value }); onInputChangeHandler(e) }}
                                maxLength={60}
                            />
                        </InputGroup>
                        <span className="text-danger">
                            {
                                formError['postDoc'] &&
                                (formError['postDoc'] === "req"
                                    ? ''
                                    : 'Enter a valid Post Doc (PDF)')}
                        </span>
                        <div class="main-heading">
                            <InputGroup className="col-md-12" >
                                <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                    {t("REGISTER.COLLEGENAME")}
                                </InputGroup.Text>
                                <FormControl id="basic-url" name="postDocCollegeName" aria-describedby="basic-addon3" placeholder={t("REGISTER.COLLEGENAME_PLACEHOLDER")}
                                    className="form-control"
                                    onBlur={onTouchHandler}
                                    value={(fromData) ? fromData.postDocCollegeName : null}
                                    onChange={(e) => { setFromData({ ...fromData, postDocCollegeName: e.target.value }); onInputChangeHandler(e) }}
                                    maxLength={100}
                                />
                            </InputGroup>
                            <span className="text-danger">
                                {
                                    formError['postDocCollegeName'] &&
                                    (formError['postDocCollegeName'] === "req"
                                        ? ''
                                        : 'Enter a valid College Name')}
                            </span>
                            <InputGroup className="col-md-12" >
                                <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                    {t("REGISTER.UNIVERSITYNAME")}
                                </InputGroup.Text>
                                <FormControl id="basic-url" name="postDocUniversityName" aria-describedby="basic-addon3" placeholder={t("REGISTER.UNIVERSITYNAME_PLACEHOLDER")}
                                    className="form-control"
                                    onBlur={onTouchHandler}
                                    value={(fromData) ? fromData.postDocUniversityName : null}
                                    onChange={(e) => { setFromData({ ...fromData, postDocUniversityName: e.target.value }); onInputChangeHandler(e) }}
                                    maxLength={100}
                                />
                            </InputGroup>
                            <span className="text-danger">
                                {
                                    formError['postDocUniversityName'] &&
                                    (formError['postDocUniversityName'] === "req"
                                        ? ''
                                        : 'Enter a valid University Name')}
                            </span>
                            <InputGroup className="col-md-12" >
                                <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                                    {t("REGISTER.COURSECOMPLETE")}
                                </InputGroup.Text>
                                <select name="postDocMonth"
                                    // className="form-control"
                                    onBlur={onTouchHandler}
                                    value={(fromData) ? fromData.postDocMonth : null}
                                    onChange={(e) => { setFromData({ ...fromData, postDocMonth: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                    <option value="" selected>{t("DROPDOWN.MONTH")}</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August ">August </option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December </option>
                                </select>
                                <select name="postDocYear"
                                    // className="form-control"
                                    onBlur={onTouchHandler}
                                    value={(fromData) ? fromData.postDocYear : null}
                                    onChange={(e) => { setFromData({ ...fromData, postDocYear: e.target.value }); onInputChangeHandler(e) }} className="form-select customize_select" aria-label="Default select example">
                                    <option value="" selected>{t("DROPDOWN.YEAR")}</option>
                                    {
                                        Data.Years.map((year) => {
                                            return (
                                                <option value={year.value} disabled={year.value > getCurrentYear()}>{year.label}</option>
                                            )
                                        })
                                    }
                                </select>

                            </InputGroup>
                        </div>
                    </div>

                    <div class="p-2 border text-white mt-4" style={{ backgroundColor: "#de8461", fontWeight: "bold" }}>{t("Register.D5")}
                    </div>
                    <InputGroup className="col-md-12 mt-4" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.AWARD")}
                        </InputGroup.Text>
                        <Form.Control
                            className="form-control"
                            as="textarea"
                            name="awards"
                            rows={4}
                            placeholder={t("REGISTER.AWARD_PLACEHOLDER")}
                            id="exampleFormControlTextarea1"
                            
                            style={{ marginBottom: '20px', resize: 'none' }}
                            value={(fromData) ? fromData.awards : null}
                            onChange={(e) => { setFromData({ ...fromData, awards: e.target.value }); onInputChangeHandler(e) }}
                            maxLength={500}
                        />
                    <span className="text-danger">
                        {
                            formError['awards'] &&
                            (formError['awards'] === "req"
                                ? ''
                                : 'Enter a valid Awards Name')}
                    </span>
                    </InputGroup>
                    <InputGroup className="col-md-12 mt-4" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.SEMINAR")}
                        </InputGroup.Text>
                        <Form.Control
                            className={
                                formError['seminars'] &&
                                (formError['seminars'] === "req"
                                    ? "form-control  is-invalid"
                                    : "form-control")}
                            as="textarea"
                            name="seminars"
                            rows={4}
                            placeholder={t("REGISTER.SEMINAR_PLACEHOLDER")}
                            id="exampleFormControlTextarea1"
                            
                            style={{ marginBottom: '20px', resize: 'none' }}
                            value={(fromData) ? fromData.seminars : null}
                            onChange={(e) => { setFromData({ ...fromData, seminars: e.target.value }); onInputChangeHandler(e) }}
                            maxLength={500}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['seminars'] &&
                            (formError['seminars'] === "req"
                                ? ''
                                : 'Enter a valid Seminars Name')}
                    </span>
                    <InputGroup className="col-md-12 mt-4" >
                        <InputGroup.Text id="basic-addon3" className="col-md-4 mb-3">
                            {t("REGISTER.CVE")}
                        </InputGroup.Text>
                        <Form.Control
                            className={
                                formError['CVE'] &&
                                (formError['CVE'] === "req"
                                    ? "form-control  is-invalid"
                                    : "form-control")}
                            as="textarea"
                            name="CVE"
                            rows={4}
                            placeholder={t("REGISTER.CVE_PLACEHOLDER")}
                            id="exampleFormControlTextarea1"
                            
                            style={{ marginBottom: '20px', resize: 'none' }}
                            value={(fromData) ? fromData.CVE : null}
                            onChange={(e) => { setFromData({ ...fromData, CVE: e.target.value }); onInputChangeHandler(e) }}
                            maxLength={500}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['CVE'] &&
                            (formError['CVE'] === "req"
                                ? ''
                                : 'Enter a valid CVE Name')}
                    </span>


                    <div class="p-2 border text-white mt-4" style={{ backgroundColor: "#de8461", fontWeight: "bold" }}>{t("Register.D4")}
                    </div>
                    <p className="mt-2 mandatory_section"> {t("REGISTER.FILEUPLOADINFO")}</p>
                    <InputGroup className="col-md-12 mt-4 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.PHOTO")} <span className="star_highlight">*</span>{t("REGISTER.PHOTO1")}
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name="upload_photo"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            className="select_image hidden" type="file"
                            style={{ color: 'transparent', zIndex: '0' }}
                        />
                        {
                            !hideModal ?
                                <label className='selected-file-name' name={fileDataName.upload_photo} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.upload_photo}</label>
                                : getFileName('upload_photo') && <>
                                    <button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('upload_photo')}`, '_blank') }}
                                    >VIEW</button>
                                    <FormControl id="basic-url" value={getFileName('upload_photo')} aria-describedby="basic-addon3" />
                                </>
                        }
                    </InputGroup>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.SIGNATURE")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name="signature"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            className="select_image" type="file"
                            style={{ color: 'transparent' }}
                        />
                        {
                            !hideModal ?
                                <label className='selected-file-name' name={fileDataName.signature} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.signature}</label>
                                :
                                getFileName('signature') && <>
                                    <button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('signature')}`, '_blank') }}
                                    >VIEW</button>
                                    <FormControl id="basic-url" value={getFileName('signature')} aria-describedby="basic-addon3" />
                                </>
                        }
                    </InputGroup>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.AADHARCARD")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name="andhar_card"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            className="select_image" type="file"
                            style={{ color: 'transparent' }}
                        />
                        {
                            !hideModal ?
                                <label className='selected-file-name' name={fileDataName.andhar_card} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.andhar_card}</label>
                                :
                                getFileName('andhar_card') && <>
                                    <button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('andhar_card')}`, '_blank') }}
                                    >VIEW</button>
                                    <FormControl id="basic-url" value={getFileName('andhar_card')} aria-describedby="basic-addon3" />
                                </>
                        }
                    </InputGroup>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.COURSECOMPLETION")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name="degree_certificate"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            className="select_image" type="file"
                            style={{ color: 'transparent' }}
                        />
                        {
                            !hideModal ?
                                <label className='selected-file-name' name={fileDataName.degree_certificate} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.degree_certificate}</label>
                                :
                                getFileName('degree_certificate') && <>
                                    <button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('degree_certificate')}`, '_blank') }}
                                    >VIEW</button>
                                    <FormControl id="basic-url" value={getFileName('degree_certificate')} aria-describedby="basic-addon3" />
                                </>
                        }
                    </InputGroup>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.IDENTITYPROOF")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name="identity_proof"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            className="select_image" type="file"
                            style={{ color: 'transparent' }}
                        />
                        {
                            !hideModal ?
                                <label className='selected-file-name' name={fileDataName.identity_proof} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.identity_proof}</label>
                                :
                                getFileName('identity_proof') && <>
                                    <button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('identity_proof')}`, '_blank') }}
                                    >VIEW</button>
                                    <FormControl id="basic-url" value={getFileName('identity_proof')} aria-describedby="basic-addon3" />
                                </>
                        }
                    </InputGroup>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.PROVISIONALREGISTRATIONCERTIFICATE")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name="provisional_regidtration_certificate"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            className="select_image" type="file"
                            style={{ color: 'transparent' }}
                        />
                        {
                            !hideModal ?
                                <label className='selected-file-name' name={fileDataName.provisional_regidtration_certificate} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.provisional_regidtration_certificate}</label>
                                :
                                getFileName('provisional_regidtration_certificate') && <>
                                    <button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('provisional_regidtration_certificate')}`, '_blank') }}
                                    >VIEW</button>
                                    <FormControl id="basic-url" value={getFileName('provisional_regidtration_certificate')} aria-describedby="basic-addon3" />
                                </>
                        }
                    </InputGroup>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.STATECERTIFICATE")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control className="select_image"
                            name="domicile_certificate"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            type="file"
                            style={{ color: 'transparent' }}
                        />
                        {
                            !hideModal ?
                                <label className='selected-file-name' name={fileDataName.domicile_certificate} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.domicile_certificate}</label>
                                :
                                getFileName('domicile_certificate') && <>
                                    <button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('domicile_certificate')}`, '_blank') }}
                                    >VIEW</button>
                                    <FormControl id="basic-url" value={getFileName('domicile_certificate')} aria-describedby="basic-addon3" />
                                </>
                        }
                    </InputGroup>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.DOBPROOF")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name="dob_proof"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            className="select_image" type="file"
                            style={{ color: 'transparent' }}
                        />
                        {
                            !hideModal ?
                                <label className='selected-file-name' name={fileDataName.dob_proof} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.dob_proof}</label>
                                :
                                getFileName('dob_proof') && <>
                                    <button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('dob_proof')}`, '_blank') }}
                                    >VIEW</button>
                                    <FormControl id="basic-url" value={getFileName('dob_proof')} aria-describedby="basic-addon3" />
                                </>
                        }
                    </InputGroup>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.PAYMENTPROOF")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name="payment_proof"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            className="select_image" type="file"
                            style={{ color: 'transparent' }}

                        />
                        {!hideModal && <button className="show-image" data-bs-toggle="modal" data-bs-target="#cancelOrder">{t("REGISTER.VIEW_BAR_CODE")}</button>}

                        {
                            !hideModal ?
                                <label className='selected-file-name' name={fileDataName.payment_proof} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.payment_proof}</label>
                                :
                                getFileName('payment_proof') && <>
                                    <button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('payment_proof')}`, '_blank') }}
                                    >VIEW</button>
                                    <FormControl id="basic-url" value={getFileName('payment_proof')} aria-describedby="basic-addon3" />
                                </>
                        }
                    </InputGroup>
                    <span style={{ fontWeight: "bold" }}>{t("REGISTER.REGISTRATION_AMOUNT")} Rs. 2,025/-</span>

                    {!hideModal && <span className="text-center" style={{ fontWeight: "bold" }}>{t("REGISTER.SCAN_THE_BARCODE")}</span>}

                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.FIR")}
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name="fir"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            className="select_image" type="file"
                            style={{ color: 'transparent' }}
                        />
                        {
                            !hideModal ?
                                <label className='selected-file-name' name={fileDataName.fir} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.fir}</label>
                                :
                                getFileName('fir') && <>
                                    <button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('fir')}`, '_blank') }}
                                    >VIEW</button>
                                    <FormControl id="basic-url" value={getFileName('fir')} aria-describedby="basic-addon3" />
                                </>
                        }
                    </InputGroup>
                    <InputGroup className="col-md-12 upload-image" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("REGISTER.AFFIDAVIT")}
                        </InputGroup.Text>
                        <FormControl />
                        <Form.Control
                            name="affidavit_magistrate"
                            onBlur={onTouchHandler}
                            onChange={(e) => onFileChange(e)}
                            className="select_image" type="file"
                            style={{ color: 'transparent' }}
                        />
                        {
                            !hideModal ?
                                <label className='selected-file-name' name={fileDataName.affidavit_magistrate} id="basic-url" disabled={true} aria-describedby="basic-addon3">{fileDataName.affidavit_magistrate}</label>
                                :
                                getFileName('affidavit_magistrate') && <>
                                    <button
                                        className="show-image"
                                        onClick={() => { window.open(`${uploadURL}images/${getFileName('affidavit_magistrate')}`, '_blank') }}
                                    >VIEW</button>
                                    <FormControl id="basic-url" value={getFileName('affidavit_magistrate')} aria-describedby="basic-addon3" />
                                </>
                        }
                    </InputGroup>
                    <div className="mandatory_section"> {t("REGISTER.MANDATORYFILED")} (<span className="star_highlight">*</span>)</div>
                    <div className="recaptcha_css">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={CAPTCHA_SECRET_KEY}
                            onChange={onChange}
                        />
                    </div>

                    <div class="row">
                        <div class="col-sm-12 text-center mb-5 mt-3">
                            <button id="btnSearch" class="btn center-block mr-4" onClick={() => onResetButtonClick()} >{t("REGISTER.RESET")}</button>
                            <button onClick={formSubmitHandler}
                                disabled={formSubmitButtonDisable}
                                // id={`${!formSubmitButtonDisable ? 'btnClear' : 'btnSearch'}`}
                                id={'btnClear'}
                                class="btn center-block">{!!isProcessing ? t("REGISTER.PROCESSING") : t("REGISTER.SUBMIT")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />

        </>
    )

}
export default Register;