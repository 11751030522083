import { React, useContext, useEffect } from 'react';
import './corporateProfile.css';
import { useTranslation } from "react-i18next";
import JsonData from './data.json';
import JsonData2 from './data2.json';
import JsonData3 from './data3.json';
import themeContext from '../../context/themeContext';
import { Link } from "react-router-dom";


function CorporateProfileJsx() {
  const { mode } = useContext(themeContext)

  const { t } = useTranslation();
  // const [data, setData] = useState([]);
  const getData = () => {
    fetch('data.json'
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        // setData(myJson)
      });
  }
  useEffect(() => {
    getData()
  }, [])



  const getData2 = () => {
    fetch('data2.json'
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        // setData(myJson)
      });
  }
  useEffect(() => {
    getData2()
  }, [])


  const getData3 = () => {
    fetch('data3.json'
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        // setData(myJson)
      });
  }
  useEffect(() => {
    getData3()
  }, [])


  const DisplayData = JsonData.map(
    (item) => {

      return (
        <tr id="tablerow" >
          <td>{item.SrNo}</td>
          <td id="sp">{t(`${item.Name}`)}</td>
          <td>{t(`${item.Type}`)}</td>
          <td>{t(`${item.Posting}`)}</td>
          <td>{t(`${item.Mobile}`)}</td>
        </tr>
      )
    }
  )
  const DisplayData2 = JsonData2.map(
    (item) => {

      return (
        <tr id="tablerow">
          <td>{item.SrNo}</td>
          <td>{t(`${item.Post}`)}</td>
          <td>{item.Sanctioned}</td>

        </tr>
      )
    }
  )


  const DisplayData3 = JsonData3.map(
    (item) => {

      return (
        <tr id="tablerow">
          <td>{item.SrNo}</td>
          <td>{t(`${item.Name}`)}</td>
          <td>{t(`${item.Designation}`)}</td>
          <td>{t(`${item.MobileNo}`)}</td>

        </tr>
      )
    }
  )

  return (
    <>
      <div className="exe-comm">
        <div className="container">
          <div className="bread_crums mt-3"><Link to="/">{t("HEADER.HOME")}</Link>/ <Link to="/aboutUS">{t("HEADER.ABOUTUS")}</Link>/<Link to="/corporateProfile">{t("Corporate_Profile.MAIN_HEAD")}</Link></div><span className="print" onClick={() => window.print()}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="skyBlue" class="bi bi-printer-fill" viewBox="0 0 16 16">
            <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
            <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
          </svg> &nbsp; {t("PRINT.TITLE")}</span>
          <h3 className="heading-corporate">{t("Corporate_Profile.MAIN_HEAD")}</h3>
          <div className='profile-label'>{t("Corporate_Profile.PROFILE")}</div>
          <p className="profile-text">{t("Corporate_Profile.P1")}</p>

          <div className="scroll_table">
            <table cellpadding="5" border='1' cellspacing="0" className="table .table-borderless">

              <tr className="tableHead">
                <th scope="col">{t("Corporate_Profile.SRNO")}</th>
                <th scope="col">{t("Corporate_Profile.NAME")}</th>
                <th scope="col">{t("Corporate_Profile.TYPE")}</th>
                <th scope="col"></th>
                <th scope="col">{t("Corporate_Profile.MOBILE")}</th>
              </tr>

              <tbody className="tableBody">
                {DisplayData}
              </tbody>
            </table>
            </div>

            <div class="p-2 border text-white" style={{ backgroundColor: "#de8461", fontWeight: "bold" }} >{t("Corporate_Profile.D1")}</div>

            <ol id="paragraph-sp">
              <li className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P2")}</li>
              <li className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P3")}</li>
              <li className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P4")}</li>
              <li className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P5")}</li>
              <li className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P6")}</li>
              <li className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P7")}</li>
              <li className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P8")}</li>
              <li className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P9")}</li>
              <li className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P10")}</li>
              <li className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P11")}</li>
              <li className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P12")}</li>
            </ol>
            <div class="p-2 border text-white" style={{ backgroundColor: "#de8461", fontWeight: "bold" }} id="sp">{t("Corporate_Profile.D2")}</div>
            <div id="paragraph-sp2">
              <span className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P13")}</span><br /><br />
              <span className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P13-1")}</span><br /><br />
              <span className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P13-2")}</span>

            </div>
            <br />
            <div class="p-2 border text-white" style={{ backgroundColor: "#de8461", fontWeight: "bold" }}> {t("Corporate_Profile.D3")}</div>

            <ol id="paragraph-sp">
              <li className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P14")}</li>
              <li className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P15")}</li>
              <li className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P16")}</li>
              <li className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P17")}</li>
              <li className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P18")}</li>
              <li className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P19")}</li>
            </ol>
            <div class="p-2 border text-white" style={{ backgroundColor: "#de8461", fontWeight: "bold" }}>{t("Corporate_Profile.D4")}</div>
            <div id="paragraph-sp2">
              <span className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P20")}</span>
            </div><br />
            <div class="p-2 border text-white" style={{ backgroundColor: "#de8461", fontWeight: "bold" }}>{t("Corporate_Profile.D5")}</div>

            <div id="paragraph-sp2">
              <span className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P21")}</span>
            </div><br />
            <div class="p-2 border text-white" style={{ backgroundColor: "#de8461", fontWeight: "bold" }}>{t("Corporate_Profile.D6")}</div>

            <div id="paragraph-sp2">
              <span className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P22")}</span>
            </div><br />
            <div className="scroll_table">

            <table class="table">
              <tr className="tableHead">
                <th>{t("Corporate_Profile.SRNO")}</th>
                <th>{t("Corporate_Profile.POST")}</th>
                <th >{t("Corporate_Profile.SANCTIONEDPOST")}</th>
              </tr>

              <tbody className="tableBody">
                {DisplayData2}
              </tbody>
            </table>
            </div>
            <div class="p-2 border text-white" style={{ backgroundColor: "#de8461", fontWeight: "bold" }}>{t("Corporate_Profile.D7")}</div>
            <div className="scroll_table">
            <table class="table">
              <tr className="tableHead">
                <th>{t("Corporate_Profile.SRNO")}</th>
                <th>{t("Corporate_Profile.NAMEEMPLOYEE")}</th>
                <th>{t("Corporate_Profile.DESIGNATION")}</th>
                <th>{t("Corporate_Profile.MOBILE")}</th>
              </tr>

              <tbody className="tableBody">
                {DisplayData3}
              </tbody>
            </table>
            </div>
            <div class="p-2 border text-white" style={{ backgroundColor: "#de8461", fontWeight: "bold" }}>{t("Corporate_Profile.D8")}</div>

            <div className="container" id="paragraph-sp2">
              {t("Corporate_Profile.P23")}
            </div>
            <div id="paragraph-sp2">
              <h6 className="info_bold">{t("Corporate_Profile.H1")} </h6>
              <h6 className="info_bold">{t("Corporate_Profile.H2")}</h6>
              <h6 className="info_bold">{t("Corporate_Profile.H3")}</h6>
            </div>

            <div id="paragraph-sp2">
              <span className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P24")}</span>
              <span className={(mode === 'dark') ? 'paragrph_text paragrph_text_dark' : 'paragrph_text'}>{t("Corporate_Profile.P25")}</span>
            </div>
            <div class="p-2 border text-white" style={{ backgroundColor: "#de8461", fontWeight: "bold" }}>{t("Corporate_Profile.D9")}</div>

            <ul className="doct" id="paragraph-sp2">
              <li className="paragrph_text doct">{t("Corporate_Profile.P26")}</li>
              <li className="paragrph_text doct">{t("Corporate_Profile.P27")}</li>
              <li className="paragrph_text doct">{t("Corporate_Profile.P28")}</li>
            </ul>
          </div>
          <div id="space-footer"></div>
        </div>

    </>
  )

}
export default CorporateProfileJsx;