import React, { Component } from 'react';
import { MasterDashboard } from './masterDashboard';
import { baseURL } from '../../constants/applicationConstants';
import axios from 'axios';

export default class Master extends Component {

    constructor(props) {
        super(props);
        this.state = {
            doctorDetail: null
        }
    }

    

   

    getDoctor = () => {
        axios.get(`${baseURL}loginAudits`, {
            headers: {
                'access-token': JSON.parse(sessionStorage.getItem('authToken'))
            }
        }).then((res) => {
            this.setState({ doctorDetail: res.data.result });
        })
    }

    componentDidMount() {
        this.getDoctor();
      }

   

    render() {
        return (
            <div>
                <MasterDashboard
                    doctorDetail={this.state.doctorDetail}
                    
                />
            </div>
        )
    }
}