import React from 'react';
import './screenReader.css';
import {useEffect} from 'react';
import JsonData from './data.json';


import { useTranslation } from "react-i18next";

function  ScreenReaderJsx() {
    const { t } = useTranslation();
    // const [data,setData]=useState([]);
    const getData=()=>{
      fetch('data.json'
      ,{
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         }
      }
      )
        .then(function(response){
          return response.json();
        })
        .then(function(myJson) {
          // setData(myJson)
        });
    }
    useEffect(()=>{
      getData()
    },[])
    
    const DisplayData=JsonData.map(
        (item)=>{
          
            return(
                <tr id="tablerow">
                    <td>{item.Screen_Reader}</td>
                     <td id="data">{item.Website}</td>
                    <td>{item.FreeCommercial}</td>
                </tr>
            )
        }
    )
  
    return (
        <>

            <div className="container">
            <div className="screenreader_linking">Home /About Us / Executive Committee</div>
                <h3 className="heading">{t("screenReader.MAIN_HEAD")}</h3>
                <div id="paragraph-sp">
                {t("screenReader.P1")}
                </div>
                <table className="table .table-borderless">
            <tr className="tableHead">
              <th>Screen Reader</th>
              <th>Website</th>
              <th>Free/Commercial</th>
            </tr>
         
          <tbody>            
          {DisplayData}
          </tbody>
        </table>
        <div id="space"></div>
            </div>

        </>
    )

}
export default ScreenReaderJsx;