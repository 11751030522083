import React from 'react';
import OfficeStaffJsx from './officeStaff.jsx'
const OfficeStaff =()=> {
    return (
        <OfficeStaffJsx
        />
    )
}

export default OfficeStaff;
