import React from 'react';
import { Component } from 'react';
import ListViewJsx from './listView.jsx'
import axios from 'axios'
import { baseURL } from "../../constants/applicationConstants";

class ListView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pdfList: null,
            page: 1,
            links:{
                regular:'',
                pending:'',
                provisional:''
            }
        }
    }

    componentDidMount() {
      this.getListData();
      axios.post(`${baseURL}veterinaryPractitioner?type=regular`,).then(res=>{
        this.setState({links:{...this.state.links,regular:res?.data?.result?.filePath}})
      })
      axios.post(`${baseURL}veterinaryPractitioner?type=provisional`,).then(res=>{
        this.setState({links:{...this.state.links,provisional:res?.data?.result?.filePath}})
      })
      axios.post(`${baseURL}veterinaryPractitioner?type=pending`,).then(res=>{
        this.setState({links:{...this.state.links,pending:res?.data?.result?.filePath}})
      })
    }

    getListData = () =>{
        axios.get(`${baseURL}news?page=${this.state.page}&perPage=10`,{
            headers:{
                'access-token':JSON.parse(sessionStorage.getItem('authToken')),
                'role':sessionStorage.getItem('role')
            }
        }).then(res=>{
           this.setState({pdfList:res?.data.data})
        })
    }

    onPaginationClick = (page) => {
        this.setState({ page: page },()=> this.getListData())
    }

    render() {
        return (
            <ListViewJsx
                pdfList={this.state.pdfList}
                onPaginationClick={this.onPaginationClick}
                links={this.state.links}
            />
        )
    }

}

export default ListView
