import { React, useContext } from 'react';
import msvc_logo from '../../assets/images/MSVCLOGO.png';
import { useTranslation } from "react-i18next";
import "./aboutBanner.css";
import { Link } from "react-router-dom";
import themeContext from '../../context/themeContext';

function AboutBannerJsx() {
    const { mode } = useContext(themeContext)

    const { t } = useTranslation();
    return (
        <>
            {/* <div className="about-container">
            <div className="about-c">
                <div><img src={msvc_logo} alt="img" className="homePageBannerMainImage" /></div>
                <div>               
                    <div>{t("HOME.ABOUT")}</div>
                    <Link to="/corporateProfile">
                    <button className="aboutBannerReadButton">{t("HOME.READMORE")}</button>
                    </Link>
                 </div>
            </div>
        </div> */}
            <div className={(mode === 'dark') ? 'about-container about-container-dark' : 'about-container'}>
                <div className="row">
                    <div className="col-sm-2">
                    </div>
                    <div className="col-sm-3">
                        <div><img src={msvc_logo} alt="img" className={(mode === 'dark') ? 'homePageBannerMainImage homePageBannerMainImage-dark' : 'homePageBannerMainImage'} />
                            <span className={(mode === 'dark') ? 'image_desc image_desc-dark' : 'image_desc'}>Welcome to Maharashtra State Veterinary Council</span>
                        </div>
                    </div>
                    <div className="col-sm-7">
                        <div className={(mode === 'dark') ? 'about_description about_description-dark' : 'about_description'}>{t("HOME.ABOUT")}</div>
                        <Link to="/corporateProfile">
                            <button className="aboutBannerReadButton">{t("HOME.READMORE")}</button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )



}
export default AboutBannerJsx;