import React, { Component } from "react";
import { PhotoCard as PhotoCardJsx } from "./photoCard.jsx";
import photoCardJson from "./photoCard.json";

class photoCard extends Component {
    constructor(props) {
      super(props);
    };
    render() {
      let cardData= photoCardJson;
        return (
          <PhotoCardJsx 
          photoCardData = {cardData}
          />
        );
      }
    }
    
    export default photoCard;
    