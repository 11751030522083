import React, { useState, useEffect } from 'react'
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import axios from 'axios';
import { baseURL, passwordRegex } from "../../constants/applicationConstants";
import './resetPass.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

export const ResetPassJsx = () => {
    const history = useHistory();

    let [isProcessing, setProcessing] = useState(false);
    // let [loading, setLoading] = useState(false);
    // const [albumListData, setAlbumData] = useState([]);

    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        Password: '',
    });
    const [formError, setFormError] = useState({})
    const [formSubmitButtonDisable, setFormSubmitButtonDisable] = useState(true)
    useEffect(() => {
        isFromSubmissionBlock();
    })
    const onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value) {
            if (!inputRegexValidation(name, value)) {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "regx" })
            } else {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "" })
            }
        } else {
            setFormData({ ...formData, [name]: '' })
            setFormError({})
        }
    };
    const inputRegexValidation = (name, value) => {
        if (name === "Password")
            return passwordRegex.test(value)
        else
            return true;
    };
    const isFromSubmissionBlock = () => {
        let { Password } = formData;
        if (Password) {
            if (
                !inputRegexValidation("Password", Password)
            ) {
                setFormSubmitButtonDisable(true)
            } else {
                setFormSubmitButtonDisable(false)
            }
        } else {
            setFormSubmitButtonDisable(true)
        }
    };
    const onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            setFormError({ ...formError, [name]: "req" })
        }
    }
    const onSubmitButtonClick = () => {
        // setLoading(true)
        setProcessing(true);
        const data = {
            id: parseInt(window.location.search.split('=')[2]),
            temporary_token: window.location.search.split('=')[1].replace('&id',''),
            password: formData.Password,
        }
        axios.post(`${baseURL}resetPassword`, data).then((res) => {
            // setLoading(false)
            if (res.data.status === "success") {
                toast.success(res.data.message)
                setProcessing(false);
                setFormData({
                    Password: '',
                })
                history.push(`/adminLogin`)


            } else {
                toast.error(res.data.message)
                setProcessing(false);
            }
        }).catch((res) => {
            setProcessing(false);
            toast.error(res.response?.data.message)
        })
    }
    console.log("id",window.location.search.split('=')[2])
    // console.log("token",window.location.search.split('=')[1].replace('&id',''))

   
  
    return (
        <>
            <div className="container officeStaffComponentOuter">
                <div className="bread_crums mt-3"><Link to="/">{t("UPLOAD_MAIN_IMAGE.HOME")}</Link>/ <Link to="/grievance">{t("RESET.TITLE")}</Link></div><span className="print" onClick={() => window.print()}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="skyBlue" class="bi bi-printer-fill" viewBox="0 0 16 16">
                    <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                    <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                </svg> &nbsp; {t("PRINT.TITLE")}</span>
                <h2 className="loginHeading mt-2">
                    {t("RESET.TITLE")}
                </h2>
                <hr />
                <div className="input" >


                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("RESET.HEADING")}<span className="star_highlight">*</span>
                        </InputGroup.Text>
                        <FormControl id="basic-url"
                            name='Password'
                            onBlur={onTouchHandler}
                            value={formData.Password}
                            onChange={onInputChange}
                            aria-describedby="basic-addon3"
                            placeholder={t("RESET.PASSWORD_PLACEHOLDER")} />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['Password'] &&
                            (formError['Password'] === "req"
                                ? 'Password is required'
                                : 'Enter a valid Password')}
                    </span>

                    <div class="row">
                        <div class="col-sm-12 text-center mb-5 mt-3">
                            <button disabled={formSubmitButtonDisable || !!isProcessing} id="btnClear" class="btn center-block" onClick={onSubmitButtonClick}>{!!isProcessing ? t("REGISTER.PROCESSING") : t("REGISTER.SUBMIT")}</button>
                        </div>
                    </div>
                   
                

                </div>
            </div>

            <ToastContainer />
        </>
    )
}
export default ResetPassJsx;