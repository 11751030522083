import React from 'react';
import greaterThanIcon from "../../assets/images/greaterThanIcon.svg";
import "./dashboard.css"


// import { useTranslation } from "react-i18next";



export default function Dashboard(props) {
    // const { t } = useTranslation();
    // let { contactUsdata } = props;
    return (
        <>
            <div className="container">
                <div className="row mt-4">
                    <div className="col-sm-3">
                        <div className="DashboardTab">Dashboard</div>
                        <div className="faqComponent mt-4">
                            <div className="dashboardHeadDiv">
                                <h5 className="dashboardQuestion">1.0 Introduction</h5>
                                <button
                                    className="faqButton"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target={`#collapseExample`}
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                >
                                    <span className="show faqArrowIcon" id={`collapseExample`}>
                                        <img src={greaterThanIcon} className="faqIconRotate"  alt=""/>
                                    </span>
                                    <span className="collapse" id={`collapseExample`}>
                                        <img src={greaterThanIcon} className="faqIcon"  alt=""/>
                                    </span>
                                </button>
                            </div>
                            <div className="collapse dashboardAnswer" id={`collapseExample`}>
                                <div className="optionDiv">1.1. Purpose</div>
                                <div className="optionDiv">1.2. Scope of Project</div>
                                <div className="optionDiv">1.3. Glossary</div>
                                <div className="optionDiv">1.4. References</div>
                                <div className="optionDiv">1.5. Overview of Document</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-9">
                        <div className="mainDashboardHead">
                            1.1. Purpose
                        </div>
                        <div className="subDashboardHead">
                            The purpose of this document is to present a detailed description of the Web application for Maharashtra State Veterinary council(MSVC). It will explain the purpose and features of the system, the interfaces of the system, what the system will do, the constraints under which it must operate and how the system will react to external stimuli. This document is intended for both the stakeholders and the developers of the system.
                        </div>
                        <div className="mainDashboardHead mt-4">
                            1.2. Scope of Project
                        </div>
                        <div className="subDashboardHead">
                            This software system is website application for MSVC. This Website states all the registered veterinarians and it’ll provide them with easy login. Users of this website may easily look onto the fees, documents , listed registered veterinary applications etc.
                        <br />
                            <br />
                        Website is specifically designed to be more user-friendly doctors can easily register themselves, which would rather be done manually. Even the renewal of registration is done on the website with no difficulties.
                        <br />
                            <br />
                        The system also contains a relational database containing a list of provisional veterinary practitioner, registered veterinary practitioner , pending MSVC applications.
                        <br />
                        </div>
                        <div className="mainDashboardHead mt-4">
                            1.3. Glossary
                        </div>
                        <div className="subDashboardHead">
                            {/* table */}
                            <table>
                                <tr>
                                    <th>Term</th>
                                    <th>Definition</th>
                                </tr>
                                <tr>
                                    <td>About us</td>
                                    <td>Page that’ll tell you all information about the MSVC.</td>
                                </tr>
                                <tr>
                                    <td>Acts and Rule</td>
                                    <td>Acts and rules provided for veterinary practice in India. Acts are part of law, under which we register .</td>
                                </tr>
                                <tr>
                                    <td>Database</td>
                                    <td>Collection of all the information monitored by this system</td>
                                </tr>
                                <tr>
                                    <td>Doctor’s login</td>
                                    <td>For the doctors who are successfully registered in the veterinary practice.</td>
                                </tr>
                                <tr>
                                    <td>Field</td>
                                    <td>A cell within a form.</td>
                                </tr>
                                <tr>
                                    <td>FAQs</td>
                                    <td>Refers to page where frequently asked questions are answered.</td>
                                </tr>
                                <tr>
                                    <td>Member</td>
                                    <td>A member of the Historical Society listed in the HS database.</td>
                                </tr>
                                <tr>
                                    <td>User</td>
                                    <td>Anyone visiting the site to collect information about veterinarians.</td>
                                </tr>
                                <tr>
                                    <td>Renewal Registration</td>
                                    <td>Provision for renewing the registration, just like renewing license.</td>
                                </tr>
                                <tr>
                                    <td>RVP</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Software Requirements Specification</td>
                                    <td>A document that completely describes all of the functions of a proposed system and the constraints under which it must operate. For example, this document.</td>
                                </tr>
                                <tr>
                                    <td>Stakeholder</td>
                                    <td>Any person with an interest in the project who is not a developer.</td>
                                </tr>
                            </table>

                        </div>
                        <div className="mainDashboardHead mt-4">
                            1.4. References
                        </div>
                        <div className="subDashboardHead">
                            IEEE. IEEE Std 830-1998 IEEE Recommended Practice for Software Requirements Specifications. IEEE Computer Society, 1998.
                        </div>
                        <div className="mainDashboardHead mt-4">
                        1.5. Overview of Document
                        </div>
                        <div className="subDashboardHead mb-5">
                        The next chapter, the Overall Description section, of this document gives an overview of the functionality of the product. It describes the informal requirements and is used to establish a context for the technical requirements specification in the next chapter. The third chapter, Requirements Specification section, of this document is written primarily for the developers and describes in technical terms the details of the functionality of the product.                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
