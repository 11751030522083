import React,{Component} from 'react';
import {UploadPDFJsx} from './uploadPDF.jsx';
import { uploadURL } from "../../constants/applicationConstants";
import axios from 'axios';


export default class UploadPDF extends Component{
    constructor(props){
        super(props);
        this.state={
            pdfList:[],
            page:1
        }
    }

    componentDidMount() {
        this.getListData();
    }

    getListData = () =>{
        axios.get(`${uploadURL}pdfDocument/list?page=${this.state.page}&perPage=10`,{
            headers:{
                'access-token':JSON.parse(sessionStorage.getItem('authToken')),
                'role':sessionStorage.getItem('role')
            }
        }).then(res=>{
           this.setState({pdfList:res?.data.data})
        })
    }

    onPaginationClick = (page) =>{
        this.setState({page:page},()=>this.getListData())
    }

    render(){
        return(
            <div>
                <UploadPDFJsx 
                    data={this.state.pdfList}
                    onPaginationClick={this.onPaginationClick}
                    getListData={this.getListData}
                />
            </div>
        )
    }
}