import React from 'react';
import './variousFilesFormat.css';
import { useEffect } from 'react';
import JsonData from './data.json';


// import { useTranslation } from "react-i18next";

function VariousFilesJsx() {
  // const { t } = useTranslation();
  // const [data, setData] = useState([]);
  const getData = () => {
    fetch('data.json'
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        // setData(myJson)
      });
  }
  useEffect(() => {
    getData()
  }, [])

  const DisplayData = JsonData.map(
    (item) => {

      return (
        <tr id="tablerow">
          <td>{item.Screen_Reader}</td>
          <td id="data">{item.Website}</td>
          <td>{item.FreeCommercial}</td>
        </tr>
      )
    }
  )

  return (
    <>

      <div className="container">
        <div className="screenreader_linking">Home / Help / Viewing Information</div>
        <h3 className="heading">Viewing Information in Various File Formats</h3>
        <div id="paragraph-sp">
          Provides information on how to access different file formats for viewing the required information.
                </div>
        <table className="table .table-borderless">
          <tr className="tableHead">
            <th width="30%">Document Type</th>
            <th width="70%">Plug-in for Download</th>
          </tr>

          <tbody>
            {DisplayData}
          </tbody>
        </table>
        <div id="space"></div>
      </div>

    </>
  )

}
export default VariousFilesJsx;