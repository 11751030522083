import ViewImageJsx from "./viewImage.jsx";

function ViewImage(){

    return (
      <ViewImageJsx
      />
    );
  }

export default ViewImage;