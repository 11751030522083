import { React, useContext  } from 'react';
import "./photoCard.css";
import img2 from "../../assets/images/registrar_new.jpg";
import img1 from "../../assets/images/president_new.jpg";
import defaultImg from "../../assets/images/man.png";
import { useTranslation } from "react-i18next";
import themeContext from '../../context/themeContext';


export const PhotoCard = (props) => {
  const {mode,updateTheme} = useContext(themeContext)

  const { t } = useTranslation();
  let { photoCardData } = props;

  const PRESIDENT_DESK = "PHOTOCARD.PHOTO_HEADER_1";
  const REGISTRAR_DESK = "PHOTOCARD.PHOTO_HEADER_2";

  const getImage = (header) => {
    if (header === PRESIDENT_DESK) {
      return img1;
    } else if (header === REGISTRAR_DESK) {
      return img2;
    } else {
      return defaultImg;
    }
  }

  return (
    photoCardData.map((data) => (
      <div className ={(mode==='dark')?'card card-dark':'card'}>
        <div className="card-header">{t(`${data.header}`)}</div>
        <div className="card-body">
          <img className="card-image registrar_photo" src={getImage(data.header)} alt=""></img>
          <div className="mt-3">
            <div className ={(mode==='dark')?'card-personName card-personName-dark':'card-personName'}>{t(`${data.name}`)}</div>
            <div className ={(mode==='dark')?'card-jobDescription card-jobDescription-dark':'card-jobDescription'}>{t(`${data.jobTitle}`)}</div>
            <div className ={(mode==='dark')?'card-jobDescription card-jobDescription-dark':'card-jobDescription'}>{t(`${data.jobTitle1}`)}</div>
            <div className ={(mode==='dark')?'card-jobDescription card-jobDescription-dark':'card-jobDescription'}>{t(`${data.jobTitle2}`)}</div>
            <div className ={(mode==='dark')?'card-subJobDescription card-subJobDescription-dark':'card-subJobDescription'}>{t(`${data.subDescription}`)}</div>
          </div>
          </div>
      </div>
    )
    )


  );
};





