import React,{Component} from 'react';
import DoctorDetails from './doctorDetails.jsx'
import axios from 'axios'
import {baseURL} from '../../constants/applicationConstants';

class DoctorProfileDetails extends Component  {

    constructor(props){
        super(props);
        this.state={
            details:null
        }
    }

    componentDidMount(){
        this.getDoctorDetails();
    }

    getDoctorDetails = () =>{
        const id = window.location.pathname.replace('/doctorDetails/','');
        // const id= sessionStorage.getItem('masterID');
        axios.get(`${baseURL}doctor/${id}`,{
            headers:{
                'access-token':JSON.parse(sessionStorage.getItem('authToken')),
                'role':sessionStorage.getItem('role')
            }
        }).then(res=>{
            this.setState({details:res.data});
        })
    }

    render(){
        return (
            <DoctorDetails
            details={this.state.details}
            getDoctorDetails={this.getDoctorDetails}
            />
        )
    }
}

export default DoctorProfileDetails;
