import React from 'react';
import ProfileDetailsJsx from './profileDetails.jsx'
const ProfileDetails =()=> {
    return (
        <ProfileDetailsJsx
        />
    )
}

export default ProfileDetails;
