import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { Pagination } from './pagination';
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import edit from "../../assets/images/edit2.png"

export const AdminDashboard = (props) => {
    const { doctorDetail, onDropDownValueChange, onPaginationButtonClick,onSearchFieldChange } = props;
    const history = useHistory();

    const onViewDetailClick = (id) => {
        history.push(`/doctorDetails/${id}`)
    }
    const onEditClick = (id) => {
        history.push(`/adminUpdate/${id}`)
    }

    const [data, setData] = useState([]);
    // const [temp, setTemp] = useState([]);
    const [searcValue, setSearchValue] = useState(null);
    const [activePage,setActivePage] = useState(1);

    useEffect(() => {
        setData(doctorDetail?.rows ?? []);
        // setTemp(doctorDetail?.rows ?? [])
    }, [doctorDetail])

    const onSearchValueChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        onSearchFieldChange(value);
        setActivePage(1);
        // if (value) {
        //     const filterData = temp?.filter(data => data.name.toLowerCase().includes(value.toLowerCase()))
        //     setData(filterData);
        // }
        // else {
        //     setData(doctorDetail?.rows)
        // }

    }

    const onDropDownValueChanges = (e) => {
        setSearchValue(null);
        setActivePage(1);
        onDropDownValueChange(e.target.value)
    }

    const onPaginationClick = (page) =>{
        onPaginationButtonClick(page);
        setActivePage(page);
    }

    const { t } = useTranslation();

    return (
        <div className="container officeStaffComponentOuter">
            {/* <div className="bread_crums mt-3"><Link to="/">{t("HEADER.HOME")}</Link>/ <Link to="/adminDashboard">{t("ADMINDASHBOARD.TITLE")}</Link></div> */}
            {/* <span className="print"  onClick={() => window.print()}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="skyBlue" class="bi bi-printer-fill" viewBox="0 0 16 16">
                <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
            </svg> &nbsp; {t("PRINT.TITLE")}</span> */}
         
            <div className="admin-dashboard-heading">
                <h2 className="loginHeading mt-2">
                {t("ADMINDASHBOARD.TITLE")}
            </h2>
                <div className="approval-section">
                    {/* <label>{t("ADMINDASHBOARD.APPROVALS")}</label> */}
                    <select name="approval" className="form-control" id="approval" style={{fontWeight:600}} onChange={(e) => onDropDownValueChanges(e)}>
                        <option className='select-option' value="">{t("ADMINDASHBOARD.SELECT")}</option>
                        <option className='select-option' value="regular">Doctor Registration</option>
                        <option className='select-option' value="provisional">Provisional Registration</option>
                        <option className='select-option' value="renewal">Renewal</option>
                        <option className='select-option' value="noc">NOC</option>
                        <option className='select-option' value="degreeUpgrade">Degree Upgrade</option>
                    </select>
                </div>

            </div>
            <div className='search-section'>
                <label className='filter-labels'>{t("ADMINDASHBOARD.SEARCHDOCTOR")}</label>
                <input className="form-control col-sm-6"
                    type={'text'}
                    value={searcValue ?? ''}
                    onChange={(e) => onSearchValueChange(e)}
                    style={{fontWeight:600}}
                />
            </div>
            <div className='admin-dashboard'>
            <div className="scroll_table">
            <table>
                <thead style={{background:'white'}}>
                    <th>{t("ADMINDASHBOARD.SR")}</th>
                    <th>{t("ADMINDASHBOARD.DOCTORNAME")}</th>
                    {/* <th>Provisional Number</th> */}
                    {/* <th>Registration Number</th> */}
                    <th>{t("ADMINDASHBOARD.REGISTRATIONDATE")}</th>
                    <th>{t("ADMINDASHBOARD.DETAILS")}</th>
                    <th>{t("ADMINDASHBOARD.ACTION")}</th>
                </thead>
                <tbody>
                    {
                        data?.map((data, index) => {
                            return <tr>
                                <td>{((activePage-1)*10 + 1 + index)}</td>
                                <td>{data.name}</td>
                                {/* <td>{data.provisional_number}</td> */}
                                {/* <td>{data.registration_number}</td> */}
                                <td>{moment(data.registration_date).format("DD-MM-YYYY")}</td>
                                <td><button onClick={() => onViewDetailClick(data.id)}>{t("ADMINDASHBOARD.VIEWDEATILS")}</button></td>
                                <td><button onClick={() => onEditClick(data.id)}>{t("ADMINDASHBOARD.EDIT")} <img className="edit_image" src={edit} alt=""></img></button></td>
                            </tr>
                        })

                    }
                    {
                        data?.length < 1 && <tr><td colSpan={6} style={{ textAlign: 'center',fontWeight:600 }}>{t("ADMINDASHBOARD.NORECORD")}</td></tr>
                    }

                </tbody>
            </table>
            </div>
            </div>
            {
                <Pagination limit={10} totalCount={doctorDetail?.count ?? 0} onPaginationButtonClick={(page) => onPaginationClick(page)} />
            }
        </div>
    )
}