import SystemEnvironemntJsx from "./systemEnvironment.jsx";

function SystemEnvironemnt(){

    return (
      <SystemEnvironemntJsx
      />
    );
  }

export default SystemEnvironemnt;