import ViewAlbumJsx from "./viewAlbum.jsx";

function ViewImage(){

    return (
      <ViewAlbumJsx
      />
    );
  }

export default ViewImage;