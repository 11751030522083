import React from 'react';
import DashboardJsx from "./dashboardJSX.jsx";

const Dashboard = () => {
    return (
        <div>
            <DashboardJsx />
        </div>)
}

export default Dashboard;
