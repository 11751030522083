import UploadPressImageJsx from "./uploadPressImage.jsx";

function UploadPressImage(){

    return (
      <UploadPressImageJsx
      />
    );
  }

export default UploadPressImage;