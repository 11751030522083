import React from 'react';
import logo from '../../assets/images/MSVCLOGO.png';
import map from '../../assets/images/map.png';

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


export default function ContactUsJsx(props) {
    const { t } = useTranslation();
    let { contactUsdata } = props;
    return (
        contactUsdata.map((data) => (
            <>
                <div className="container">
                    <div className="bread_crums mt-3"><Link to="/">{t("HEADER.HOME")}</Link>/ <Link to="/contactUs">{t("HEADER.CONTACTUS")}</Link></div>
                    <div className="heading">{t("HEADER.CONTACTUS")}</div>
                </div>

                <div className="contact">

                    <div className="container">


                        <div className="row">
                            <div className="col-md-5">
                                <div className="contact_address" style={{marginBottom:'20px'}}>
                                    <img src={logo} alt="logo" className="logo_msvc" />
                                    <div className="address_heading">{t("CONTACTUS.ADDRESSHEADING")} <span className="address_detail">{t(`${data.address}`)}</span></div>
                                    <div className="address_heading">{t("CONTACTUS.PHONEHEADING")} <span className="address_detail">{t(`${data.phone}`)}/{t(`${data.fax}`)}</span></div>
                                    {/* <div className="address_heading">{t("CONTACTUS.FAXHEADING")} <span className="address_detail">{t(`${data.fax}`)}</span></div> */}
                                    <div className="address_heading">{t("CONTACTUS.EMAILHEADING")} <span className="address_detail">{t(`${data.email}`)}</span></div>


                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="contact_location">
                                    {/* <iframe width="375" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.co.in/maps/ms?msid=213607560235521229262.0004e409b702630ba63f8&amp;msa=0&amp;ie=UTF8&amp;ll=21.158247,79.071849&amp;spn=0,0&amp;t=m&amp;iwloc=0004e409c9e4e6ccf2b8b&amp;output=embed"></iframe> */}

                                    <div className="contact_bottom">
                                        <a href="https://www.google.com/maps/place/Udyog+Bhawan,+Nagpur/@21.1578393,79.0701431,15z/data=!4m5!3m4!1s0x0:0x32e8e1964809a9d8!8m2!3d21.1578393!4d79.0701431"><img className="map"  alt ="mao" src={map}></img></a>


                                        <p className="link_web">{t("CONTACTUS.VIEW")}<span className="link"> {t("CONTACTUS.MAHARASHTRA_STATE_VETERINARY_COUNCIL")}</span> {t("CONTACTUS.IN_LARGER_MAP")}</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
        )
    )
}
