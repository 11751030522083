import React, { useState, useEffect } from 'react';
// import { useHistory } from "react-router";
import { Pagination } from '../admin/pagination';
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

import DatePicker3 from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

export const MasterDashboard = (props) => {

    const { doctorDetail } = props;
   

    return (
        <div className="container officeStaffComponentOuter">
            
           


               
            <div className='admin-dashboard'>
                <div className="scroll_table">
                    <table>
                        <thead style={{ background: 'white' }}>
                            {/* <th className="table_head">{t("MASTERDASHBOARD.SR")}</th>
                        <th className="table_head">{t("MASTERDASHBOARD.PR")}</th>
                        <th className="table_head">{t("MASTERDASHBOARD.DOCTORNAME")}</th>
                        <th className="table_head">{t("MASTERDASHBOARD.REGISTRATIONDATE")}</th>
                        <th className="table_head">{t("MASTERDASHBOARD.DOB")}</th>
                        <th className="table_head">{t("MASTERDASHBOARD.ADDRESS")}</th>
                        <th className="table_head">{t("MASTERDASHBOARD.DUEDATE")}</th>
                        <th className="table_head"><>{t("MASTERDASHBOARD.STATUS")}
                        <div>
                            <select style={{fontWeight:600}} onChange={onStatusDropDownClick}>
                                <option className='select-option' value='verified'>verified</option>    
                                <option className='select-option' value='pending'>pending</option>    
                                <option className='select-option' value='rejected'>rejected</option>    
                                <option className='select-option' value='re-submitted'>re-submitted</option>    
                            </select>    
                        </div></></th>
                        <th className="table_head">{t("MASTERDASHBOARD.DETAILS")}</th> */}

                            <th className="table_head">Username</th>
                            <th className="table_head">Time Stamp</th>
                            <th className="table_head">IP Address</th>
                            <th className="table_head">Login/Logout</th>

                        </thead>
                        <tbody>
                            {
                                doctorDetail?.map((doctorDetail, index) => {
                                    return <tr>
                                        <td width="10%">{doctorDetail?.doctor?.name}</td>
                                        <td width="10%">{doctorDetail?.createdAt ? moment(doctorDetail?.createdAt).format("DD-MM-YYYY , HH:mm:ss") : ''}</td>
                                        <td width="10%">{doctorDetail?.ip_address}</td>
                                        <td width="10%">{doctorDetail?.status}</td>

                                        {/* <td width="7%">{data?.registration_number}</td>
                                        <td width="7%">{data?.provisional_number ? `PR${data.provisional_number}` : ""}</td>
                                        <td width="10%">{data?.name}</td>
                                        <td width="10%">{data?.registration_date ? moment(data?.registration_date).format("DD-MM-YYYY") : ''}</td>
                                        <td width="10%">{data?.dob ? moment(data?.dob).format("DD-MM-YYYY") : ''}</td>
                                        <td width="10%">{data?.contact_detail_doctor?.permanent_address}</td>
                                        <td width="10%">{data?.due_date ? moment(data?.due_date).format("DD-MM-YYYY") : ''}</td>
                                        <td width="10%">{data?.account_verified ? <button type="button" class={data.status === 'verified' ? `btn btn-primary` : "btn btn-danger"}>{data?.status}</button> : <button type="button" class={data.status === 'verified' ? `btn btn-primary` : "btn btn-danger"}>{data?.status}</button>}</td>
                                        <td width="10%"><a href='' onClick={() => onViewDetailClick(data.id)} >{t("MASTERDASHBOARD.VIEWDEATILS")}</a></td> */}
                                    </tr>
                                    // <button type="button" class="btn btn-primary">Primary</button>

                                })

                            }
                           

                        </tbody>
                    </table>
                </div>
            </div>
           
        </div>
    )
}