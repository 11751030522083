import React from 'react'
import AboutBannerJsx from './aboutBanner.jsx'
export const AboutBanner = () => {
    return (
        <>
        <AboutBannerJsx />
        </>
    )
}
export default AboutBanner
