import {React } from "react";
import "./home.css";
import AboutBanner from "../aboutBanner/aboutBanner.js";
import BannerData from "../bannerData/bannerData.js";
import ListView from "../listView/listView.js";
import PhotoCard from "../photoCard/photoCard.js";
import PhotoGallery from "../photoGallery/photoGallery.js";
// import { useTranslation } from "react-i18next";
// import themeContext from "../../context/themeContext";
const HomeJsx = () => {
  
  // const { t } = useTranslation();
  // const {mode} = useContext(themeContext)
  
  return (
    <div className="aligningTheSite">
      <BannerData />
      <AboutBanner />
      <div className="listViewPhotoCard">
           <ListView />
           <div>
            <PhotoCard /> 
           </div>
      </div>
      <PhotoGallery />
    </div>


  );
};

export default HomeJsx;
