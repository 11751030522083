import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { Pagination } from '../admin/pagination';
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

import DatePicker3 from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

export const MasterDashboard = (props) => {

    const { doctorDetail, onDropDownValueChange, onPaginationButtonClick, onSearchFieldChange } = props;
    const history = useHistory();

    const onViewDetailClick = (id) => {
        history.push(`/doctorDetails/${id}`)
    }

    const [data, setData] = useState([]);
    // const [temp, setTemp] = useState([]);
    const [searcValue, setSearchValue] = useState('');
    // const [doctorType, setDoctorType] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [statusFilter,setStatusFilter] = useState('verified')

    useEffect(() => {
        setData(doctorDetail?.rows ?? []);
        // setTemp(doctorDetail?.rows ?? [])
    }, [doctorDetail])

    const onSearchValueChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        onSearchFieldChange({startDate:formatInputValue(startDate),endDate:formatInputValue(endDate),searcValue:value,statusFilter})
    }

    const onDropDownValueChanges = (e) => {
        setSearchValue('');
        onDropDownValueChange(e.target.value)
        // setDoctorType(e.target.value)
    }

    const formatInputValue = (date) => {
        if (!date) return '';
        return `${date.day}/${date.month}/${date.year}`;
      };
    
    const onStatusDropDownClick = (e) =>{
        setStatusFilter(e.target.value);
        onSearchFieldChange({startDate:'',endDate:'',searcValue,statusFilter:e.target.value})
    }

    const onResetButtonClick = () =>{
        setStartDate(null);
        setEndDate(null)
        onSearchFieldChange({startDate:'',endDate:'',searcValue,statusFilter})
      }
    
      const onSearchButtonClick = () =>{
        onSearchFieldChange({startDate:formatInputValue(startDate),endDate:formatInputValue(endDate),searcValue,statusFilter})
      }

    const { t } = useTranslation();

    return (
        <div className="container officeStaffComponentOuter">
            {/* <div className="bread_crums mt-3"><Link to="/">{t("HEADER.HOME")}</Link>/ <Link to="/masterDashboard">{t("MASTERDASHBOARD.TITLE")}</Link></div> */}
            {/* <span className="print"  onClick={() => window.print()}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="skyBlue" class="bi bi-printer-fill" viewBox="0 0 16 16">
            <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
            <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
        </svg> &nbsp; {t("PRINT.TITLE")}</span> */}

            <div className="admin-dashboard-heading">
                <h2 className="loginHeading mt-2">
                    {t("MASTERDASHBOARD.TITLE")}
                </h2>
                <div className="approval-section">
                    <select className="form-control" name="approval" id="approval" style={{fontWeight:600}} onChange={(e) => onDropDownValueChanges(e)}>
                        <option className='select-option' value="">{t("ADMINDASHBOARD.SELECT")}</option>
                        <option className='select-option' value="regular">Registration Doctor Details</option>
                        <option className='select-option' value="provisional">Provisional Doctor Details</option>
                    </select>
                </div>

            </div>

            <div className='search-filters' style={{marginBottom:'20px'}}>
          <div>
            <div className='filter-labels'>{'Start Date'}</div>
            <DatePicker3 className="form-control" name="startDate"
              value={startDate ?? null}
              onChange={(date) => { setStartDate(date) }}
              inputPlaceholder="dd/mm/yyyy"
              shouldHighlightWeekends
              formatInputText={() => formatInputValue(startDate)}
            />
          </div>
          <div className='end-date-filter'>
            <div className='filter-labels'>{'End Date'}</div>
            <DatePicker3 className="form-control" name="endDate"
              value={endDate ?? null}
              onChange={(date) => { setEndDate(date) }}
              inputPlaceholder="dd/mm/yyyy"
              shouldHighlightWeekends
              formatInputText={() => formatInputValue(endDate)}
            />
          </div>

          <button 
            className='reset-button'
            onClick={onResetButtonClick}
          >
            Reset
          </button>
          <button 
            className='search-button'
            onClick={onSearchButtonClick}
            disabled={!(startDate && endDate)}
          >
            Search
          </button>

          <div className='keyword-based-filter'>
            <div className='filter-labels'>{t("ADMINDASHBOARD.SEARCHDOCTOR")}</div>
                <input className="form-control"
                    type={'text'}
                    value={searcValue ?? ''}
                    onChange={(e) => onSearchValueChange(e)}
                    style={{fontWeight:600}}
                />
          </div>

        </div>
            <div className='admin-dashboard'>
            <div className="scroll_table">
                <table>
                    <thead style={{ background: 'white' }}>
                        <th className="table_head">{t("MASTERDASHBOARD.SR")}</th>
                        <th className="table_head">{t("MASTERDASHBOARD.PR")}</th>
                        <th className="table_head">{t("MASTERDASHBOARD.DOCTORNAME")}</th>
                        <th className="table_head">{t("MASTERDASHBOARD.REGISTRATIONDATE")}</th>
                        <th className="table_head">{t("MASTERDASHBOARD.DOB")}</th>
                        <th className="table_head">{t("MASTERDASHBOARD.ADDRESS")}</th>
                        <th className="table_head">{t("MASTERDASHBOARD.DUEDATE")}</th>
                        <th className="table_head"><>{t("MASTERDASHBOARD.STATUS")}
                        <div>
                            <select style={{fontWeight:600}} onChange={onStatusDropDownClick}>
                                <option className='select-option' value='verified'>verified</option>    
                                <option className='select-option' value='pending'>pending</option>    
                                <option className='select-option' value='rejected'>rejected</option>    
                                <option className='select-option' value='re-submitted'>re-submitted</option>    
                            </select>    
                        </div></></th>
                        <th className="table_head">{t("MASTERDASHBOARD.DETAILS")}</th>
                    </thead>
                    <tbody>
                        {
                            data?.map((data, index) => {
                                return <tr>
                                    <td width="7%">{data?.registration_number}</td>
                                    <td width="7%">{data?.provisional_number? `PR${data.provisional_number}`:""}</td>
                                    <td width="10%">{data?.name}</td>
                                    <td width="10%">{data?.registration_date ? moment(data?.registration_date).format("DD-MM-YYYY") : ''}</td>
                                    <td width="10%">{data?.dob ? moment(data?.dob).format("DD-MM-YYYY") : ''}</td>
                                    <td width="10%">{data?.contact_detail_doctor?.permanent_address}</td>
                                    <td width="10%">{data?.due_date ? moment(data?.due_date).format("DD-MM-YYYY") : ''}</td>
                                    <td width="10%">{data?.account_verified ? <button type="button" class={data.status === 'verified'?`btn btn-primary`:"btn btn-danger"}>{data?.status}</button> : <button type="button" class={data.status === 'verified'?`btn btn-primary`:"btn btn-danger"}>{data?.status}</button> }</td>
                                    <td width="10%"><a href='' onClick={() => onViewDetailClick(data.id)} >{t("MASTERDASHBOARD.VIEWDEATILS")}</a></td>
                                </tr>
                                // <button type="button" class="btn btn-primary">Primary</button>

                            })

                        }
                        {
                            data?.length < 1 && <tr><td colSpan={9} style={{ textAlign: 'center',fontWeight:600 }}>{t("ADMINDASHBOARD.NORECORD")}</td></tr>
                        }

                    </tbody>
                </table>
                </div>
            </div>
            {
                <Pagination limit={10} totalCount={doctorDetail?.count ?? 0} onPaginationButtonClick={(page) => onPaginationButtonClick(page)} />
            }
        </div>
    )
}