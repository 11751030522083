import React, { Component } from 'react';
import { MasterDashboard } from './masterDashboard';
import { baseURL } from '../../constants/applicationConstants';
import axios from 'axios';

export default class Master extends Component {

    constructor(props) {
        super(props);
        this.state = {
            doctorDetail: null,
            dropDownValue: null,
            searchValue: '',
            startDate: '',
            endDate: '',
            page: 1,
            statusDropdown:'verified'
        }
    }

    onDropDownValueChange = (value) => {
        this.setState({ dropDownValue: value }, () => this.getDoctor());
    }

    getProperDateFormat = (date) =>{
        if(date){
            const data = date.split('/');
            return `${data[1]}/${data[0]}/${data[2]}`
        }
            return ''
      }

    getDoctor = () => {
        axios.get(`${baseURL}getMasterDoctors?page=${this.state.page}&limit=10&type=${this.state.dropDownValue}&start_date=${this.state.startDate}&end_date=${this.state.endDate}&name=${this.state.searchValue}&status=${this.state.statusDropdown}`, {
            headers: {
                'access-token': JSON.parse(sessionStorage.getItem('authToken')),
                'role': sessionStorage.getItem('role')
            }
        }).then((res) => {
            this.setState({ doctorDetail: res.data.result });
        })
    }

    onPaginationButtonClick = (page) => {
        this.setState({ page: page }, () => this.getDoctor());
    }

    onSearchFieldChange = (data) => {
        const { startDate, endDate, searcValue,statusFilter } = data;
        this.setState({ startDate: this.getProperDateFormat(startDate), endDate: this.getProperDateFormat(endDate), searchValue: searcValue, statusDropdown:statusFilter}, () => this.getDoctor())
    }

    render() {
        return (
            <div>
                <MasterDashboard
                    doctorDetail={this.state.doctorDetail}
                    onDropDownValueChange={this.onDropDownValueChange}
                    onPaginationButtonClick={this.onPaginationButtonClick}
                    onSearchFieldChange={this.onSearchFieldChange}
                />
            </div>
        )
    }
}