import React from 'react';
import HomeJsx from './home.jsx'
const Home =()=> {
    return (
        <HomeJsx
        />
    )
}

export default Home
