import UploadPressAlbumJsx from "./uploadPressAlbum.jsx";

function UploadImage(){

    return (
      <UploadPressAlbumJsx
      />
    );
  }

export default UploadImage;