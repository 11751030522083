import React from "react";
import "./accessibilityStatement.css";
import accessibilityStatement from "./accessibilityStatement.json";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const AccessibilityStatementJsx = () => {
  const { t } = useTranslation();

  return (<>
    <div className="container bread_crums mt-3"><Link to="/">{t("HEADER.HOME")}</Link>/ <Link to="/accessibilityStatement"> {t(`${accessibilityStatement.MAIN_HEAD}`)}</Link></div>
    <div className="container accessibilityStatementComponentOuter">
      <h2 className="accessibilityStatementHeadline">
        {t(`${accessibilityStatement.MAIN_HEAD}`)}
      </h2>
      <br/>
      <div className="accessibilityStatementmainFlexDiv">
        <p>
          {t(`${accessibilityStatement.P_1}`)}
        </p>
        <p>

          {t(`${accessibilityStatement.P_2}`)}
        </p>

        <p>

          {t(`${accessibilityStatement.P_3}`)}
        </p>



        <h4>

          {t(`${accessibilityStatement.SUB_HEAD_1}`)}
        </h4>
        <p>
          <b>

            {t(`${accessibilityStatement.SUB_HEAD_1_MAIN_1}`)}
          </b>

          {t(`${accessibilityStatement.SUB_HEAD_1_PARA_1}`)}
        </p>
        <p>
          <b>

            {t(`${accessibilityStatement.SUB_HEAD_1_MAIN_2}`)}
          </b>

          {t(`${accessibilityStatement.SUB_HEAD_1_PARA_2}`)}
        </p>
        <p>
          <b>

            {t(`${accessibilityStatement.SUB_HEAD_1_MAIN_3}`)}
          </b>

          {t(`${accessibilityStatement.SUB_HEAD_1_PARA_3}`)}
        </p>
        <p>
          <b>

            {t(`${accessibilityStatement.SUB_HEAD_1_MAIN_4}`)}
          </b>

          {t(`${accessibilityStatement.SUB_HEAD_1_PARA_4}`)}
        </p>
        <p>
          <b>

            {t(`${accessibilityStatement.SUB_HEAD_1_MAIN_5}`)}
          </b>

          {t(`${accessibilityStatement.SUB_HEAD_1_PARA_5}`)}
        </p>
        <br/>
        <p>
          <b>

            {t(`${accessibilityStatement.SUB_HEAD_1_MAIN_6}`)}
          </b>

          {t(`${accessibilityStatement.SUB_HEAD_1_PARA_6}`)}
        </p>
        <p>
          <b>

            {t(`${accessibilityStatement.SUB_HEAD_1_MAIN_7}`)}
          </b>

          {t(`${accessibilityStatement.SUB_HEAD_1_PARA_7}`)}
        </p>
        <p>
          <b>

            {t(`${accessibilityStatement.SUB_HEAD_1_MAIN_8}`)}
          </b>

          {t(`${accessibilityStatement.SUB_HEAD_1_PARA_8}`)}
        </p>
        <p>
          <b>

            {t(`${accessibilityStatement.SUB_HEAD_1_MAIN_9}`)}
          </b>

          {t(`${accessibilityStatement.SUB_HEAD_1_PARA_9}`)}
        </p>
        <p>
          <b>

            {t(`${accessibilityStatement.SUB_HEAD_1_MAIN_10}`)}
          </b>

          {t(`${accessibilityStatement.SUB_HEAD_1_PARA_10}`)}
        </p>
        <p>
          <b>

            {t(`${accessibilityStatement.SUB_HEAD_1_MAIN_11}`)}
          </b>

          {t(`${accessibilityStatement.SUB_HEAD_1_PARA_11}`)}
        </p>
        <p>
          <b>

            {t(`${accessibilityStatement.SUB_HEAD_1_MAIN_12}`)}
          </b>

          {t(`${accessibilityStatement.SUB_HEAD_1_PARA_12}`)}
        </p>
        <p>
          <b>

            {t(`${accessibilityStatement.SUB_HEAD_1_MAIN_13}`)}
          </b>

          {t(`${accessibilityStatement.SUB_HEAD_1_PARA_13}`)}
        </p>
        <h4>

          {t(`${accessibilityStatement.SUB_HEAD_2}`)}
        </h4>
        <p>

          {t(`${accessibilityStatement.SUB_HEAD_2_PARA}`)}
        </p>
        <h4>

          {t(`${accessibilityStatement.SUB_HEAD_3}`)}
        </h4>
        <p>

          {t(`${accessibilityStatement.SUB_HEAD_3_PARA_1}`)}
        </p>

        <p>

          {t(`${accessibilityStatement.SUB_HEAD_3_PARA_2}`)}
        </p>

        <p>

          {t(`${accessibilityStatement.SUB_HEAD_3_PARA_3}`)}
        </p>

        <p>

          {t(`${accessibilityStatement.SUB_HEAD_3_PARA_4}`)}
        </p>

      </div>
    </div>
</>
  );
};

export default AccessibilityStatementJsx;
