import React,{ Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import "./assets/font/Gilroy-Light.ttf";
import './i18n';
import IdleTimeout from '../src/components/idleTimer.js';

// import reportWebVitals from './reportWebVitals';
// import {Provider} from 'react-redux';
// import store from './store';
const loadingMarkup=(
  <h2>Loading..</h2>
)

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
  <React.StrictMode>
    {/* <Provider store={store}> */}
  
      <App />
      <IdleTimeout/>
    {/* </Provider> */}
  </React.StrictMode>
  </Suspense>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
