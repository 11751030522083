import React from 'react';
import { useTranslation } from "react-i18next";
import "./disclaimer.css";
import { Link } from "react-router-dom";

export default function DisclaimerJsx(props) {
    const { t } = useTranslation();
  let { disclaimerdata } = props;
    return (
        disclaimerdata.map((data) => (
        <div>
            <div className="container">
            <div  className="bread_crums mt-3"><Link to="/">{t("HEADER.HOME")}</Link>/ <Link to="/disclaimer">{t(`${data.HEADING_1}`)}</Link></div>
                <div className="heading_main">{t(`${data.HEADING_1}`)}</div>
                <div className="heading">{t(`${data.DESCRIPTION_1}`)}</div>
                <div className="description">{t(`${data.DESCRIPTION_2}`)}</div>
                <div className="heading">{t(`${data.HEADING_3}`)}</div>
                <div className="description">{t(`${data.DESCRIPTION_3}`)}</div>
                <div className="sub_heading">{t(`${data.HEADING_4}`)}</div>
                <div className="description">{t(`${data.DESCRIPTION_4}`)}</div>
                <div className="sub_heading">{t(`${data.HEADING_5}`)}</div>
                <div className="description">{t(`${data.DESCRIPTION_5}`)}</div>
                <div className="sub_heading">{t(`${data.HEADING_6}`)}</div>
                <div className="description">{t(`${data.DESCRIPTION_6}`)}</div>
                <div className="sub_heading">{t(`${data.HEADING_7}`)}</div>
                {/* <div className="link">{t(`${data.DESCRIPTION_7}`)}</div> */}
                <div className="sub_heading">{t(`${data.HEADING_8}`)}</div>
                {/* <div className="link">{t(`${data.DESCRIPTION_8}`)}</div> */}
                <div className="sub_heading">{t(`${data.HEADING_9}`)}</div>
                {/* <div className="link">{t(`${data.DESCRIPTION_9}`)}</div> */}
                <div className="sub_heading">{t(`${data.HEADING_10}`)}</div>
                {/* <div className="link">{t(`${data.DESCRIPTION_10}`)}</div> */}

            </div>

        </div>
    )
        )
    )

}
